import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { userHasAccessTokenSelector } from '@redux/oauth/oauthSelectors';
import { RouteNames } from 'utils/navigationUtils';

const RedirectIfLoggedInRoute = ({ component: Component, ...rest }: any) => {
  const isLoggedIn = useSelector(userHasAccessTokenSelector);
  const redirectUrl = RouteNames.HOME;

  // Check if the user is authenticated
  if (isLoggedIn) {
    // If authenticated, redirect to the home page
    return <Navigate to={redirectUrl} replace />;
  }

  // If authenticated, render the child routes
  return <Outlet />;
};

export default RedirectIfLoggedInRoute;
