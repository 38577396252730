import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { patchCancelTaskThunk } from '@redux/tasks/tasksThunks';
import { ConfirmationDialogV2 } from 'components/ConfirmationDialog/ConfirmationDialogV2';
import { WarningCircleIcon } from 'components/Icons';

export type DeletePatientProps = {
  visible: boolean;
  onClosePress: () => void;
  onConfirmPress?: () => void;
  taskId: string;
};

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & DeletePatientProps;

const CancelTaskModal: React.FC<Props> = ({ taskId, visible, onConfirmPress, onClosePress, cancelTaskCallback }) => {
  const onConfirm = async () => {
    onClosePress();
    try {
      await cancelTaskCallback(taskId);
      onConfirmPress?.();
    } catch (err) {
      console.log('Failed to cancel task', err);
    }
  };

  return (
    <ConfirmationDialogV2
      open={visible}
      icon={WarningCircleIcon}
      title="Cancel task"
      description="You sure you want to cancel this task? This action cannot be undone."
      proceedEvent={onConfirm}
      proceedEventText="Cancel task"
      closeEvent={onClosePress}
      closeEventText="Keep task"
    />
  );
};

const connectRedux = connect(() => ({}), {
  cancelTaskCallback: patchCancelTaskThunk
});

export default compose(connectRedux)(CancelTaskModal);
