import classNames from 'classnames';
import React, { memo } from 'react';
import { Text } from 'components/Typography';
import { timeAgoString } from 'utils/time';
import { getInitials } from 'utils/util';
import style from './ConversationsArea.scss';
import { ConversationsAreaProps } from './ConversationsArea.types';

export const ConversationsArea: React.FC<ConversationsAreaProps> = memo(({ inboxMessages, selectedInboxMessage, onInboxMessageSelected: onConversationSelected }) => {
  return (
    <>
      <Text variant="2xl" style={{ marginLeft: '32px', marginTop: '32px' }}>
        Inbox
      </Text>
      <div className={style.conversationsHolder}>
        {inboxMessages.length ? (
          inboxMessages.map((item, index) => (
            <div
              key={index}
              className={classNames(style.conversationItem, { [style.selectedConversation]: item.patientId == selectedInboxMessage })}
              onClick={() => onConversationSelected(item.patientId)}
            >
              <div className={style.avatar}>
                <Text variant="lg" weight={500} className={style.initials}>
                  {getInitials(item.patientFullName)}
                </Text>
              </div>
              <div className={style.conversationLabel}>
                <Text className={classNames(style.conversationLabelText, style.personName)} variant="md">
                  {item.patientFullName}
                </Text>
                <Text className={style.conversationLabelText} variant="md">
                  {item.messageType}
                </Text>
              </div>
              <Text className={style.conversationTimeText} variant="sm">
                {timeAgoString(item.lastCreated)}
              </Text>
            </div>
          ))
        ) : (
          <Text className={style.noRequestsText}>You have no requests.</Text>
        )}
      </div>
    </>
  );
});
