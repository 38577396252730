import React, { Dispatch, SetStateAction } from 'react';
import { DeviceContentPage, ProgramContent, WorkflowTaskTemplate } from '@redux/content/contentTypes';
import { RankType } from 'utils/content';
import { RankedContentIcons } from '../RankedContentIcons/RankedContentIcons';

interface Props {
  rankMapDispatcher: [Map<number, string>, Dispatch<SetStateAction<Map<number, string>>>];
  expiredSetDispatcher: [Set<string>, Dispatch<SetStateAction<Set<string>>>];
  content: ProgramContent | DeviceContentPage | WorkflowTaskTemplate;
  newContent?: Nullable<ProgramContent | DeviceContentPage | WorkflowTaskTemplate>;
  rankFieldName: RankType;
  fontSize: 'small' | 'inherit';
  cssClass: 'tileIcon' | 'subTileIcon';
}

export const RankedContentActions: React.FC<Props> = ({ rankMapDispatcher, expiredSetDispatcher, content, newContent, rankFieldName, fontSize, cssClass }) => {
  const findMaxPageRank = (): number => {
    let max: number = 0;
    rankMapDispatcher[0].forEach((value, key) => {
      max = Math.max(max, key);
    });
    return max;
  };

  const expireContent = <T extends ProgramContent | DeviceContentPage | WorkflowTaskTemplate>(
    content: T,
    rankMapDispatcher: [Map<number, string>, Dispatch<SetStateAction<Map<number, string>>>],
    expiredSetDispatcher: [Set<string>, Dispatch<SetStateAction<Set<string>>>]
  ): void => {
    const newMap: Map<number, string> = new Map<number, string>(rankMapDispatcher[0]);
    let deletedStep: Nullable<number>;
    newMap.forEach((programContentId, programStep) => {
      if (programContentId === content.id) {
        deletedStep = programStep;
      }
    });
    if (deletedStep) {
      newMap.delete(deletedStep);
    }

    const newSet: Set<string> = new Set<string>(expiredSetDispatcher[0]);
    newSet.add(content?.id || '');
    expiredSetDispatcher[1](() => newSet);

    const ranksToUpdate: number[] = [];
    newMap.forEach((id, rank) => {
      if (deletedStep && rank > deletedStep) {
        ranksToUpdate.push(rank);
      }
    });
    ranksToUpdate.sort((a, b) => a - b);
    ranksToUpdate.map((rank) => {
      const id: Nullable<string> = newMap.get(rank);
      if (id) {
        newMap.delete(rank);
        newMap.set(rank - 1, id);
      }
    });
    rankMapDispatcher[1](() => newMap);
  };

  const movePageUp = <T extends ProgramContent | DeviceContentPage | WorkflowTaskTemplate>(
    content: T,
    rankMapDispatcher: [Map<number, string>, Dispatch<SetStateAction<Map<number, string>>>],
    rankFieldName: string
  ): void => {
    movePage(content, (content[rankFieldName] || 0) - 1, rankMapDispatcher, rankFieldName);
  };

  const movePageDown = <T extends ProgramContent | DeviceContentPage | WorkflowTaskTemplate>(
    content: T,
    rankMapDispatcher: [Map<number, string>, Dispatch<SetStateAction<Map<number, string>>>],
    rankFieldName: string
  ): void => {
    movePage(content, (content[rankFieldName] || 0) + 1, rankMapDispatcher, rankFieldName);
  };

  const movePage = <T extends ProgramContent | DeviceContentPage | WorkflowTaskTemplate>(
    content: T,
    newRank: number,
    rankMapDispatcher: [Map<number, string>, Dispatch<SetStateAction<Map<number, string>>>],
    rankFieldName: string
  ): void => {
    const currentRank: number = content[rankFieldName] || 0;
    const newMap: Map<number, string> = new Map<number, string>(rankMapDispatcher[0]);
    newMap.delete(currentRank);
    const otherPageId: Nullable<string> = newMap.get(newRank);
    if (otherPageId) {
      newMap.set(currentRank, otherPageId);
    }
    newMap.set(newRank, content.id || '');
    rankMapDispatcher[1](newMap);
  };

  return (
    <RankedContentIcons
      remove={() => expireContent(content, rankMapDispatcher, expiredSetDispatcher)}
      moveUp={() => movePageUp(content, rankMapDispatcher, rankFieldName)}
      moveDown={() => movePageDown(content, rankMapDispatcher, rankFieldName)}
      content={content}
      newContent={newContent}
      rankFieldName={rankFieldName}
      maxPageRank={findMaxPageRank()}
      fontSize={fontSize}
      cssClass={cssClass}
    />
  );
};
