import { Chip } from '@material-ui/core';
import React from 'react';
import { DeviceContent, ProgramContent, ProgramSubType, subTypeList } from '@redux/content/contentTypes';
import { findProgram, normalizeEnumName } from 'utils/content';
import style from './SubTypeBar.scss';

interface Props {
  deviceContent?: Nullable<DeviceContent>;
  hideMissing?: Nullable<boolean>;
  onChipSelect?: Nullable<(programSubType: ProgramSubType) => void>;
}

export const SubTypeBar: React.FC<Props> = ({ deviceContent, hideMissing, onChipSelect }) => {
  const makeSubTypeChip = (programSubType: ProgramSubType, index: number): JSX.Element => {
    const programContent: Nullable<ProgramContent> = findProgram(programSubType, deviceContent?.programContents);
    if (!programContent && hideMissing) {
      return <React.Fragment key={index}></React.Fragment>;
    }
    if (onChipSelect) {
      return (
        <Chip
          key={index}
          className={style.subTypeChip}
          label={`${normalizeEnumName(programSubType) + (programContent ? ': ' + programContent?.programStep : '')}`}
          color={programContent ? 'primary' : 'default'}
          onClick={() => onChipSelect(programSubType)}
        />
      );
    }
    return (
      <Chip
        key={index}
        className={style.subTypeChip}
        label={`${normalizeEnumName(programSubType) + (programContent ? ': ' + programContent?.programStep : '')}`}
        color={programContent ? 'primary' : 'default'}
      />
    );
  };

  return (
    <React.Fragment>
      {subTypeList.filter((programSubType) => programSubType !== ProgramSubType.UNKNOWN).map((programSubType, index) => makeSubTypeChip(programSubType, index))}
    </React.Fragment>
  );
};
