import UaParser from 'ua-parser-js';
import { ClientAppType, ClientDeviceType, OperatingSystemType } from '@redux/clientDevice/clientDeviceTypes';
const { name, version } = require('../../package.json');

// Wrapper around UaParser and package.json to streamline
// data collection for passing to clientDevice request
export class UserAgent {
  data: any;
  appName: string;
  appVersion: string;
  appBuild: string;
  userAgent: string;
  clientAppType: ClientAppType;
  osType: OperatingSystemType;
  osVersion: string;
  browserName: string;
  browserVersion: string;
  deviceName: string;
  deviceMaker: string;
  clientDeviceType: ClientDeviceType;
  screenWidth: number;
  screenHeight: number;
  preferredLanguages: string;
  clientTimeZoneId: string;

  constructor() {
    const osTypeLookup = {
      Android: OperatingSystemType.ANDROID,
      iOS: OperatingSystemType.IOS,
      Linux: OperatingSystemType.LINUX,
      Windows: OperatingSystemType.WINDOWS,
      'Mac OS': OperatingSystemType.MACOS
    };

    const clientAppType = {
      Android: ClientAppType.ANDROID,
      iOS: ClientAppType.IOS,
      Linux: ClientAppType.WEB,
      Windows: ClientAppType.WEB,
      'Mac OS': ClientAppType.WEB
    };

    const deviceTypeLookup = {
      mobile: ClientDeviceType.PHONE,
      tablet: ClientDeviceType.TABLET
    };

    const uaParser = new UaParser();
    this.data = uaParser.getResult();

    this.appName = name;
    this.appVersion = version;
    this.appBuild = 'config.appBuild';
    this.userAgent = this.data.ua;
    this.clientAppType = ClientAppType.WEB;
    this.osType = osTypeLookup[this.data.os.name] || OperatingSystemType.UNSPECIFIC;
    this.osVersion = this.data.os.version || 'unspecific';
    this.browserName = this.data.browser.name;
    this.browserVersion = this.data.browser.version;
    this.deviceName = this.data.device.model || 'NA';
    this.deviceMaker = this.data.device.vendor || 'NA';
    this.clientDeviceType = deviceTypeLookup[this.data.device.type] || (this.data.device.type ? ClientDeviceType.UNSPECIFIC : ClientDeviceType.DESKTOP);
    this.screenWidth = screen.width * devicePixelRatio;
    this.screenHeight = screen.height * devicePixelRatio;
    this.preferredLanguages = navigator.language || navigator['userLanguage']; // IE10 does not have navigator.langthis.datage; using userLangthis.datage instead
    this.clientTimeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
