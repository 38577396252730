import React from 'react';
import assesmentIcon from 'assets/images/assesment-icon.png';
import multiNumbersIcon from 'assets/images/multi-numbers.png';
import numberIcon from 'assets/images/number-icon.png';
import openQuestionIcon from 'assets/images/open-question-icon.png';
import optionSetIcon from 'assets/images/option-set-icon.png';
import roundHalfStrikeIcon from 'assets/images/round-half-strike.png';
import style from './QuestionIcon.scss';

export type QuesitonIconType = 'openQuestion' | 'assesment' | 'yesNo' | 'number' | 'multiNumbers' | 'optionSet';

interface QuestionIconProps {
  type: QuesitonIconType;
  className?: string;
}

const questionIcon: Record<QuestionIconProps['type'], string> = {
  openQuestion: openQuestionIcon,
  assesment: assesmentIcon,
  yesNo: roundHalfStrikeIcon,
  number: numberIcon,
  multiNumbers: multiNumbersIcon,
  optionSet: optionSetIcon
};

const QuestionIcon: React.FC<QuestionIconProps> = ({ type, className }) => {
  return (
    <div className={`${style.quesitonIconType} ${style[type]} ${className}`}>
      <img src={questionIcon[type]} className={style.icon} />
    </div>
  );
};

export default QuestionIcon;
