import { Container, MuiThemeProvider } from '@material-ui/core';
import { DataGrid, GridCellEditCommitParams, GridColDef, GridRowsProp, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { BreadCrumbData, Error } from '@redux/common/types';
import { getClearDeviceResponseStatus } from '@redux/device/deviceActions';
import {
  getDeviceErrorSelector,
  getDeviceIsLoadingSelector,
  getDeviceSuccessSelector,
  getHwRevisionFirmwareMappingsSelector,
  selectDeviceProvisioningByIdSelector
} from '@redux/device/deviceSelectors';
import {
  getDeviceProvisioningOverviewThunk,
  getHwRevisionsAndFirmwareMappingsThunk,
  getSensorDeviceThunk,
  provisionDeviceThunk,
  provisioningEnvMoveOutThunk,
  provisioningEnvUpdateThunk,
  putProvisioningDeviceNumberThunk
} from '@redux/device/deviceThunks';
import { authKeyList, AuthKeyType, DeviceProvisioning, DeviceRequest, DeviceType, FirmwareType, HwRevision, HwRevisionFirmware } from '@redux/device/deviceTypes';
import { appendBreadcrumbAction } from '@redux/oauth/oauthActions';
import { getAccessTokenSelector, getBreadcrumbsSelector } from '@redux/oauth/oauthSelectors';
import { ReduxState } from '@redux/types';
import { GridToolbar } from 'components/GridToolbar/GridToolbar';
import { NavBar } from 'components/NavBar/NavBar';
import { StatusMessage } from 'components/StatusMessage/StatusMessage';
import { defaultDeviceProvisioningCrumb, defaultDeviceProvisioningCrumbs } from 'utils/breadcrumbs';
import { getActiveRecord, hasActiveRecord } from 'utils/createFrontEndError';
import { EnvType, EnvUrl, getCurrentAppEnvironment, getEnvUrlFromEnvType } from 'utils/environment';
import { defaultTheme } from 'utils/styles';
import { formatTimestamp } from 'utils/time';
import { getFirmwareTypeForHwRevision } from 'utils/util';
import { uuid16StringToBase64 } from '../../../shared/src/util/uuid';
import Config from '../../config/config';
import 'assets/styles/Table.scss';
import style from './DeviceProvisioningOverview.scss';

const UNPROVISIONED_TYPES: Set<EnvType> = new Set<EnvType>([EnvType.UNKNOWN, EnvType.LOCAL, EnvType.PROVISION]);

interface DeviceProvisioningRow {
  id: string;
  deviceId64: string;
  serialNumber: string;
  deviceNumber?: Nullable<number>;
  deviceNumberText?: Nullable<string>; // only used for quick search matching
  hwRevision: HwRevision;
  firmwareType: FirmwareType;
  versionNumber?: Nullable<string>;
  userAgent?: Nullable<string>;
  keyId: string;
  keyType: AuthKeyType;
  key: string;
  ipAddress: string;
  environmentType?: Nullable<EnvType>;
  created: string;
}

interface Props {
  deviceProvisioningById: Record<string, DeviceProvisioning>;
  breadCrumbs: BreadCrumbData[];
  requestSensorDevice: (deviceId: string, baseUrl: string, authToken: string) => Promise<any>;
  requestProvisionDevice: (device: DeviceRequest, baseUrl: string, serialNumber: string, authToken: string) => Promise<any>;
  requestProvisioningEnvMoveOut: (deviceId: string, baseUrl: string, authToken?: Nullable<string>) => Promise<any>;
  requestProvisioningEnvUpdate: (deviceId: string, baseUrl: string, environment: EnvType) => Promise<any>;
  hwRevisionFirmwareMappings: HwRevisionFirmware[];
  getHwRevisionFirmwareMappings: () => void;
  accessToken?: Nullable<string>;
  isLoading: Record<string, boolean>;
  error: Record<string, Error>;
  success: Record<string, boolean>;
  requestUpdateDeviceNumber: (deviceId: string, deviceNumber?: Nullable<number>) => void;
  appendBreadCrumbs: (breadCrumb: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => void;
  clearResponseStatus: () => void;
  getDeviceProvisioningOverview: () => void;
}

const DeviceProvisioningOverview: React.FC<Props> = ({
  deviceProvisioningById,
  breadCrumbs,
  requestSensorDevice,
  requestProvisionDevice,
  requestProvisioningEnvMoveOut,
  requestProvisioningEnvUpdate,
  hwRevisionFirmwareMappings,
  getHwRevisionFirmwareMappings,
  accessToken,
  isLoading,
  error,
  success,
  requestUpdateDeviceNumber,
  appendBreadCrumbs,
  clearResponseStatus,
  getDeviceProvisioningOverview
}) => {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [updated, setUpdated] = useState<boolean>(false);
  const [userWarning, setUserWarning] = useState<string>('');

  useEffect(() => {
    getHwRevisionFirmwareMappings();
    getDeviceProvisioningOverview();
  }, []);

  useEffect(() => {
    setRows(() => getAllRows());
  }, [deviceProvisioningById, hwRevisionFirmwareMappings]);

  useEffect(() => {
    if (updated) {
      setRows(() => getAllRows());
      setUpdated(() => false);
    }
  }, [updated]);

  const getAllRows = (): DeviceProvisioningRow[] =>
    Object.values(deviceProvisioningById).map((deviceProvisioning) => {
      const row: DeviceProvisioningRow = {
        ...deviceProvisioning,
        firmwareType: getFirmwareTypeForHwRevision(deviceProvisioning.hwRevision, hwRevisionFirmwareMappings),
        deviceId64: uuid16StringToBase64(deviceProvisioning.id),
        deviceNumberText: deviceProvisioning.deviceNumber ? `D${deviceProvisioning.deviceNumber}` : undefined
      };
      return row;
    });

  const stagingPrompt = (): string => prompt('Please provide an authentication token from staging environment') || '';
  const deviceAssignedConfirmation = (): boolean => confirm('This device is assigned to a user in its current environment. Proceed anyway?');

  const provision = async (deviceId: string, targetEnvType: EnvType) => {
    const deviceProvisioning: DeviceProvisioning = deviceProvisioningById[deviceId];

    if (!deviceProvisioning?.deviceNumber) {
      setUserWarning('Selected device does not have a valid device number');
      return;
    }

    const deviceRequest: DeviceRequest = {
      deviceId: deviceProvisioning.id,
      deviceNumber: deviceProvisioning.deviceNumber,
      hwRevision: deviceProvisioning.hwRevision,
      deviceType: DeviceType.RADAR_MAIN,
      keyId: deviceProvisioning.keyId,
      keyType: deviceProvisioning.keyType,
      publicKey: deviceProvisioning.key,
      softwareVersion: deviceProvisioning.versionNumber,
      userAgent: deviceProvisioning.userAgent
    };
    const currentAppEnvironment = getCurrentAppEnvironment();

    const useCaseProvisionNewToProdOrStaging = deviceProvisioning.environmentType === EnvType.PROVISION && currentAppEnvironment === EnvType.PROD;
    const useCaseMoveFromStagingToProd = deviceProvisioning.environmentType === EnvType.STAGING && currentAppEnvironment === EnvType.PROD;
    const useCaseMoveFromProdToStaging = deviceProvisioning.environmentType === EnvType.PROD && currentAppEnvironment === EnvType.PROD;
    const useCaseMoveBackToProvisioningFromProd =
      deviceProvisioning.environmentType == EnvType.PROD && currentAppEnvironment === EnvType.PROD && targetEnvType === EnvType.PROVISION;
    const useCaseMoveBackToProvisioningFromStaging =
      deviceProvisioning.environmentType == EnvType.STAGING && currentAppEnvironment === EnvType.PROD && targetEnvType === EnvType.PROVISION;

    const moveOutFromStaging = useCaseMoveFromStagingToProd || useCaseMoveBackToProvisioningFromStaging;
    const moveBackToProvision = useCaseMoveBackToProvisioningFromProd || useCaseMoveBackToProvisioningFromProd;

    const baseUrl = getEnvUrlFromEnvType(targetEnvType);

    let authToken;

    // If action needs authorization in staging ask for staging access Token.
    if (targetEnvType === EnvType.STAGING || moveOutFromStaging) {
      authToken = stagingPrompt();
    }

    //  if Device needs to be retired and remote command "moved" flag enabled in current device environment before move
    if (
      useCaseProvisionNewToProdOrStaging ||
      useCaseMoveFromProdToStaging ||
      useCaseMoveFromStagingToProd ||
      useCaseMoveBackToProvisioningFromProd ||
      useCaseMoveBackToProvisioningFromStaging
    ) {
      const authTokenProvision = targetEnvType === EnvType.STAGING ? authToken : undefined;
      const sensorDeviceResponse = await requestSensorDevice(deviceId, baseUrl, authTokenProvision);
      if (sensorDeviceResponse?.data && !deviceAssignedConfirmation()) {
        return;
      }
      if (!moveBackToProvision) {
        await requestProvisionDevice(deviceRequest, baseUrl, deviceProvisioning.serialNumber, authTokenProvision);
      }
      if (useCaseMoveFromProdToStaging || useCaseMoveFromStagingToProd || useCaseMoveBackToProvisioningFromProd || useCaseMoveBackToProvisioningFromStaging) {
        const moveOutUrl = moveOutFromStaging ? EnvUrl.STAGING : EnvUrl.PROD;
        const moveOutAuthToken = moveOutFromStaging ? authToken : undefined;
        await requestProvisioningEnvMoveOut(deviceProvisioning.id, moveOutUrl, moveOutAuthToken);
      }
      const currentEnvUrl = getEnvUrlFromEnvType(currentAppEnvironment);
      await requestProvisioningEnvUpdate(deviceProvisioning.id, currentEnvUrl, targetEnvType);
    } else {
      setUserWarning('Provisioning attempt does not satisfy any use case');
    }
  };

  const saveEdit = async (updateId: string, updatedDeviceNum: number): Promise<any> => {
    const deviceProvisioning: DeviceProvisioning = deviceProvisioningById[updateId];
    const deviceEnvironment: EnvType = EnvType[deviceProvisioning.environmentType];

    if (deviceProvisioning.deviceNumber && deviceEnvironment && !UNPROVISIONED_TYPES.has(deviceEnvironment)) {
      const deviceRequest: DeviceRequest = {
        deviceId: deviceProvisioning.id,
        deviceType: DeviceType.RADAR_MAIN,
        keyId: deviceProvisioning.keyId,
        keyType: deviceProvisioning.keyType,
        publicKey: deviceProvisioning.key,
        softwareVersion: deviceProvisioning.versionNumber,
        userAgent: deviceProvisioning.userAgent,
        deviceNumber: updatedDeviceNum,
        hwRevision: deviceProvisioning.hwRevision
      };
      const authToken: Nullable<string> = deviceEnvironment === EnvType.STAGING ? stagingPrompt() : undefined;
      const baseUrl = getEnvUrlFromEnvType(EnvType[deviceProvisioning.environmentType]);
      if (baseUrl && authToken) {
        await requestProvisionDevice(deviceRequest, baseUrl, deviceProvisioning.serialNumber, authToken);
      }
    }
    await requestUpdateDeviceNumber(updateId, updatedDeviceNum);
  };

  const clearResponse = (): void => {
    clearResponseStatus();
    setUserWarning(() => '');
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'UUID', width: 350 },
    { field: 'deviceId64', headerName: 'Base64 ID', width: 250 },
    { field: 'serialNumber', headerName: 'Serial #', width: 200 },
    {
      field: 'deviceNumber',
      headerName: 'Device #',
      width: 140,
      type: 'number',
      editable: true,
      valueFormatter: (params: GridValueFormatterParams) => params.row?.['deviceNumberText']
    },
    {
      field: 'environmentType',
      headerName: 'Provisioning Environment',
      width: 240,
      editable: true,
      type: 'singleSelect',
      valueOptions: [
        { value: EnvType.PROVISION, label: 'Provision' },
        { value: EnvType.STAGING, label: 'Staging' },
        { value: EnvType.PROD, label: 'Production' }
      ]
    },
    { field: 'hwRevision', headerName: 'HW Revision', width: 300 },
    { field: 'firmwareType', headerName: 'FW Type', width: 150 },
    { field: 'versionNumber', headerName: 'Version Number', width: 370 },
    { field: 'userAgent', headerName: 'User Agent', width: 830 },
    { field: 'keyId', headerName: 'Key ID', width: 120 },
    { field: 'keyType', headerName: 'Key Type', width: 140, type: 'singleSelect', valueOptions: authKeyList },
    { field: 'key', headerName: 'Key', width: 1400, disableExport: true },
    { field: 'ipAddress', headerName: 'IP Address', width: 150 },
    { field: 'created', headerName: 'Created', width: 200, type: 'dateTime', valueGetter: (params: GridValueGetterParams) => formatTimestamp(params.value as string) }
  ];

  const handleEditCommit = (params: GridCellEditCommitParams): void => {
    handleCellCommit<number>(params, 'deviceNumber', (id, val) => saveEdit(id, val));
    handleCellCommit<EnvType>(params, 'environmentType', (id, val) => provision(id, val));
  };

  const handleCellCommit = <T extends number | EnvType>(params: GridCellEditCommitParams, field: string, call: (id: string, val: T) => Promise<any>): void => {
    if (params.field === field) {
      const id: string = params.id as string;
      const value: T = params.value as T;
      if (!rows.find((x) => x?.['id'] === id && x?.[field] === value)) {
        call(id, value).finally(() => setUpdated(true));
      }
    }
  };

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={style.root}>
        <Container maxWidth={false}>
          <NavBar
            breadCrumbs={breadCrumbs}
            appendBreadCrumbs={appendBreadCrumbs}
            defaultCurrentCrumb={defaultDeviceProvisioningCrumb}
            defaultCrumbHistory={defaultDeviceProvisioningCrumbs}
          />
          <div className={style.instructions}>
            <div>Note: Provisioning can only be used in Production! Not in staging!</div>
            <div>Main use case: Provision device to production or staging.</div>
            <div>
              To provision new device: Select device in list and press assign. If provisioning to staging, go to staging environment and copy access token. App will prompt for
              access token
            </div>
          </div>
          {Config.getDeploymentEnv() !== 'production' && <div className={style.instructions}>Your access Token: {accessToken}</div>}
          <div className={style.dataGrid}>
            <DataGrid
              rows={rows}
              columns={columns}
              pagination
              disableSelectionOnClick
              disableVirtualization
              onCellEditCommit={handleEditCommit}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{ toolbar: { clientQuickSearchProps: { rows: getAllRows(), setRows } } }}
            />
          </div>
          <StatusMessage
            saveSuccessful={!hasActiveRecord(isLoading) && hasActiveRecord(success) && !hasActiveRecord(error)}
            saveUnsuccessful={(!hasActiveRecord(isLoading) && hasActiveRecord(error)) || !!userWarning}
            clearResponseStatus={clearResponse}
            errorMessage={getActiveRecord(error)?.message || userWarning}
          />
        </Container>
      </div>
    </MuiThemeProvider>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      deviceProvisioningById: selectDeviceProvisioningByIdSelector(state),
      accessToken: getAccessTokenSelector(state),
      isLoading: getDeviceIsLoadingSelector(state),
      error: getDeviceErrorSelector(state),
      success: getDeviceSuccessSelector(state),
      breadCrumbs: getBreadcrumbsSelector(state),
      hwRevisionFirmwareMappings: getHwRevisionFirmwareMappingsSelector(state)
    };
  },
  (dispatch: Function) => ({
    getDeviceProvisioningOverview: () => {
      return dispatch(getDeviceProvisioningOverviewThunk());
    },
    requestSensorDevice: (deviceId: string, baseUrl: string, authToken: string) => {
      return dispatch(getSensorDeviceThunk(deviceId, baseUrl, authToken));
    },
    requestProvisionDevice: (device: DeviceRequest, baseUrl: string, serialNumber: string, authToken: string) => {
      return dispatch(provisionDeviceThunk(device, baseUrl, serialNumber, authToken));
    },
    requestProvisioningEnvMoveOut: (deviceId: string, baseUrl: string, authToken?: Nullable<string>) => {
      return dispatch(provisioningEnvMoveOutThunk(deviceId, baseUrl, authToken));
    },
    requestProvisioningEnvUpdate: (deviceId: string, baseUrl: string, environment: EnvType) => {
      return dispatch(provisioningEnvUpdateThunk(deviceId, baseUrl, environment));
    },
    requestUpdateDeviceNumber: (deviceId: string, deviceNumber?: Nullable<number>) => {
      dispatch(putProvisioningDeviceNumberThunk(deviceId, deviceNumber));
    },
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => {
      dispatch(appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }));
    },
    clearResponseStatus: () => {
      dispatch(getClearDeviceResponseStatus());
    },
    getHwRevisionFirmwareMappings: () => {
      dispatch(getHwRevisionsAndFirmwareMappingsThunk());
    }
  })
);

export default compose(connectRedux)(DeviceProvisioningOverview) as React.ComponentType;
