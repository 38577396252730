import { Error } from '../common/types';
import { ReduxState } from '../types';
import {
  ContentState,
  DeviceContent,
  DeviceContentPage,
  DeviceContentType,
  DeviceContentTypeOrProgramContentType,
  FeedItem,
  PersonWorkflow,
  ProgramContent,
  ProgramType,
  SurveyScoreResponse,
  WorkflowTemplate
} from './contentTypes';

const getContentState = (state: ReduxState): ContentState => {
  return state.content;
};

export const isCreatingAdHocContentSelector = (state: ReduxState): boolean => {
  return getContentState(state).isCreatingAdHocContent;
};

export const getCreateAdContentErrorSelector = (state: ReduxState): Nullable<string> => {
  return getContentState(state).creatingAdHocContentError?.message;
};

export const getSurveyResponsesSelector = (state: ReduxState): SurveyScoreResponse[] => {
  return getContentState(state).surveyResponses;
};

export const getFeedItemsSelector = (state: ReduxState): Nullable<FeedItem[]> => {
  return getContentState(state).feedItems;
};

export const getDeviceContentByTypeSelector = (state: ReduxState): Record<DeviceContentType, DeviceContent[]> => {
  return getContentState(state).deviceContentByType;
};

export const getProgramContentByTypeSelector = (state: ReduxState): Record<ProgramType, ProgramContent[]> => {
  return getContentState(state).programContentByType;
};

export const getFlagByIdSelector = (state: ReduxState): Record<string, WorkflowTemplate> => {
  return getContentState(state).flagById;
};

export const getFlagsByPersonIdSelector = (state: ReduxState): Record<string, Set<string>> => {
  return getContentState(state).flagsByPersonId;
};

export const getPersonWorkflowsSelector = (state: ReduxState): PersonWorkflow[] => {
  return getContentState(state).personWorkflows;
};

export const getSelectedDeviceContentSelector = (state: ReduxState): Nullable<DeviceContent> => {
  return getContentState(state).selectedDeviceContent;
};

export const getSelectedDeviceContentPageSelector = (state: ReduxState): Nullable<DeviceContentPage> => {
  return getContentState(state).selectedDeviceContentPage;
};

export const getSaveSuccessfulSelector = (state: ReduxState): Nullable<boolean> => {
  return getContentState(state).saveSuccessful;
};

export const getContentIsLoadingSelector = (state: ReduxState): Record<string, boolean> => {
  return getContentState(state).isLoading;
};

export const getContentErrorSelector = (state: ReduxState): Record<string, Error> => {
  return getContentState(state).error;
};

export const getContentSuccessSelector = (state: ReduxState): Record<string, boolean> => {
  return getContentState(state).success;
};

export const getCopiedDeviceContentIdSelector = (state: ReduxState): Nullable<string> => {
  return getContentState(state).copiedDeviceContentId;
};

export const getSuccessMessageSelector = (state: ReduxState): Nullable<string> => {
  return getContentState(state).successMessage;
};

export const getSelectedContentType = (state: ReduxState): Nullable<DeviceContentTypeOrProgramContentType> => {
  return getContentState(state).selectedContentType;
};

export const getCarePlanDeviceContentByIdSelector = (state: ReduxState): Record<string, DeviceContent> => {
  return getContentState(state).carePlanDeviceContentById;
};
