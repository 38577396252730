import React from 'react';
import { SVGBasicType } from 'types/global';
import { Color } from 'utils/color';

export const ResponseArrowIcon = ({ width = 18, height = 21, color = '#DDDDE4' }: SVGBasicType) => (
  <svg width={width} height={height} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 1C1.5 0.723858 1.27614 0.5 1 0.5C0.723858 0.5 0.5 0.723858 0.5 1H1.5ZM17.3536 17.3536C17.5488 17.1583 17.5488 16.8417 17.3536 16.6464L14.1716 13.4645C13.9763 13.2692 13.6597 13.2692 13.4645 13.4645C13.2692 13.6597 13.2692 13.9763 13.4645 14.1716L16.2929 17L13.4645 19.8284C13.2692 20.0237 13.2692 20.3403 13.4645 20.5355C13.6597 20.7308 13.9763 20.7308 14.1716 20.5355L17.3536 17.3536ZM0.5 1V13H1.5V1H0.5ZM5 17.5H17V16.5H5V17.5ZM0.5 13C0.5 15.4853 2.51472 17.5 5 17.5V16.5C3.067 16.5 1.5 14.933 1.5 13H0.5Z"
      fill={color}
    />
  </svg>
);
