import React from 'react';
import { SVGBasicType } from 'types/global';
import { Color } from 'utils/color';

export const DeviceIcon = ({ width = 20, height = 20, color = Color.DARK_GREEN }: SVGBasicType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="5.5" y="17.501" width="8.5" height="1.5" rx="0.75" stroke={color} />
      <rect x="5.5" y="1" width="8.5" height="14.5" rx="4.25" stroke={color} />
      <rect x="7" y="2.50195" width="5.5" height="5.5" rx="2.75" stroke={color} />
    </svg>
  );
};
