import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { loginThunk } from '@redux/oauth/oauthThunks';
import { LoginPayload } from '@redux/oauth/oauthTypes';
import { ReduxState } from '@redux/types';
import { Text } from 'components/Typography';
import style from './Login.scss';

interface Props {
  login: Function;
  errorMessage: any;
  startLogin: Function;
}

const Login: React.FC<Props> = ({ startLogin }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const onChangePassword = (event) => {
    setPassword(event.target.value);
  };
  const submitLogin = () => {
    startLogin({ username: email, password });
  };

  return (
    <div className={style.credentials_container}>
      <Text variant="h5" className={style.login_header}>
        Log in
      </Text>
      <Text variant="sm" className={style.label}>
        Email
      </Text>
      <input className={style.email} type="text" placeholder="Enter your email" value={email} onChange={onChangeEmail} />
      <div className={style.password_label_container}>
        <Text variant="sm" className={style.label}>
          Password
        </Text>
        <button className={style.link_button} onClick={() => navigate('/forgot-password')}>
          Forgot?
        </button>
      </div>
      <input className={style.password} type="password" placeholder="Password" value={password} onChange={onChangePassword} />
      <button className={style.action_button} onClick={submitLogin}>
        Log in
      </button>
    </div>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {};
  },
  (dispatch: Function) => ({
    startLogin: (loginPayload: LoginPayload) => {
      dispatch(loginThunk(loginPayload));
    }
  })
);

export default compose(connectRedux)(Login);
