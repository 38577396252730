import { createReducer } from '@reduxjs/toolkit';
import { getActionStringWithoutState } from '@redux/reduxUtils';
import {
  ACTIVITY_ACTION_PREFIX,
  getActivityEventsInRangeActions,
  getFallsActions,
  getHeatMapActions,
  getPoiChangeEventsActions,
  getPoisActions,
  getSleepLogsActions,
  getSleepStageEventsActions,
  getSupertrackActions,
  getTracksActions,
  getVoiceMessageStatusActions,
  setPoisAction
} from './activityActions';
import { ActivityLoadingTags, ActivityState } from './activityTypes';

export const initialActivityState: ActivityState = {
  isLoading: {},
  error: {},
  tracks: [],
  supertrack: [],
  heatMap: { entries: [] },
  falls: {},
  pois: [],
  voiceMessageStatus: '',
  sleepLogs: [],
  sleepLogsByPersonId: {},
  success: {}
};

export default createReducer(initialActivityState, (builder) => {
  builder
    .addCase(getVoiceMessageStatusActions.success, (state, action) => {
      state.voiceMessageStatus = action.payload;
    })
    .addCase(getTracksActions.success, (state, action) => {
      state.tracks = action.payload;
    })
    .addCase(getSupertrackActions.success, (state, action) => {
      state.supertrack = action.payload;
    })
    .addCase(getHeatMapActions.success, (state, action) => {
      state.heatMap = action.payload;
    })
    .addCase(getFallsActions.success, (state, action) => {
      const deviceId = action.payload.meta.deviceId;
      delete action.payload.meta;
      const falls = action.payload;

      state.falls.byDeviceId = {};
      state.falls.byDeviceId[deviceId] = action.payload;

      state.falls.bySensorId = {};
      for (const fall of falls) {
        if (!state.falls.bySensorId[fall.sensorId]) state.falls.bySensorId[fall.sensorId] = [];
        state.falls.bySensorId[fall.sensorId].push(fall);
      }
    })
    .addCase(getPoisActions.success, (state, action) => {
      const pois = action.payload;
      for (const poi of pois) {
        poi.meta = {};
      }
      state.pois = pois;
    })
    .addCase(setPoisAction, (state, action) => {
      state.pois = action.payload;
    })
    .addCase(getSleepLogsActions.start, (state, action) => {
      state.sleepLogs = [];
    })
    .addCase(getSleepLogsActions.success, (state, action) => {
      const { personId, payload } = action.payload;
      state.sleepLogs = payload;
      state.sleepLogsByPersonId[personId] = payload;
    })
    .addCase(getSleepStageEventsActions.start, (state, action) => {
      state.kokoMapSleepStageEvents = undefined;
      state.isLoading[ActivityLoadingTags.GET_SLEEP_STAGES] = true;
    })
    .addCase(getSleepStageEventsActions.success, (state, action) => {
      state.isLoading[ActivityLoadingTags.GET_SLEEP_STAGES] = false;
      state.kokoMapSleepStageEvents = action.payload;
    })
    .addCase(getSleepStageEventsActions.fail, (state, action) => {
      state.isLoading[ActivityLoadingTags.GET_SLEEP_STAGES] = false;
    })
    .addCase(getPoiChangeEventsActions.start, (state, action) => {
      state.kokoMapSleepStageEvents = undefined;
      state.isLoading[ActivityLoadingTags.GET_POI_CHANGE_EVENTS] = true;
    })
    .addCase(getPoiChangeEventsActions.success, (state, action) => {
      state.isLoading[ActivityLoadingTags.GET_POI_CHANGE_EVENTS] = false;
      state.kokoMapPoiChangeEvents = action.payload;
    })
    .addCase(getPoiChangeEventsActions.fail, (state, action) => {
      state.isLoading[ActivityLoadingTags.GET_POI_CHANGE_EVENTS] = false;
    })
    .addCase(getActivityEventsInRangeActions.start, (state, action) => {
      state.kokoMapSleepStageEvents = undefined;
      state.isLoading[ActivityLoadingTags.GET_ACTIVITY_EVENTS_RANGE] = true;
    })
    .addCase(getActivityEventsInRangeActions.success, (state, action) => {
      state.kokoMapActivityEvents = action.payload;
      state.isLoading[ActivityLoadingTags.GET_ACTIVITY_EVENTS_RANGE] = false;
    })
    .addCase(getActivityEventsInRangeActions.fail, (state, action) => {
      state.isLoading[ActivityLoadingTags.GET_ACTIVITY_EVENTS_RANGE] = false;
    })

    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-start') && action.type.startsWith(ACTIVITY_ACTION_PREFIX),
      (state, action) => {
        const tag = getActionStringWithoutState(action.type);
        state.isLoading[tag] = true;
        state.success[tag] = false;
        delete state.error[tag]; // todo: doesn't delete
      }
    )
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-success') && action.type.startsWith(ACTIVITY_ACTION_PREFIX),
      (state, action) => {
        const tag = getActionStringWithoutState(action.type);
        state.isLoading[tag] = false;
      }
    )
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-fail') && action.type.startsWith(ACTIVITY_ACTION_PREFIX),
      (state, action: any) => {
        const tag = getActionStringWithoutState(action.type);
        delete state.isLoading[tag]; // todo: doesn't delete
        state.isLoading[tag] = false; // remove this line after fixing "delete"
        state.error[tag] = {
          message: action.payload?.message,
          status: action.payload?.status
        };
      }
    );
});
