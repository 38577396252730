import Link from '@material-ui/core/Link';
import moment from 'moment';
import React, { MouseEventHandler, useMemo, useState } from 'react';
import { deviceSleeperTypeList } from '@redux/content/contentTypes';
import {
  DeviceDataCollection,
  DeviceDiagnosticState,
  DeviceSleeperType,
  GpioButtonDiagnostics,
  HostDiagnostics,
  I2CBusDiagnostics,
  MainAppDiagnostics,
  MicDiagnostics,
  MotionInferenceDiagnostics,
  ObjectTrackingDiagnostics,
  PhoneDiagnostics,
  RadarDiagnostics,
  SensorDiagnostics
} from '@redux/device/deviceTypes';
import { CircleIndicator } from '../../components/CircleIndicator/CircleIndicator';
import Popup from '../../components/Popup/Popup';
import { SelectButton } from '../../components/SelectButton/SelectButton';
import { normalizeEnumName } from '../../utils/content';
import { formatTimestamp } from '../../utils/time';
import { getKokoMapUrl } from '../../utils/util';
import { DeviceOverviewRow } from '../DeviceOverview/DeviceOverview';
import style from './DeviceEditor.scss';

interface Props {
  updateDeviceConfig: (deviceId: string, ledFlag: boolean, micFlag: boolean) => Promise<any>;
  updateDeviceDataCollection: (deviceDataCollection: DeviceDataCollection) => Promise<any>;
  updateEsId: (personId: Nullable<string>, deviceId: string, esId?: Nullable<string>) => Promise<any>;
  updateAutoUpdate: (deviceId: string, autoUpdateDisabled: boolean) => Promise<any>;
  updateConnectivityNotification: (deviceId: string, connectivityNotification: boolean) => Promise<any>;
  row: DeviceOverviewRow;
  handleClose: MouseEventHandler;
}

export const DeviceEditor: React.FC<Props> = ({
  updateDeviceConfig,
  updateDeviceDataCollection,
  updateEsId,
  updateAutoUpdate,
  updateConnectivityNotification,
  row,
  handleClose
}) => {
  const [ledFlag, setLedFlag] = useState<boolean>(row.ledsEnabled);
  const [micFlag, setMicFlag] = useState<boolean>(row.micEnabled);
  const [fitbitId, setFitbitId] = useState<Nullable<string>>(row.fitbitId);
  const [kobeId, setKobeId] = useState<Nullable<string>>(row.kobeId);
  const [fitbitAuthToken, setFitbitAuthToken] = useState<Nullable<string>>(row.fitbitAuthToken);
  const [museId, setMuseId] = useState<Nullable<string>>(row.museId);
  const [hexoId, setHexoId] = useState<Nullable<string>>(row.hexoId);
  const [deviceSleeperType, setDeviceSleeperType] = useState<Nullable<DeviceSleeperType>>(row.deviceSleeperType);
  const [esId, setEsId] = useState<string>(row.esId || '');
  const [showHealthInfoPopup, setShowHealthInfoPopup] = useState<boolean>(false);
  const deviceSleeperTypesNormalized = useMemo(() => deviceSleeperTypeList.map((deviceSleeperType) => normalizeEnumName(deviceSleeperType)), []);

  const Checkbox = ({ label, value, onChange }) => {
    return (
      <label>
        <input type="checkbox" checked={value} onChange={onChange} />
        {label}
      </label>
    );
  };

  const displayHealthStats = (unhealthyOnly: boolean): JSX.Element => {
    const mainApp: JSX.Element = displayHealthBullet('mainApp', unhealthyOnly);
    const radar: JSX.Element = displayHealthBullet('radar', unhealthyOnly);
    const mic: JSX.Element = displayHealthBullet('mic', unhealthyOnly);
    const i2cBus: JSX.Element = displayHealthBullet('i2cBus', unhealthyOnly);
    const host: JSX.Element = displayHealthBullet('host', unhealthyOnly);
    const gpioButton: JSX.Element = displayHealthBullet('gpioButton', unhealthyOnly);
    const phone: JSX.Element = displayHealthBullet('phone', unhealthyOnly);
    const motionInference: JSX.Element = displayHealthBullet('motionInference', unhealthyOnly);
    const objectTracking: JSX.Element = displayHealthBullet('objectTracking', unhealthyOnly);
    const sensor: JSX.Element = displayHealthBullet('sensor', unhealthyOnly);
    const microphone: JSX.Element = displayHealthBullet('microphone', unhealthyOnly);
    return (
      <ul>
        {mainApp}
        {radar}
        {mic}
        {i2cBus}
        {host}
        {gpioButton}
        {phone}
        {motionInference}
        {objectTracking}
        {sensor}
        {microphone}
      </ul>
    );
  };

  const displayHealthBullet = (componentName: string, unhealthyOnly: boolean): JSX.Element => {
    const component:
      | MainAppDiagnostics
      | RadarDiagnostics
      | MicDiagnostics
      | I2CBusDiagnostics
      | HostDiagnostics
      | GpioButtonDiagnostics
      | PhoneDiagnostics
      | SensorDiagnostics
      | ObjectTrackingDiagnostics
      | MotionInferenceDiagnostics = row?.deviceComponentStates?.[componentName];
    const componentDiagnosticState: DeviceDiagnosticState = component?.diagnosticState;
    const componentHealth: string = componentDiagnosticState?.healthStatus;
    if (!component || (unhealthyOnly && (!row.healthStatus?.includes('UNHEALTHY') || componentHealth !== 'UNHEALTHY'))) {
      return <React.Fragment />;
    }
    return (
      <li>
        <CircleIndicator status={componentHealth} />
        {componentName}: {unhealthyOnly ? '' : componentHealth} ({formatTimestamp('' + new Date(componentDiagnosticState.stateTimestamp))})
      </li>
    );
  };

  return (
    <div className={style.popupBox} onClick={handleClose}>
      <div className={style.box}>
        <table className={style.popupTable} onClick={(e) => e.stopPropagation()}>
          <tbody>
            <tr>
              <td>ES ID</td>
              <td className={style.popupValueCell}>
                <input disabled={!row.personId} type="text" value={esId} onChange={(e) => setEsId(e.target.value)} />
                <br />
                <button disabled={esId === row.esId || (!esId && !row.esId) || !row.personId} onClick={() => updateEsId(row.personId, row.id, esId || undefined)}>
                  Update ES ID
                </button>
              </td>
            </tr>
            <tr>
              <td>Owner</td>
              <td className={style.popupValueCell}>{row.owner}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td className={style.popupValueCell}>{row.email}</td>
            </tr>
            <tr>
              <td>Device UUID</td>
              <td className={style.popupValueCell}>{row.id}</td>
            </tr>
            <tr>
              <td>Device ID 64</td>
              <td className={style.popupValueCell}>{row.deviceId64}</td>
            </tr>
            <tr>
              <td>Serial #</td>
              <td className={style.popupValueCell}>{row.serialNumber}</td>
            </tr>
            <tr>
              <td>Device #</td>
              <td className={style.popupValueCell}>{row.deviceNumber}</td>
            </tr>
            <tr>
              <td>Koko Map</td>
              <td className={style.popupValueCell}>
                <Link target="_blank" hidden={!row.personId} href={getKokoMapUrl(row.personId, row.timeZoneId, true) || ''} onClick={(e) => e.stopPropagation()}>
                  Koko Map
                </Link>
              </td>
            </tr>
            <tr>
              <td>Online Status</td>
              <td className={style.popupValueCell}>
                <CircleIndicator status={row.connectivityStatus} />
                {row.connectivityStatus} ({row.connectivityStatusTimestamp})
              </td>
            </tr>
            <tr>
              <td>Device Health</td>
              <td className={style.popupValueCell}>
                <CircleIndicator status={row.healthStatus} />
                {row.healthStatus} ({row.healthStatusTimestamp}){displayHealthStats(true)}
                <div className={style.infoIcon} onClick={() => setShowHealthInfoPopup(!showHealthInfoPopup)}>
                  ⓘ
                  {showHealthInfoPopup && (
                    <Popup offsetX={5} offsetY={8} width={470}>
                      <pre className={style.componentInfoPopup}>
                        {displayHealthStats(false)}
                        {row.deviceComponentStates && JSON.stringify(row.deviceComponentStates)}
                      </pre>
                    </Popup>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>Feature Flags</td>
              <td className={style.popupValueCell}>
                <Checkbox label="LEDs Enabled" value={ledFlag} onChange={() => setLedFlag(!ledFlag)} />
                <br />
                <Checkbox label="Mic Enabled" value={micFlag} onChange={() => setMicFlag(!micFlag)} />
                <br />
                <button disabled={ledFlag === row.ledsEnabled && micFlag === row.micEnabled} onClick={() => updateDeviceConfig(row.id, !!ledFlag, !!micFlag)}>
                  Update Feature Flags
                </button>
              </td>
            </tr>
            <tr>
              <td>Data Collection</td>
              <td className={style.popupValueCell}>
                <div className={style.labeledInput}>
                  <div>Fitbit ID</div>
                  <input type="text" id="fitbitId" value={fitbitId || ''} onChange={(e) => setFitbitId(e.target.value)} />
                </div>
                <div className={style.labeledInput}>
                  <div>Kobe ID</div>
                  <input type="text" id="kobeId" value={kobeId || ''} onChange={(e) => setKobeId(e.target.value)} />
                </div>
                <div className={style.labeledInput}>
                  <div>Fitbit Auth Token</div>
                  <input type="text" id="fitbitAuthToken" value={fitbitAuthToken || ''} onChange={(e) => setFitbitAuthToken(e.target.value)} />
                </div>
                <div className={style.labeledInput}>
                  <div>Muse Id</div>
                  <input type="text" id="museId" value={museId || ''} onChange={(e) => setMuseId(e.target.value)} />
                </div>
                <div className={style.labeledInput}>
                  <div>Hexo Id</div>
                  <input type="text" id="hexoId" value={hexoId || ''} onChange={(e) => setHexoId(e.target.value)} />
                </div>
                <div className={style.labeledSelect}>
                  <SelectButton
                    options={deviceSleeperTypesNormalized}
                    activeIndex={deviceSleeperType ? deviceSleeperTypeList.indexOf(deviceSleeperType) : 0}
                    onClick={(selectedIndex) => setDeviceSleeperType(DeviceSleeperType?.[deviceSleeperTypeList[selectedIndex]])}
                    label={'Sleeper Type'}
                    customPadding={'10px'}
                    disableConfirmation={true}
                    customWidth={'150px'}
                  />
                </div>
                <button
                  disabled={
                    (fitbitId === row.fitbitId || (!fitbitId && !row.fitbitId)) &&
                    (fitbitAuthToken === row.fitbitAuthToken || (!fitbitAuthToken && !row.fitbitAuthToken)) &&
                    (kobeId === row.kobeId || (!kobeId && !row.kobeId)) &&
                    (museId === row.museId || (!museId && !row.museId)) &&
                    (hexoId === row.hexoId || (!hexoId && !row.hexoId)) &&
                    (deviceSleeperType === row.deviceSleeperType || (!deviceSleeperType && !row.deviceSleeperType))
                  }
                  onClick={() => updateDeviceDataCollection({ id: row.id, fitbitId, fitbitAuthToken, kobeId, hexoId, museId, deviceSleeperType })}
                >
                  Update Data Collection
                </button>
              </td>
            </tr>
            <tr>
              <td>Remote Command Port</td>
              <td className={style.popupValueCell}>{row.deviceRemoteCommandPort}</td>
            </tr>
            <tr>
              <td>SW Version</td>
              <td className={style.popupValueCell}>{row.softwareVersion}</td>
            </tr>
            <tr>
              <td>FW Version</td>
              <td className={style.popupValueCell}>{row.firmwareVersion}</td>
            </tr>
            <tr>
              <td>FW Version Updating</td>
              <td className={style.popupValueCell}>{row.firmwareVersionUpdate}</td>
            </tr>
            <tr>
              <td>Update Status</td>
              <td className={style.popupValueCell}>{row.firmwareVersionUpdateStatus}</td>
            </tr>
            <tr>
              <td>Lock Status</td>
              <td className={style.popupValueCell}>{row.osLockStatus}</td>
            </tr>
            <tr>
              <td>Auto-Update</td>
              <td className={style.popupValueCell}>
                <button onClick={() => updateAutoUpdate(row.id, !row.autoUpdateDisabled)}>{row.autoUpdateDisabled ? 'Enable' : 'Disable'} Auto Update</button>
              </td>
            </tr>
            <tr>
              <td>Connectivity Notification</td>
              <td className={style.popupValueCell}>
                <button onClick={() => updateConnectivityNotification(row.id, !row.connectivityNotification)}>
                  {row.connectivityNotification ? 'Disable' : 'Enable'} Connectivity Notification
                </button>
              </td>
            </tr>
            <tr>
              <td>Factory Reset</td>
              <td className={style.popupValueCell}>{row.latestFactoryResetTs ? moment(row.latestFactoryResetTs).format('MM/DD/YYYY HH:mm') : '-'}</td>
            </tr>
            <tr>
              <td>Retired</td>
              <td className={style.popupValueCell}>{row.endDate ? moment(row.endDate).format('MM/DD/YYYY HH:mm') : '-'}</td>
            </tr>
            <tr>
              <td>Device Group</td>
              <td className={style.popupValueCell}>{row.deviceGroup}</td>
            </tr>
            <tr>
              <td>Cohort ID</td>
              <td className={style.popupValueCell}>{row.cohortId}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
