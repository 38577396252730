import AddIcon from '@mui/icons-material/Add';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { BreadCrumbData } from '@redux/common/types';
import { getDevicePersonOverviewThunk } from '@redux/device/deviceThunks';
import { appendBreadcrumbAction } from '@redux/oauth/oauthActions';
import { getAuthPersonSelector } from '@redux/oauth/oauthSelectors';
import { getPersonByIdSelector, getPersonVitalsByPersonIdSelector, getDevicePersonByPersonIdSelector, hasPatientCreatePermissionSelector } from '@redux/person/personSelector';
import { getPersonByIdThunk, getPersonVitalsThunk, setSelectedPatientIdThunk } from '@redux/person/personThunks';
import { ReduxState } from '@redux/types';
import { Button } from 'components/Button/Button';
import NavTopBarContentWrapper from 'components/NavTopBarContentWrapper/NavTopBarContentWrapper';
import { PatientInfoDrawer } from 'components/PatientInfoDrawer/PatientInfoDrawer';
import NoPatients from './components/NoPatients';
import PatientTable from './components/PatientTable/PatientTable';
import { PatientTableData } from './components/PatientTable/PatientTableTypes';

const FETCH_PATIENT_STATE_INTERVAL = 30000;

type PropsFromRedux = ConnectedProps<typeof connectRedux>;

type Props = PropsFromRedux & {};

const AllPatients: React.FC<Props> = ({
  hasPatientCreatePermission,
  personVitalsByPersonIdSelector,
  authPersonSelector,
  personByIdSelector,
  devicePersonByPersonIdSelector,
  appendBreadCrumbs,
  getPersonVitals,
  setSelectedPatientIdThunkCallback,
  fetchPersonById,
  fetchDevicePersonOverview
}) => {
  const [patientTableData, setPatientTableData] = useState<PatientTableData[]>([]);
  const [selectedPatientId, setSelectedPatientId] = useState<string>('');
  const organizationId = authPersonSelector?.organizationId ?? '';
  const [initDone, setInitDone] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchMeasurements = async () => {
      await getPersonVitals(organizationId);
      setInitDone(true);
    };

    fetchMeasurements();
    fetchDevicePersonOverview();

    const intervalId = setInterval(() => {
      fetchMeasurements();
    }, FETCH_PATIENT_STATE_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const getPatientTableData = (): PatientTableData[] => {
      const tableData: PatientTableData[] = [];
      for (const personId of Object.keys(personVitalsByPersonIdSelector)) {
        const personVitals = personVitalsByPersonIdSelector[personId];
        const devicePerson = devicePersonByPersonIdSelector[personId];
        if (!personVitals) {
          continue;
        }

        const tableRow: PatientTableData = {
          ...personVitals,
          name: personVitals.personName ?? '',
          avatar: '',
          deviceNumber: devicePerson?.deviceNumber ?? undefined
        };
        tableData.push(tableRow);
      }

      return tableData;
    };

    setPatientTableData(getPatientTableData());
  }, [personVitalsByPersonIdSelector]);

  const unselectPatientId = useCallback(() => {
    setSelectedPatientId('');
  }, []);

  const goToPatientDashboard = useCallback((personId: string) => {
    fetchPersonById(personId);
    setSelectedPatientId(personId);
    setSelectedPatientIdThunkCallback(personId);
  }, []);

  const goToNewPatientPage = useCallback(() => {
    navigate('/patients/newPatient');
  }, [navigate]);

  return (
    <NavTopBarContentWrapper
      headerText="Patients"
      headerContent={
        initDone &&
        hasPatientCreatePermission &&
        patientTableData.length && (
          <Button variant="contained" onClick={goToNewPatientPage} startIcon={<AddIcon />}>
            Add Patient
          </Button>
        )
      }
    >
      {initDone && !!patientTableData.length && <PatientTable patientTableData={patientTableData} onPressTableRow={goToPatientDashboard} />}
      {initDone && !patientTableData.length && <NoPatients onAddPress={goToNewPatientPage} isButtonVisible={hasPatientCreatePermission} />}
      <PatientInfoDrawer person={personByIdSelector[selectedPatientId]} visible={!!selectedPatientId} onClose={unselectPatientId} withButtons />
    </NavTopBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    hasPatientCreatePermission: hasPatientCreatePermissionSelector(state),
    authPersonSelector: getAuthPersonSelector(state),
    personVitalsByPersonIdSelector: getPersonVitalsByPersonIdSelector(state),
    personByIdSelector: getPersonByIdSelector(state),
    devicePersonByPersonIdSelector: getDevicePersonByPersonIdSelector(state)
  }),
  {
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[], isOldApp?: boolean) =>
      appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory, isOldApp }),
    getPersonVitals: getPersonVitalsThunk,
    setSelectedPatientIdThunkCallback: setSelectedPatientIdThunk,
    fetchPersonById: getPersonByIdThunk,
    fetchDevicePersonOverview: getDevicePersonOverviewThunk
  }
);

export default compose(connectRedux)(AllPatients);
