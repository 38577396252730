import { IconButton } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import moment from 'moment';
import React, { memo, useState } from 'react';
import { TaskStatusEnum } from '@redux/tasks/tasksTypes';
import { Button } from 'components/Button/Button';
import { CairnsAssistantIcon, DotsThreeIcon, DoubleCheckmarkIcon, InformationIcon, ResponseArrowIcon, SendIcon, SingleCheckmarkIcon } from 'components/Icons';
import { StatusLabel } from 'components/StatusLabel/StatusLabel';
import { TextArea } from 'components/TextArea/TextArea';
import { Text } from 'components/Typography';
import { TASK_LABEL_STATUSES } from 'containers/Tasks/Tasks.const';
import { Color } from 'utils/color';
import { timeAgoString } from 'utils/time';
import { getInitials } from 'utils/util';
import { ResponseMenu } from '../ResponseMenu/ResponseMenu';
import style from './ConversationFeedItem.scss';
import { ConversationFeedItemProps } from './ConversationFeedItem.types';

export const ConversationFeedItem: React.FC<ConversationFeedItemProps> = memo(({ message, sendResponse, onSelectTaskSwitch }) => {
  const [reply, setReply] = useState<string>('');

  return (
    <>
      <div className={style.container}>
        <Text variant="xl" className={style.darkBlueText}>
          {message.messageType}
        </Text>
        <div className={style.senderHeader}>
          <CairnsAssistantIcon></CairnsAssistantIcon>
          <Text className={classNames(style.darkBlueText, style.senderName)} variant="md">
            Cairns Assistant
          </Text>
          <Text className={classNames(style.lightGrayText, style.lastMessageTime)} variant="sm">
            {timeAgoString(message.assistantMessageCreated)}
          </Text>
        </div>
        <Text variant="md" className={style.lightGrayText}>
          {message.assistantMessage}
        </Text>
        {message.taskId && (
          <div className={style.taskContainer}>
            <div className={style.taskTimeStatusText}>
              <Text className={classNames(style.darkBlueText, style.taskNameText)}>{message.taskName}</Text>
              <div className={style.taskTimeStatusText}>
                <Text className={classNames(style.lightGrayText)}>{moment(message.scheduledFor).format('MMM D • h:mmA • ')}</Text>
                <StatusLabel
                  containerClassName={style.noBorderStatusInline}
                  label={(TASK_LABEL_STATUSES[message.taskStatus as string] || TASK_LABEL_STATUSES['Default']).label}
                  status={(TASK_LABEL_STATUSES[message.taskStatus as string] || TASK_LABEL_STATUSES['Default']).type}
                />
              </div>
            </div>
            <Button className={style.viewTaskButton} variant="outlined" onClick={() => onSelectTaskSwitch(message.taskId)}>
              View Task
            </Button>
          </div>
        )}
        {!message.response && (
          <div className={style.replyInputContainer}>
            <TextArea
              rows={reply ? 4 : 1}
              maxLength={1000}
              placeholder="Leave a reply..."
              onChange={(e) => setReply(e.target.value)}
              onSendButton={() => sendResponse(reply, message.inboxMessageId)}
              sendButtonIcon={<SendIcon color={Color.WHITE}></SendIcon>}
            ></TextArea>
          </div>
        )}
        {!!message.response && (
          <>
            <div className={style.responseHeader}>
              <ResponseArrowIcon />
              {!!message.responderFullName && (
                <>
                  <div className={style.avatar}>
                    <Text variant="sm" weight={500} className={style.initials}>
                      {getInitials(message.responderFullName)}
                    </Text>
                  </div>
                  <Text className={classNames(style.darkBlueText, style.senderName)} variant="md">
                    {message.responderFullName}
                  </Text>
                </>
              )}
              {!!message.responseCreated && (
                <Text className={classNames(style.lightGrayText, style.lastMessageTime)} variant="sm">
                  {timeAgoString(message.responseCreated)}
                </Text>
              )}
              <div className={style.responseDetailsButton}>
                <ResponseMenu onDeleteResponse={() => {}}></ResponseMenu>
              </div>
            </div>
            <div className={style.responseMessageContainer}>
              <Text>{message.response}</Text>
            </div>
            <div className={style.responseFooter}>
              {message.responseStatus == TaskStatusEnum.SCHEDULED && <SingleCheckmarkIcon color={Color.DARK_BLUE_500}></SingleCheckmarkIcon>}
              {message.responseStatus == TaskStatusEnum.COMPLETED && <DoubleCheckmarkIcon color={Color.LIGHT_GREEN_700}></DoubleCheckmarkIcon>}
              <StatusLabel
                containerClassName={style.noBorderStatusInline}
                label={(TASK_LABEL_STATUSES[message.responseStatus as string] || TASK_LABEL_STATUSES['Default']).label}
                status={(TASK_LABEL_STATUSES[message.responseStatus as string] || TASK_LABEL_STATUSES['Default']).type}
              />
              <Tooltip
                title="This message will send the next time the patient is in the designated monitoring zones around their bed, including nearside bed, farside bed, and bedroom. The message expires at the end of the day."
                placement="bottom-end"
              >
                <div>
                  <InformationIcon color={Color.GRAY} height={16} width={16}></InformationIcon>
                </div>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    </>
  );
});
