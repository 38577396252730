import { Grid } from '@mui/material';
import React from 'react';
import { PersonVitalControlLevel, PersonVitalMetrics } from '@redux/person/personTypes';
import { getFixedPointDisplay } from 'utils/util';
import { GridRow } from '../GridRow/GridRow';

type Props = {
  patientMetrics?: PersonVitalMetrics;
  controlLevel?: PersonVitalControlLevel;
  containerClassName?: string;
};

export const InsightsGrid: React.FC<Props> = ({ patientMetrics, controlLevel, containerClassName }) => {
  return (
    <Grid container className={containerClassName}>
      <GridRow label="" isHeaderRow metricValue="Patient metrics" normalControlValue="Control level" suboptimalControlValue="Suboptimal" abnormalControlValue="Abnormal" />
      <GridRow
        label="Min (bpm)"
        metricValue={getFixedPointDisplay(patientMetrics?.minRate)}
        normalControlValue={getFixedPointDisplay(controlLevel?.normalMinRate)}
        abnormalControlValue={getFixedPointDisplay(controlLevel?.abnormalMinRate)}
        suboptimalControlValue={getFixedPointDisplay(controlLevel?.subnormalMinRate)}
        metricStatus={patientMetrics?.minRateStatus}
      />
      <GridRow
        label="Max (bpm)"
        metricValue={getFixedPointDisplay(patientMetrics?.maxRate)}
        normalControlValue={getFixedPointDisplay(controlLevel?.normalMaxRate)}
        abnormalControlValue={getFixedPointDisplay(controlLevel?.abnormalMaxRate)}
        suboptimalControlValue={getFixedPointDisplay(controlLevel?.subnormalMaxRate)}
        metricStatus={patientMetrics?.maxRateStatus}
      />
      <GridRow label="Avg (bpm)" metricValue={getFixedPointDisplay(patientMetrics?.avgRate)} normalControlValue="-" suboptimalControlValue="-" abnormalControlValue="-" />
    </Grid>
  );
};
