import { VitalSignsStatus } from '@redux/person/personTypes';

export const getVitalStatusOutOfRangeText = (status?: VitalSignsStatus) => {
  switch (status) {
    case VitalSignsStatus.ABNORMAL:
      return 'Abnormal';
    case VitalSignsStatus.SUBOPTIMAL:
      return 'Suboptimal';
    default:
      return '';
  }
};
