import { Avatar, Box, Chip, Typography } from '@material-ui/core';
import React from 'react';
import { BEDTIME_IMAGE_URL, WAKETIME_IMAGE_URL } from 'assets/images/images';
import timeIcon from 'assets/images/time-outline.png';
import { Person } from 'redux/person/personTypes';
import { SleepSchedule } from 'redux/sleepschedule/sleepScheduleTypes';
import { timeAsTimeStr, tsAsTimeSinceUnit } from 'utils/time';
import style from './SleepScheduleCard.scss';
interface Props {
  sleepSchedule: SleepSchedule;
  person: Person;
  onClick: (sleepSchedule: Nullable<SleepSchedule>) => void;
}

export const SleepScheduleCard: React.FC<Props> = ({ sleepSchedule, person, onClick }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      border={1}
      borderRadius={5}
      borderColor={'#D0DCE2'}
      height={'68px'}
      className={style.container}
      onClick={() => onClick(sleepSchedule)}
    >
      <Typography variant="h6" component="h3" className={style.cardContent}>
        {person.givenNames + ' ' + person.familyName}
      </Typography>
      <Box className={style.cardRight}>
        <Chip className={style.chipBedTime} avatar={<Avatar alt="Bed Time" src={BEDTIME_IMAGE_URL} />} label={timeAsTimeStr(sleepSchedule.sleepTime)} />
        <Chip className={style.chipWakeTime} avatar={<Avatar alt="Wake Time" src={WAKETIME_IMAGE_URL} />} label={timeAsTimeStr(sleepSchedule.wakeTime)} />
        {sleepSchedule.enabledTs && <Chip className={style.chipTime} avatar={<Avatar alt="Time" src={timeIcon} />} label={tsAsTimeSinceUnit(sleepSchedule.enabledTs)} />}
      </Box>
    </Box>
  );
};
