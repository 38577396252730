import React from 'react';
import { VitalSignsStatus } from '@redux/person/personTypes';
import { Text } from 'components/Typography';
import { VitalSign } from 'components/VitalSign/VitalSign';
import style from './VitalCard.scss';

export type VitalCardProps = {
  vitalTitle?: string;
  vitalSubtitle?: string;
  vitalValue?: string | number;
  vitalUnit?: string;
  icon: React.FC;
  vitalStatus?: VitalSignsStatus;
  vitalStatusText?: string;
  showVitalSubtitleStub?: boolean;
};

const getVitalTextClassName = (status?: VitalSignsStatus) => {
  switch (status) {
    case VitalSignsStatus.ABNORMAL:
      return style.redText;
    case VitalSignsStatus.SUBOPTIMAL:
      return style.yellowText;
    default:
      return null;
  }
};

export const VitalCard: React.FC<VitalCardProps> = ({
  vitalTitle,
  vitalSubtitle,
  vitalValue,
  vitalUnit = 'BPM',
  icon: Icon,
  vitalStatus,
  vitalStatusText = '',
  showVitalSubtitleStub = false
}) => {
  return (
    <div className={style.card}>
      <div className={style.cardTitleContainer}>
        <Text variant="md" weight={500}>
          {vitalTitle}
        </Text>
        {vitalSubtitle ? (
          <Text variant="sm" className={style.defaultGrayText}>
            {vitalSubtitle}
          </Text>
        ) : (
          showVitalSubtitleStub && <div className={style.vitalSubtitleStub} />
        )}
      </div>
      <div className={style.vitalContainer}>
        <Icon />
        <div className={style.vitalValueContainer}>
          <Text variant="h3" className={style.vitalValueText}>
            {vitalValue ? vitalValue : '-'}
          </Text>
        </div>
        <div className={style.vitalValueContainer}>
          <Text variant="md" className={style.vitalValueUnit}>
            {vitalUnit}
          </Text>
        </div>
      </div>
      <VitalSign status={vitalStatus} showBg textValue={vitalStatusText} textClassName={getVitalTextClassName(vitalStatus)} />
    </div>
  );
};
