import React from 'react';
import { SVGBasicType } from 'types/global';
import { Color } from 'utils/color';

export const MessagesIcon = ({ width = 20, height = 20, color = Color.GRAY }: SVGBasicType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.75 12.5H28.75V7.5C28.75 6.83696 28.4866 6.20107 28.0178 5.73223C27.5489 5.26339 26.913 5 26.25 5H6.25C5.58696 5 4.95107 5.26339 4.48223 5.73223C4.01339 6.20107 3.75 6.83696 3.75 7.5V27.5C3.75073 27.7352 3.81779 27.9654 3.94347 28.1641C4.06915 28.3629 4.24835 28.5222 4.46049 28.6237C4.67263 28.7252 4.90909 28.7648 5.14273 28.7379C5.37636 28.7111 5.59767 28.6189 5.78125 28.4719L11.25 24.0625V28.75C11.25 29.413 11.5134 30.0489 11.9822 30.5178C12.4511 30.9866 13.087 31.25 13.75 31.25H28.3734L34.2188 35.9719C34.4399 36.1508 34.7155 36.2489 35 36.25C35.3315 36.25 35.6495 36.1183 35.8839 35.8839C36.1183 35.6495 36.25 35.3315 36.25 35V15C36.25 14.337 35.9866 13.7011 35.5178 13.2322C35.0489 12.7634 34.413 12.5 33.75 12.5ZM10.3984 21.5281L6.25 24.8828V7.5H26.25V21.25H11.1844C10.8983 21.25 10.6209 21.3482 10.3984 21.5281ZM33.75 32.3828L29.6016 29.0281C29.3804 28.8492 29.1048 28.7511 28.8203 28.75H13.75V23.75H26.25C26.913 23.75 27.5489 23.4866 28.0178 23.0178C28.4866 22.5489 28.75 21.913 28.75 21.25V15H33.75V32.3828Z"
        fill={color}
      />
    </svg>
  );
};
