import classNames from 'classnames';
import React from 'react';
import { SVGBasicType } from 'types/global';
import { Color } from 'utils/color';
import style from './CircledCustomIcon.scss';

type Props = SVGBasicType & {
  icon: React.FC<SVGBasicType>;
  containerStyle?: string;
};

export const CircledCustomIcon: React.FC<Props> = ({ width = 32, height = 32, color = Color.GRAY_BLUE, icon: Icon, containerStyle }) => {
  return (
    <div className={classNames(style.container, containerStyle)}>
      <Icon width={width} height={height} color={color} />
    </div>
  );
};
