import { Grid } from '@mui/material';
import React from 'react';
import { PersonSleepMetrics, PersonVitalControlLevel, PersonVitalTimeFrame, VitalSignsStatus } from '@redux/person/personTypes';
import { getFixedPointDisplay } from 'utils/util';
import { formatMinutesToHoursAndMinutes } from 'utils/time';
import { GridRow } from '../GridRow/GridRow';
import style from './InsightsGrid.scss';

type Props = {
  patientMetrics?: PersonSleepMetrics;
  controlLevel?: PersonVitalControlLevel;
  containerClassName?: string;
  timeFrame?: PersonVitalTimeFrame;
};

const CONTROL_SLEEP_TIME_IN_MINUTES = 420;

export const SleepInsightsGrid: React.FC<Props> = ({ patientMetrics, controlLevel, containerClassName, timeFrame }) => {
  const getLabel = (label: string): string => {
    return timeFrame !== PersonVitalTimeFrame.TWENTY_FOUR_HOURS ? `Avg. ${label}` : label;
  };

  return (
    <Grid container className={containerClassName}>
      <GridRow label="" isHeaderRow metricValue="Patient metrics" normalControlValue="Control level" suboptimalControlValue="Suboptimal" abnormalControlValue="Abnormal" />
      <GridRow
        label={getLabel('Sleep Time (h)')}
        metricValue={formatMinutesToHoursAndMinutes(patientMetrics?.totalSleepTimeInMinutes ?? 0)}
        normalControlValue={formatMinutesToHoursAndMinutes(CONTROL_SLEEP_TIME_IN_MINUTES)}
        metricStatus={
          (patientMetrics?.totalSleepTimeInMinutes ?? CONTROL_SLEEP_TIME_IN_MINUTES) < CONTROL_SLEEP_TIME_IN_MINUTES ? VitalSignsStatus.ABNORMAL : VitalSignsStatus.NORMAL
        }
        hasRowLine={false}
      />
      <GridRow
        label={getLabel('REM')}
        metricValue={formatMinutesToHoursAndMinutes(patientMetrics?.remSleepTimeInMinutes ?? 0)}
        normalControlValue={getFixedPointDisplay(controlLevel?.normalMaxRate)}
        hasRowLine={false}
        alignCenter
        rowHeaderClassName={style.remText}
        rowValueTextClassName={style.valueText}
      />
      <GridRow
        label={getLabel('Deep')}
        metricValue={formatMinutesToHoursAndMinutes(patientMetrics?.deepSleepTimeInMinutes ?? 0)}
        normalControlValue={getFixedPointDisplay(controlLevel?.normalMaxRate)}
        alignCenter
        rowHeaderClassName={style.deepText}
        rowValueTextClassName={style.valueText}
      />
      <GridRow
        label={getLabel('Light')}
        metricValue={formatMinutesToHoursAndMinutes(patientMetrics?.lightSleepTimeInMinutes ?? 0)}
        normalControlValue={getFixedPointDisplay(controlLevel?.normalMaxRate)}
        hasRowLine={false}
        alignCenter
        rowHeaderClassName={style.lightText}
        rowValueTextClassName={style.valueText}
      />
      <GridRow
        label={getLabel('Awake')}
        metricValue={formatMinutesToHoursAndMinutes(patientMetrics?.awakeTimeInMinutes ?? 0)}
        normalControlValue={getFixedPointDisplay(controlLevel?.normalMaxRate)}
        hasRowLine={false}
        alignCenter
        rowHeaderClassName={style.awakeText}
        rowValueTextClassName={style.valueText}
      />
      {/*
      Motion data is hidden in the current version
      <GridRow
        label={getLabel('Motion')}
        metricValue={getFixedPointDisplay(patientMetrics?.motionSleepTimeInMinutes)}
        normalControlValue={getFixedPointDisplay(controlLevel?.normalMaxRate)}
        alignCenter
        rowHeaderClassName={style.motionText}
        rowValueTextClassName={style.valueText}
      />*/}
    </Grid>
  );
};
