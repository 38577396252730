import { IconButton } from '@material-ui/core';
import React from 'react';
import { DropDownMenu } from 'components/DropDownMenu/DropDownMenu';
import { DotsThreeIcon, ListChecksIcon, XSquareIcon } from 'components/Icons';
import { Color } from 'utils/color';
import style from './ResponseMenu.scss';

interface ResponseMenuProps {
  onDeleteResponse: () => void;
  additionalButtonClass?: string;
}

export const ResponseMenu: React.FC<ResponseMenuProps> = ({ onDeleteResponse, additionalButtonClass }) => {
  return (
    <DropDownMenu
      customButtonClass={additionalButtonClass}
      customMenuElement={(onMenuClick) => (
        <IconButton onClick={onMenuClick} size="small">
          <DotsThreeIcon />
        </IconButton>
      )}
      menuItems={[
        {
          text: 'Delete',
          disabled: true,
          textClassName: style.redText,
          onClick: onDeleteResponse
        }
      ]}
    />
  );
};
