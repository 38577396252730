import { IconButton, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';

interface Props {
  searchText: string;
  requestSearch: (searchValue: string) => void;
}

export const QuickSearchField: React.FC<Props> = ({ searchText, requestSearch }) => (
  <TextField
    variant="standard"
    value={searchText}
    onChange={(e) => requestSearch(e.target.value)}
    placeholder="Search..."
    InputProps={{
      startAdornment: <SearchIcon fontSize="small" />,
      endAdornment: (
        <IconButton size="small" style={{ visibility: searchText ? 'visible' : 'hidden' }} onClick={() => requestSearch('')}>
          <ClearIcon fontSize="small" />
        </IconButton>
      )
    }}
  />
);
