import { PersonVitalInsights, PersonVitalTimeFrame, PersonVitalType, DetailedSleepStage } from '@redux/person/personTypes';
import { CircledCustomIcon, TableIcon, ClockCountdownIcon } from 'components/Icons';
import { Text } from 'components/Typography';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Chart } from 'react-google-charts';
import { createSleepTooltip, createAggregatedSleepTooltip, getMinTime, updateControlLinesStyles, updateHeaderStyles, getDateFormat, getXAxisGridlineCount } from 'utils/charts';
import { Color } from 'utils/color';
import { getDateFromStringIgnoreTimezone, getFormatedTime } from 'utils/time';
import style from './SleepChart.scss';

export type SleepChartProps = {
  timeZoneId: string;
  personVitalInsights?: PersonVitalInsights | null;
  timeFrame: PersonVitalTimeFrame;
  minRate?: number;
  maxRate?: number;
  minControlRate?: number;
  maxControlRate?: number;
};

type SleepChartDataType = Array<Array<Date | number | string>>;

const formatSleepStage = (stage: DetailedSleepStage) => {
  switch (stage) {
    case DetailedSleepStage.AWAKE:
      return 'Awake';
    case DetailedSleepStage.LIGHT_SLEEP:
      return 'Light';
    case DetailedSleepStage.DEEP_SLEEP:
      return 'Deep';
    case DetailedSleepStage.REM_SLEEP:
      return 'REM';
    default:
      return '';
  }
};

export const SleepChart: React.FC<SleepChartProps> = ({ timeZoneId, personVitalInsights, timeFrame, minControlRate, maxControlRate }) => {
  // Use separate variables for the chart and timeline to avoid error flickering
  // when swapping between the two
  const patLocalTime = moment.tz(timeZoneId).format('YYYY-MM-DD HH:mm:ss z');
  const xMaxValue = getDateFromStringIgnoreTimezone(patLocalTime)!;
  const xMinValue = getMinTime(xMaxValue, timeFrame);
  const xAxisGridlineCount = getXAxisGridlineCount(timeFrame);

  const isDateValid = (dt: Date | null) => {
    return Boolean(dt && dt >= xMinValue && dt <= xMaxValue);
  };

  const calculateTimelineData = (personVitalInsights?: PersonVitalInsights | null): SleepChartDataType => {
    if (!personVitalInsights || !personVitalInsights.listOfAggregatedSleepData) {
      return [];
    }
    const aggregatedSleepData = personVitalInsights.listOfAggregatedSleepData;

    const desiredOrder = ['REM', 'Deep', 'Light', 'Awake'];
    return aggregatedSleepData
      .map((vitalSign) => {
        const stage = formatSleepStage(vitalSign.detailedSleepStage);
        const start = getDateFromStringIgnoreTimezone(vitalSign.startTime);
        const end = getDateFromStringIgnoreTimezone(vitalSign.endTime);

        if (isDateValid(start) && isDateValid(end)) {
          return [stage, '', createSleepTooltip(start!, end!, stage, timeFrame), start!, end!];
        }
        return [];
      })
      .filter((data) => !!data?.length)
      .sort((a, b) => desiredOrder.indexOf(a[0] as DetailedSleepStage) - desiredOrder.indexOf(b[0] as DetailedSleepStage));
  };

  const calculateChartData = (personVitalInsights?: PersonVitalInsights | null): SleepChartDataType => {
    if (!personVitalInsights || !personVitalInsights.listOfAggregatedDailyTotalSleepData) {
      return [];
    }
    const sleepData: SleepChartDataType = [];
    const aggregatedTotalSleepData = personVitalInsights.listOfAggregatedDailyTotalSleepData;
    const numBuckets = timeFrame === PersonVitalTimeFrame.SEVEN_DAYS ? 7 : timeFrame === PersonVitalTimeFrame.THIRTY_DAYS ? 30 : 12;
    const sleepDataArray: [string, number, number, number, number][] = Array.from({ length: numBuckets }, () => ['', 0, 0, 0, 0]);
    const units = timeFrame === PersonVitalTimeFrame.TWELVE_MONTHS ? 'months' : 'days';
    const startDate = moment(xMaxValue).subtract(numBuckets - 1, units);
    let i = 0;
    sleepDataArray.forEach((data) => {
      const date = startDate.clone().add(i++, units).toDate();
      data[0] = getFormatedTime(date.toString(), timeFrame === PersonVitalTimeFrame.TWELVE_MONTHS ? 'MMM YYYY' : 'ddd, MMM. D');
    });
    aggregatedTotalSleepData?.forEach((vitalSign) => {
      const bucket = moment(getDateFromStringIgnoreTimezone(vitalSign.bucketLocal)).diff(startDate, units);
      switch (vitalSign.sleepStage) {
        case DetailedSleepStage.AWAKE:
          sleepDataArray[bucket][1] = (sleepDataArray[bucket][1] as number) + vitalSign.totalMinutes;
          break;
        case DetailedSleepStage.LIGHT_SLEEP:
          sleepDataArray[bucket][2] = (sleepDataArray[bucket][2] as number) + vitalSign.totalMinutes;
          break;
        case DetailedSleepStage.DEEP_SLEEP:
          sleepDataArray[bucket][3] = (sleepDataArray[bucket][3] as number) + vitalSign.totalMinutes;
          break;
        case DetailedSleepStage.REM_SLEEP:
          sleepDataArray[bucket][4] = (sleepDataArray[bucket][4] as number) + vitalSign.totalMinutes;
          break;
        default:
          break;
      }
    });
    for (let i = 0; i < numBuckets; i++) {
      const tooltip = createAggregatedSleepTooltip(...sleepDataArray[i]);
      sleepData.push([...sleepDataArray[i], tooltip]);
    }
    return sleepData;
  };

  const chartData = useMemo(() => {
    console.log('personVitalInsights', personVitalInsights);
    const data = calculateChartData(personVitalInsights);
    return [['Sleep Stage', 'Awake', 'Light Sleep', 'Deep Sleep', 'REM Sleep', { type: 'string', role: 'tooltip', p: { html: true } }], ...data];
  }, [personVitalInsights]);

  const timelineData = useMemo(() => {
    const data = calculateTimelineData(personVitalInsights);
    return [['Stage', '', { type: 'string', role: 'tooltip', p: { html: true } }, 'Start', 'End'], ...data];
  }, [personVitalInsights]);

  const timelineOptions = {
    legend: 'none',
    fontSize: 12,
    fontName: 'Outfit',
    tooltip: { isHtml: true },
    colors: [Color.DARK_BLUE_700, Color.DARK_BLUE_600, Color.DARK_BLUE_500, Color.GRAY_400]
  };

  const chartOptions = {
    legend: 'none',
    isStacked: true,
    fontSize: 12,
    colors: [Color.GRAY_400, Color.DARK_BLUE_500, Color.DARK_BLUE_600, Color.DARK_BLUE_700],
    fontName: 'Outfit',
    tooltip: {
      isHtml: true
    },
    hAxis: {
      title: '',
      textStyle: {
        color: Color.GRAY,
        fontSize: 12,
        lineHeight: 18
      },
      viewWindowMode: 'explicit',
      minValue: xMinValue,
      maxValue: xMaxValue,
      viewWindow: {
        min: xMinValue,
        max: xMaxValue
      },
      format: getDateFormat(timeFrame),
      gridlines: {
        count: xAxisGridlineCount,
        color: 'transparent'
      },
      minorGridlines: {
        count: 0
      }
    },
    series: {
      0: { tooltip: false },
      1: { tooltip: false },
      2: { tooltip: false },
      3: { tooltip: true }
    }
  };

  const updateChartStyles = useCallback(() => {
    updateHeaderStyles();
    updateControlLinesStyles(minControlRate, maxControlRate);
  }, [minControlRate, maxControlRate]);

  return timeFrame === PersonVitalTimeFrame.TWENTY_FOUR_HOURS && timelineData?.length > 1 ? (
    <div className={style.chartContainer}>
      <Chart
        width="100%"
        height="100%"
        chartEvents={[{ eventName: 'ready', callback: updateChartStyles }]}
        chartType="Timeline"
        loader={<div>Loading Chart</div>}
        data={timelineData}
        options={timelineOptions}
      />
    </div>
  ) : timeFrame === PersonVitalTimeFrame.TWELVE_MONTHS ? (
    <div className={style.noDataChartContainer}>
      <CircledCustomIcon icon={ClockCountdownIcon} />
      <Text>Comming Soon</Text>
    </div>
  ) : chartData?.length > 1 ? (
    <div className={style.chartContainer}>
      <Chart
        width="100%"
        height="100%"
        chartEvents={[{ eventName: 'ready', callback: updateChartStyles }]}
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        data={chartData}
        options={chartOptions}
      />
    </div>
  ) : (
    <div className={style.noDataChartContainer}>
      <CircledCustomIcon icon={TableIcon} />
      <Text>No data to display</Text>
    </div>
  );
};
