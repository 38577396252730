import { Typography } from '@material-ui/core';
import React from 'react';
import { NOBACK_MULTICOLOR_IMAGE_URL } from 'assets/images/images';
import style from './DevicePane.scss';

export const DevicePane: React.FC = () => (
  <React.Fragment>
    <img className={style.imageBox} src={NOBACK_MULTICOLOR_IMAGE_URL} alt="Cairns Health" />
    <Typography className={style.helpFooterLeft}>
      Questions? Email us at:{' '}
      <a className={style.contactUs} href="mailto:support@cairns.ai">
        support@cairns.ai
      </a>
    </Typography>
  </React.Fragment>
);
