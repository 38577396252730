import { TaskDetailsType, TasksListItemType, TasksState } from '@redux/tasks/tasksTypes';
import { ReduxState } from '@redux/types';

const getTasksState = (state: ReduxState): TasksState => {
  return state.tasks;
};

export const getOrganizationTasksListSelector = (state: ReduxState): TasksListItemType[] => {
  return getTasksState(state).tasksList;
};

export const getTaskDetailsSelector = (state: ReduxState): Nullable<TaskDetailsType> => {
  return getTasksState(state).selectedTaskDetails;
};
