import React from 'react';
import { TasksListItemType, TaskStatusEnum } from '@redux/tasks/tasksTypes';

export enum TasksTableColName {
  PATIENT_NAME = 'patientName',
  TASK_MESSAGE = 'taskMessage',
  PATIENT_RESPONSE = 'patientResponse',
  SCHEDULED_DATE = 'scheduledDate',
  STATUS = 'status',
  DETAILS = 'details'
}

export type TasksTableData = TasksListItemType & {
  avatar?: string;
};

export type TaskMenuProps = {
  onSelectTask: () => void;
  onCancelTask: () => void;
  status: TaskStatusEnum;
};

export type TasksTableProps = React.ComponentPropsWithoutRef<'table'> & {
  data: TasksTableData[];
  onSelectTaskSwitch: (id?: string) => void;
  onCancelTask: (id: string) => void;
};
