import { cloneDeep } from 'lodash';
import { Moment } from 'moment';
import {
  DeviceContent,
  DeviceContentPage,
  DeviceContentPageType,
  DeviceContentType,
  FeedItem,
  ProgramContent,
  ProgramSubType,
  ProgramType,
  SurveyOption,
  SurveyQuestion,
  WorkflowTaskTemplate,
  WorkflowTemplate
} from '@redux/content/contentTypes';
import { textToMoment } from './time';

export const isLesson = (value?: Nullable<FeedItem>): boolean =>
  value?.personProgram?.programType !== ProgramType.AD_HOC_NATIVE &&
  value?.personProgram?.programType !== ProgramType.AD_HOC_CONTENT &&
  value?.personProgram?.programType !== ProgramType.MANUAL_SLEEP_LOG &&
  value?.programContent?.programType !== ProgramType.AD_HOC_NATIVE &&
  value?.programContent?.programType !== ProgramType.AD_HOC_CONTENT &&
  value?.programContent?.programType !== ProgramType.MANUAL_SLEEP_LOG;

export const isLessonNoSurveys = (value?: Nullable<FeedItem>): boolean =>
  isLesson(value) &&
  value?.personProgram?.programType !== ProgramType.SLEEP_NEED_SURVEY &&
  value?.programContent?.programType !== ProgramType.SLEEP_NEED_SURVEY &&
  value?.personProgram?.programType !== ProgramType.ISI_SURVEY &&
  value?.programContent?.programType !== ProgramType.ISI_SURVEY;

export const isAdHoc = (value?: Nullable<FeedItem>): boolean =>
  value?.personProgram?.programType === ProgramType.AD_HOC_NATIVE || value?.programContent?.programType === ProgramType.AD_HOC_NATIVE;

export const pluralize = (value: number, singular: string) => value + ' ' + singular + (value !== 1 ? (isUpperCase(singular) ? 'S' : 's') : '');

export const findInRange = <T>(
  start: Moment,
  end: Moment,
  tsCallback: (t: T) => Nullable<string>,
  tzCallback?: Nullable<(t: T) => Nullable<string>>,
  items?: Nullable<T[]>,
  dateFormat?: Nullable<string>
): T[] =>
  !items
    ? []
    : items.filter((item) => {
        const date: Nullable<Moment> = textToMoment(tsCallback(item), !tzCallback ? undefined : tzCallback(item), dateFormat);
        return !!date && date.isBefore(end) && date.isAfter(start);
      });

export const findProgram = (programSubType: ProgramSubType, programContents?: Nullable<ProgramContent[]>): Nullable<ProgramContent> =>
  programContents?.find((deviceContent) => deviceContent.programSubType === programSubType);

export const normalizeEnumName = (enumName: any): string => {
  if (enumName === DeviceContentPageType.QUIZ) {
    return 'Quiz Question';
  }
  let enumAsString: string = enumName;
  return enumAsString
    ?.toLowerCase()
    ?.split('_')
    ?.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    ?.join(' ')
    ?.replace('Cbti', 'CBTI')
    ?.replace('Html', 'HTML')
    ?.replace('Ll', 'LL');
};

export function normalizeBooleanValue(value: boolean | null | undefined): string | undefined {
  if (value === true) return 'Yes';
  if (value === false) return 'No';
  return undefined;
}

export const extractMarkdownPageText = (markdown: string, pageType: DeviceContentPageType): string => {
  const coords: Nullable<{ start: number; end: number }> = findMarkdownVideoCoords(markdown, pageType);
  if (coords) {
    return markdown.substring(coords.end + 1).trim();
  }
  return markdown;
};

export const extractMarkdownVideoUrl = (markdown: string, pageType: DeviceContentPageType): string => {
  const coords: Nullable<{ start: number; end: number }> = findMarkdownVideoCoords(markdown, pageType);
  if (coords) {
    return markdown.substring(coords.start, coords.end);
  }
  return '';
};

const findMarkdownVideoCoords = (markdown: string, pageType: DeviceContentPageType): Nullable<{ start: number; end: number }> => {
  if (isVideoPage(pageType) && markdown?.startsWith('![](')) {
    const endVideoIndex: number = markdown.indexOf(')');
    if (endVideoIndex >= 0) {
      return { start: 4, end: endVideoIndex };
    }
  }
};

export type RankType = 'rank' | 'programStep';
export type TimestampType = 'releasedTs' | 'endDateTs' | 'endTs';

export const constructRankedMap = <T extends ProgramContent | DeviceContentPage | WorkflowTaskTemplate>(
  rankFieldName: RankType,
  rankedContent?: Nullable<T[]>,
  requiredFieldName?: Nullable<string>,
  newId?: Nullable<string>
): Map<number, string> => {
  const rankedMap: Map<number, string> = new Map<number, string>();
  let maxStep: number = 0;
  if (rankedContent?.length) {
    rankedContent?.forEach((content) => {
      if (content[rankFieldName] && (!requiredFieldName || content[requiredFieldName]) && content.id) {
        const programStep: number = content[rankFieldName] || 0;
        rankedMap.set(programStep, content.id);
        maxStep = Math.max(maxStep, programStep);
      }
    });
  }
  if (newId) {
    rankedMap.set(maxStep + 1, newId);
  }
  return rankedMap;
};

export const constructOrderedMapContent = <T extends ProgramContent | DeviceContentPage | WorkflowTaskTemplate>(
  rankMap: Map<number, string>,
  expiredSet: Set<string>,
  rankFieldName: RankType,
  includeExpired: boolean,
  tsFieldName: TimestampType,
  tsFieldValue?: Nullable<string>,
  rankedContent?: Nullable<T[]>,
  newContent?: Nullable<T>
): T[] => {
  const orderedContent: T[] = [];
  const content: T[] = rankedContent || [];
  rankMap.forEach((id, rank) => {
    let selectedContent: Nullable<T> = content.find((x) => x.id === id);
    if (!selectedContent && newContent && id === newContent?.id) {
      selectedContent = newContent;
    }
    if (selectedContent) {
      const updatedContent: T = { ...selectedContent };
      updatedContent[rankFieldName] = rank;
      orderedContent.push(updatedContent);
    }
  });
  if (includeExpired) {
    expiredSet.forEach((programId) => {
      let selectedContent: Nullable<T> = content.find((x) => x.id === programId);
      if (selectedContent) {
        const updatedContent: T = { ...selectedContent };
        updatedContent[tsFieldName] = tsFieldValue;
        orderedContent.push(updatedContent);
      }
    });
  }
  orderedContent.sort((p1, p2) => (p1[rankFieldName] || 0) - (p2[rankFieldName] || 0));
  return orderedContent;
};

export const mapProgramTypeToDeviceContentType = (programType: ProgramType): DeviceContentType => programType.toString() as DeviceContentType;

export const isVideoPage = (pageType: DeviceContentPageType): boolean => pageType === DeviceContentPageType.VIDEO || pageType === DeviceContentPageType.ANIMATION;

export const extractAnswer = (surveyQuestion: SurveyQuestion): Nullable<string> => {
  const firstAnswer: Nullable<SurveyOption> = surveyQuestion?.surveyOptions?.find((option) => !!option.surveyResponse);
  return firstAnswer?.surveyResponse?.longText ? firstAnswer.surveyResponse.longText : firstAnswer?.title;
};

export const contentDisplayName = (deviceContent?: Nullable<DeviceContent>): string => {
  if (deviceContent?.title) {
    return (deviceContent?.subTitle ? `(${deviceContent.subTitle}) ` : '') + deviceContent.title;
  }
  return deviceContent?.description || '';
};

export const sortedMutableFlagTasks = (flag: WorkflowTemplate): WorkflowTaskTemplate[] => cloneDeep(flag?.workflowTasks)?.sort((t1, t2) => t1.rank - t2.rank);

const isUpperCase = (string) => /^[A-Z]*$/.test(string);

export const CBTI_NATIVE_CONTENT_CATEGORY: string = '0179e724-0a62-688c-af75-4cdb62daf300';
export const AD_HOC_CONTENT_CATEGORY: string = '00000000-0005-0000-0000-000000000064';
export const CBTI_14_DAY_FREE_TRIAL_CATEGORY: string = '00000000-0002-0000-0000-000000000014';
