import React from 'react';
import { SVGBasicType } from 'types/global';
import { Color } from 'utils/color';

export const ChatIcon = ({ width = 20, height = 20, color = Color.GRAY }: SVGBasicType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3328 1.875C8.93007 1.87469 7.55112 2.23757 6.33021 2.9283C5.1093 3.61904 4.08802 4.6141 3.36578 5.81664C2.64353 7.01918 2.24492 8.38822 2.20876 9.79052C2.17259 11.1928 2.50009 12.5806 3.15939 13.8188L2.27267 16.4789C2.19922 16.6992 2.18856 16.9355 2.24189 17.1615C2.29521 17.3874 2.41042 17.5941 2.57459 17.7582C2.73876 17.9224 2.9454 18.0376 3.17137 18.0909C3.39733 18.1443 3.63368 18.1336 3.85392 18.0602L6.51408 17.1734C7.60374 17.753 8.81112 18.0767 10.0446 18.12C11.278 18.1633 12.5051 17.925 13.6327 17.4232C14.7603 16.9215 15.7588 16.1694 16.5524 15.2241C17.3459 14.2789 17.9137 13.1652 18.2126 11.9678C18.5115 10.7703 18.5337 9.52047 18.2775 8.31315C18.0212 7.10584 17.4933 5.97276 16.7338 4.99993C15.9743 4.02711 15.0031 3.24009 13.894 2.69864C12.7849 2.15718 11.567 1.87551 10.3328 1.875ZM10.3328 16.875C9.12422 16.8758 7.93681 16.5575 6.89064 15.9523C6.81404 15.9079 6.72888 15.8803 6.6408 15.8713C6.55271 15.8622 6.46372 15.872 6.3797 15.9L3.45783 16.875L4.43205 13.9531C4.46013 13.8692 4.47006 13.7802 4.46117 13.6921C4.45228 13.604 4.42478 13.5188 4.38048 13.4422C3.62265 12.132 3.31838 10.6083 3.51488 9.10747C3.71138 7.60667 4.39767 6.21267 5.46727 5.14171C6.53688 4.07076 7.93001 3.38271 9.43056 3.18431C10.9311 2.98592 12.4552 3.28826 13.7664 4.04444C15.0775 4.80062 16.1025 5.96837 16.6823 7.36652C17.2621 8.76468 17.3643 10.3151 16.973 11.7773C16.5817 13.2394 15.7189 14.5316 14.5183 15.4533C13.3177 16.375 11.8464 16.8748 10.3328 16.875Z"
        fill={color}
      />
    </svg>
  );
};
