import { Button } from '@mui/material';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { getSelectedPersonVitalInsights } from '@redux/person/personSelector';
import { getPersonVitalInsightsThunk } from '@redux/person/personThunks';
import { PersonVitalTimeFrame, PersonVitalType } from '@redux/person/personTypes';
import { ReduxState } from '@redux/types';
import { EnumSelect } from 'components/EnumSelect/EnumSelect';
import { Text } from 'components/Typography';
import { Charts } from 'containers/PatientDashboard/components/Charts/Charts';
import { InsightsGrid } from '../InsightsGrid/InsightsGrid';
import { SleepInsightsGrid } from '../InsightsGrid/SleepInsightsGrid';
import style from './Insights.scss';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & {
  personId: string;
  timeZoneId: string;
};

const Insights: React.FC<Props> = ({ personId, timeZoneId, selectedPersonVitalInsights, getPersonVitalInsights }) => {
  const [vitalType, setVitalType] = useState<PersonVitalType>(PersonVitalType.HEART_RATE);
  const [timeFrame, setTimeFrame] = useState<PersonVitalTimeFrame>(PersonVitalTimeFrame.TWENTY_FOUR_HOURS);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const { patientMetrics, sleepPatientMetrics, controlLevel } = selectedPersonVitalInsights ?? {};

  const onSelectionChange = useCallback((selected: string) => {
    setVitalType(selected as PersonVitalType);
  }, []);

  useEffect(() => {
    const getData = async () => {
      setIsLoadingData(true);
      await getPersonVitalInsights(personId, vitalType, timeFrame);
      setIsLoadingData(false);
    };

    getData();
  }, [vitalType, timeFrame, personId]);

  return (
    <div className={style.topContainer}>
      <div className={style.leftContainer}>
        <div className={style.insightsTextContainer}>
          <Text variant="xl">Insights</Text>
        </div>
        <div>
          <Text variant="sm" className={style.defaultGrayText}>
            Select related data
          </Text>
          <EnumSelect enumData={PersonVitalType} onSelectionChange={onSelectionChange} initialSelection={PersonVitalType.HEART_RATE} />
          {vitalType === PersonVitalType.SLEEP ? (
            <SleepInsightsGrid
              containerClassName={style.metricsContainer}
              patientMetrics={!isLoadingData ? sleepPatientMetrics : undefined}
              controlLevel={!isLoadingData ? controlLevel : undefined}
              timeFrame={timeFrame}
            />
          ) : (
            <InsightsGrid
              containerClassName={style.metricsContainer}
              patientMetrics={!isLoadingData ? patientMetrics : undefined}
              controlLevel={!isLoadingData ? controlLevel : undefined}
            />
          )}
        </div>
      </div>

      <div className={style.rightContainer}>
        <div className={style.durationHeader}>
          {Object.keys(PersonVitalTimeFrame).map((key, index) => {
            return (
              <Button
                key={index}
                className={classNames(style.tab, { [style.active]: timeFrame === PersonVitalTimeFrame[key] })}
                onClick={() => setTimeFrame(PersonVitalTimeFrame[key])}
              >
                {PersonVitalTimeFrame[key]}
              </Button>
            );
          })}
        </div>
        <Charts
          timeZoneId={timeZoneId}
          personVitalInsights={selectedPersonVitalInsights}
          vitalType={vitalType}
          timeFrame={timeFrame}
          minRate={patientMetrics?.minRate}
          maxRate={patientMetrics?.maxRate}
          minControlRate={controlLevel?.normalMinRate}
          maxControlRate={controlLevel?.normalMaxRate}
        />
      </div>
    </div>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    selectedPersonVitalInsights: getSelectedPersonVitalInsights(state)
  }),
  {
    getPersonVitalInsights: getPersonVitalInsightsThunk
  }
);

export default compose(connectRedux)(Insights);
