import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Text } from '../Typography/Text';
import style from './LinkCrumb.scss';

interface Props {
  label: string;
  url: string;
  state?: Nullable<any>;
}

export const UserProfileCrumb: React.FC<Props> = ({ label, url, state }): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();
  const { userId } = params || {};

  return (
    <div onClick={() => navigate(`/patients/${userId}`)}>
      <Text variant="md" className={style.linkText}>
        {label}
      </Text>
    </div>
  );
};
