import { IconButton } from '@material-ui/core';
import React from 'react';
import { TaskStatusEnum } from '@redux/tasks/tasksTypes';
import { DropDownMenu } from 'components/DropDownMenu/DropDownMenu';
import { DotsThreeIcon, ListChecksIcon, XSquareIcon } from 'components/Icons';
import { TaskMenuProps } from 'containers/Tasks/components/TasksTable/TasksTable.types';
import { Color } from 'utils/color';
import style from './TaskMenu.scss';

export const TaskMenu: React.FC<TaskMenuProps> = ({ onSelectTask, onCancelTask, status }) => {
  return (
    <DropDownMenu
      customMenuElement={(onMenuClick) => (
        <IconButton onClick={onMenuClick} size="small">
          <DotsThreeIcon />
        </IconButton>
      )}
      menuItems={[
        {
          text: 'View details',
          onClick: onSelectTask,
          icon: () => <ListChecksIcon width={16} height={16} />
        },
        {
          text: 'Cancel',
          textClassName: style.redText,
          onClick: onCancelTask,
          icon: () => <XSquareIcon width={16} height={16} color={Color.RED_600} />,
          disabled: status !== TaskStatusEnum.SCHEDULED
        }
      ]}
    />
  );
};
