import { startAnalyticsThunk } from '../analytics/analyticsThunks';
import { registerClientDeviceThunk } from '../clientDevice/clientDeviceThunks';
import { isUserLoggedInSelector } from '../oauth/oauthSelectors';
import { ThunkParams } from '../types';

export function hydrateStartThunk(): any {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    // register regardless of whether the device has been already registered
    // in case any device info changes
    if (isUserLoggedInSelector(getState())) {
      dispatch(registerClientDeviceThunk(getState().clientDevice));
    }
    dispatch(startAnalyticsThunk(getState().analytics));
  };
}
