import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectOption } from '@redux/common/types';
import { StyledBreadcrumb } from 'utils/styles';
import { goTo } from 'utils/util';

interface Props {
  selectedIndex: number;
  valueOptions: SelectOption[];
}

export const SelectCrumb: React.FC<Props> = ({ selectedIndex, valueOptions }): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  const anchorRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const onCloseMenu = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <StyledBreadcrumb
        label={valueOptions?.[selectedIndex]?.label}
        onClick={() => setOpen(!open)}
        ref={anchorRef}
        deleteIcon={<ExpandMoreIcon />}
        onDelete={() => setOpen(!open)}
      />
      <Popper open={open} anchorEl={anchorRef.current} transition style={{ zIndex: 2147483647 }}>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper style={{ maxHeight: '400px', overflowY: 'auto' }}>
              <ClickAwayListener onClickAway={onCloseMenu}>
                <MenuList>
                  {valueOptions.map((valueOption, index) => (
                    <MenuItem key={index} selected={selectedIndex === index} onClick={goTo(valueOption.value, navigate)}>
                      {valueOption.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
