import { Box, Grid } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import React, { memo } from 'react';
import { PersonWorkflow, PersonWorkflowComment, PersonWorkflowStatus, PersonWorkflowTask, PersonWorkflowTaskStatus } from '@redux/content/contentTypes';
import { Person } from '@redux/person/personTypes';
import { FlagBullet } from '../FlagBullet/FlagBullet';
import { FlagCommentBullet } from '../FlagCommentBullet/FlagCommentBullet';
import { FlagStatusBullet } from '../FlagStatusBullet/FlagStatusBullet';
import { FlagTaskBullet } from '../FlagTaskBullet/FlagTaskBullet';
import style from './FlagCards.scss';

interface Props {
  personWorkflows?: Nullable<PersonWorkflow[]>;
  authenticatedPersonId?: Nullable<string>;
  personById: Record<string, Person>;
  saveFlagComment: (personWorkflowComment: PersonWorkflowComment) => void;
  saveFlagTaskStatus: (personWorkflowTaskStatus: PersonWorkflowTaskStatus) => void;
  includeWorkflowStatuses?: Nullable<boolean>;
}

const sortedWorkflowTasks = (personWorkflow: PersonWorkflow): PersonWorkflowTask[] =>
  cloneDeep(personWorkflow?.personWorkflowTasks)?.sort((s1, s2) => s1?.workflowTask?.rank - s2?.workflowTask?.rank);
const sortedWorkflowComments = (personWorkflow: PersonWorkflow): PersonWorkflowComment[] =>
  cloneDeep(personWorkflow?.personWorkflowComments)?.sort((s1, s2) => s2?.created?.localeCompare(s1?.created));
const sortedWorkflowStatuses = (personWorkflow: PersonWorkflow): PersonWorkflowStatus[] =>
  cloneDeep(personWorkflow?.personWorkflowStatuses)?.sort((s1, s2) => s2?.submittedTs?.localeCompare(s1?.submittedTs));

export const FlagCards: React.FC<Props> = memo(({ personWorkflows, authenticatedPersonId, personById, saveFlagComment, saveFlagTaskStatus, includeWorkflowStatuses }) => {
  const latestFlagOfType = (personWorkflow: PersonWorkflow): boolean => {
    if (!personWorkflow.completedTs) {
      return true;
    } else {
      const latestFlag: Nullable<PersonWorkflow> = personWorkflows
        ?.filter((w) => w.workflowId === personWorkflow.workflowId)
        .reduce((acc, cv) => {
          if (!acc || !cv.completedTs) {
            return cv;
          } else if (!acc.completedTs) {
            return acc;
          } else if (acc.completedTs.localeCompare(cv.completedTs) >= 0) {
            return acc;
          } else {
            return cv;
          }
        });
      return personWorkflow.id === latestFlag?.id;
    }
  };

  return (
    <React.Fragment>
      {personWorkflows?.map((personWorkflow, index) => {
        const latestWorkflowOfType: boolean = latestFlagOfType(personWorkflow) && !personWorkflow.expirationTs;

        return (
          <Grid key={index} item xs={12}>
            <Box key={index} mt={1} className={style.underline} style={{ color: personWorkflow.completedTs ? 'gray' : undefined }}>
              <FlagBullet
                style={{ marginBottom: '0', padding: '0 0 5px 0' }}
                workflowType={personWorkflow?.workflow?.workflowType}
                title={personWorkflow?.workflow?.name}
                personWorkflowId={personWorkflow?.id}
                flagComplete={!!personWorkflow.completedTs}
                saveFlagComment={saveFlagComment}
              />
              {sortedWorkflowComments(personWorkflow)?.map((comment, index) => (
                <FlagCommentBullet
                  key={index}
                  personWorkflowComment={comment}
                  authorInitials={`${personById?.[comment?.authorPersonId]?.givenNames?.charAt(0)}${personById?.[comment?.authorPersonId]?.familyName?.charAt(0)}`}
                />
              ))}
              {sortedWorkflowTasks(personWorkflow)
                ?.filter((personTask) => !personTask.expirationTs)
                .map((personTask, index) => (
                  <FlagTaskBullet
                    key={index}
                    personWorkflowTask={personTask}
                    authenticatedPersonId={authenticatedPersonId}
                    latestWorkflowOfType={latestWorkflowOfType}
                    saveFlagTaskStatus={saveFlagTaskStatus}
                  />
                ))}
              {includeWorkflowStatuses &&
                sortedWorkflowStatuses(personWorkflow)?.map((personStatus, index) => {
                  const submitterFirstName: Nullable<string> = personStatus?.submitterPersonId ? personById?.[personStatus?.submitterPersonId]?.givenNames : '';
                  return <FlagStatusBullet key={index} personWorkflowStatus={personStatus} submitterFirstName={submitterFirstName} />;
                })}
            </Box>
          </Grid>
        );
      })}
    </React.Fragment>
  );
});
