import moment from 'moment-timezone';
import { PersonVitalTimeFrame, PersonVitalType, VitalSignsStatus } from '@redux/person/personTypes';
import { getVitalStatusOutOfRangeText } from 'containers/PatientDashboard/PatientDashboardUtil';
import exclamationOctagon from '../assets/images/exclamationOctagon.png';
import yellowOctagon from '../assets/images/yellowOctagon.png';
import { Color } from './color';
import { formatMinutesToHoursAndMinutes } from './time';

const RATE_RANGE = 10;
const CONTROL_RANGE = 20;

export const getDateFormat = (timeFrame?: PersonVitalTimeFrame) => {
  switch (timeFrame) {
    case PersonVitalTimeFrame.TWENTY_FOUR_HOURS:
      // return 'hh:mm a';
      return null;
    case PersonVitalTimeFrame.SEVEN_DAYS:
      return 'EEE, d MMM';
    case PersonVitalTimeFrame.THIRTY_DAYS:
      return 'd MMM';
    case PersonVitalTimeFrame.TWELVE_MONTHS:
      return 'MMM yy';
    default:
      return null;
  }
};

export const getXAxisGridlineCount = (timeFrame?: PersonVitalTimeFrame) => {
  switch (timeFrame) {
    case PersonVitalTimeFrame.TWENTY_FOUR_HOURS:
      return 12;
    case PersonVitalTimeFrame.SEVEN_DAYS:
      return 7;
    case PersonVitalTimeFrame.THIRTY_DAYS:
      return 15;
    case PersonVitalTimeFrame.TWELVE_MONTHS:
      return 12;
    default:
      return 12;
  }
};

export const getYMinValue = (vitalType: PersonVitalType, minRate: number = -10, minControlRate: number = -10): number => {
  //undefined will be transformed to a negative value and then the max will be taken
  let value: number;

  switch (vitalType) {
    case PersonVitalType.HEART_RATE: {
      value = Math.min(minRate - RATE_RANGE, minControlRate - CONTROL_RANGE, 30);
      break;
    }
    case PersonVitalType.BREATHING_RATE:
    default: {
      value = Math.min(minRate - RATE_RANGE, minControlRate - CONTROL_RANGE, 0);
    }
  }

  return value < 0 ? 0 : Math.trunc(value);
};

export const getYMaxValue = (vitalType: PersonVitalType, maxRate: number = 0, maxControlRate: number = 0): number => {
  let value: number;

  switch (vitalType) {
    case PersonVitalType.BREATHING_RATE: {
      value = Math.max(maxRate + RATE_RANGE, maxControlRate + CONTROL_RANGE, 60);
      break;
    }
    case PersonVitalType.HEART_RATE:
    default: {
      value = Math.max(maxRate + RATE_RANGE, maxControlRate + CONTROL_RANGE, 140);
    }
  }

  return Math.trunc(value);
};

export const getChartColor = (vitalType?: PersonVitalType) => {
  switch (vitalType) {
    case PersonVitalType.HEART_RATE:
      return Color.PINK_600;
    case PersonVitalType.BREATHING_RATE:
    case PersonVitalType.SLEEP:
    default:
      return Color.DARK_BLUE_500;
  }
};

export const getMinTime = (dateNow: Date, timeFrame?: PersonVitalTimeFrame) => {
  const now = moment(dateNow);
  switch (timeFrame) {
    case PersonVitalTimeFrame.SEVEN_DAYS:
      return now.subtract(7, 'days').toDate();
    case PersonVitalTimeFrame.THIRTY_DAYS:
      return now.subtract(30, 'days').toDate();
    case PersonVitalTimeFrame.TWELVE_MONTHS:
      return now.subtract(12, 'months').toDate();
    case PersonVitalTimeFrame.TWENTY_FOUR_HOURS:
    default:
      return now.subtract(1, 'days').toDate();
  }
};

export const generateChartTicks = (minValue: number, maxValue: number, minControlValue?: number, maxControlValue?: number) => {
  let countedTicks = [] as number[];
  const difference = maxValue - minValue;
  const stepSize = difference < 100 ? 10 : 20;

  for (let value = minValue; value < maxValue; value += stepSize) {
    countedTicks.push(Math.floor(value / 10) * 10);
  }

  if (minControlValue || maxControlValue) {
    countedTicks = countedTicks.filter((value) => value !== minControlValue && value !== maxControlValue);
  }

  return [...countedTicks, { v: minControlValue, f: minControlValue }, { v: maxControlValue, f: maxControlValue }];
};

export const updateControlLinesStyles = (minControlRate?: number, maxControlRate?: number): void => {
  let minControlRateIndex: number | undefined = undefined;
  let maxControlRateIndex: number | undefined = undefined;

  const titles = [...document.querySelectorAll('svg[aria-label="A chart."] text[text-anchor="end"]')];
  titles?.forEach((title, index) => {
    const titleText = title.innerHTML;
    if (+titleText === minControlRate) {
      minControlRateIndex = index;
      title.setAttribute('fill', Color.DARK_GREEN);
    }
    if (+titleText === maxControlRate) {
      maxControlRateIndex = index;
      title.setAttribute('fill', Color.DARK_GREEN);
    }
  });

  const lines = [...document.querySelectorAll('svg[aria-label="A chart."] g[clip-path] g rect[stroke-width="0"]')];
  lines.forEach((line, index) => {
    if (index === minControlRateIndex || index === maxControlRateIndex) {
      line.setAttribute('fill', Color.DARK_GREEN);
    }
  });
};

export const updateCandleStickStyles = (): void => {
  // the approach doesn't work because the candlesticks are rendered each time when showing or hiding a tooltip
  const candleSticks = [...document.querySelectorAll('svg[aria-label="A chart."] g[clip-path] g rect[stroke-width="2"]')];
  candleSticks.forEach((candleStick) => {
    candleStick.setAttribute('rx', '6');
  });
};

export const updateHeaderStyles = (): void => {
  const header = document.querySelector('svg[aria-label="A chart."] text[text-anchor="start"]');
  header?.setAttribute('x', '8');
  header?.setAttribute('y', '24');
};

export const createCustomTooltip = (start: Date, end: Date, min: number | string, max: number | string, timeFrame?: PersonVitalTimeFrame, status?: VitalSignsStatus) => {
  let dateTooltip = '';
  switch (timeFrame) {
    case PersonVitalTimeFrame.TWENTY_FOUR_HOURS:
      dateTooltip = `${moment(start).format('D MMM, hh:mm A')} - ${moment(end).format('hh:mm A')}`;
      break;
    case PersonVitalTimeFrame.SEVEN_DAYS: {
      dateTooltip = `${moment(start).format('ddd, D MMM')}`;
      break;
    }
    case PersonVitalTimeFrame.THIRTY_DAYS:
      dateTooltip = `${moment(start).format('D MMM YYYY')}`;
      break;
    case PersonVitalTimeFrame.TWELVE_MONTHS:
      dateTooltip = `${moment(start).format('MMM YYYY')}`;
      break;
    default:
      dateTooltip = `${moment(start).format('MMM YYYY')}`;
      break;
  }

  let statusTooltip = '';
  if (status === VitalSignsStatus.ABNORMAL || status === VitalSignsStatus.SUBOPTIMAL) {
    statusTooltip = `<div style="margin: 16px 0; padding: 0;  height: 20px">
      <image src="${status === VitalSignsStatus.ABNORMAL ? exclamationOctagon : yellowOctagon}" style="width: 20px; position: absolute" />
      <p style="padding: 0; margin: 0 0 0 28px; color:${status === VitalSignsStatus.ABNORMAL ? Color.RED_600 : Color.YELLOW_400}; font-size:14px; line-height: 20px;">${getVitalStatusOutOfRangeText(status)}</p>
    </div>`;
  }

  return `<div style="height: ${statusTooltip ? '166px' : '130px'}; padding: 24px; border-radius: 16px; white-space: nowrap;">
    <p style="padding: 0; margin: 0 0 4px;  color:${Color.GRAY}; font-size:14px; line-height: 20px;">Range</p>
    <p style="padding: 0; margin: 0; color:${Color.DARK_BLUE_NEW}; font-size:30px; line-height: 38px;">${min} - ${max} bpm</p>
    <p style="padding: 0; margin: 0; color:${Color.GRAY}; font-size:14px; line-height: 20px;">${dateTooltip}</p>
    ${statusTooltip}
    </div>`;
};

export const createSleepTooltip = (start: Date, end: Date, stage: string, timeFrame: PersonVitalTimeFrame) => {
  const dateTooltip = `${moment(start).format('D MMM, hh:mm A')} - ${moment(end).format('hh:mm A')}`;
  const duration = moment(end).diff(moment(start), 'minutes');

  return `<div style="height: 130px; padding: 24px; border-radius: 16px; white-space: nowrap;">
    <p style="padding: 0; margin: 0; color:${Color.DARK_BLUE_NEW}; font-size:30px; line-height: 38px;">${stage}</p>
    <p style="padding: 0; margin: 0; color:${Color.GRAY}; font-size:14px; line-height: 20px;">${dateTooltip}</p>
    <p style="padding: 0; margin: 0; color:${Color.GRAY}; font-size:14px; line-height: 20px;">Duration: ${duration} min</p>
    </div>`;
};

export const createAggregatedSleepTooltip = (date: string, awake: number, light: number, deep: number, rem: number) => {
  const totalSleepMinutes = awake + light + deep + rem;
  const totalSleepDuration = formatMinutesToHoursAndMinutes(totalSleepMinutes, true);

  return `<div style="height: 220px; width: 160px; padding: 16px; border-radius: 16px; white-space: nowrap; font-family: sans-serif;">
      <p style="padding: 0; margin: 0; color:${Color.GRAY}; font-size: 14px; line-height: 20px;">${date}</p>
      <p style="padding: 0; margin: 4px 0; color:${Color.DARK_BLUE_NEW}; font-size: 14px; line-height: 20px;">Total sleep</p>
      <p style="padding: 0; margin: 0; color:${Color.DARK_BLUE_NEW}; font-size: 24px; line-height: 32px; margin-bottom: 16px;">${totalSleepDuration}</p>

      <div style="display: flex; flex-direction: column; gap: 8px;">
          <div style="display: flex; align-items: center; color:${Color.GRAY}; font-size: 12px; line-height: 18px;">
              <div style="width: 8px; height: 8px; border-radius: 50%; background-color: ${Color.DARK_BLUE_700}; margin-right: 8px;"></div>
              REM <span style="margin-left: auto;">${formatMinutesToHoursAndMinutes(rem, true)}</span>
          </div>
          <div style="display: flex; align-items: center; color:${Color.GRAY}; font-size: 12px; line-height: 18px;">
              <div style="width: 8px; height: 8px; border-radius: 50%; background-color: ${Color.DARK_BLUE_600}; margin-right: 8px;"></div>
              Deep <span style="margin-left: auto;">${formatMinutesToHoursAndMinutes(deep, true)}</span>
          </div>
          <div style="display: flex; align-items: center; color:${Color.GRAY}; font-size: 12px; line-height: 18px;">
              <div style="width: 8px; height: 8px; border-radius: 50%; background-color: ${Color.DARK_BLUE_500}; margin-right: 8px;"></div>
              Light <span style="margin-left: auto;">${formatMinutesToHoursAndMinutes(light, true)}</span>
          </div>
          <div style="display: flex; align-items: center; color:${Color.GRAY}; font-size: 12px; line-height: 18px;">
              <div style="width: 8px; height: 8px; border-radius: 50%; background-color: ${Color.GRAY_400}; margin-right: 8px;"></div>
              Awake <span style="margin-left: auto;">${formatMinutesToHoursAndMinutes(awake, true)}</span>
          </div>
      </div>
  </div>`;
};
