import { List, MenuItem, Popover } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { getOrganizationByIdSelector } from '@redux/organization/organizationSelectors';
import { getAllOrganizationsThunk } from '@redux/organization/organizationThunks';
import {
  getPermissionRoleByIdSelector,
  getPermissionRoleTypeByIdSelector,
  getPersonByIdSelector,
  getPersonPermissionRoleByIdSelector,
  getPersonPermissionRoleIdsByPersonIdSelector,
  hasAuthUserAdminPermissionSelector
} from '@redux/person/personSelector';
import { getAllPermissionRolesThunk, getPersonPermissionsByOrgIdThunk, putPersonPermissionRoleThunk, removePersonPermissionRoleThunk } from '@redux/person/personThunks';
import { ReduxState } from '@redux/types';
import { AddCrossIcon, CancelCrossIcon } from 'components/Icons';
import {
  CarePlanAutocomplete,
  CarePlanAutocompletePaper,
  CarePlanAutocompletePopper,
  CarePlanAutocompleteTextField
} from '../../components/CarePlanAutocomplete/CarePlanAutocomplete';
import NavSideBarContentWrapper from '../../components/NavSideBarContentWrapper/NavSideBarContentWrapper';
import { Text } from '../../components/Typography/Text';
import style from './AdminUserPermissions.scss';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & {};

const AdminUserPermissions: React.FC<Props> = ({
  organizationById,
  getPersonPermissionsByOrgId,
  getAllOrganizations,
  removePersonPermissionRole,
  putPersonPermissionRole,
  getAllPermissionRoles,
  hasAuthUserAdminPermission,
  personPermissionRoleIdsByPersonId,
  permissionRoleTypeById,
  permissionRoleById,
  personById,
  personPermissionRoleById
}) => {
  if (!hasAuthUserAdminPermission) {
    return null;
  }
  const [selectedOrgId, setSelectedOrgId] = useState<Nullable<string>>(null);
  const [addAnchorEl, setAddAnchorEl] = useState<Nullable<HTMLElement>[]>(Array(Object.values(permissionRoleById).length).fill(null));
  const [addOpen, setAddOpen] = useState<boolean[]>(Array(Object.values(permissionRoleById).length).fill(false));

  useEffect(() => {
    getAllOrganizations();
    getAllPermissionRoles();
  }, []);

  const handleAddButtonClick = (index: number, event: any) => {
    const addAnchorElCopy = [...addAnchorEl];
    addAnchorElCopy[index] = event.currentTarget;
    setAddAnchorEl(addAnchorElCopy);
    const addOpenCopy = [...addOpen];
    addOpenCopy[index] = true;
    setAddOpen(addOpenCopy);
  };

  const handleAddButtonClose = (index: number) => {
    const addAnchorElCopy = [...addAnchorEl];
    addAnchorElCopy[index] = null;
    setAddAnchorEl(addAnchorElCopy);
    const addOpenCopy = [...addOpen];
    addOpenCopy[index] = false;
    setAddOpen(addOpenCopy);
  };

  const orgIds: { name: string; id?: string }[] = [];
  Object.values(organizationById).forEach((org) => {
    orgIds.push({ name: org.name, id: org.id });
  });

  const handleOrgIdChange = (event, value) => {
    if (value?.id) {
      setSelectedOrgId(value.id);
      getPersonPermissionsByOrgId(value.id);
    }
  };

  const onRemovePermissionRole = async (personPermissionRoleId: string, permissionRoleName: string) => {
    if (confirm(`Are you sure you want to remove this permission role: ${permissionRoleName}?`)) {
      await removePersonPermissionRole(personPermissionRoleId);
    }
  };

  // event handler to add a permission role to a person
  const onAddPermissionRole = async (index: number, personId: string, permissionRoleId: string) => {
    await putPersonPermissionRole(personId, permissionRoleId);
    handleAddButtonClose(index);
  };

  const selectedValue = orgIds.find((org) => org.id === selectedOrgId) || null;

  return (
    <NavSideBarContentWrapper headerText="User Permissions">
      <div className={style.orgHeader}>
        <Text variant="sm" className={style.labelSelect}>
          Choose Organization
        </Text>
        <CarePlanAutocomplete
          options={orgIds}
          value={selectedValue} // Ensure value is not undefined
          onChange={handleOrgIdChange}
          getOptionLabel={(option) => (option as any).name || ''}
          isOptionEqualToValue={(option, value) => (option as any).id === (value as any).id}
          PaperComponent={CarePlanAutocompletePaper}
          PopperComponent={CarePlanAutocompletePopper}
          renderInput={(params) => <CarePlanAutocompleteTextField {...params} placeholder="Select" />}
          renderOption={(props, option, state) => (
            <li {...props} key={(option as any).id}>
              <div>{(option as any).name}</div>
            </li>
          )}
        />
      </div>
      {selectedOrgId ? (
        <table className={style.table}>
          <thead>
            <tr>
              <th>
                <Text variant="sm">Name</Text>
              </th>
              <th>
                <Text variant="sm">Email</Text>
              </th>
              <th>
                <Text variant="sm">Permissions</Text>
              </th>
              <th>
                <Text variant="sm">OrganizationId</Text>
              </th>
              <th>
                <Text variant="sm">PersonId</Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(personPermissionRoleIdsByPersonId).map((personId, personIdIndex) => {
              const person = personById[personId];
              const permissionRoleIds: string[] = [];
              if (person?.organizationId === selectedOrgId) {
                return (
                  <tr key={personId}>
                    <td>
                      <Text variant="sm">
                        {person.givenNames} {person.familyName}
                      </Text>
                    </td>
                    <td className={style.wrap}>
                      <Text variant="sm" className={style.textWrap}>
                        {person.email}
                      </Text>
                    </td>
                    <td>
                      {personPermissionRoleIdsByPersonId[personId]?.length === 0 && (
                        <button className={style.pillAdd} onClick={(event) => handleAddButtonClick(personIdIndex, event)}>
                          <AddCrossIcon width={20} height={20} />
                        </button>
                      )}
                      {personPermissionRoleIdsByPersonId[personId]?.map((personPermissionRoleId, index, array) => {
                        const isLast = index === array.length - 1;
                        const personPermissionRole = personPermissionRoleById[personPermissionRoleId];
                        if (personPermissionRole) {
                          const permissionRole = permissionRoleById[personPermissionRole.permissionRoleId];
                          if (permissionRole) {
                            permissionRoleIds.push(permissionRole.id);
                            const permissionRoleName = permissionRoleTypeById[permissionRole.permissionRoleTypeId].name;
                            return (
                              <div key={personPermissionRoleId} className={classNames(style.pillContainer, isLast && style.last)}>
                                <div className={style.pill}>
                                  <Text variant="sm">{permissionRoleName}</Text>
                                  <button className={style.pillMinus} onClick={() => onRemovePermissionRole(personPermissionRoleId, permissionRoleName)}>
                                    <CancelCrossIcon width={20} height={20} />
                                  </button>
                                </div>
                                {isLast && (
                                  <button className={classNames(style.pillAdd, style.pillAddAbsolute)} onClick={(event) => handleAddButtonClick(personIdIndex, event)}>
                                    <AddCrossIcon width={20} height={20} />
                                  </button>
                                )}
                              </div>
                            );
                          }
                        }
                      })}
                      <Popover
                        anchorEl={addAnchorEl[personIdIndex]}
                        open={addOpen[personIdIndex] || false}
                        onClose={() => handleAddButtonClose(personIdIndex)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right'
                        }}
                        className={style.popover}
                      >
                        <List className={style.contextMenu}>
                          {Object.keys(permissionRoleById).map((permissionRoleId) => {
                            const permissionRoleList = permissionRoleById[permissionRoleId];
                            if (!permissionRoleList) return null;
                            const permissionRoleTypeList = permissionRoleTypeById[permissionRoleList.permissionRoleTypeId];
                            if (!permissionRoleTypeList) return null;
                            if (permissionRoleIds.includes(permissionRoleList.id)) return null;
                            return (
                              <MenuItem key={permissionRoleId} onClick={() => onAddPermissionRole(personIdIndex, personId, permissionRoleId)}>
                                <Text variant="md">{permissionRoleTypeList?.name}</Text>
                              </MenuItem>
                            );
                          })}
                        </List>
                      </Popover>
                    </td>
                    <td>
                      <Text variant="sm">{person.organizationId}</Text>
                    </td>
                    <td>
                      <Text variant="sm">{person.id}</Text>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      ) : null}
    </NavSideBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    organizationById: getOrganizationByIdSelector(state),
    personById: getPersonByIdSelector(state),
    hasAuthUserAdminPermission: hasAuthUserAdminPermissionSelector(state),
    personPermissionRoleIdsByPersonId: getPersonPermissionRoleIdsByPersonIdSelector(state),
    personPermissionRoleById: getPersonPermissionRoleByIdSelector(state),
    permissionRoleTypeById: getPermissionRoleTypeByIdSelector(state),
    permissionRoleById: getPermissionRoleByIdSelector(state)
  }),
  {
    getPersonPermissionsByOrgId: getPersonPermissionsByOrgIdThunk,
    getAllOrganizations: getAllOrganizationsThunk,
    removePersonPermissionRole: removePersonPermissionRoleThunk,
    putPersonPermissionRole: putPersonPermissionRoleThunk,
    getAllPermissionRoles: getAllPermissionRolesThunk
  }
);

export default compose(connectRedux)(AdminUserPermissions);
