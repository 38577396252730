import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { Button, Menu, MenuItem } from '@mui/material';
import classNames from 'classnames';
import React, { memo, useState } from 'react';
import { Text } from 'components/Typography';
import { SVGBasicType } from 'types/global';
import { Color } from 'utils/color';
import style from './DropDownMenu.scss';

export type DropDownMenuProps = {
  menuText?: string;
  customButtonClass?: string;
  additionalButtonClass?: string;
  customMenuElement?: (onMenuClick: (event) => void) => React.JSX.Element;
  menuItems: MenuItemType[];
};

export type MenuItemType = MenuItemProps | false;

export type MenuItemProps = {
  onClick?: () => void;
  text?: string;
  textClassName?: string;
  disabled?: boolean;
  icon?: React.FC<SVGBasicType>;
  customMenuItem?: () => React.JSX.Element;
};

export const DropDownMenu: React.FC<DropDownMenuProps> = memo(({ menuText, customMenuElement, customButtonClass, additionalButtonClass, menuItems = [] }) => {
  const [menuAnchorElement, setMenuAnchorElement] = useState(null);
  const isMenuOpen = Boolean(menuAnchorElement);

  const onMenuClick = (event) => {
    event.stopPropagation();
    setMenuAnchorElement(event.currentTarget);
  };

  const onMenuClose = (event) => {
    event.stopPropagation();
    setMenuAnchorElement(null);
  };
  const filteredMenuItems = menuItems.filter(Boolean) as MenuItemProps[];

  const getMenuItem = (menuItem: MenuItemProps, index): React.JSX.Element => {
    let menuItemElement;

    if (menuItem.customMenuItem) {
      menuItemElement = menuItem.customMenuItem();
    } else if (menuItem.icon) {
      const Icon = menuItem.icon;
      menuItemElement = (
        <div className={style.menuItem}>
          <Icon />
          <Text variant="md" className={classNames(style.menuItemText, menuItem.textClassName)}>
            {menuItem.text}
          </Text>
        </div>
      );
    } else {
      menuItemElement = (
        <div className={classNames(style.menuItemText, menuItem.textClassName)} key={menuItem.text}>
          {menuItem.text}
        </div>
      );
    }

    return (
      <MenuItem
        key={index}
        disabled={menuItem.disabled}
        onClick={(event) => {
          event.stopPropagation();
          menuItem.onClick?.();
          onMenuClose(event);
        }}
      >
        {menuItemElement}
      </MenuItem>
    );
  };

  return (
    <>
      {!customMenuElement && (
        <Button
          className={classNames(customButtonClass || [style.button, additionalButtonClass], isMenuOpen ? style.active : {})}
          onClick={onMenuClick}
          endIcon={isMenuOpen ? <KeyboardArrowUp htmlColor={Color.DARK_BLUE_NEW} /> : <KeyboardArrowDown htmlColor={Color.DARK_BLUE_NEW} />}
        >
          {menuText}
        </Button>
      )}
      {customMenuElement && customMenuElement(onMenuClick)}
      <Menu disableEnforceFocus anchorEl={menuAnchorElement} open={isMenuOpen} onClose={onMenuClose} className={style.menu} onClick={(event) => event.stopPropagation()}>
        {filteredMenuItems.map((menuItem, index) => getMenuItem(menuItem, index))}
      </Menu>
    </>
  );
});
