import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import {
  hasClinicalAccountsCreatePermissionSelector,
  hasNonClinicalAccountsCreatePermissionSelector,
  hasOrgAdminAccountsCreatePermissionSelector,
  hasTeamMemberAccountsCreatePermissionSelector,
  isLoadingPostNewTeamMemberSelector
} from '@redux/person/personSelector';
import { postNewTeamMemberThunk } from '@redux/person/personThunks';
import {
  NewTeamMemberRequest,
  PERMISSION_ROLE_ID_CARE_NAVIGATOR,
  PERMISSION_ROLE_ID_MD,
  PERMISSION_ROLE_ID_NP,
  PERMISSION_ROLE_ID_ORG_ADMIN,
  PERMISSION_ROLE_ID_RN
} from '@redux/person/personTypes';
import { ReduxState } from '@redux/types';
import envelopeSimpleIcon from '../../assets/images/envelope-simple.png';
import { Button } from '../../components/Button/Button';
import {
  CarePlanAutocomplete,
  CarePlanAutocompletePaper,
  CarePlanAutocompletePopper,
  CarePlanAutocompleteTextField as CarePlanTextField
} from '../../components/CarePlanAutocomplete/CarePlanAutocomplete';
import NavTopBarContentWrapper from '../../components/NavTopBarContentWrapper/NavTopBarContentWrapper';
import { TextField } from '../../components/TextField';
import { Text } from '../../components/Typography/Text';
import { emailAlertMessage, isEmailValid } from '../../utils/util';
import style from './NewTeamMember.scss';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & {};

const NewTeamMember: React.FC<Props> = ({
  isLoadingPostNewTeamMember,
  postNewTeamMember,
  hasTeamMemberAccountsCreatePermission,
  hasClinicalAccountsCreatePermission,
  hasNonClinicalAccountsCreatePermission,
  hasOrgAdminAccountsCreatePermission
}) => {
  if (!hasTeamMemberAccountsCreatePermission) {
    return null;
  }
  const [personFormData, setPersonFormData] = useState({
    givenNames: '',
    familyName: '',
    email: '',
    permissionRoleType: {
      id: '',
      name: ''
    }
  });
  const [errors, setErrors] = useState({
    email: ''
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [apiError, setApiError] = useState('');

  const isSubmitBtnDisabled = isLoadingPostNewTeamMember || !personFormData.givenNames || !personFormData.familyName || !personFormData.email || !personFormData.permissionRoleType;

  const handlePersonChange = (e) => {
    setSuccessMessage('');
    setApiError('');
    const { name, value } = e.target;
    setPersonFormData({
      ...personFormData,
      [name]: value
    });
  };

  const handlePermissionTypeChange = (event, value) => {
    setSuccessMessage('');
    setApiError('');
    setPersonFormData({
      ...personFormData,
      permissionRoleType: value
    });
  };

  const validateForm = () => {
    const newErrors = {
      email: isEmailValid(personFormData.email) ? '' : emailAlertMessage(personFormData.email) || ''
    };

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === '');
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const newTeamMember: NewTeamMemberRequest = { ...personFormData, permissionRoleTypeId: personFormData.permissionRoleType.id };
      try {
        setApiError('');
        await postNewTeamMember(newTeamMember);
        setSuccessMessage('Success!');
        setPersonFormData({
          givenNames: '',
          familyName: '',
          email: '',
          permissionRoleType: {
            id: '',
            name: ''
          }
        });
      } catch (err) {
        setApiError('Something went wrong. Please try again.');
      }
    }
  };

  const allowedTypes: { name: string; id?: string }[] = [{ name: '-- Select--', id: '' }];
  if (hasOrgAdminAccountsCreatePermission) {
    allowedTypes.push({ name: 'Organization Admin', id: PERMISSION_ROLE_ID_ORG_ADMIN });
  }
  if (hasClinicalAccountsCreatePermission) {
    allowedTypes.push({ name: 'Medical Doctor', id: PERMISSION_ROLE_ID_MD });
    allowedTypes.push({ name: 'Nurse Practitioner', id: PERMISSION_ROLE_ID_NP });
    allowedTypes.push({ name: 'Resident Nurse', id: PERMISSION_ROLE_ID_RN });
  }
  if (hasNonClinicalAccountsCreatePermission) {
    allowedTypes.push({ name: 'Care Navigator', id: PERMISSION_ROLE_ID_CARE_NAVIGATOR });
  }

  return (
    <NavTopBarContentWrapper centerAlignContent headerText="New team member">
      <form onSubmit={handleFormSubmit} className={style.mainContainer}>
        <div className={style.subContainer}>
          {/* TYPE */}
          <div>
            <Text variant="sm" className={style.labelSelect}>
              Member Type
            </Text>
            <div>
              <CarePlanAutocomplete
                options={allowedTypes}
                value={personFormData.permissionRoleType || null} // Ensure value is not undefined
                onChange={handlePermissionTypeChange}
                getOptionLabel={(option) => (option as any).name || ''}
                isOptionEqualToValue={(option, value) => (option as any).id === (value as any).id}
                PaperComponent={CarePlanAutocompletePaper}
                PopperComponent={CarePlanAutocompletePopper}
                renderInput={(params) => <CarePlanTextField {...params} placeholder="Select" />}
                renderOption={(props, option, state) => (
                  <li {...props} key={(option as any).id}>
                    <div>{(option as any).name}</div>
                  </li>
                )}
              />
            </div>
          </div>
          {/* EMAIL */}
          <div className={style.formRow}>
            <TextField
              name="email"
              value={personFormData.email}
              onChange={handlePersonChange}
              label="Email"
              placeholder="Email"
              autoComplete="new-password"
              error={errors.email}
              leading={<img src={envelopeSimpleIcon} />}
            />
          </div>
          <div className={style.formRow}>
            {/* FIRST NAME */}
            <TextField name="givenNames" value={personFormData.givenNames} onChange={handlePersonChange} label="First name" placeholder="First name" autoComplete="new-password" />
            {/* LAST NAME */}
            <TextField name="familyName" value={personFormData.familyName} onChange={handlePersonChange} label="Last name" placeholder="Last name" autoComplete="new-password" />
          </div>
        </div>
        <Button type="submit" variant="contained" disabled={isSubmitBtnDisabled} className={style.button}>
          Add new team member
        </Button>
        {!!apiError && (
          <Text variant="sm" className={style.apiError}>
            {apiError}
          </Text>
        )}
        {!!successMessage && (
          <Text variant="sm" className={style.successMessage}>
            {successMessage}
          </Text>
        )}
      </form>
    </NavTopBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    isLoadingPostNewTeamMember: isLoadingPostNewTeamMemberSelector(state),
    hasTeamMemberAccountsCreatePermission: hasTeamMemberAccountsCreatePermissionSelector(state),
    hasClinicalAccountsCreatePermission: hasClinicalAccountsCreatePermissionSelector(state),
    hasNonClinicalAccountsCreatePermission: hasNonClinicalAccountsCreatePermissionSelector(state),
    hasOrgAdminAccountsCreatePermission: hasOrgAdminAccountsCreatePermissionSelector(state)
  }),
  {
    postNewTeamMember: postNewTeamMemberThunk
  }
);

export default compose(connectRedux)(NewTeamMember);
