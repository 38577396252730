import React from 'react';
import { Color } from 'utils/color';

const colorMap = {
  CONNECTED: Color.GREEN,
  DISCONNECTED: Color.YELLOW,
  HEALTHY: Color.GREEN,
  UNHEALTHY: Color.RED,
  UNKNOWN: Color.ORANGE
};

export const CircleIndicator: React.FC<{ status?: Nullable<string> }> = ({ status }) => {
  const size = 7; // px
  const color: Color = colorMap[!status ? 'UNKNOWN' : status];
  return (
    <div
      style={{
        backgroundColor: !color ? Color.ORANGE : color,
        borderRadius: size / 2,
        display: 'inline-block',
        height: size,
        marginRight: size / 2,
        marginBottom: (10 - size) / 2,
        width: size
      }}
    />
  );
};
