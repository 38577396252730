import { Add } from '@mui/icons-material';
import { Box, Drawer, ListItemIcon, Menu, MenuItem, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { getCarePlanActivitiesByIdSelector, getPersonWithCarePlansByIdSelector } from '@redux/carePlan/carePlanSelectors';
import { createNewActivityThunk, fetchCarePlanActivitiesThunk } from '@redux/carePlan/carePlanThunks';
import { ActivityListItem, FreeFormMessage, NewCarePlanActivityRequest, NewCarePlanActivityStep, TriggerIDs } from '@redux/carePlan/carePlanTypes';
import { DeviceContentType } from '@redux/content/contentTypes';
import { getDevicePersonOverviewThunk, getDeviceUserTimezoneThunk } from '@redux/device/deviceThunks';
import { ReduxState } from '@redux/types';
import { NOTEPAD_IMAGE_URL } from 'assets/images/images';
import { CloseIconButton } from 'components/Button/CloseButton/CloseIconButton';
import { timestampAndRandomCombinedUUIDString } from '../../../shared/src/util/uuid';
import ListPlusIcon from '../../assets/images/ListPlus.png';
import StethoscopeIcon from '../../assets/images/stethoscope.png';
import { Button } from '../../components/Button/Button';
import {
  CarePlanAutocomplete,
  CarePlanAutocompletePaper,
  CarePlanAutocompletePopper,
  CarePlanAutocompleteTextField
} from '../../components/CarePlanAutocomplete/CarePlanAutocomplete';
import DatePicker from '../../components/DatePickerV2/DatePickerV2';
import NavTopBarContentWrapper from '../../components/NavTopBarContentWrapper/NavTopBarContentWrapper';
import { Text } from '../../components/Typography';
import DraggableList from '../../containers/DraggableList/DraggableList';
import { DEFAULT_TIMEZONE_ID, formatDateWithLuxon, getNowRoundedTo30Minutes } from '../../utils/time';
import { AssessmentsLibraryDrawer } from './AssessmentsLibraryDrawer';
import { FreeFormMessageLibraryDrawer } from './FreeFormMessageLibraryDrawer';
import style from './NewActivity.scss';
import { QuestionLibraryDrawer } from './QuestionLibraryDrawer';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & {};

const MAX_ACTIVITY_ITEMS = 30;

const NewActivity: React.FC<Props> = ({ personWithCarePlansById, fetchCarePlanActivities, fetchDevicePersonOverview, activitiesById, createNewActivity, getDeviceTimeZone }) => {
  const [activityAnchorEl, setActivityAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const activityAnchorOpen = Boolean(activityAnchorEl);
  const [currentFreeFormMessage, setCurrentFreeFormMessage] = useState<Nullable<FreeFormMessage>>(null);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [triggerId, setTriggerId] = useState<string>('');
  const [expHours, setExpHours] = useState<Nullable<number>>(null);

  const [showQuestionLibrary, setShowQuestionLibrary] = useState(false);
  const [showAssessments, setShowAssessments] = useState(false);
  const [showFreeFormMessage, setShowFreeFormMessage] = useState(false);

  // const [activityListItems, setActivityListItems] = useState<ActivityListItem[]>(['00000000-0002-0000-0000-000000000005', '00000000-0002-0000-0000-000000000001']);
  const [activityListItems, setActivityListItems] = useState<ActivityListItem[]>([]);

  const afterTs = selectedDate && DateTime.fromJSDate(selectedDate);

  const params = useParams();
  const { userId } = params || {};
  const person = userId ? personWithCarePlansById[userId] : undefined;
  const fullName = person?.fullName || '';

  const [timeZone, setTimezone] = useState(DEFAULT_TIMEZONE_ID);

  const navigate = useNavigate();

  useEffect(() => {
    fetchCarePlanActivities();
    fetchDevicePersonOverview();
    getDeviceTimeZone({ userId, setTimezone });
  }, []);

  const handleActivityButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActivityAnchorEl(event.currentTarget);
  };

  const handleActivityButtonClose = () => {
    setActivityAnchorEl(null);
  };

  const onQuestionLibrarySubmit = (questionIds: string[]) => {
    const tempDeviceContentIds = activityListItems;
    const existingQuestionIdMap = {};
    activityListItems.map((item) => {
      if (typeof item === 'string') {
        const deviceContent = activitiesById[item];
        if (deviceContent.deviceContentType === DeviceContentType.CARE_QUESTION) {
          existingQuestionIdMap[item] = true;
        }
      }
    });
    const newQuestionIdMap = {};
    questionIds.forEach((id) => {
      newQuestionIdMap[id] = true;
    });

    Object.keys(existingQuestionIdMap).forEach((id) => {
      if (!newQuestionIdMap[id]) {
        tempDeviceContentIds.splice(tempDeviceContentIds.indexOf(id), 1);
      }
    });

    Object.keys(newQuestionIdMap).forEach((id) => {
      if (!existingQuestionIdMap[id]) {
        tempDeviceContentIds.push(id);
      }
    });

    if (tempDeviceContentIds.length > MAX_ACTIVITY_ITEMS) {
      showMaxQuestionsAlert();
    } else {
      setActivityListItems(tempDeviceContentIds);
      setShowQuestionLibrary(false);
      setActivityAnchorEl(null);
    }
  };

  const onSelectAssessment = (deviceContentId: string) => {
    const tempDeviceContentIds = activityListItems;
    if (!tempDeviceContentIds.includes(deviceContentId)) {
      tempDeviceContentIds.push(deviceContentId);
    }
    setActivityListItems(tempDeviceContentIds);
    setShowAssessments(false);
    setActivityAnchorEl(null);
  };

  const onSubmitFreeFormMessage = (freeFormMessage: FreeFormMessage) => {
    const activityListItemsCopy = [...activityListItems];
    if (!freeFormMessage.id) {
      freeFormMessage.id = timestampAndRandomCombinedUUIDString();
      activityListItemsCopy.push(freeFormMessage);
    } else {
      activityListItems.forEach((item, index) => {
        if (typeof item !== 'string' && item.id === freeFormMessage.id) {
          activityListItemsCopy[index] = freeFormMessage;
        }
      });
    }
    if (activityListItemsCopy.length > MAX_ACTIVITY_ITEMS) {
      showMaxQuestionsAlert();
    } else {
      setActivityListItems(activityListItemsCopy);
      setShowFreeFormMessage(false);
      setActivityAnchorEl(null);
    }
  };

  const showMaxQuestionsAlert = () => {
    alert(`You can only add up to ${MAX_ACTIVITY_ITEMS} questions and free form messages pluse one assessment. Please select fewer questions.`);
  };

  const onDrawerCancelClick = () => {
    setShowAssessments(false);
    setShowFreeFormMessage(false);
    setShowQuestionLibrary(false);
    setActivityAnchorEl(null);
  };

  const submitNewActivity = async () => {
    const activitySteps: NewCarePlanActivityStep[] = [];
    activityListItems.forEach((item) => {
      if (typeof item === 'string') {
        activitySteps.push({ deviceContentId: item });
      } else {
        activitySteps.push({ message: item.message, shouldPatientAnswer: item.shouldPatientAnswer });
      }
    });
    if (!afterTs || !triggerId || !expHours) {
      return;
    }
    let expirationTs = afterTs?.plus({ hours: expHours });
    if (expirationTs.day !== afterTs.day) {
      expirationTs = expirationTs.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).minus({ milliseconds: 1 });
    }
    const afterTsUTC = afterTs?.setZone('utc', { keepLocalTime: true });
    const expirationTsUTC = expirationTs?.setZone('utc', { keepLocalTime: true });
    const newCarePlanActivity: NewCarePlanActivityRequest = {
      triggerId: triggerId,
      afterTs: afterTsUTC?.toISO() || '',
      expirationTs: expirationTsUTC?.toISO(),
      activitySteps
    };
    try {
      await createNewActivity(userId!, newCarePlanActivity);
      navigate(`/patients/${userId}`);
    } catch (e) {
      console.log(e);
      alert('Failed to create new activity. Please make sure the user has a device linked.');
    }
  };

  const onEditListItem = (index: number) => {
    if (typeof activityListItems[index] === 'string') {
      const deviceContentId = activityListItems[index] as string;
      const deviceContent = activitiesById[deviceContentId];
      if (deviceContent.deviceContentType === DeviceContentType.CARE_ASSESSMENT) {
        setShowAssessments(true);
      } else {
        setShowQuestionLibrary(true);
      }
    } else {
      setCurrentFreeFormMessage(activityListItems[index] as FreeFormMessage);
      setShowFreeFormMessage(true);
    }
  };
  const onCancelClick = () => {
    navigate(`/patients/${userId}`);
  };

  const isDisabled = !activityListItems.length || !afterTs || !triggerId || !expHours;

  const isDrawerOpen = showQuestionLibrary || showAssessments || showFreeFormMessage;

  const questions = Object.values(activitiesById).filter((activity) => activity.deviceContentType === DeviceContentType.CARE_QUESTION);
  const assessments = Object.values(activitiesById).filter((activity) => activity.deviceContentType === DeviceContentType.CARE_ASSESSMENT);

  const hoursLeftAfterTs = afterTs ? 24 - afterTs.hour : null;
  const hoursLeftArray: number[] = [];
  if (hoursLeftAfterTs) {
    for (let i = 1; i <= hoursLeftAfterTs; i++) {
      hoursLeftArray.push(i);
    }
  }

  interface TriggerOption {
    id: TriggerIDs;
    label: string;
  }

  const TriggerOption: TriggerOption[] = [
    { id: TriggerIDs.IN_BED, label: 'When the patient is in the bed' },
    { id: TriggerIDs.OUT_BED, label: 'When the patient is near the bed' }
  ];

  return (
    <NavTopBarContentWrapper
      centerAlignContent
      headerText="Create new activity"
      headerContent={
        <div className={style.headerButtonsContainer}>
          <Button variant="outlined" onClick={onCancelClick} className={style.headerButton}>
            Cancel
          </Button>
          <Button variant="contained" onClick={submitNewActivity} className={style.headerButton} disabled={isDisabled}>
            Submit
          </Button>
        </div>
      }
    >
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <div className={style.container}>
          <div className={style.content}>
            <div className={style.contentRow}>
              <div className={!activityListItems?.length ? style.activityHeaderRow : style.activityHeaderRowContent}>
                <div className={style.activityHeader}>
                  <Text as="div" variant="md">
                    Add Activity
                  </Text>
                  <Text as="div" variant="md" className={style.rowDescription}>
                    Select the question from the library and set the order
                  </Text>
                </div>
                <Button
                  variant="outlined"
                  id="basic-button"
                  aria-controls={activityAnchorOpen ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={activityAnchorOpen ? 'true' : undefined}
                  onClick={handleActivityButtonClick}
                  startIcon={<Add />}
                  className={!activityListItems?.length ? style.btnActivity : style.btnActivityContent}
                >
                  Add activity
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={activityAnchorEl}
                  open={activityAnchorOpen && !isDrawerOpen}
                  onClose={handleActivityButtonClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                  className={style.contextMenu}
                >
                  <MenuItem onClick={() => setShowQuestionLibrary(true)}>
                    <ListItemIcon>
                      <img src={NOTEPAD_IMAGE_URL} className={style.contextIcons} />
                    </ListItemIcon>
                    <span className={style.menuItemText}>Question Library</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setCurrentFreeFormMessage(null);
                      setShowFreeFormMessage(true);
                    }}
                  >
                    <ListItemIcon>
                      <img src={ListPlusIcon} className={style.contextIcons} />
                    </ListItemIcon>
                    <span className={style.menuItemText}>Free form message</span>
                  </MenuItem>
                  <MenuItem onClick={() => setShowAssessments(true)}>
                    <ListItemIcon>
                      <img src={StethoscopeIcon} className={style.contextIcons} />
                    </ListItemIcon>
                    <span className={style.menuItemText}>Assessments</span>
                  </MenuItem>
                </Menu>
              </div>
              {activityListItems && activityListItems.length > 0 && (
                <div>
                  <DraggableList onEditListItem={onEditListItem} activityListItems={activityListItems} activityById={activitiesById} setActivitListItems={setActivityListItems} />
                </div>
              )}
            </div>
            <div className={style.contentRow}>
              <Text as="div" variant="md">
                Add Conditions
              </Text>
              <Text as="div" variant="md" className={style.rowDescription}>
                Set condition to be met for the activity below to trigger
              </Text>
              <div className={style.conditionsRow}>
                <div className={style.datepicker}>
                  <DatePicker
                    id="activity-datetime"
                    disablePast
                    date={selectedDate}
                    onChange={(date) => setSelectedDate(date as Date | undefined)}
                    showTimeInput
                    variant="default"
                    timeCaption={`Patient's time: ${formatDateWithLuxon(null, timeZone)}`}
                    minDate={getNowRoundedTo30Minutes(timeZone)}
                  />
                </div>
                <div className={style.triggerSelect}>
                  <CarePlanAutocomplete
                    id="select-trigger-autocomplete"
                    options={TriggerOption}
                    onChange={(event, value) => setTriggerId((value as TriggerOption).id)}
                    getOptionLabel={(option) => (option as TriggerOption).label || ''}
                    isOptionEqualToValue={(option, value) => (option as TriggerOption).id === (value as TriggerOption).id}
                    PaperComponent={CarePlanAutocompletePaper}
                    PopperComponent={CarePlanAutocompletePopper}
                    renderInput={(params) => <CarePlanAutocompleteTextField {...params} placeholder="Trigger" />}
                    renderOption={(props, option, state) => (
                      <li {...props} key={(option as TriggerOption).id}>
                        <Box>{(option as TriggerOption).label}</Box>
                      </li>
                    )}
                  />
                </div>
              </div>
              {/* </Select>
            </FormControl> */}
            </div>
            <div className={style.contentRow}>
              <Text as="div" variant="md">
                Expiration time
              </Text>
              <Text as="div" variant="md" className={style.rowDescription}>
                The final deadline by which a specific activity must be completed
              </Text>
              <TextField
                value={expHours || ''}
                onChange={(event) => setExpHours(parseInt(event.target.value))}
                select // tell TextField to render select
                label="Expiration"
                className={style.expirationSelect}
                size="small"
                disabled={!afterTs}
              >
                {hoursLeftArray.map((hour) => (
                  <MenuItem key={hour} value={hour}>
                    {hour}h
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <Drawer anchor="right" open={isDrawerOpen} onClose={onDrawerCancelClick}>
            <div className={style.drawerContainer}>
              <div className={style.drawerHeader}>
                <CloseIconButton onClick={onDrawerCancelClick} />
              </div>
              {showQuestionLibrary && (
                <QuestionLibraryDrawer activityListItems={activityListItems} questions={questions} onSubmit={onQuestionLibrarySubmit} onCancel={onDrawerCancelClick} />
              )}
              {showAssessments && <AssessmentsLibraryDrawer assessments={assessments} onSelectAssessment={onSelectAssessment} onCancel={onDrawerCancelClick} />}
              {showFreeFormMessage && <FreeFormMessageLibraryDrawer onSubmit={onSubmitFreeFormMessage} onCancel={onDrawerCancelClick} freeFormMessage={currentFreeFormMessage} />}
            </div>
          </Drawer>
        </div>
      </LocalizationProvider>
    </NavTopBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    personWithCarePlansById: getPersonWithCarePlansByIdSelector(state),
    activitiesById: getCarePlanActivitiesByIdSelector(state)
  }),
  {
    fetchCarePlanActivities: fetchCarePlanActivitiesThunk,
    fetchDevicePersonOverview: getDevicePersonOverviewThunk,
    getDeviceTimeZone: getDeviceUserTimezoneThunk,
    createNewActivity: createNewActivityThunk
  }
);

export default compose(connectRedux)(NewActivity);
