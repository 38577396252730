import { Container, MuiThemeProvider, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { cloneDeep, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { Error } from '@redux/common/types';
import { getClearResponseStatusAction } from '@redux/content/contentActions';
import { getContentErrorSelector, getContentIsLoadingSelector, getContentSuccessSelector, getFlagByIdSelector, getSaveSuccessfulSelector } from '@redux/content/contentSelectors';
import { getFlagContentThunk, saveFlagContentThunk } from '@redux/content/contentThunks';
import { PersonWorkflow, WorkflowTaskTemplate, WorkflowTemplate, WorkflowType } from '@redux/content/contentTypes';
import { ReduxState } from '@redux/types';
import { timestampAndRandomCombinedUUIDString } from '../../../shared/src/util/uuid';
import { ContentTile } from '../../components/ContentTile/ContentTile';
import { Header } from '../../components/Header/Header';
import { RankedContentIcons } from '../../components/RankedContentIcons/RankedContentIcons';
import { StatusMessage } from '../../components/StatusMessage/StatusMessage';
import { getIconForWorkflow } from '../../utils/breadcrumbs';
import { normalizeEnumName, sortedMutableFlagTasks } from '../../utils/content';
import { getActiveRecord, hasActiveRecord } from '../../utils/createFrontEndError';
import { defaultTheme } from '../../utils/styles';
import style from './FlagDetails.scss';

interface Props {
  flagId?: Nullable<string>;
  flagType?: Nullable<WorkflowType>;
  flagById: Record<string, WorkflowTemplate>;
  personId?: Nullable<string>;
  isLoading: Record<string, boolean>;
  error: Record<string, Error>;
  success: Record<string, boolean>;
  saveSuccessful?: Nullable<boolean>;
  getFlag?: Nullable<(flagId: string) => void>;
  saveFlag: (flag: WorkflowTemplate, personId?: Nullable<string>) => void;
  clearResponseStatus: () => void;
}

const createNewFlag = (workflowType: Nullable<WorkflowType>): WorkflowTemplate => {
  return {
    name: 'Type name here...',
    id: timestampAndRandomCombinedUUIDString(),
    workflowType: workflowType || WorkflowType.UNKNOWN,
    workflowTasks: [],
    personWorkflows: []
  };
};

const createNewTask = (flag: WorkflowTemplate): WorkflowTaskTemplate => {
  return {
    id: timestampAndRandomCombinedUUIDString(),
    workflowId: flag?.id || '',
    title: 'Type task here...',
    rank: findMax(flag) + 1
  };
};

const findMax = (flag: WorkflowTemplate): number => flag?.workflowTasks?.reduce((accumulator, currentValue) => Math.max(accumulator, currentValue.rank), 0) || 0;

export const FlagDetails: React.FC<Props> = ({ flagId, flagType, flagById, personId, isLoading, error, success, saveSuccessful, getFlag, saveFlag, clearResponseStatus }) => {
  const [flag, setFlag] = useState<WorkflowTemplate>(flagId && flagById?.[flagId] ? flagById?.[flagId] : createNewFlag(flagType));

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const flagId = params['flagId'];
    if (getFlag && flagId) {
      getFlag(flagId);
    }
  }, [params]);

  useEffect(() => {
    setFlag(() => (flagId && flagById?.[flagId] ? flagById?.[flagId] : createNewFlag(flagType)));
  }, [flagId, flagType, flagById]);

  const addNewTask = (): void => {
    const updatedFlag: WorkflowTemplate = cloneDeep(flag);
    const workflowTasks: WorkflowTaskTemplate[] = updatedFlag?.workflowTasks || [];
    workflowTasks.push(createNewTask(flag));
    updatedFlag.workflowTasks = workflowTasks;
    setFlag(() => updatedFlag);
  };

  const removeTask = (task: WorkflowTaskTemplate): void => {
    const updatedFlag: WorkflowTemplate = cloneDeep(flag);
    const index: number = updatedFlag?.workflowTasks?.findIndex((t) => t.rank === task.rank) || 0;
    const length = updatedFlag.workflowTasks?.length || 0;
    for (let i: number = index + 1; i < length; i++) {
      if (updatedFlag.workflowTasks) {
        updatedFlag.workflowTasks[i].rank = updatedFlag.workflowTasks?.[i].rank - 1;
      }
    }
    updatedFlag?.workflowTasks?.splice(index, 1);
    setFlag(() => updatedFlag);
  };

  const moveTask = (task: WorkflowTaskTemplate, moveIncrement: number): void => {
    const updatedFlag: WorkflowTemplate = cloneDeep(flag);
    const index: number = updatedFlag?.workflowTasks?.findIndex((t) => t.rank === task.rank) || 0;
    if (updatedFlag.workflowTasks) {
      updatedFlag.workflowTasks[index].rank = updatedFlag.workflowTasks[index].rank + moveIncrement;
      updatedFlag.workflowTasks[index + moveIncrement].rank = updatedFlag.workflowTasks[index + moveIncrement].rank - moveIncrement;
      updatedFlag.workflowTasks.sort((t1, t2) => t1.rank - t2.rank);
    }

    setFlag(() => updatedFlag);
  };

  const editFlag = (text: string): void => {
    const updatedFlag: WorkflowTemplate = cloneDeep(flag);
    updatedFlag.name = text;
    setFlag(() => updatedFlag);
  };

  const editTask = (text: string, task: WorkflowTaskTemplate): void => {
    const updatedFlag: WorkflowTemplate = cloneDeep(flag);
    const index: number = updatedFlag?.workflowTasks?.findIndex((t) => t.rank === task.rank) || 0;
    if (updatedFlag.workflowTasks) {
      updatedFlag.workflowTasks[index].title = text;
    }
    setFlag(() => updatedFlag);
  };

  const deleteWorkflow = async (): Promise<any> => {
    if (flag?.id) {
      const updatedFlag: WorkflowTemplate = cloneDeep(flag);
      updatedFlag.expirationTs = new Date().toISOString();
      await saveFlag(updatedFlag);
      navigate(-1);
    }
  };

  const saveWorkflow = (): void => {
    if (flagType && !flag.id) {
      flag.id = timestampAndRandomCombinedUUIDString();
      let personWorkflow: PersonWorkflow;
      if (personId) {
        personWorkflow = {
          id: timestampAndRandomCombinedUUIDString(),
          personId: personId,
          workflowId: flag.id,
          workflowType: flag.workflowType,
          openedTs: new Date().toISOString(),
          personWorkflowTasks: []
        };
        flag.personWorkflows = [personWorkflow];
      }
      flag?.workflowTasks?.forEach((task) => {
        if (!task.id) {
          task.id = timestampAndRandomCombinedUUIDString();
        }
        if (!task.workflowId) {
          task.workflowId = flag.id || '';
        }
      });
    }
    saveFlag(flag);
  };

  const createActionIcons = (task: WorkflowTaskTemplate): JSX.Element => (
    <Box component="div" className={style.tileIconBar} onClick={(e) => e.stopPropagation()}>
      <RankedContentIcons
        remove={() => removeTask(task)}
        moveUp={() => moveTask(task, -1)}
        moveDown={() => moveTask(task, 1)}
        content={task}
        rankFieldName="rank"
        maxPageRank={findMax(flag)}
        fontSize="small"
        cssClass="tileIcon"
      />
    </Box>
  );

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={style.root}>
        <Container maxWidth="xl">
          <Header
            title={flagType ? 'Create New Flag' : 'Edit Flag'}
            saveFunction={saveWorkflow}
            deleteFunction={flag.workflowType === WorkflowType.FLAG_MANUAL && !!flag?.id ? deleteWorkflow : undefined}
            saveSuccessful={saveSuccessful}
            disableSave={isEqual(flagId && flagById?.[flagId] ? flagById?.[flagId] : undefined, flag) || isEqual(createNewFlag(flagType), flag) || !flag?.workflowTasks?.length}
          />
          <Typography variant="h5">Name</Typography>
          <ContentTile title={flag?.name || 'Add flag name'} editTitle={(text) => editFlag(text)} />
          <Typography variant="h5">Type</Typography>
          <ContentTile title={normalizeEnumName(flag?.workflowType)} headElement={<Box ml={1}>{getIconForWorkflow(flag?.workflowType, false)}</Box>} />
          <Typography variant="h5">Flag Task(s)</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            *Must include at least 1 task
          </Typography>
          {sortedMutableFlagTasks(flag)?.map((task, index) => (
            <ContentTile key={index} title={task?.title} tileNumber={task?.rank} tailElement={createActionIcons(task)} editTitle={(text) => editTask(text, task)} />
          ))}
          <ContentTile title="+ Add New Task" onClick={addNewTask} />
          <StatusMessage
            saveSuccessful={!hasActiveRecord(isLoading) && !hasActiveRecord(error) && hasActiveRecord(success)}
            saveUnsuccessful={!hasActiveRecord(isLoading) && hasActiveRecord(error)}
            clearResponseStatus={clearResponseStatus}
            errorMessage={getActiveRecord(error)?.message}
          />
        </Container>
      </div>
    </MuiThemeProvider>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      flagById: getFlagByIdSelector(state),
      isLoading: getContentIsLoadingSelector(state),
      error: getContentErrorSelector(state),
      success: getContentSuccessSelector(state),
      saveSuccessful: getSaveSuccessfulSelector(state)
    };
  },
  (dispatch: Function) => ({
    getFlag: (flagId: string): void => {
      dispatch(getFlagContentThunk(flagId));
    },
    saveFlag: (flag: WorkflowTemplate): void => {
      dispatch(saveFlagContentThunk(flag));
    },
    clearResponseStatus: (): void => {
      dispatch(getClearResponseStatusAction());
    }
  })
);

export default compose(connectRedux)(FlagDetails) as React.ComponentType;
