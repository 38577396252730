import { Box, Button, Chip, Container, MuiThemeProvider, TextField, Typography } from '@material-ui/core';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { BreadCrumbData } from '@redux/common/types';
import { getClearResponseStatusAction } from '@redux/content/contentActions';
import { getSaveSuccessfulSelector, getSelectedDeviceContentPageSelector, getSelectedDeviceContentSelector } from '@redux/content/contentSelectors';
import { addDeviceContentPageThunk, getDeviceContentDetailsThunk, getDeviceContentPageThunk, updateDeviceContentPageThunk } from '@redux/content/contentThunks';
import {
  DeviceContent,
  DeviceContentPage,
  DeviceContentPageType,
  FeatureUnlockType,
  featureUnlockTypeList,
  SurveyOption,
  SurveyOptionType,
  SurveyQuestion
} from '@redux/content/contentTypes';
import { appendBreadcrumbAction } from '@redux/oauth/oauthActions';
import { getBreadcrumbsSelector } from '@redux/oauth/oauthSelectors';
import { ReduxState } from '@redux/types';
import { timestampAndRandomCombinedUUIDString } from '../../../shared/src/util/uuid';
import { Header } from '../../components/Header/Header';
import { MarkdownEditor } from '../../components/MarkdownEditor/MarkdownEditor';
import { QuizOption } from '../../components/QuizOption/QuizOption';
import { SelectButton } from '../../components/SelectButton/SelectButton';
import { StatusMessage } from '../../components/StatusMessage/StatusMessage';
import { defaultPageDetailsCrumb, defaultPageDetailsCrumbs } from '../../utils/breadcrumbs';
import { extractMarkdownPageText, extractMarkdownVideoUrl, isVideoPage, normalizeEnumName } from '../../utils/content';
import { defaultTheme } from '../../utils/styles';
import { areEqualBlank, areEqualZero, arraysAreEqual } from '../../utils/util';
import style from './PageDetails.scss';

interface Props {
  breadCrumbs?: Nullable<BreadCrumbData[]>;
  selectedDeviceContentPage?: Nullable<DeviceContentPage>;
  selectedDeviceContent?: Nullable<DeviceContent>;
  saveSuccessful?: Nullable<boolean>;
  deviceContentPageType?: Nullable<DeviceContentPageType>;
  openAsDialog?: Nullable<boolean>;
  updateDeviceContentPage?: Nullable<(deviceContentPage: DeviceContentPage) => void>;
  addDeviceContentPage?: Nullable<(deviceContentPage: DeviceContentPage) => void>;
  clearResponseStatus: () => void;
  appendBreadCrumbs?: Nullable<(breadCrumb: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => void>;
  getDeviceContentPage: (deviceContentPageId: string) => void;
  getDeviceContentDetails: (deviceContentId: string) => void;
}

const createSurveyOption = (optionId: string, questionId: string, optionTitle: string, index: number, created?: Nullable<string>, modified?: Nullable<string>): SurveyOption => {
  return {
    id: optionId,
    surveyQuestionId: questionId,
    title: optionTitle,
    rank: index + 1,
    optionType: SurveyOptionType.FIXED_VALUE,
    created: created,
    modifiedDate: modified
  };
};

export const getDefaultOption = (): SurveyOption => createSurveyOption(timestampAndRandomCombinedUUIDString(), '', '', 0);

export const PageDetails: React.FC<Props> = ({
  breadCrumbs,
  selectedDeviceContentPage,
  selectedDeviceContent,
  saveSuccessful,
  deviceContentPageType,
  openAsDialog,
  updateDeviceContentPage,
  addDeviceContentPage,
  clearResponseStatus,
  appendBreadCrumbs,
  getDeviceContentPage,
  getDeviceContentDetails
}) => {
  const params = useParams();
  const location: any = useLocation();

  const { pageType } = params || {};

  const defaultMarkdown: string = addDeviceContentPage ? '# This is the header of Page 1 \n This is the body of page 1 as you can see here.' : '';

  const getCorrectIndex = (): Nullable<number> => {
    const surveyOptionId: Nullable<string> = selectedDeviceContentPage?.successSurveyOptionId;
    if (surveyOptionId) {
      const index: Nullable<number> = selectedDeviceContentPage?.surveyQuestion?.surveyOptions?.findIndex((surveyOption) => surveyOption.id === surveyOptionId);
      if (index && index >= 0) {
        return index;
      }
    }
    return undefined;
  };

  const getInitialOptions = (): SurveyOption[] =>
    selectedDeviceContentPage?.surveyQuestion?.surveyOptions?.length ? [...selectedDeviceContentPage.surveyQuestion.surveyOptions] : [getDefaultOption()];

  const getPageType = (): DeviceContentPageType =>
    selectedDeviceContentPage?.deviceContentPageType || deviceContentPageType || (pageType && DeviceContentPageType?.[pageType]) || DeviceContentPageType.UNKNOWN;
  const isQuizPage = (): boolean => getPageType() === DeviceContentPageType.QUIZ;
  const isResultQuizPage = (): boolean =>
    isQuizPage() &&
    (!selectedDeviceContentPage || !!selectedDeviceContentPage?.quizSuccessText || !!selectedDeviceContentPage?.quizFailText || !!selectedDeviceContentPage?.successSurveyOptionId);

  const [unlockFeature, setUnlockFeature] = useState<FeatureUnlockType>(selectedDeviceContentPage?.featureUnlockType || FeatureUnlockType.UNKNOWN);
  const pageTextDispatcher: [string, Dispatch<SetStateAction<string>>] = useState<string>(
    extractMarkdownPageText(selectedDeviceContentPage?.longText || '', getPageType()) || defaultMarkdown
  );
  const [videoUrl, setVideoUrl] = useState<string>(extractMarkdownVideoUrl(selectedDeviceContentPage?.longText || '', getPageType()));
  const [question, setQuestion] = useState<string>(selectedDeviceContentPage?.surveyQuestion?.questionText || '');
  const [correctResponse, setCorrectResponse] = useState<string>(selectedDeviceContentPage?.quizSuccessText || '');
  const [incorrectResponse, setIncorrectResponse] = useState<string>(selectedDeviceContentPage?.quizFailText || '');
  const optionsState: [SurveyOption[], Dispatch<SetStateAction<SurveyOption[]>>] = useState<SurveyOption[]>(getInitialOptions());
  const selectedIndexState: [number, Dispatch<SetStateAction<number>>] = useState<number>(getCorrectIndex() || 0);
  const [quizResultPage, setQuizResultPage] = useState<boolean>(isResultQuizPage());

  const saveChanges = async (
    pageText?: Nullable<string>,
    videoUrl?: Nullable<string>,
    questionText?: Nullable<string>,
    optionTitles?: Nullable<SurveyOption[]>,
    selectedIndexState?: Nullable<number>,
    correctResponse?: Nullable<string>,
    incorrectResponse?: Nullable<string>,
    unlockFeature?: Nullable<FeatureUnlockType>
  ) => {
    if (updateDeviceContentPage && selectedDeviceContentPage) {
      const deviceContentPage: DeviceContentPage = { ...selectedDeviceContentPage };
      deviceContentPage.featureUnlockType = unlockFeature === FeatureUnlockType.UNKNOWN ? undefined : unlockFeature;
      if (questionText) {
        const questionId: string = timestampAndRandomCombinedUUIDString();
        const surveyQuestion: SurveyQuestion = createSurveyQuestion(
          selectedDeviceContentPage.surveyQuestion?.id ? selectedDeviceContentPage.surveyQuestion?.id : questionId,
          selectedDeviceContentPage.surveyQuestion?.surveyId ? selectedDeviceContentPage.surveyQuestion?.surveyId : questionId,
          questionText,
          selectedDeviceContentPage.surveyQuestion?.rank ? selectedDeviceContentPage.surveyQuestion?.rank : 1,
          selectedDeviceContentPage.surveyQuestion?.created,
          selectedDeviceContentPage.surveyQuestion?.modifiedDate
        );
        optionTitles?.map((option, index) => {
          addSurveyOption(option.id, surveyQuestion.id, option.title, index, surveyQuestion, option.created, option.modifiedDate);
        });
        setSharedPageValues(deviceContentPage, surveyQuestion, surveyQuestion.id, selectedIndexState, correctResponse, incorrectResponse);
      } else {
        deviceContentPage.longText = composeMarkdown(pageText, videoUrl) || undefined;
      }
      await updateDeviceContentPage(deviceContentPage);
    } else if (addDeviceContentPage && selectedDeviceContent) {
      const newDeviceContentPage: DeviceContentPage = createDeviceContentPage(timestampAndRandomCombinedUUIDString(), pageText, videoUrl, unlockFeature);
      if (questionText) {
        newDeviceContentPage.longText = undefined;
        const questionId: string = timestampAndRandomCombinedUUIDString();
        const surveyQuestion: SurveyQuestion = createSurveyQuestion(questionId, questionId, questionText, 1);
        optionTitles?.map((option, index) => {
          addSurveyOption(option.id, questionId, option.title, index, surveyQuestion);
        });
        setSharedPageValues(newDeviceContentPage, surveyQuestion, questionId, selectedIndexState, correctResponse, incorrectResponse);
      }
      await addDeviceContentPage(newDeviceContentPage);
    }
  };

  const composeMarkdown = (pageText?: Nullable<string>, pageUrl?: Nullable<string>): string => (pageUrl ? `![](${pageUrl}) \n` : '') + (pageText ? pageText : '');

  const createDeviceContentPage = (
    deviceContentPageId: string,
    pageText?: Nullable<string>,
    videoUrl?: Nullable<string>,
    unlockFeature?: Nullable<FeatureUnlockType>
  ): DeviceContentPage => {
    return {
      id: deviceContentPageId,
      pageId: deviceContentPageId,
      deviceContentId: selectedDeviceContent?.id || '-1',
      version: 1,
      deviceContentPageType: deviceContentPageType ? deviceContentPageType : DeviceContentPageType.UNKNOWN,
      releasedTs: new Date().toISOString(),
      rank: (selectedDeviceContent?.deviceContentPages?.length ? selectedDeviceContent?.deviceContentPages?.length : 0) + 1,
      longText: composeMarkdown(pageText, videoUrl) || undefined,
      featureUnlockType: unlockFeature === FeatureUnlockType.UNKNOWN ? undefined : unlockFeature
    };
  };

  const createSurveyQuestion = (
    questionId: string,
    surveyId: string,
    questionText: string,
    rank: number,
    created?: Nullable<string>,
    modified?: Nullable<string>
  ): SurveyQuestion => {
    return {
      id: questionId,
      surveyId: surveyId,
      questionText: questionText,
      allowMultipleResponses: false,
      rank: rank,
      surveyOptions: [],
      created: created,
      modifiedDate: modified
    };
  };

  const addSurveyOption = (
    optionId: string,
    questionId: string,
    optionTitle: string,
    index: number,
    surveyQuestion: SurveyQuestion,
    created?: Nullable<string>,
    modified?: Nullable<string>
  ): void => {
    const surveyOption: SurveyOption = createSurveyOption(optionId, questionId, optionTitle, index, created, modified);
    surveyQuestion.surveyOptions?.push(surveyOption);
  };

  const setSharedPageValues = (
    deviceContentPage: DeviceContentPage,
    surveyQuestion: SurveyQuestion,
    questionId: string,
    selectedIndexState?: Nullable<number>,
    correctResponse?: Nullable<string>,
    incorrectResponse?: Nullable<string>
  ): void => {
    deviceContentPage.surveyQuestion = surveyQuestion;
    deviceContentPage.surveyQuestionId = questionId;
    deviceContentPage.successSurveyOptionId = selectedIndexState && selectedIndexState >= 0 && quizResultPage ? surveyQuestion.surveyOptions?.[selectedIndexState]?.id : undefined;
    deviceContentPage.quizSuccessText = quizResultPage ? correctResponse : undefined;
    deviceContentPage.quizFailText = quizResultPage ? incorrectResponse : undefined;
  };

  const noChanges = (): boolean => {
    if (isQuizPage()) {
      return (
        areEqualBlank(question, selectedDeviceContentPage?.surveyQuestion?.questionText) &&
        areEqualBlank(correctResponse, selectedDeviceContentPage?.quizSuccessText) &&
        areEqualBlank(incorrectResponse, selectedDeviceContentPage?.quizFailText) &&
        (arraysAreEqual(optionsState[0], selectedDeviceContentPage?.surveyQuestion?.surveyOptions || []) ||
          (!selectedDeviceContentPage?.surveyQuestion?.surveyOptions?.length && arraysAreEqual(optionsState[0], [getDefaultOption()]))) &&
        areEqualZero(getCorrectIndex(), selectedIndexState[0]) &&
        isResultQuizPage() === quizResultPage &&
        (unlockFeature === selectedDeviceContentPage?.featureUnlockType ||
          (unlockFeature === FeatureUnlockType.UNKNOWN && selectedDeviceContentPage?.featureUnlockType === undefined))
      );
    } else {
      return (
        areEqualBlank(videoUrl, extractMarkdownVideoUrl(selectedDeviceContentPage?.longText || '', getPageType())) &&
        (areEqualBlank(pageTextDispatcher[0], extractMarkdownPageText(selectedDeviceContentPage?.longText || '', getPageType())) ||
          areEqualBlank(pageTextDispatcher[0], defaultMarkdown)) &&
        (unlockFeature === selectedDeviceContentPage?.featureUnlockType ||
          (unlockFeature === FeatureUnlockType.UNKNOWN && selectedDeviceContentPage?.featureUnlockType === undefined))
      );
    }
  };

  const getNormalizedPageType = (): string => normalizeEnumName(getPageType());

  const requiredSaveFieldsEmpty = (): boolean => {
    if (isQuizPage()) {
      return !question || !optionsState[0].every((option) => !!option.title);
    } else if (isVideoPage(getPageType())) {
      return !videoUrl;
    } else {
      return !pageTextDispatcher[0];
    }
  };

  useEffect(() => {
    const deviceContentPageId: Nullable<string> = params['deviceContentPageId'];
    if (deviceContentPageId) {
      getDeviceContentPage(deviceContentPageId);
      getDeviceContentDetails(deviceContentPageId);
    }
  }, []);

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={style.root}>
        <Container maxWidth="xl">
          <Header
            title={selectedDeviceContentPage ? `Page ${selectedDeviceContentPage?.rank}` : 'New Page'}
            breadCrumbs={!openAsDialog ? breadCrumbs : []}
            appendBreadCrumbs={appendBreadCrumbs}
            defaultCurrentCrumb={defaultPageDetailsCrumb}
            defaultCrumbHistory={defaultPageDetailsCrumbs}
            overrideUrl={location?.pathname}
            saveFunction={() => saveChanges(pageTextDispatcher[0], videoUrl, question, optionsState[0], selectedIndexState[0], correctResponse, incorrectResponse, unlockFeature)}
            saveSuccessful={saveSuccessful}
            disableSave={requiredSaveFieldsEmpty() || noChanges()}
          />
          <StatusMessage
            saveSuccessful={saveSuccessful !== undefined && saveSuccessful}
            saveUnsuccessful={saveSuccessful !== undefined && !saveSuccessful}
            clearResponseStatus={clearResponseStatus}
          />
          <Box>
            <Box className={style.pageDetailTop}>
              <Chip
                className={style.subTypeChip}
                label={normalizeEnumName(
                  selectedDeviceContentPage?.deviceContentPageType || deviceContentPageType || (pageType && DeviceContentPageType[pageType]) || DeviceContentPageType.UNKNOWN
                )}
                color="primary"
              />
              <Box component="span" className={style.featureSelect}>
                <SelectButton
                  options={featureUnlockTypeList.map((featureUnlockType) => normalizeEnumName(featureUnlockType))}
                  activeIndex={!selectedDeviceContentPage?.featureUnlockType ? 0 : featureUnlockTypeList.indexOf(selectedDeviceContentPage?.featureUnlockType)}
                  onClick={(selectedIndex) => setUnlockFeature(FeatureUnlockType?.[featureUnlockTypeList[selectedIndex]])}
                  label={'Feature Unlock'}
                  customWidth={'240px'}
                  disableConfirmation={true}
                />
              </Box>
            </Box>
            {!isQuizPage() ? (
              <React.Fragment>
                {isVideoPage(getPageType()) && (
                  <React.Fragment>
                    <Typography variant="subtitle1" component="div">
                      {getNormalizedPageType()} URL
                    </Typography>
                    <TextField className={style.deviceContentInput} variant="outlined" value={videoUrl} onChange={(e) => setVideoUrl(e.target.value)} />
                  </React.Fragment>
                )}
                <MarkdownEditor textDispatcher={pageTextDispatcher} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography variant="subtitle1" component="div">
                  Question
                </Typography>
                <TextField className={style.deviceContentInput} multiline rows={2} variant="outlined" value={question} onChange={(e) => setQuestion(e.target.value)} />
                {optionsState[0].map((title, index) => (
                  <QuizOption key={index} label={`Answer ${index + 1}`} index={index} indexState={selectedIndexState} optionState={optionsState} selectable={quizResultPage} />
                ))}
                {quizResultPage && (
                  <React.Fragment>
                    <Typography variant="h5" component="div">
                      Response Pages
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                      Correct Response Message
                    </Typography>
                    <TextField className={style.deviceContentInput} variant="outlined" value={correctResponse} onChange={(e) => setCorrectResponse(e.target.value)} />
                    <Typography variant="subtitle1" component="div">
                      Incorrect Response Message
                    </Typography>
                    <TextField className={style.deviceContentInput} variant="outlined" value={incorrectResponse} onChange={(e) => setIncorrectResponse(e.target.value)} />
                  </React.Fragment>
                )}
                <Button onClick={() => setQuizResultPage(!quizResultPage)}>{quizResultPage ? 'Exclude' : 'Include'} Result Page</Button>
              </React.Fragment>
            )}
          </Box>
        </Container>
      </div>
    </MuiThemeProvider>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      selectedDeviceContentPage: getSelectedDeviceContentPageSelector(state),
      selectedDeviceContent: getSelectedDeviceContentSelector(state),
      saveSuccessful: getSaveSuccessfulSelector(state),
      breadCrumbs: getBreadcrumbsSelector(state)
    };
  },
  (dispatch: Function) => ({
    addDeviceContentPage: (deviceContentPage: DeviceContentPage) => {
      dispatch(addDeviceContentPageThunk(deviceContentPage));
    },
    updateDeviceContentPage: (deviceContentPage: DeviceContentPage) => {
      dispatch(updateDeviceContentPageThunk(deviceContentPage));
    },
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => {
      dispatch(appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }));
    },
    clearResponseStatus: () => {
      dispatch(getClearResponseStatusAction());
    },
    getDeviceContentPage: (deviceContentPageId: string) => {
      dispatch(getDeviceContentPageThunk(deviceContentPageId));
    },
    getDeviceContentDetails: (deviceContentId: string) => {
      dispatch(getDeviceContentDetailsThunk(deviceContentId));
    }
  })
);

export default compose(connectRedux)(PageDetails) as React.ComponentType;
