import { SvgIcon } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import React, { MouseEventHandler } from 'react';

interface Props {
  asButton?: Nullable<boolean>;
  buttonSize?: Nullable<'small' | 'medium'>;
  style?: React.CSSProperties; //external component does not support nullable types, so we can't apply Nullable<> type here
  onClick?: Nullable<MouseEventHandler<any>>;
}

export const DismissedIcon: React.FC<Props> = ({ asButton, buttonSize, style, onClick }) => {
  const svgIcon = (): JSX.Element => ( //@ts-expect-error: TODO add description
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" style={style} onClick={onClick}>
      <rect x="1.60005" y="1.5998" width="12.8" height="12.8" rx="0.8" fill="#FFDBD5" stroke="#D0490F" strokeWidth="1.6" />
      <path d="M4.80005 4.99902L11.3 11.499" stroke="#D0490F" strokeWidth="2" strokeLinecap="round" />
      <path d="M11.3 4.99902L4.80005 11.499" stroke="#D0490F" strokeWidth="2" strokeLinecap="round" />
    </SvgIcon>
  );

  if (asButton) {
    return <IconButton size={buttonSize ? buttonSize : 'medium'}>{svgIcon()}</IconButton>;
  }
  return svgIcon();
};
