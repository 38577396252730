import { Box } from '@material-ui/core';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import React from 'react';
import { ClientQuickSearchProps, GridClientQuickSearch } from '../GridQuickSearch/GridClientQuickSearch';
import { GridServerQuickSearch, ServerQuickSearchProps } from '../GridQuickSearch/GridServerQuickSearch';
import style from './GridToolbar.scss';

interface Props {
  hideColumnsButton?: Nullable<boolean>;
  hideFiltersButton?: Nullable<boolean>;
  hideDensityButton?: Nullable<boolean>;
  hideExportButton?: Nullable<boolean>;
  customButton?: Nullable<JSX.Element>;
  clientQuickSearchProps?: Nullable<ClientQuickSearchProps>;
  serverQuickSearchProps?: Nullable<ServerQuickSearchProps>;
}

const missedProps = {
  placeholder: undefined,
  onPointerEnterCapture: undefined,
  onPointerLeaveCapture: undefined
};

export const GridToolbar: React.FC<Props> = ({
  hideColumnsButton,
  hideFiltersButton,
  hideDensityButton,
  hideExportButton,
  customButton,
  clientQuickSearchProps,
  serverQuickSearchProps
}) => (
  <GridToolbarContainer className={style.gridToolbar}>
    <Box>
      {!hideColumnsButton && <GridToolbarColumnsButton {...missedProps} />}
      {!hideFiltersButton && <GridToolbarFilterButton {...missedProps} />}
      {!hideDensityButton && <GridToolbarDensitySelector {...missedProps} />}
      {!hideExportButton && <GridToolbarExport {...missedProps} />}
      {!!customButton && customButton}
    </Box>
    {clientQuickSearchProps && <GridClientQuickSearch {...clientQuickSearchProps} />}
    {serverQuickSearchProps && <GridServerQuickSearch {...serverQuickSearchProps} />}
  </GridToolbarContainer>
);
