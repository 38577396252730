import { Error } from '@redux/common/types';

export const BACKUP_MESSAGE = 'There was an error. Try again.';
export const NO_INTERNET_MESSAGE = 'No internet connection!';

export const BACKUP_MESSAGE_BY_STATUS: any = {
  400: 'There was something wrong with your request. Correct any errors and try again.',
  401: 'Unauthorized.',
  403: 'You are not allowed to perform that action.',
  500: BACKUP_MESSAGE
};

export function structureResponseError(apiResponse: any) {
  // Network Error
  if (!apiResponse?.response && !apiResponse?.data) {
    return {
      meta: { status: 503 },
      error: {}
    };
  }

  // Internal Error
  if (!apiResponse?.response?.data && !apiResponse?.data) {
    return {
      meta: { status: 500 },
      error: {}
    };
  }

  if (apiResponse?.data) {
    return apiResponse?.data;
  }

  return apiResponse.response.data;
}

export default function createFrontEndError(apiResponse: any, isInternetConnected: boolean = true): Error {
  const structuredResponse = structureResponseError(apiResponse);
  const status = structuredResponse.status || structuredResponse.meta?.status || 500;
  const message: string =
    !isInternetConnected && status === 503
      ? NO_INTERNET_MESSAGE
      : scrubMessage(structuredResponse?.message || structuredResponse.error?.data?.message || structuredResponse.error?.message) ||
        BACKUP_MESSAGE_BY_STATUS[status] ||
        BACKUP_MESSAGE;
  return {
    message,
    status
  };
}

function scrubMessage(message: string): Nullable<string> {
  if (!message) {
    return undefined;
  }
  const prefixIndex: number = message.lastIndexOf('Exception:');
  if (prefixIndex >= 0) {
    message = message.substring(prefixIndex + 10).trim();
  }
  if (message.toLowerCase() === '<no description>') {
    return undefined;
  }
  return message;
}

export const hasActiveRecord = (cache: Record<string, any>): boolean => {
  return !!getActiveRecord(cache);
};

export const getActiveRecord = (cache: Record<string, any>): any => {
  for (const key in cache) {
    if (cache[key]) {
      return cache[key];
    }
  }
};

export const clarifyErrorMessage = (error: Error): string => {
  // Replace some cryptic errors with more user-friendly messages
  if (error?.message?.includes('CSRF token invalid')) {
    return 'Invalid user state. Make sure you are not already logged in and try again';
  } else if (error?.message === 'Unable to process JSON') {
    return 'Make sure that input fields are formatted properly. Ex. for UUIDS, provide a valid 36 character input such as 00000000-0000-0000-0000-000000000000';
  } else if (error?.message?.includes('insufficient sleep logs')) {
    return 'User has insufficient sleep logs to perform this action.';
  }

  return error?.message;
};
