import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';

interface Props {
  unlinkDevice: () => void;
  personName?: Nullable<string>;
  deviceNumber?: Nullable<string>;
  disabled?: boolean; //external component does not support nullable types, so we can't apply Nullable<> type here
}

export const UnlinkButton: React.FC<Props> = ({ unlinkDevice, personName, deviceNumber, disabled }) => {
  const [openUnlinkDialog, setOpenUnlinkDialog] = useState<boolean>(false);

  const onClick = (e: any): void => onAction(e, true);

  const onClose = (e: any): void => onAction(e, false);

  const onAction = (e: any, openDialog: boolean): void => {
    e.preventDefault();
    e.stopPropagation();
    setOpenUnlinkDialog(openDialog);
  };

  return (
    <React.Fragment>
      <Button disabled={disabled} variant="contained" onClick={(e) => onClick(e)}>
        Unlink
      </Button>
      <ConfirmationDialog
        open={openUnlinkDialog}
        dialogText={`ARE YOU SURE YOU WANT TO UNLINK ${personName} FROM ${deviceNumber}?`}
        proceedEvent={(e) => {
          e.stopPropagation();
          unlinkDevice();
          setOpenUnlinkDialog(false);
        }}
        closeEvent={(e) => onClose(e)}
      />
    </React.Fragment>
  );
};
