import { createAction } from '@reduxjs/toolkit';
import { ErrorPayload } from '../common/types';
import { createReduxActionCreator } from '../types';
import { AppendBreadcrumbPayload, DemoAccessCode, DemoAccessCodesResponsePayload, LoginResponsePayload, RegisterUserEmailPayload, RegisterUserFullNamePayload } from './oauthTypes';

export const OAUTH_ACTION_PREFIX = 'oauth-redux/';

export const deleteAccountNotifySupportEmailActions = createReduxActionCreator<undefined>(`${OAUTH_ACTION_PREFIX}delete-account-notify-support-email`);
export const getLoginActions = createReduxActionCreator<LoginResponsePayload>(`${OAUTH_ACTION_PREFIX}login`);
export const getLogoutActions = createReduxActionCreator<undefined>(`${OAUTH_ACTION_PREFIX}logout`);
export const getConfirmEmailActions = createReduxActionCreator<undefined, undefined, Nullable<ErrorPayload>>(`${OAUTH_ACTION_PREFIX}confirm-email`);
export const getRequestRegisterActions = createReduxActionCreator<LoginResponsePayload>(`${OAUTH_ACTION_PREFIX}register`);
export const getResetPasswordActions = createReduxActionCreator<undefined>(`${OAUTH_ACTION_PREFIX}reset-password`);
export const getAcceptInviteActions = createReduxActionCreator<undefined>(`${OAUTH_ACTION_PREFIX}accept-invite`);
export const getDemoAccessCodesAction = createReduxActionCreator<DemoAccessCodesResponsePayload>(`${OAUTH_ACTION_PREFIX}get-demo-access-codes`);
export const createDemoAccessCodeAction = createReduxActionCreator<DemoAccessCode[]>(`${OAUTH_ACTION_PREFIX}create-demo-access-code`);
export const updateDemoAccessCodesAction = createReduxActionCreator<DemoAccessCode>(`${OAUTH_ACTION_PREFIX}update-demo-access-code`);

export const getRegisterUserEmailAction = createAction<RegisterUserEmailPayload>(`${OAUTH_ACTION_PREFIX}register-user-email`);
export const getRegisterUserFullNameAction = createAction<RegisterUserFullNamePayload>(`${OAUTH_ACTION_PREFIX}register-user-fullname`);
export const getClearLoginResponseAction = createAction<undefined>(`${OAUTH_ACTION_PREFIX}clear-login-response`);
export const appendBreadcrumbAction = createAction<AppendBreadcrumbPayload>(`${OAUTH_ACTION_PREFIX}append-breadcrumb`);
