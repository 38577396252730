import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { sendDeleteAccountConfirmationEmailThunk } from '@redux/oauth/oauthThunks';
import { ReduxState } from '@redux/types';
import DeleteAccountDataList from '../../components/DeleteAccount/DeleteAccountDataList';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux;

const DeleteAccount: React.FC<Props> = ({ sendDeleteAccountConfirmationEmail }) => {
  const [email, setEmail] = useState('');
  const [isAccepted, setIsAccepted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAccepted(event.target.checked);
  };

  const handleSendConfirmationCode = (event: React.FormEvent) => {
    event.preventDefault();
    sendDeleteAccountConfirmationEmail(email);
    setIsSubmitted(true);
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Full Sleep Account Deletion
      </Typography>
      <Typography paragraph>Thank you for choosing Full Sleep! To delete your account and associated data, please follow these steps:</Typography>
      <form onSubmit={handleSendConfirmationCode}>
        <Box sx={{ my: 2 }}>
          <Typography variant="h6">Step 1: Provide Email</Typography>
          <TextField label="Email" type="email" value={email} onChange={handleEmailChange} fullWidth margin="normal" autoComplete="off" name={`email-${Math.random()}`} />
        </Box>

        <Typography variant="h6">Step 2: Review Deletion Details</Typography>
        <DeleteAccountDataList />

        <FormControlLabel
          control={<Checkbox checked={isAccepted} onChange={handleCheckboxChange} />}
          label="I accept that my account and associated data will be permanently deleted"
          sx={{ my: 2 }}
        />

        <Typography variant="h6">Step 3: Receive Confirmation Email</Typography>
        <Box sx={{ my: 2 }}>
          <Typography paragraph>
            After clicking 'Send Confirmation Email', please check your email inbox (and the spam/junk folder) for the confirmation link to finalize the deletion process.{' '}
            <strong>Your account will not be deleted until you confirm via email.</strong>
          </Typography>
          {isSubmitted && (
            <Typography variant="body1" color={'blue'}>
              Confirmation code sent. Please check your email to confirm deletion.
            </Typography>
          )}
        </Box>
        <Button type="submit" variant="contained" color="primary" disabled={!isAccepted || !email} sx={{ mt: 1 }}>
          Send Confirmation Email Button
        </Button>
      </form>
      <Typography paragraph>
        If you do not receive an email or need assistance, please contact us at <strong>support@fullsleep.com.</strong>
      </Typography>
    </Box>
  );
};

const connectRedux = connect((state: ReduxState) => ({}), {
  sendDeleteAccountConfirmationEmail: sendDeleteAccountConfirmationEmailThunk
});

export default compose(connectRedux)(DeleteAccount);
