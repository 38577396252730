import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { Button } from 'components/Button/Button';
import { CircledCustomIcon } from 'components/Icons';
import { Text } from 'components/Typography';
import style from './EmptyListArea.scss';

type Props = {
  onAddPress: () => void;
  buttonText: string;
  title: string;
  description: string;
  icon: React.FC;
  isButtonVisible?: boolean;
};

export const EmptyListArea: React.FC<Props> = ({ title, description, icon, buttonText, onAddPress, isButtonVisible = true }) => {
  return (
    <div className={style.container}>
      <CircledCustomIcon icon={icon} />
      <div className={style.textWrapper}>
        <Text variant="h5">{title}</Text>
        <Text variant="md" className={style.description}>
          {description}
        </Text>
      </div>
      {isButtonVisible && (
        <Button variant="contained" onClick={onAddPress} startIcon={<AddIcon />}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};
