import { Chip, Container, Dialog, Grid, IconButton, Link, MuiThemeProvider } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import React, { MouseEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { BreadCrumbData, Error } from '@redux/common/types';
import { getClearResponseStatusAction } from '@redux/content/contentActions';
import { getContentErrorSelector, getContentIsLoadingSelector, getContentSuccessSelector, getFlagByIdSelector, getSaveSuccessfulSelector } from '@redux/content/contentSelectors';
import { getAllFlagContentThunk, saveFlagContentThunk } from '@redux/content/contentThunks';
import { SortBy, WorkflowTaskTemplate, WorkflowTemplate, WorkflowType } from '@redux/content/contentTypes';
import { appendBreadcrumbAction } from '@redux/oauth/oauthActions';
import { getBreadcrumbsSelector } from '@redux/oauth/oauthSelectors';
import { FlagTaskStatus } from '@redux/person/personTypes';
import { ReduxState } from '@redux/types';
import { ContentTile, Subtitle } from '../../components/ContentTile/ContentTile';
import { Header } from '../../components/Header/Header';
import { StatusMessage } from '../../components/StatusMessage/StatusMessage';
import { FlagBullet } from '../../components/Workflow/FlagBullet/FlagBullet';
import { defaultAllFlagsBreadCrumb, defaultAllFlagsBreadCrumbs, defaultDialogTransition } from '../../utils/breadcrumbs';
import { normalizeEnumName } from '../../utils/content';
import { getActiveRecord, hasActiveRecord } from '../../utils/createFrontEndError';
import { defaultTheme } from '../../utils/styles';
import { goTo } from '../../utils/util';
import { FlagDetails } from '../FlagDetails/FlagDetails';
import style from './AllFlags.scss';

interface Props {
  breadCrumbs: BreadCrumbData[];
  flagById: Record<string, WorkflowTemplate>;
  isLoading: Record<string, boolean>;
  error: Record<string, Error>;
  success: Record<string, boolean>;
  saveSuccessful?: Nullable<boolean>;
  appendBreadCrumbs: (breadCrumb: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => void;
  saveFlag: (flag: WorkflowTemplate) => void;
  clearContentResponse: () => void;
  getAllFlagContent: () => void;
}

const cardHeaderTypographyProps: TypographyProps = {
  align: 'left',
  style: {
    fontSize: '20px'
  }
};

const assembleFlags = (flags: WorkflowTemplate[], flagType: WorkflowType, sortBy: SortBy): WorkflowTemplate[] =>
  flags
    ?.filter((f) => f.workflowType === flagType && !f.expirationTs)
    ?.sort((f1, f2) => {
      if (sortBy === SortBy.ALPHABETICAL) {
        const comp: number = f1.name.localeCompare(f2.name);
        return comp === 0 ? (f2?.personWorkflows?.length || 0) - (f1?.personWorkflows?.length || 0) : comp;
      } else {
        const comp: number = (f2?.personWorkflows?.length || 0) - (f1?.personWorkflows?.length || 0);
        return comp === 0 ? f1.name.localeCompare(f2.name) : comp;
      }
    });

const assembleTasks = (tasks: WorkflowTaskTemplate[]): Subtitle[] =>
  tasks
    ?.map((task) => {
      return { position: task.rank, text: task.title };
    })
    .sort((t1, t2) => t1.position - t2.position);

export const AllFlags: React.FC<Props> = ({
  flagById,
  breadCrumbs,
  isLoading,
  error,
  success,
  saveSuccessful,
  appendBreadCrumbs,
  saveFlag,
  clearContentResponse,
  getAllFlagContent
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.BY_PEOPLE);

  const navigate = useNavigate();

  useEffect(() => {
    setOpenDialog(() => false);
  }, [flagById]);

  useEffect(() => {
    getAllFlagContent();
  }, []);

  const preventLinkAction = (e: MouseEvent<any>): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  const createContentGrid = (flagType: WorkflowType, title: string): JSX.Element => {
    return (
      <React.Fragment>
        <FlagBullet
          workflowType={flagType}
          title={title}
          style={{ borderBottom: '1px solid #D0DCE2', marginBottom: '10px', padding: '0 0 5px 0' }}
          titleTypographyProps={cardHeaderTypographyProps}
          tailElement={
            <IconButton
              className={style.createButton}
              disabled={flagType !== WorkflowType.FLAG_MANUAL}
              hidden={flagType !== WorkflowType.FLAG_MANUAL}
              color="primary"
              onClick={() => setOpenDialog(true)}
            >
              <AddCircleIcon visibility={flagType === WorkflowType.FLAG_MANUAL ? 'visible' : 'hidden'} fontSize="inherit" />
            </IconButton>
          }
          flagComplete={false}
        />
        {assembleFlags(Object.values(flagById), flagType, sortBy)?.map((flag, index) => (
          <Link
            underline="none"
            key={index}
            href={`/flaggedUsers?activeFlags=${flag?.id}&activeFlagsOp=is&taskStatus=${FlagTaskStatus.UNCOMPLETED}&taskStatusOp=is`}
            onClick={preventLinkAction}
          >
            <ContentTile
              key={index}
              title={flag?.name}
              subTitles={assembleTasks(flag?.workflowTasks || [])}
              onClick={() => goTo(`/flaggedUsers?activeFlags=${flag?.id}&activeFlagsOp=is&taskStatus=${FlagTaskStatus.UNCOMPLETED}&taskStatusOp=is`, navigate).call(this)}
              tailElement={flag?.personWorkflows?.length ? <Chip className={style.userCount} label={flag?.personWorkflows?.length}></Chip> : undefined}
            />
          </Link>
        ))}
      </React.Fragment>
    );
  };

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={style.root}>
        <Container maxWidth="xl">
          <Header
            breadCrumbs={breadCrumbs}
            title="All Flags"
            appendBreadCrumbs={appendBreadCrumbs}
            defaultCurrentCrumb={defaultAllFlagsBreadCrumb}
            defaultCrumbHistory={defaultAllFlagsBreadCrumbs}
            sortFunction={() => setSortBy((prevState) => (prevState === SortBy.BY_PEOPLE ? SortBy.ALPHABETICAL : SortBy.BY_PEOPLE))}
            sortLabel={normalizeEnumName(sortBy)}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              {createContentGrid(WorkflowType.FLAG_MILESTONE, 'Milestone Flags')}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              {createContentGrid(WorkflowType.FLAG_STATE, 'State Flags')}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              {createContentGrid(WorkflowType.FLAG_MANUAL, 'Manual Flags')}
            </Grid>
          </Grid>
          <Dialog fullScreen className={style.sliderDialog} open={openDialog} onClose={() => setOpenDialog(false)} TransitionComponent={defaultDialogTransition}>
            <FlagDetails
              flagType={WorkflowType.FLAG_MANUAL}
              isLoading={isLoading}
              error={error}
              success={success}
              saveSuccessful={saveSuccessful}
              saveFlag={saveFlag}
              flagById={flagById}
              clearResponseStatus={clearContentResponse}
            />
          </Dialog>
          <StatusMessage
            saveSuccessful={!hasActiveRecord(isLoading) && !hasActiveRecord(error) && hasActiveRecord(success)}
            saveUnsuccessful={!hasActiveRecord(isLoading) && hasActiveRecord(error)}
            clearResponseStatus={clearContentResponse}
            errorMessage={getActiveRecord(error)?.message}
          />
        </Container>
      </div>
    </MuiThemeProvider>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      breadCrumbs: getBreadcrumbsSelector(state),
      flagById: getFlagByIdSelector(state),
      isLoading: getContentIsLoadingSelector(state),
      error: getContentErrorSelector(state),
      success: getContentSuccessSelector(state),
      saveSuccessful: getSaveSuccessfulSelector(state)
    };
  },
  (dispatch: Function) => ({
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => {
      dispatch(appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }));
    },
    getAllFlagContent: (): void => {
      dispatch(getAllFlagContentThunk());
    },
    saveFlag: (flag: WorkflowTemplate): void => {
      dispatch(saveFlagContentThunk(flag));
    },
    clearContentResponse: (): void => {
      dispatch(getClearResponseStatusAction());
    }
  })
);

export default compose(connectRedux)(AllFlags) as React.ComponentType;
