import React, { Dispatch, SetStateAction } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { pageLeftThunk } from '@redux/analytics/analyticsThunks';
import { PageType } from '@redux/analytics/analyticsTypes';
import { resetPasswordThunk } from '@redux/oauth/oauthThunks';
import { ResetPasswordPayload } from '@redux/oauth/oauthTypes';
import { DualPane } from '../../components/DualPane/DualPane';
import { DevicePane } from '../../components/DualPane/Panes/DevicePane/DevicePane';
import { ResetPasswordPane } from '../../components/DualPane/Panes/ResetPasswordPane/ResetPasswordPane';

interface Props {
  changePassword: Function;
  pageLeft: Function;
}

const ChangePassword: React.FC<Props> = ({ changePassword, pageLeft }) => (
  <DualPane
    pageLeft={pageLeft}
    pageType={PageType.RESET_PASSWORD_BY_TOKEN}
    leftPane={<DevicePane />}
    rightPane={(loadingState: [boolean, Dispatch<SetStateAction<boolean>>], handleExternalRouting: (url?: Nullable<string>) => void, handleError: (err: any) => void) => (
      <ResetPasswordPane changePassword={changePassword} loadingState={loadingState} handleError={handleError} handleExternalRouting={handleExternalRouting} />
    )}
  />
);

const connectRedux = connect(
  () => {
    return {};
  },
  (dispatch: Function) => ({
    changePassword: async (resetPasswordPayload: ResetPasswordPayload, token: any) => {
      return dispatch(resetPasswordThunk(token, resetPasswordPayload));
    },
    pageLeft: async (pageType: PageType) => {
      return dispatch(pageLeftThunk(pageType));
    }
  })
);

export default compose(connectRedux)(ChangePassword);
