import { Checkbox, FormControlLabel, TextareaAutosize } from '@mui/material';
import React, { useState } from 'react';
import { FreeFormMessage } from '@redux/carePlan/carePlanTypes';
import { Button } from 'components/Button/Button';
import { Text } from 'components/Typography';
import style from './FreeFormMessageLibraryDrawer.scss';

interface Props {
  onCancel: Function;
  freeFormMessage?: Nullable<FreeFormMessage>;
  onSubmit: (freeFormMessage: FreeFormMessage) => void;
}

export const FreeFormMessageLibraryDrawer: React.FC<Props> = ({ onCancel, onSubmit, freeFormMessage }) => {
  const [newFreeFormMessage, setNewFreeFormMessage] = useState<FreeFormMessage>(freeFormMessage || { id: null, message: '', shouldPatientAnswer: false });

  return (
    <div className={style.container}>
      <Text as="div" variant="h5" className={style.title}>
        Free form message
      </Text>
      <Text as="div" variant="sm" className={style.subtitle}>
        Allows you to communicate directly with patients by adding personalized messages
      </Text>
      <div className={style.inputContainer}>
        <TextareaAutosize
          aria-label="minimum height"
          minRows={3}
          placeholder="Type message"
          value={newFreeFormMessage.message}
          onChange={(e) => setNewFreeFormMessage({ ...newFreeFormMessage, message: e.target.value })}
          className={style.textArea}
        />
        <FormControlLabel
          key={1}
          control={
            <Checkbox
              checked={newFreeFormMessage.shouldPatientAnswer}
              onChange={() => setNewFreeFormMessage({ ...newFreeFormMessage, shouldPatientAnswer: !newFreeFormMessage.shouldPatientAnswer })}
            />
          }
          label="Patient should answer this question"
        />
      </div>

      <div className={style.divider} />
      <div className={style.drawerButtonsContainer}>
        <Button variant="outlined" onClick={() => onCancel()} className={style.headerButton}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => onSubmit(newFreeFormMessage)} className={style.headerButton}>
          Submit
        </Button>
      </div>
    </div>
  );
};
