import { Button, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import React, { MouseEventHandler } from 'react';
import style from './AccountButton.scss';

interface Props {
  label: string;
  onClick: MouseEventHandler<any>;
  loading?: Nullable<boolean>;
  disabled?: Nullable<boolean>;
  cssClass?: Nullable<string>;
}

export const AccountButton: React.FC<Props> = ({ label, loading, onClick, disabled, cssClass }) => (
  <Button color="primary" disabled={!!loading || !!disabled} className={clsx(style.submitButton, cssClass)} variant="contained" onClick={onClick}>
    {!loading ? label : <CircularProgress size={24} />}
  </Button>
);
