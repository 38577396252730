import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { userHasAccessTokenSelector } from '@redux/oauth/oauthSelectors';
import { RouteNames } from 'utils/navigationUtils';

const ProtectedRoutes = ({ component: Component, ...rest }: any) => {
  const isLoggedIn = useSelector(userHasAccessTokenSelector);
  const redirectUrl = RouteNames.LOGIN;

  // Check if the user is authenticated
  if (!isLoggedIn) {
    // If not authenticated, redirect to the login page
    return <Navigate to={redirectUrl} replace />;
  }

  // If authenticated, render the child routes
  return <Outlet />;
};

export default ProtectedRoutes;
