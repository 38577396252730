import { ErrorPayload } from '@redux/common/types';
import { cancelTaskAction, clearSelectedTaskDetails, getTaskDetailsAction, getTasksListAction, postNewTaskAction } from '@redux/tasks/tasksActions';
import { TaskCreationPayloadType, TaskDetailsType, TasksListItemType } from '@redux/tasks/tasksTypes';
import { ThunkParams } from '@redux/types';

function processGetTasksListSuccess(payload: TasksListItemType[]) {
  return function (dispatch: Function, _: Function, { apiClient }: ThunkParams) {
    dispatch(getTasksListAction.success(payload));
  };
}

function processGetTasksListFail(error: ErrorPayload) {
  return function (dispatch: Function, _: Function, { apiClient }: ThunkParams) {
    dispatch(getTasksListAction.fail(error));
  };
}

export function getTasksListThunk(organizationId: string) {
  return async function (dispatch: Function, _: Function, { apiClient }: ThunkParams) {
    dispatch(getTasksListAction.start());
    return apiClient.get(`v1/engagementPlan/tasks/${organizationId}`, {
      auth: true,
      successAction: processGetTasksListSuccess,
      failAction: processGetTasksListFail
    });
  };
}

function processGetTaskDetailsSuccess(payload: TaskDetailsType) {
  return function (dispatch: Function, _: Function, { apiClient }: ThunkParams) {
    dispatch(getTaskDetailsAction.success(payload));
  };
}

function processGetTaskDetailsFail(error: ErrorPayload) {
  return function (dispatch: Function, _: Function, { apiClient }: ThunkParams) {
    dispatch(getTaskDetailsAction.fail(error));
  };
}

export function getTaskDetailsThunk(taskId: string) {
  return async function (dispatch: Function, _: Function, { apiClient }: ThunkParams) {
    dispatch(getTaskDetailsAction.start());
    return apiClient.get(`v1/engagementPlan/task/${taskId}`, {
      auth: true,
      successAction: processGetTaskDetailsSuccess,
      failAction: processGetTaskDetailsFail
    });
  };
}

export function clearSelectedTaskDetailsThunk() {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(clearSelectedTaskDetails());
  };
}

function processPatchCancelTaskSuccess(payload: TaskDetailsType) {
  return function (dispatch: Function, _: Function, { apiClient }: ThunkParams) {
    dispatch(cancelTaskAction.success(payload));
  };
}

function processPatchCancelTaskFail(error: ErrorPayload) {
  return function (dispatch: Function, _: Function, { apiClient }: ThunkParams) {
    dispatch(cancelTaskAction.fail(error));
  };
}

export function patchCancelTaskThunk(taskId: string) {
  return async function (dispatch: Function, _: Function, { apiClient }: ThunkParams) {
    dispatch(cancelTaskAction.start());
    return apiClient.patch(`v1/engagementPlan/task/${taskId}/cancel`, {
      auth: true,
      successAction: processPatchCancelTaskSuccess,
      failAction: processPatchCancelTaskFail
    });
  };
}

function processPostNewTaskThunkSuccess(payload: {}) {
  return function (dispatch: Function, _: Function, { apiClient }: ThunkParams) {
    dispatch(postNewTaskAction.success({}));
  };
}

function processPostNewTaskThunkFail(error: ErrorPayload) {
  return function (dispatch: Function, _: Function, { apiClient }: ThunkParams) {
    dispatch(postNewTaskAction.fail(error));
  };
}

export function postNewTaskThunk(task: TaskCreationPayloadType) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(postNewTaskAction.start());

    return apiClient.post(`v1/engagementPlan/task`, {
      auth: true,
      data: task,
      successAction: processPostNewTaskThunkSuccess,
      failAction: processPostNewTaskThunkFail
    });
  };
}
