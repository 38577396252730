import { Grid } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { GenderType, Person, PronounType } from '@redux/person/personTypes';
import { Text } from 'components/Typography';
import { Location as PatientLocation } from 'redux/device/deviceTypes';
import { normalizeEnumName } from 'utils/content';
import { getFormattedPhoneNumber, getInitials } from 'utils/util';
import style from './PatientDetails.scss';

type ViewPersonProps = {
  person: Person;
  children?: React.ReactNode;
};

export const PatientDetails: React.FC<ViewPersonProps> = ({ person, children }) => {
  const personName = person?.fullName ?? '';
  const email = person?.email ?? '';
  const personInitial = getInitials(personName);

  const getAddressLine = (address: PatientLocation) => {
    return [address.street, address.streetSecondLine, address.city, address.state, address.postalCode].filter(Boolean).join(', ');
  };

  const fieldValues = [
    {
      'Date of birth': person?.birthDate ? moment(person.birthDate).format('MM/DD/YYYY') : '-',
      'Phone number': person?.phoneNumber ? getFormattedPhoneNumber(person.phoneNumber) : '-'
    },
    {
      Gender: person?.genderType ? GenderType[person.genderType] : '-',
      Pronouns: person?.pronounType ? PronounType[person.pronounType] : '-'
    },
    {
      Address: person?.shippingAddress ? getAddressLine(person?.shippingAddress) : '-',
      Nickname: person?.nickname ?? '-'
    }
  ];

  return (
    <div className={style.topContainer}>
      <div className={style.avatarAndText}>
        <div className={style.avatar}>
          <Text variant="2xl" className={style.grayText}>
            {personInitial}
          </Text>
        </div>
        <Text variant="2xl" weight={500}>
          {personName}
        </Text>
        <Text variant="md" className={style.grayEmailText}>
          {email}
        </Text>
      </div>
      {children}
      <div className={style.patientDetail}>
        <Text variant="xl">Patient details</Text>
      </div>
      <Grid container>
        {fieldValues.map((fieldValue, index) => (
          <Grid container key={index}>
            <Grid container>
              {Object.keys(fieldValue).map((label) => (
                <Grid item xs={6} key={label}>
                  <Text variant="md">{label}</Text>
                </Grid>
              ))}
            </Grid>
            <Grid container className={style.fieldValue}>
              {Object.values(fieldValue).map((value, index) => (
                <Grid item xs={6} key={value + index}>
                  <Text variant="md" className={style.grayText}>
                    {value}
                  </Text>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
