import { Box, Chip, Container, Dialog, MuiThemeProvider } from '@material-ui/core';
import React, { MouseEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { BreadCrumbData } from '@redux/common/types';
import { getClearDeviceContentDetailsAction, getClearResponseStatusAction } from '@redux/content/contentActions';
import {
  getCopiedDeviceContentIdSelector,
  getDeviceContentByTypeSelector,
  getSaveSuccessfulSelector,
  getSelectedContentType,
  getSelectedDeviceContentSelector
} from '@redux/content/contentSelectors';
import {
  addDeviceContentPageThunk,
  addDeviceContentThunk,
  copyDeviceContentDetailsThunk,
  getDeviceContentDetailsThunk,
  getDeviceContentPageThunk,
  getDeviceContentThunk,
  getProgramContentByTypeThunk,
  saveDeviceAndProgramContentsThunk,
  updateDeviceContentPageThunk,
  updateDeviceContentThunk
} from '@redux/content/contentThunks';
import {
  ContentDropdownOption,
  DeviceAndProgramContentUpdateRequest,
  DeviceContent,
  DeviceContentPage,
  DeviceContentType,
  ProgramContent,
  ProgramSubType,
  ProgramType
} from '@redux/content/contentTypes';
import { appendBreadcrumbAction } from '@redux/oauth/oauthActions';
import { getBreadcrumbsSelector } from '@redux/oauth/oauthSelectors';
import { ReduxState } from '@redux/types';
import { ContentDropdown } from '../../components/ContentDropdown/ContentDropdown';
import { ContentTile } from '../../components/ContentTile/ContentTile';
import { CopyIcon } from '../../components/CopyIcon/CopyIcon';
import { FeatureUnlockChips } from '../../components/FeatureUnlockChips/FeatureUnlockChips';
import { Header } from '../../components/Header/Header';
import { StatusMessage } from '../../components/StatusMessage/StatusMessage';
import { SubTypeBar } from '../../components/SubTypeBar/SubTypeBar';
import { defaultAllContentCrumb, defaultAllContentCrumbs, defaultDialogTransition } from '../../utils/breadcrumbs';
import { deviceContentTypes, programContentTypes } from '../../utils/dropdownUtils';
import { defaultTheme } from '../../utils/styles';
import { goTo } from '../../utils/util';
import { DeviceContentDetails } from '../DeviceContentDetails/DeviceContentDetails';
import style from './AllContent.scss';

interface Props {
  selectedDeviceContent?: Nullable<DeviceContent>;
  programContentByType?: Nullable<Record<ProgramType, ProgramContent[]>>;
  deviceContentByType?: Nullable<Record<DeviceContentType, DeviceContent[]>>;
  breadCrumbs: BreadCrumbData[];
  saveSuccessful?: Nullable<boolean>;
  copiedDeviceContentId?: Nullable<string>;
  getDeviceContent: () => void;
  getProgramContentByType: (programSubType: ProgramSubType) => void;
  updateDeviceContent: (deviceContent: DeviceContent) => void;
  addDeviceContent: (deviceContent: DeviceContent) => void;
  updateDeviceContentPage: (deviceContentPage: DeviceContentPage) => void;
  addDeviceContentPage: (deviceContentPage: DeviceContentPage) => void;
  saveDeviceAndProgramContent: (deviceAndProgramContentUpdateRequest: DeviceAndProgramContentUpdateRequest) => void;
  copyDeviceContent: (deviceContentId: string) => void;
  appendBreadCrumbs: (breadCrumb: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => void;
  clearDeviceContent: () => void;
  clearResponseStatus: () => void;
  getDeviceContentDetails: (deviceContentId: string) => void;
  getProgramContentByTypeAndSubType: (programType: ProgramType, programSubType?: ProgramSubType) => void;
  getDeviceContentPage: (deviceContentPageId: string) => void;
}

export const AllContent: React.FC<Props> = ({
  selectedDeviceContent,
  programContentByType,
  deviceContentByType,
  breadCrumbs,
  saveSuccessful,
  copiedDeviceContentId,
  getDeviceContent,
  getDeviceContentDetails,
  getDeviceContentPage,
  getProgramContentByType,
  updateDeviceContent,
  addDeviceContent,
  updateDeviceContentPage,
  addDeviceContentPage,
  saveDeviceAndProgramContent,
  copyDeviceContent,
  appendBreadCrumbs,
  clearDeviceContent,
  clearResponseStatus,
  getProgramContentByTypeAndSubType
}) => {
  const [filter, setFilter] = useState<Set<ProgramSubType>>(new Set());
  const [selectedContentType, setSelectedContentType] = useState<Nullable<DeviceContentType>>(null);
  const [filteredData, setFilteredData] = useState<Nullable<DeviceContent[]>>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const defaultDeviceContentTypes = deviceContentTypes;

  useEffect(() => {
    if (deviceContentByType) {
      const deviceContentTypes: DeviceContentType[] = selectedContentType ? [selectedContentType] : defaultDeviceContentTypes;
      const filtered: DeviceContent[] = getDeviceContentForTypes(deviceContentByType, deviceContentTypes, filter);
      setFilteredData(filtered);
    }
  }, [selectedContentType, filter, deviceContentByType]);

  useEffect(() => {
    setOpenDialog(false);
  }, [selectedDeviceContent]);

  useEffect(() => {
    getDeviceContent();
    clearDeviceContent();
  }, []);

  useEffect(() => {
    if (copiedDeviceContentId) {
      getDeviceContent();
    }
  }, [copiedDeviceContentId]);

  const handleDropdownChange = (option: ContentDropdownOption) => {
    if (option.value === 'SELECT_ALL') {
      setSelectedContentType(null);
    } else {
      const selected: DeviceContentType = option.value as DeviceContentType;
      setSelectedContentType(selected);
    }
  };

  const passesProgramTypeFilter = (deviceContent: DeviceContent, filter: Set<any>) => {
    if (filter.size === 0) return true;

    const programContents: ProgramContent[] = deviceContent.programContents || [];
    return passesFilterForUnknown(programContents, filter) || passesFilterForSubtype(programContents, filter);
  };
  const passesFilterForUnknown = (programContents: ProgramContent[], filter: Set<any>): boolean =>
    (filter.size === 0 && programContents.length === 0) || (allProgramsLackSubType(programContents) && filter.has(ProgramSubType.UNKNOWN));
  const passesFilterForSubtype = (programContents: ProgramContent[], filter: Set<any>): boolean =>
    programContents?.some((programContent) => !!programContent.programSubType && filter.has(programContent.programSubType));
  const allProgramsLackSubType = (programContents: ProgramContent[]): boolean => !programContents?.some((programContent) => programContent.programSubType);

  const getDeviceContentForTypes = (deviceContentByType: Record<DeviceContentType, DeviceContent[]>, typesToInclude: DeviceContentType[], filter: Set<any>): DeviceContent[] => {
    if (!deviceContentByType) return [];
    return Object.entries(deviceContentByType)
      .filter(([type, deviceContents]) => typesToInclude.includes(type as DeviceContentType))
      .reduce((result, [type, deviceContents]) => result.concat(deviceContents), new Array<DeviceContent>()) //flatMap
      .filter((deviceContent) => passesProgramTypeFilter(deviceContent, filter))
      .sort((c1, c2) => (c1.title || '').localeCompare(c2.title || ''));
  };

  const addFilter = (item: ProgramSubType): void => {
    setFilter((prev) => new Set(prev).add(item));
  };

  const removeFilter = (item: ProgramSubType): void => {
    setFilter((prev) => {
      const next = new Set(prev);
      next.delete(item);
      return next;
    });
  };

  const toggleFilter = (item: ProgramSubType): void => {
    if (filter.has(item)) {
      removeFilter(item);
    } else {
      addFilter(item);
    }
  };

  const copyRowContent = (e: MouseEvent, deviceContentId: string): void => {
    e.stopPropagation();
    copyDeviceContent(deviceContentId);
  };

  const renderFilterChip = (label: string, subType: ProgramSubType) => (
    <Chip className={style.subTypeChip} label={label} color={filter.has(subType) ? 'primary' : 'default'} onClick={() => toggleFilter(subType)} />
  );

  const navigate = useNavigate();

  const createActiveSubTypeBar = (deviceContent: DeviceContent): JSX.Element => (
    <React.Fragment>
      <FeatureUnlockChips programContents={deviceContent.programContents} deviceContentPages={deviceContent.deviceContentPages} />
      <SubTypeBar deviceContent={deviceContent} hideMissing={true} />
      <CopyIcon asButton={true} buttonSize={'small'} buttonClass={style.tileIcon} onClick={(e) => copyRowContent(e, deviceContent?.id || '')} />
    </React.Fragment>
  );

  const openDetailsDialog = async (): Promise<any> => {
    await clearDeviceContent();
    setOpenDialog(true);
  };

  const assembleAllContent = (): JSX.Element[] => {
    if (filteredData) {
      return filteredData.map((deviceContent, index) => (
        <ContentTile
          key={index}
          title={deviceContent?.title}
          subTitles={[{ text: deviceContent?.subTitle || '' }]}
          onClick={goTo(`/deviceContentDetails/${deviceContent.id}`, navigate)}
          tailElement={createActiveSubTypeBar(deviceContent)}
        />
      ));
    }
    return [];
  };

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={style.root}>
        <Container maxWidth="xl">
          <Header
            breadCrumbs={breadCrumbs}
            title="All Content"
            appendBreadCrumbs={appendBreadCrumbs}
            defaultCurrentCrumb={defaultAllContentCrumb}
            defaultCrumbHistory={defaultAllContentCrumbs}
            newFunction={openDetailsDialog}
          />
          <StatusMessage
            saveSuccessful={saveSuccessful !== undefined && saveSuccessful}
            saveUnsuccessful={saveSuccessful !== undefined && !saveSuccessful}
            saveMessage={copiedDeviceContentId ? 'Copy Successful' : undefined}
            errorMessage={copiedDeviceContentId ? 'Error While Copying' : undefined}
            clearResponseStatus={clearResponseStatus}
          />
          <Dialog fullScreen className={style.sliderDialog} open={openDialog} onClose={() => setOpenDialog(false)} TransitionComponent={defaultDialogTransition}>
            <DeviceContentDetails
              selectedDeviceContent={selectedDeviceContent}
              programContentByType={programContentByType}
              openAsDialog={true}
              getProgramContentByTypeAndSubType={getProgramContentByTypeAndSubType}
              getProgramContentByType={getProgramContentByType}
              updateDeviceContent={updateDeviceContent}
              addDeviceContent={addDeviceContent}
              updateDeviceContentPage={updateDeviceContentPage}
              addDeviceContentPage={addDeviceContentPage}
              saveDeviceAndProgramContent={saveDeviceAndProgramContent}
              copyDeviceContent={copyDeviceContent}
              clearResponseStatus={clearResponseStatus}
              getDeviceContentDetails={getDeviceContentDetails}
              clearDeviceContent={clearDeviceContent}
              getDeviceContent={getDeviceContent}
              getDeviceContentPage={getDeviceContentPage}
            />
          </Dialog>
          <ContentDropdown contentTypeOptions={deviceContentTypes} onClick={handleDropdownChange} selectAll={true} />
          <Box className={style.headerChipBar}>
            {renderFilterChip('Aging', ProgramSubType.AGING)}
            {renderFilterChip('Chronic Pain', ProgramSubType.CHRONIC_PAIN)}
            {renderFilterChip('Depression', ProgramSubType.DEPRESSION)}
            {renderFilterChip('Menopause', ProgramSubType.MENOPAUSE)}
            {renderFilterChip('General', ProgramSubType.GENERAL)}
            {renderFilterChip('No Program', ProgramSubType.UNKNOWN)}
          </Box>
          {assembleAllContent()}
        </Container>
      </div>
    </MuiThemeProvider>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      selectedDeviceContent: getSelectedDeviceContentSelector(state),
      deviceContentByType: getDeviceContentByTypeSelector(state),
      saveSuccessful: getSaveSuccessfulSelector(state),
      copiedDeviceContentId: getCopiedDeviceContentIdSelector(state),
      breadCrumbs: getBreadcrumbsSelector(state),
      selectedContentType: getSelectedContentType(state)
    };
  },
  (dispatch: Function) => ({
    getDeviceContent: () => {
      dispatch(getDeviceContentThunk(deviceContentTypes));
    },
    getProgramContentByType: (programSubType: ProgramSubType) => {
      programContentTypes.forEach((programType: ProgramType) => {
        dispatch(getProgramContentByTypeThunk(programType, programSubType));
      });
    },
    getProgramContentByTypeAndSubType: (programType: ProgramType, programSubType?: ProgramSubType) => {
      dispatch(getProgramContentByTypeThunk(programType, programSubType));
    },
    updateDeviceContent: (deviceContent: DeviceContent) => {
      dispatch(updateDeviceContentThunk(deviceContent));
    },
    addDeviceContent: (deviceContent: DeviceContent) => {
      dispatch(addDeviceContentThunk(deviceContent));
    },
    updateDeviceContentPage: (deviceContentPage: DeviceContentPage) => {
      dispatch(updateDeviceContentPageThunk(deviceContentPage));
    },
    addDeviceContentPage: (deviceContentPage: DeviceContentPage) => {
      dispatch(addDeviceContentPageThunk(deviceContentPage));
    },
    saveDeviceAndProgramContent: (deviceAndProgramContentUpdateRequest: DeviceAndProgramContentUpdateRequest) => {
      dispatch(saveDeviceAndProgramContentsThunk(deviceAndProgramContentUpdateRequest));
    },
    copyDeviceContent: (deviceContentId: string) => {
      dispatch(copyDeviceContentDetailsThunk(deviceContentId));
    },
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => {
      dispatch(appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }));
    },
    clearDeviceContent: () => {
      dispatch(getClearDeviceContentDetailsAction());
    },
    clearResponseStatus: () => {
      dispatch(getClearResponseStatusAction());
    },
    getDeviceContentDetails: (deviceContentId: string) => {
      dispatch(getDeviceContentDetailsThunk(deviceContentId));
    },
    getDeviceContentPage: (deviceContentPageId: string) => {
      dispatch(getDeviceContentPageThunk(deviceContentPageId));
    }
  })
);

export default compose(connectRedux)(AllContent) as React.ComponentType;
