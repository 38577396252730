import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ReduxState } from '@redux/types';
import style from './Popup.scss';

interface Props {
  children: any;
  direction?: Nullable<'top' | 'bottom'>;
  offsetX?: Nullable<number>;
  offsetY?: Nullable<number>;
  width: number;
}

const Popup: React.FC<Props> = ({ children, direction, offsetX, offsetY, width }) => {
  const boxStyle: React.CSSProperties = {
    left: -width / 2 + (offsetX || 0),
    top: (offsetY || 0) + 15,
    width: width
  };

  const triangleSize = 10;
  const triangeStyle: React.CSSProperties = {
    height: triangleSize,
    top: 1 - triangleSize * (2 ** 0.5 / 2),
    transform: 'rotate(45deg)',
    width: triangleSize
  };

  if (direction == 'top') {
    [boxStyle.bottom, boxStyle.top] = [boxStyle.top, undefined];

    [triangeStyle.bottom, triangeStyle.top] = [triangeStyle.top, undefined];
    triangeStyle.transform = 'rotate(225deg)';
  }

  return (
    <div className={style.popup} style={boxStyle} onClick={(e) => e.stopPropagation()}>
      {children}
      <div className={style.triangle} style={triangeStyle}></div>
    </div>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {};
  },
  (dispatch: Function) => ({})
);

export default compose(connectRedux)(Popup);
