import { Dialog } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { Button } from 'components/Button/Button';
import { CloseIconButton } from 'components/Button/CloseButton/CloseIconButton';
import { CircledCustomIcon } from 'components/Icons';
import { Text } from 'components/Typography';
import { SVGBasicType } from 'types/global';
import { Color } from 'utils/color';
import style from './ConfirmationDialogV2.scss';

interface Props {
  open: boolean;
  icon?: React.FC<SVGBasicType>;
  title?: string;
  description?: string;
  proceedEvent?: () => void;
  proceedEventText?: string;
  closeEvent: () => void;
  closeEventText?: string;
}

export const ConfirmationDialogV2: React.FC<Props> = ({ open, icon, title, description, proceedEventText, proceedEvent, closeEvent, closeEventText }) => (
  <Dialog disableEnforceFocus open={open} onClose={closeEvent}>
    <div className={style.container}>
      <CloseIconButton onClick={closeEvent} containerClassName={style.closeIconButton} />
      {icon && <CircledCustomIcon icon={icon} width={24} height={24} color={Color.RED_600} containerStyle={style.iconWrapper} />}
      {(title || description) && (
        <div className={classNames(style.contentWrapper, !icon && style.noIconContentWrapper)}>
          {title && (
            <Text variant="lg" weight={500}>
              {title}
            </Text>
          )}
          {description && (
            <Text variant="sm" className={style.subtitle}>
              {description}
            </Text>
          )}
        </div>
      )}
      <div className={classNames(style.buttonsWrapper, !proceedEvent && style.singleButton)}>
        <Button variant="outlined" color="primary" onClick={closeEvent} className={style.button}>
          <Text variant="md">{closeEventText || 'Cancel'}</Text>
        </Button>
        {proceedEvent && (
          <Button variant="contained" color="error" onClick={proceedEvent} className={classNames(style.button, style.confirmButton)}>
            <Text variant="md" className={style.confirmButtonText}>
              {proceedEventText || 'Yes'}
            </Text>
          </Button>
        )}
      </div>
    </div>
  </Dialog>
);
