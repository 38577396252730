import classNames from 'classnames';
import React from 'react';
import { ClockCountdownIcon } from 'components/Icons';
import { Text } from 'components/Typography';
import style from './ComingSoonLabel.scss';

type Props = {
  containerClassName?: string;
};

export const ComingSoonLabel: React.FC<Props> = ({ containerClassName }) => (
  <div className={classNames(style.container, containerClassName)}>
    <ClockCountdownIcon />
    <Text variant="xs" className={style.text}>
      Coming soon
    </Text>
  </div>
);
