import { Box, Checkbox, Chip, Container, Dialog, Grid, MuiThemeProvider, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { BreadCrumbData } from '@redux/common/types';
import { getClearDeviceContentDetailsAction, getClearResponseStatusAction } from '@redux/content/contentActions';
import {
  getCopiedDeviceContentIdSelector,
  getDeviceContentByTypeSelector,
  getProgramContentByTypeSelector,
  getSaveSuccessfulSelector,
  getSelectedContentType,
  getSelectedDeviceContentSelector
} from '@redux/content/contentSelectors';
import {
  addDeviceContentPageThunk,
  addDeviceContentThunk,
  copyDeviceContentDetailsThunk,
  getDeviceContentDetailsThunk,
  getDeviceContentPageThunk,
  getDeviceContentThunk,
  getProgramContentByTypeThunk,
  saveDeviceAndProgramContentsThunk,
  updateDeviceContentPageThunk,
  updateDeviceContentThunk
} from '@redux/content/contentThunks';
import {
  ContentDropdownOption,
  DeviceAndProgramContentUpdateRequest,
  DeviceContent,
  DeviceContentPage,
  DeviceContentPageType,
  DeviceContentSubType,
  DeviceContentType,
  FeatureUnlockType,
  featureUnlockTypeList,
  findCategoryId,
  pageTypeList,
  ProgramContent,
  ProgramSubType,
  ProgramType
} from '@redux/content/contentTypes';
import { appendBreadcrumbAction } from '@redux/oauth/oauthActions';
import { getBreadcrumbsSelector } from '@redux/oauth/oauthSelectors';
import { ReduxState } from '@redux/types';
import { timestampAndRandomCombinedUUIDString } from '../../../shared/src/util/uuid';
import { ConfirmationDialog } from '../../components/ConfirmationDialog/ConfirmationDialog';
import { ContentDropdown } from '../../components/ContentDropdown/ContentDropdown';
import { ContentTile } from '../../components/ContentTile/ContentTile';
import { CopyIcon } from '../../components/CopyIcon/CopyIcon';
import { FeatureUnlockChips } from '../../components/FeatureUnlockChips/FeatureUnlockChips';
import { Header } from '../../components/Header/Header';
import { RankedContentActions } from '../../components/RankedContentActions/RankedContentActions';
import { SelectButton } from '../../components/SelectButton/SelectButton';
import { StatusMessage } from '../../components/StatusMessage/StatusMessage';
import { SubTypeBar } from '../../components/SubTypeBar/SubTypeBar';
import { defaultDeviceContentDetailsCrumb, defaultDeviceContentDetailsCrumbs, defaultDialogTransition } from '../../utils/breadcrumbs';
import { constructOrderedMapContent, constructRankedMap, extractMarkdownPageText, findProgram, normalizeEnumName } from '../../utils/content';
import { deviceContentTypes, programContentTypes } from '../../utils/dropdownUtils';
import { defaultTheme } from '../../utils/styles';
import { areEqualBlank, ellipsify, goTo, mapsAreEqual } from '../../utils/util';
import { PageDetails } from '../PageDetails/PageDetails';
import { Track } from '../Track/Track';
import style from './DeviceContentDetails.scss';

interface Props {
  selectedDeviceContent?: Nullable<DeviceContent>;
  programContentByType?: Nullable<Record<ProgramType, ProgramContent[]>>;
  deviceContentByType?: Nullable<Record<DeviceContentType, DeviceContent[]>>;
  saveSuccessful?: Nullable<boolean>;
  copiedDeviceContentId?: Nullable<string>;
  openAsDialog?: Nullable<boolean>;
  breadCrumbs?: Nullable<BreadCrumbData[]>;
  getProgramContentByType: (programSubType: ProgramSubType) => void;
  getProgramContentByTypeAndSubType: (programType: ProgramType, programSubType?: ProgramSubType) => void;
  updateDeviceContent: (deviceContent: DeviceContent) => void;
  addDeviceContent: (deviceContent: DeviceContent) => void;
  updateDeviceContentPage: (deviceContentPage: DeviceContentPage) => void;
  addDeviceContentPage: (deviceContentPage: DeviceContentPage) => void;
  saveDeviceAndProgramContent: (deviceAndProgramContentUpdateRequest: DeviceAndProgramContentUpdateRequest) => void;
  copyDeviceContent: (deviceContentId: string) => void;
  appendBreadCrumbs?: Nullable<(breadCrumb: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => void>;
  clearResponseStatus: () => void;
  getDeviceContentDetails: (deviceContentId: string) => void;
  clearDeviceContent: () => void;
  getDeviceContent: (deviceContentTypes: DeviceContentType[]) => void;
  getDeviceContentPage: (deviceContentPageId: string) => void;
}

export const DeviceContentDetails: React.FC<Props> = ({
  selectedDeviceContent,
  programContentByType,
  deviceContentByType,
  saveSuccessful,
  copiedDeviceContentId,
  openAsDialog,
  breadCrumbs,
  getProgramContentByType,
  updateDeviceContent,
  addDeviceContent,
  updateDeviceContentPage,
  addDeviceContentPage,
  saveDeviceAndProgramContent,
  copyDeviceContent,
  appendBreadCrumbs,
  clearResponseStatus,
  getDeviceContentDetails,
  clearDeviceContent,
  getDeviceContent,
  getDeviceContentPage,
  getProgramContentByTypeAndSubType
}) => {
  const filteredPrograms = (): ProgramContent[] => selectedDeviceContent?.programContents?.filter((programContent) => programContent.programType === ProgramType.CBTI_NATIVE) || [];
  const findFeatureUnlock = (): FeatureUnlockType =>
    filteredPrograms().find((programContent) => !!programContent.featureUnlockType)?.featureUnlockType || FeatureUnlockType.UNKNOWN;

  const [unlockFeature, setUnlockFeature] = useState<FeatureUnlockType>(findFeatureUnlock());
  const [title, setTitle] = useState<string>(selectedDeviceContent?.title || '');
  const [subTitle, setSubTitle] = useState<string>(selectedDeviceContent?.subTitle || '');
  const [description, setDescription] = useState<string>(selectedDeviceContent?.description || '');
  const [imageUrl, setImageUrl] = useState<string>(selectedDeviceContent?.contentUrl || '');
  const [forceOpen, setForceOpen] = useState<Nullable<boolean>>(selectedDeviceContent?.forceOpen);
  const [selectedSubType, setSelectedSubType] = useState<ProgramSubType>(ProgramSubType.UNKNOWN);
  const [selectedContentType, setSelectedContentType] = useState<DeviceContentType>(DeviceContentType.AD_HOC_NATIVE);
  const [selectedPage, setSelectedPage] = useState<Nullable<DeviceContentPage>>(undefined);
  const [selectedPageType, setSelectedPageType] = useState<DeviceContentPageType>(DeviceContentPageType.UNKNOWN);
  const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] = useState<boolean>(false);
  const rankMapDispatcher: [Map<number, string>, Dispatch<SetStateAction<Map<number, string>>>] = useState<Map<number, string>>(
    constructRankedMap('rank', selectedDeviceContent?.deviceContentPages)
  );
  const expiredSetDispatcher: [Set<string>, Dispatch<SetStateAction<Set<string>>>] = useState<Set<string>>(new Set<string>());

  const navigate = useNavigate();
  const location: any = useLocation();
  const params: any = useParams();

  useEffect(() => {
    const deviceContentId: Nullable<string> = params?.['deviceContentId'];
    if (deviceContentId) {
      getDeviceContentDetails(deviceContentId);
    } else {
      clearDeviceContent();
    }
    getDeviceContent([DeviceContentType.CBTI_NATIVE, DeviceContentType.AD_HOC_NATIVE, DeviceContentType.CBTI_14_DAY_TRIAL, DeviceContentType.DEMO_CONTENT]);
  }, []);

  useEffect(() => {
    setUnlockFeature(() => findFeatureUnlock());
    setTitle(() => selectedDeviceContent?.title || '');
    setSubTitle(() => selectedDeviceContent?.subTitle || '');
    setDescription(() => selectedDeviceContent?.description || '');
    setImageUrl(() => selectedDeviceContent?.contentUrl || '');
    setForceOpen(() => selectedDeviceContent?.forceOpen);
    setSelectedSubType(() => ProgramSubType.UNKNOWN);
    setSelectedPage(() => undefined);
    setSelectedPageType(() => DeviceContentPageType.UNKNOWN);
    setOpenUnsavedChangesDialog(() => false);
    setSelectedContentType(() => selectedDeviceContent?.deviceContentType || DeviceContentType.AD_HOC_NATIVE);
    rankMapDispatcher[1](() => constructRankedMap('rank', selectedDeviceContent?.deviceContentPages));
    expiredSetDispatcher[1](() => new Set<string>());
  }, [selectedDeviceContent]);

  useEffect(() => {
    if (copiedDeviceContentId) {
      goTo(`/deviceContentDetails/${copiedDeviceContentId}`, navigate).call(this);
    }
  }, [copiedDeviceContentId]);

  const saveDeviceContent = async () => {
    if (selectedDeviceContent) {
      const updatedDeviceContent: DeviceContent = { ...selectedDeviceContent };
      updatedDeviceContent.title = title || undefined;
      updatedDeviceContent.subTitle = subTitle || undefined;
      updatedDeviceContent.description = description || undefined;
      updatedDeviceContent.contentUrl = imageUrl || undefined;
      updatedDeviceContent.forceOpen = forceOpen;
      updatedDeviceContent.deviceContentType = selectedContentType || undefined;
      updatedDeviceContent.deviceContentPages = constructOrderedMapContent(
        rankMapDispatcher[0],
        expiredSetDispatcher[0],
        'rank',
        true,
        'releasedTs',
        undefined,
        selectedDeviceContent?.deviceContentPages
      );
      updatedDeviceContent.programContents = filteredPrograms().map((programContent) => {
        const updatedProgramContent: ProgramContent = { ...programContent };
        updatedProgramContent.featureUnlockType = unlockFeature;
        return updatedProgramContent;
      });
      await updateDeviceContent(updatedDeviceContent);
    } else {
      const newDeviceContent: DeviceContent = {
        id: timestampAndRandomCombinedUUIDString(),
        categoryId: findCategoryId(selectedContentType),
        deviceContentType: selectedContentType || undefined,
        deviceContentSubType: DeviceContentSubType.UNKNOWN,
        title: title || undefined,
        subTitle: subTitle || undefined,
        description: description || undefined,
        rank: 1,
        contentUrl: imageUrl || undefined,
        forceOpen: forceOpen
      };
      await addDeviceContent(newDeviceContent);
    }
  };

  const createActionIcons = (deviceContentPage: DeviceContentPage): JSX.Element => (
    <Box component="div" className={style.tileIconBar} onClick={(e) => e.stopPropagation()}>
      <FeatureUnlockChips deviceContentPages={[deviceContentPage]} />
      <Chip className={style.autoAdjustChipIcon} label={normalizeEnumName(deviceContentPage.deviceContentPageType)} color="primary" />
      <RankedContentActions
        rankMapDispatcher={rankMapDispatcher}
        expiredSetDispatcher={expiredSetDispatcher}
        content={deviceContentPage}
        rankFieldName="rank"
        fontSize="inherit"
        cssClass="subTileIcon"
      />
    </Box>
  );

  const createNewPageIcons = (): JSX.Element => (
    <Box component="div" className={style.tileIconBar}>
      {pageTypeList
        .filter(
          (pageType) =>
            pageType !== DeviceContentPageType.UNKNOWN &&
            pageType !== DeviceContentPageType.COMPLETED &&
            pageType !== DeviceContentPageType.IMAGE &&
            pageType !== DeviceContentPageType.COVER
        )
        .map((pageType, index) => (
          <Chip
            key={index}
            className={style.autoAdjustChipIcon}
            label={normalizeEnumName(pageType)}
            disabled={!selectedDeviceContent}
            onClick={() => handlePageSelection(undefined, DeviceContentPageType[pageType])}
          />
        ))}
    </Box>
  );

  const handleChipClick = async (programType: ProgramType, programSubType: ProgramSubType) => {
    await getProgramContentByType(programSubType);
    setSelectedSubType(programSubType);
  };

  const initiateNewProgramContent = (programType: ProgramType, programSubType: ProgramSubType): ProgramContent => {
    return {
      id: timestampAndRandomCombinedUUIDString(),
      programType: programType,
      programSubType: programSubType,
      deviceContentId: selectedDeviceContent?.id || '',
      deviceContent: selectedDeviceContent
    };
  };

  const saveDeviceAndProgramChanges = async (multiContentUpdateRequest: DeviceAndProgramContentUpdateRequest) => {
    await saveDeviceAndProgramContent(multiContentUpdateRequest);
    setSelectedSubType(ProgramSubType.UNKNOWN);
  };

  const savePageUpdates = async (deviceContentPage: DeviceContentPage) => {
    await updateDeviceContentPage(deviceContentPage);
    setSelectedPage(undefined);
  };

  const savePageAdditions = async (deviceContentPage: DeviceContentPage) => {
    await addDeviceContentPage(deviceContentPage);
    setSelectedPageType(DeviceContentPageType.UNKNOWN);
  };

  const determinePageTitle = (devicePage: DeviceContentPage): string => {
    if (devicePage.surveyQuestion) {
      return ellipsify(devicePage.surveyQuestion.questionText, 22);
    } else if (devicePage.longText) {
      const startIndex: number = devicePage.longText.indexOf('# ');
      if (startIndex >= 0) {
        let sub: string = devicePage.longText.substring(startIndex + 2);
        const endIndex: number = sub.indexOf('\n');
        return ellipsify(sub.substring(0, endIndex >= 0 ? endIndex : sub.length), 22);
      } else {
        const markdownWithoutFirstImage: string = extractMarkdownPageText(devicePage.longText, DeviceContentPageType.VIDEO);
        if (markdownWithoutFirstImage.length) {
          return ellipsify(markdownWithoutFirstImage, 22);
        }
      }
    }
    return `Page ${devicePage.rank}`;
  };

  const handlePageSelection = (deviceContentPage?: Nullable<DeviceContentPage>, deviceContentPageType?: Nullable<DeviceContentPageType>): void => {
    if (noChangesToPageStructure()) {
      if (deviceContentPage) {
        setSelectedPage(deviceContentPage);
      } else if (deviceContentPageType) {
        setSelectedPageType(deviceContentPageType);
      }
    } else {
      setOpenUnsavedChangesDialog(true);
    }
  };

  const noChangesToPageStructure = (): boolean => mapsAreEqual(rankMapDispatcher[0], constructRankedMap('rank', selectedDeviceContent?.deviceContentPages));

  const noChanges = (): boolean =>
    areEqualBlank(title, selectedDeviceContent?.title) &&
    areEqualBlank(subTitle, selectedDeviceContent?.subTitle) &&
    areEqualBlank(description, selectedDeviceContent?.description) &&
    areEqualBlank(selectedContentType, selectedDeviceContent?.deviceContentType) &&
    areEqualBlank(imageUrl, selectedDeviceContent?.contentUrl) &&
    noChangesToPageStructure() &&
    unlockFeature === findFeatureUnlock();

  const handleDropdownChange = (option: ContentDropdownOption) => {
    setSelectedContentType(option.value as DeviceContentType);
  };

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={style.root}>
        <Container maxWidth="xl">
          <Header
            subtitle="Tracks"
            breadCrumbs={!openAsDialog ? breadCrumbs : []}
            appendBreadCrumbs={appendBreadCrumbs}
            defaultCurrentCrumb={defaultDeviceContentDetailsCrumb}
            defaultCrumbHistory={defaultDeviceContentDetailsCrumbs}
            overrideUrl={location?.pathname}
            saveFunction={saveDeviceContent}
            saveSuccessful={saveSuccessful}
            disableSave={!title || noChanges()}
          ></Header>
          <StatusMessage
            saveSuccessful={saveSuccessful !== undefined && saveSuccessful}
            saveUnsuccessful={saveSuccessful !== undefined && !saveSuccessful}
            saveMessage={copiedDeviceContentId ? 'Copy Successful' : undefined}
            errorMessage={copiedDeviceContentId ? 'Error While Copying' : undefined}
            clearResponseStatus={clearResponseStatus}
          />
          <Box className={style.headerChipBar}>
            <ContentDropdown
              contentTypeOptions={deviceContentTypes}
              selectAll={false}
              selectedOption={selectedDeviceContent?.deviceContentType || selectedContentType}
              onClick={handleDropdownChange}
              disabled={!!selectedDeviceContent && 'CBTI, DEMO'.includes(selectedDeviceContent?.deviceContentType || '')}
            />
          </Box>
          <Box className={style.headerChipBar}>
            {selectedDeviceContent && (
              <SubTypeBar
                deviceContent={selectedDeviceContent}
                onChipSelect={(programSubType) => handleChipClick(selectedDeviceContent.deviceContentType.toString() as ProgramType, programSubType)}
              />
            )}
            <Box component="span" className={style.featureSelect}>
              {!openAsDialog && !!selectedDeviceContent && (
                <CopyIcon
                  asButton={true}
                  buttonSize={'medium'}
                  buttonClass={clsx(style.tileIcon, style.copyTile)}
                  onClick={() => copyDeviceContent(selectedDeviceContent?.id || '')}
                />
              )}
              <SelectButton
                options={featureUnlockTypeList.map((featureUnlockType) => normalizeEnumName(featureUnlockType))}
                activeIndex={featureUnlockTypeList.indexOf(unlockFeature)}
                onClick={(selectedIndex) => setUnlockFeature(FeatureUnlockType?.[featureUnlockTypeList[selectedIndex]])}
                label={'Feature Unlock'}
                customWidth={'240px'}
                disableConfirmation={true}
                disableSelection={!filteredPrograms().length}
              />
            </Box>
          </Box>
          <Dialog
            fullScreen
            className={style.trackSliderDialog}
            open={selectedSubType !== ProgramSubType.UNKNOWN}
            onClose={() => setSelectedSubType(ProgramSubType.UNKNOWN)}
            TransitionComponent={defaultDialogTransition}
          >
            <Track
              programContentByType={programContentByType}
              deviceContentByType={deviceContentByType}
              saveSuccessful={saveSuccessful}
              track={selectedSubType}
              openAsDialog={true}
              newProgramContent={
                findProgram(selectedSubType, selectedDeviceContent?.programContents)
                  ? undefined
                  : initiateNewProgramContent(selectedContentType.valueOf() as ProgramType, selectedSubType)
              }
              programTypeFilter={selectedContentType.toString() as ProgramType}
              selectedDeviceContentId={selectedDeviceContent?.id}
              saveDeviceAndProgramContent={saveDeviceAndProgramChanges}
              syncContentToProd={() => {}}
              getUserPermissions={() => {}}
              clearResponseStatus={clearResponseStatus}
              getProgramContentByTypeAndSubType={getProgramContentByTypeAndSubType}
            />
          </Dialog>

          <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box className={style.deviceContentBox}>
                <Typography variant="subtitle1" component="div">
                  Title
                </Typography>
                <TextField className={style.deviceContentInput} variant="outlined" value={title} onChange={(e) => setTitle(e.target.value)} />
                <Typography variant="subtitle1" component="div">
                  Subtitle
                </Typography>
                <TextField className={style.deviceContentInput} variant="outlined" value={subTitle} onChange={(e) => setSubTitle(e.target.value)} />
                <Typography variant="subtitle1" component="div">
                  Description
                </Typography>
                <TextField className={style.deviceContentInput} multiline rows={4} variant="outlined" value={description} onChange={(e) => setDescription(e.target.value)} />
                <Typography variant="subtitle1" component="div">
                  Image URL
                </Typography>
                <TextField className={style.deviceContentInput} variant="outlined" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
                <Typography variant="subtitle1" component="div">
                  Force content to open on mobile app startup:
                  <Checkbox checked={!!forceOpen} color="primary" onChange={(e) => setForceOpen(e.target.checked)} inputProps={{ 'aria-label': 'primary checkbox' }} />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Typography variant="subtitle1" component="div">
                Pages
              </Typography>
              {constructOrderedMapContent(rankMapDispatcher[0], expiredSetDispatcher[0], 'rank', false, 'releasedTs', undefined, selectedDeviceContent?.deviceContentPages).map(
                (deviceContentPage, index) => (
                  <ContentTile
                    key={index}
                    tileNumber={deviceContentPage.rank}
                    title={determinePageTitle(deviceContentPage)}
                    onClick={() => handlePageSelection(deviceContentPage)}
                    tailElement={createActionIcons(deviceContentPage)}
                    isSubTile={true}
                  />
                )
              )}
              <ConfirmationDialog
                open={openUnsavedChangesDialog}
                dialogText={
                  'Unsaved changes to page structure detected. This usually indicates that you have removed or reordered some of the pages associated with this content without saving those modifications. Please save or revert any changes before proceeding to the page editor.'
                }
                closeEvent={() => setOpenUnsavedChangesDialog(false)}
                closeEventText={'Close'}
              />
              <Dialog fullScreen className={style.pageSliderDialog} open={!!selectedPage} onClose={() => setSelectedPage(undefined)} TransitionComponent={defaultDialogTransition}>
                <PageDetails
                  selectedDeviceContentPage={selectedPage}
                  saveSuccessful={saveSuccessful}
                  openAsDialog={true}
                  updateDeviceContentPage={savePageUpdates}
                  clearResponseStatus={clearResponseStatus}
                  getDeviceContentDetails={getDeviceContentDetails}
                  getDeviceContentPage={getDeviceContentPage}
                />
              </Dialog>
              <ContentTile title="New Page" tailElement={createNewPageIcons()} isSubTile={true} />
              <Dialog
                fullScreen
                className={style.pageSliderDialog}
                open={selectedPageType !== DeviceContentPageType.UNKNOWN}
                onClose={() => setSelectedPageType(DeviceContentPageType.UNKNOWN)}
                TransitionComponent={defaultDialogTransition}
              >
                <PageDetails
                  selectedDeviceContent={selectedDeviceContent}
                  deviceContentPageType={selectedPageType}
                  saveSuccessful={saveSuccessful}
                  openAsDialog={true}
                  addDeviceContentPage={savePageAdditions}
                  clearResponseStatus={clearResponseStatus}
                  getDeviceContentDetails={getDeviceContentDetails}
                  getDeviceContentPage={getDeviceContentPage}
                />
              </Dialog>
            </Grid>
          </Grid>
        </Container>
      </div>
    </MuiThemeProvider>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      selectedDeviceContent: getSelectedDeviceContentSelector(state),
      programContentByType: getProgramContentByTypeSelector(state),
      deviceContentByType: getDeviceContentByTypeSelector(state),
      saveSuccessful: getSaveSuccessfulSelector(state),
      copiedDeviceContentId: getCopiedDeviceContentIdSelector(state),
      breadCrumbs: getBreadcrumbsSelector(state),
      selectedContentType: getSelectedContentType(state)
    };
  },
  (dispatch: Function) => ({
    getProgramContentByType: (programSubType: ProgramSubType) => {
      programContentTypes.forEach((programType: ProgramType) => {
        dispatch(getProgramContentByTypeThunk(programType, programSubType));
      });
    },
    getProgramContentByTypeAndSubType: (programType: ProgramType, programSubType?: ProgramSubType) => {
      dispatch(getProgramContentByTypeThunk(programType, programSubType));
    },
    getDeviceContentDetails: (deviceContentId: string) => {
      dispatch(getDeviceContentDetailsThunk(deviceContentId));
    },
    clearDeviceContentDetailsAction: () => {
      dispatch(getClearDeviceContentDetailsAction());
    },
    getDeviceContent: (deviceContentTypes: DeviceContentType[]) => {
      dispatch(getDeviceContentThunk(deviceContentTypes));
    },
    updateDeviceContent: (deviceContent: DeviceContent) => {
      dispatch(updateDeviceContentThunk(deviceContent));
    },
    addDeviceContent: (deviceContent: DeviceContent) => {
      dispatch(addDeviceContentThunk(deviceContent));
    },
    updateDeviceContentPage: (deviceContentPage: DeviceContentPage) => {
      dispatch(updateDeviceContentPageThunk(deviceContentPage));
    },
    addDeviceContentPage: (deviceContentPage: DeviceContentPage) => {
      dispatch(addDeviceContentPageThunk(deviceContentPage));
    },
    saveDeviceAndProgramContent: (deviceAndProgramContentUpdateRequest: DeviceAndProgramContentUpdateRequest) => {
      dispatch(saveDeviceAndProgramContentsThunk(deviceAndProgramContentUpdateRequest));
    },
    copyDeviceContent: (deviceContentId: string) => {
      dispatch(copyDeviceContentDetailsThunk(deviceContentId));
    },
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => {
      dispatch(appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }));
    },
    clearResponseStatus: () => {
      dispatch(getClearResponseStatusAction());
    },
    getDeviceContentPage: (deviceContentPageId: string) => {
      dispatch(getDeviceContentPageThunk(deviceContentPageId));
    },
    clearDeviceContent: () => {
      dispatch(getClearDeviceContentDetailsAction());
    }
  })
);

export default compose(connectRedux)(DeviceContentDetails) as React.ComponentType;
