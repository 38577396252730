import classNames from 'classnames';
import React from 'react';
import { VitalSignsStatus } from '@redux/person/personTypes';
import { AlertIcon, WarningIcon } from 'components/Icons';
import { Text, TextVariant } from '../Typography';
import style from './VitalSign.scss';

export type VitalSignStatusProps = {
  status?: VitalSignsStatus;
  showBg?: boolean;
  textValue?: string | number;
  textVariant?: TextVariant;
  textClassName?: string;
  line2TextValue?: string | number;
  line2TextVariant?: TextVariant;
  line2TextClassName?: string;
};

export const VitalSign: React.FC<VitalSignStatusProps> = ({
  status,
  showBg = false,
  textValue,
  textVariant = 'sm',
  textClassName,
  line2TextValue = '',
  line2TextVariant = 'sm',
  line2TextClassName
}) => {
  const isDefaultStatus = status === VitalSignsStatus.UNKNOWN || status === VitalSignsStatus.NORMAL;
  return (
    <div>
      <div className={style.iconTextContainer}>
        <div
          className={classNames(
            !isDefaultStatus && style.imgContainer,
            isDefaultStatus && line2TextValue && style.textContainer,
            { [style.redBg]: showBg && status === VitalSignsStatus.ABNORMAL },
            { [style.yellowBg]: showBg && status === VitalSignsStatus.SUBOPTIMAL },
            { [style.noBg]: !showBg }
          )}
        >
          {status === VitalSignsStatus.ABNORMAL && <AlertIcon />}
          {status === VitalSignsStatus.SUBOPTIMAL && <WarningIcon />}
        </div>
        {textValue && (
          <Text variant={textVariant} className={classNames(style.valueText, textClassName)}>
            {textValue}
          </Text>
        )}
      </div>
      {line2TextValue && (
        <div className={style.textContainer}>
          <Text variant={line2TextVariant} className={classNames(style.line2Text, line2TextClassName)}>
            {line2TextValue}
          </Text>
        </div>
      )}
    </div>
  );
};
