import { createReducer } from '@reduxjs/toolkit';
import { defaultActionFail, defaultActionStart, getActionStringWithoutState, setDefaultFail, setDefaultStart } from '@redux/reduxUtils';
import { cancelTaskAction, clearSelectedTaskDetails, getTaskDetailsAction, getTasksListAction, postNewTaskAction, TASK_ACTION_PREFIX } from '@redux/tasks/tasksActions';
import { TasksState, TaskStatusEnum } from '@redux/tasks/tasksTypes';

export const initialTasksState: TasksState = {
  isLoading: {},
  error: {},
  success: {},
  tasksList: [],
  selectedTaskDetails: null
};

export default createReducer(initialTasksState, (builder) => {
  builder
    .addCase(getTasksListAction.start, defaultActionStart)
    .addCase(getTasksListAction.fail, defaultActionFail)
    .addCase(getTasksListAction.success, (state, action) => {
      const tasksTag = getActionStringWithoutState(action.type);
      state.isLoading[tasksTag] = false;
      state.tasksList = action.payload ?? [];
    })

    .addCase(getTaskDetailsAction.start, defaultActionStart)
    .addCase(getTaskDetailsAction.fail, defaultActionFail)
    .addCase(getTaskDetailsAction.success, (state, action) => {
      const tasksTag = getActionStringWithoutState(action.type);
      state.isLoading[tasksTag] = false;
      state.selectedTaskDetails = action.payload;
    })

    .addCase(cancelTaskAction.start, defaultActionStart)
    .addCase(cancelTaskAction.fail, defaultActionFail)
    .addCase(cancelTaskAction.success, (state, action) => {
      const tasksTag = getActionStringWithoutState(action.type);
      state.isLoading[tasksTag] = false;
      const updatedTasksList = [...state.tasksList];
      const canceledTaskIndex = state.tasksList.findIndex((task) => task.taskId === action.payload.id);
      updatedTasksList[canceledTaskIndex].status = TaskStatusEnum.CANCELED;
      state.tasksList = updatedTasksList;
    })
    .addCase(postNewTaskAction.start, defaultActionStart)
    .addCase(postNewTaskAction.fail, defaultActionFail)
    .addCase(postNewTaskAction.success, (state, action) => {
      const tasksTag = getActionStringWithoutState(action.type);
      state.isLoading[tasksTag] = false;
    })
    .addCase(clearSelectedTaskDetails, (state) => {
      state.selectedTaskDetails = null;
    });

  builder
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-start') && action.type.startsWith(TASK_ACTION_PREFIX),
      (state, action) => {
        setDefaultStart(state, action.type);
      }
    )
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-success') && action.type.startsWith(TASK_ACTION_PREFIX),
      (state, action) => {
        const tag = getActionStringWithoutState(action.type);
        state.isLoading[tag] = false;
      }
    )
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-fail') && action.type.startsWith(TASK_ACTION_PREFIX),
      (state, action) => {
        setDefaultFail(state, action);
      }
    );
});
