import classNames from 'classnames';
import React from 'react';
import { TaskLogsType } from '@redux/tasks/tasksTypes';
import { CircleIcon } from 'components/Icons';
import { Text } from 'components/Typography';
import { getFormatedTimeIgnoreTimezone } from 'utils/time';
import style from './TaskLogs.scss';

type Props = { logsList: TaskLogsType[] };

export const TaskLogs: React.FC<Props> = ({ logsList }) => {
  return (
    <div className={style.container}>
      <Text variant="md" className={style.grayText}>
        Task log
      </Text>
      <div className={style.logsContainer}>
        {logsList.map((log, index, array) => (
          <div className={style.logContainer} key={log.id}>
            <div className={style.decoration}>
              <CircleIcon width={20} height={20} />
              <div className={style.lineWrapper}>
                <div className={style.line} />
              </div>
            </div>
            <div className={classNames(style.log, index === array.length - 1 && style.lastLog)}>
              <Text variant="md">{log.logTitle}</Text>
              <Text variant="sm" className={style.grayText}>
                {getFormatedTimeIgnoreTimezone(log.logCreated)}
              </Text>
              {log.logMessage && (
                <div className={style.messageWrapper}>
                  <Text variant="md">{log.logMessage}</Text>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
