import { createReducer } from '@reduxjs/toolkit';
import {
  changeSleepCoachMessageReadActions,
  getChatGPTDeviceMessagesActions,
  getChatGPTMobileMessagesActions,
  getChatSleepCoachMessagesActions,
  getLatestUnreadSleepCoachMessagesActions,
  postChatSleepCoachMessageActions
} from './chatActions';
import { ChatState } from './chatTypes';

export const initialChatState: ChatState = {
  errorGetChatGPTDeviceMessages: {},
  errorGetChatGPTMobileMessages: {},
  errorGetChatSleepCoachMessages: {},
  errorPostChatSleepCoachMessage: {},
  isChangingSleepCoachMessageRead: false,
  isLoadingChatGPTDeviceMessages: {},
  isLoadingChatGPTMobileMessages: {},
  isLoadingChatSleepCoachMessages: {},
  isLoadingLatestUnreadSleepCoachMessages: false,
  isPostingChatSleepCoachMessage: {},
  chatGPTDeviceMessages: {},
  chatGPTMobileMessages: {},
  chatSleepCoachMessages: {},
  latestUnreadSleepCoachMessages: []
};

type ChatStateWithoutReadonly = {
  -readonly [key in keyof ChatState]: ChatState[key];
};

export default createReducer(initialChatState, (builder) => {
  builder
    .addCase(getChatGPTMobileMessagesActions.start, (state, action) => {
      const personId = action.payload;
      state.isLoadingChatGPTMobileMessages[personId] = true;
      state.errorGetChatGPTMobileMessages[personId] = undefined;
    })
    .addCase(getChatGPTMobileMessagesActions.success, (state, action) => {
      const { personId, messages } = action.payload;
      state.isLoadingChatGPTMobileMessages[personId] = false;
      state.chatGPTMobileMessages[personId] = messages;
    })
    .addCase(getChatGPTMobileMessagesActions.fail, (state, action) => {
      const { personId, error } = action.payload;
      state.isLoadingChatGPTMobileMessages[personId] = false;
      state.errorGetChatGPTMobileMessages[personId] = error;
    })
    .addCase(getChatGPTDeviceMessagesActions.start, (state, action) => {
      const personId = action.payload;
      state.isLoadingChatGPTDeviceMessages[personId] = true;
      state.errorGetChatGPTDeviceMessages[personId] = undefined;
    })
    .addCase(getChatGPTDeviceMessagesActions.success, (state, action) => {
      const { personId, messages } = action.payload;
      state.isLoadingChatGPTDeviceMessages[personId] = false;
      state.chatGPTDeviceMessages[personId] = messages;
    })
    .addCase(getChatGPTDeviceMessagesActions.fail, (state, action) => {
      const { personId, error } = action.payload;
      state.isLoadingChatGPTDeviceMessages[personId] = false;
      state.errorGetChatGPTDeviceMessages[personId] = error;
    })
    .addCase(getChatSleepCoachMessagesActions.start, (state, action) => {
      const personId = action.payload;
      state.isLoadingChatSleepCoachMessages[personId] = true;
      state.errorGetChatSleepCoachMessages[personId] = undefined;
    })
    .addCase(getChatSleepCoachMessagesActions.success, (state, action) => {
      const { personId, messages } = action.payload;
      state.isLoadingChatSleepCoachMessages[personId] = false;
      state.chatSleepCoachMessages[personId] = messages;
    })
    .addCase(getChatSleepCoachMessagesActions.fail, (state, action) => {
      const { personId, error } = action.payload;
      state.isLoadingChatSleepCoachMessages[personId] = false;
      state.errorGetChatSleepCoachMessages[personId] = error;
    })
    .addCase(getLatestUnreadSleepCoachMessagesActions.start, (state, action) => {
      state.isLoadingLatestUnreadSleepCoachMessages = true;
      delete state.errorGetLatestUnreadSleepCoachMessages;
    })
    .addCase(getLatestUnreadSleepCoachMessagesActions.success, (state, action) => {
      state.isLoadingLatestUnreadSleepCoachMessages = false;
      state.latestUnreadSleepCoachMessages = action.payload;
    })
    .addCase(getLatestUnreadSleepCoachMessagesActions.fail, (state, action) => {
      state.isLoadingLatestUnreadSleepCoachMessages = false;
      state.errorGetLatestUnreadSleepCoachMessages = action.payload;
    })
    .addCase(changeSleepCoachMessageReadActions.start, (state, action) => {
      state.isChangingSleepCoachMessageRead = true;
      delete state.errorChangeSleepCoachMessageRead;
    })
    .addCase(changeSleepCoachMessageReadActions.success, (state, action) => {
      state.isChangingSleepCoachMessageRead = false;
      updateMessageReadStatus(state, action.payload);
    })
    .addCase(changeSleepCoachMessageReadActions.fail, (state, action) => {
      state.isChangingSleepCoachMessageRead = false;
      state.errorChangeSleepCoachMessageRead = action.payload;
    })
    .addCase(postChatSleepCoachMessageActions.start, (state, action) => {
      const personId = action.payload;
      state.isPostingChatSleepCoachMessage[personId] = true;
      state.errorPostChatSleepCoachMessage[personId] = undefined;
    })
    .addCase(postChatSleepCoachMessageActions.success, (state, action) => {
      const { personId, message } = action.payload;
      state.isPostingChatSleepCoachMessage[personId] = false;
      state.chatSleepCoachMessages[personId] = [...state.chatSleepCoachMessages[personId], message];
    })
    .addCase(postChatSleepCoachMessageActions.fail, (state, action) => {
      const { personId, error } = action.payload;
      state.isPostingChatSleepCoachMessage[personId] = false;
      state.errorPostChatSleepCoachMessage[personId] = error;
    });
});

const updateMessageReadStatus = (state: ChatStateWithoutReadonly, payload: { personId: string; messageId: string; readStatus: boolean }) => {
  const { personId, messageId, readStatus } = payload;
  const updatedMessages = [...state.chatSleepCoachMessages[personId]];
  let messageIndex = -1;
  for (let i = updatedMessages.length - 1; i >= 0; i--) {
    if (updatedMessages[i].id === messageId) {
      messageIndex = i;
      break;
    }
  }
  if (messageIndex !== -1) {
    updatedMessages[messageIndex].messageReadTs = readStatus ? new Date().toISOString() : undefined;
  }
  state.chatSleepCoachMessages[personId] = updatedMessages;
};
