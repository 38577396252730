import { Container, MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { BreadCrumbData } from '@redux/common/types';
import { ProgramSubType, subTypeList } from '@redux/content/contentTypes';
import { appendBreadcrumbAction } from '@redux/oauth/oauthActions';
import { getBreadcrumbsSelector } from '@redux/oauth/oauthSelectors';
import { ReduxState } from '@redux/types';
import { ContentTile } from '../../components/ContentTile/ContentTile';
import { Header } from '../../components/Header/Header';
import { defaultAllTracksCrumb, defaultAllTracksCrumbs } from '../../utils/breadcrumbs';
import { normalizeEnumName } from '../../utils/content';
import { defaultTheme } from '../../utils/styles';
import { goTo } from '../../utils/util';
import style from './AllTracks.scss';

interface Props {
  breadCrumbs: BreadCrumbData[];
  appendBreadCrumbs: (breadCrumb: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => void;
}

export const AllTracks: React.FC<Props> = ({ breadCrumbs, appendBreadCrumbs }) => {
  const navigate = useNavigate();

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={style.root}>
        <Container maxWidth="xl">
          <Header
            breadCrumbs={breadCrumbs}
            appendBreadCrumbs={appendBreadCrumbs}
            title={'All Tracks'}
            defaultCurrentCrumb={defaultAllTracksCrumb}
            defaultCrumbHistory={defaultAllTracksCrumbs}
          ></Header>
          {subTypeList
            .filter((programSubType) => programSubType !== ProgramSubType.UNKNOWN)
            .map((programSubType, index) => (
              <ContentTile key={index} title={normalizeEnumName(programSubType)} onClick={goTo(`/track/${programSubType}`, navigate)} />
            ))}

          <ContentTile key={subTypeList.length} title="Demo Content" onClick={goTo(`/track/DEMO_CONTENT`, navigate)} />
        </Container>
      </div>
    </MuiThemeProvider>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      breadCrumbs: getBreadcrumbsSelector(state)
    };
  },
  (dispatch: Function) => ({
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => {
      dispatch(appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }));
    }
  })
);

export default compose(connectRedux)(AllTracks) as React.ComponentType;
