import { Container, MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { BreadCrumbData } from '@redux/common/types';
import { appendBreadcrumbAction } from '@redux/oauth/oauthActions';
import { getBreadcrumbsSelector } from '@redux/oauth/oauthSelectors';
import { logoutThunk } from '@redux/oauth/oauthThunks';
import { ReduxState } from '@redux/types';
import { ContentTile } from 'components/ContentTile/ContentTile';
import { Header } from 'components/Header/Header';
import { defaultHomeBreadCrumbs, defaultHomeOldBreadCrumb } from 'utils/breadcrumbs';
import { defaultTheme } from 'utils/styles';
import { goTo } from 'utils/util';
import style from './Home.scss';

interface Props {
  breadCrumbs: BreadCrumbData[];
  appendBreadCrumbs: (breadCrumb: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => void;
  startLogout: () => void;
}

const Home: React.FC<Props> = ({ breadCrumbs, appendBreadCrumbs, startLogout }) => {
  const navigate = useNavigate();

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={style.root}>
        <Container maxWidth="xl">
          <Header
            breadCrumbs={breadCrumbs}
            title="Home"
            appendBreadCrumbs={appendBreadCrumbs}
            defaultCurrentCrumb={defaultHomeOldBreadCrumb}
            defaultCrumbHistory={defaultHomeBreadCrumbs}
            logoutFunction={startLogout}
          />
          <ContentTile title="Devices" onClick={goTo('/devices', navigate)} />
          <ContentTile title="Permissions" onClick={goTo('/permissions', navigate)} />
          <ContentTile title="Program Management" onClick={goTo('/program', navigate)} />
          <ContentTile title="Firmware Provisioning" onClick={goTo('/firmwareVersions', navigate)} />
          <ContentTile title="Device Provisioning" onClick={goTo('/deviceProvisioning', navigate)} />
          <ContentTile title="Users" onClick={goTo('/users', navigate)} />
          <ContentTile title="Content Manager" onClick={goTo('/contentManager', navigate)} />
        </Container>
      </div>
    </MuiThemeProvider>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      breadCrumbs: getBreadcrumbsSelector(state)
    };
  },
  (dispatch: Function) => ({
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => {
      dispatch(appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }));
    },
    startLogout: () => {
      dispatch(logoutThunk());
    }
  })
);

export default compose(connectRedux)(Home) as React.ComponentType;
