import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { getInboxMessagesDetailsSelector, getOrganizationInboxMessagesSelector } from '@redux/inboxMessages/inboxMessagesSelector';
import {
  clearSelectedInboxMessagesDetailsThunk,
  getInboxMessagesDetailsThunk,
  getInboxMessagesThunk,
  postInboxMessagesResponseThunk
} from '@redux/inboxMessages/inboxMessagesThunks';
import { InboxMessages, InboxMessagesDetails } from '@redux/inboxMessages/inboxMessagesTypes';
import { getAuthPersonSelector } from '@redux/oauth/oauthSelectors';
import { TaskStatusEnum, TasksListItemType } from '@redux/tasks/tasksTypes';
import { ReduxState } from '@redux/types';
import NavTopBarContentWrapper from 'components/NavTopBarContentWrapper/NavTopBarContentWrapper';
import TaskInfoDrawer from 'containers/Tasks/components/TaskInfoDrawer/TaskInfoDrawer';
import { ConversationFeedArea } from './components/ConversationFeedArea/ConversationFeedArea';
import { ConversationsArea } from './components/ConversationsArea/ConversationsArea';
import { NoRequestSelectedArea } from './components/NoRequestSelectedArea/NoRequestSelectedArea';
import style from './Inbox.scss';

// const demo_convos: InboxMessages[] = [
//     {
//       id: "111",
//       patientFullName: 'Sara Ortega',
//       messageType: 'Medication Refill',
//       lastCreated: new Date(Date.now() - 5 * 60 * 1000).toISOString()
//     },
//     {
//       id: "222",
//       patientFullName: 'Phoenix Baker',
//       messageType: 'Health status update',
//       lastCreated: new Date(Date.now() - 20 * 60 * 1000).toISOString()
//     },
//     {
//       id: "333",
//       patientFullName: 'Andi Lane',
//       messageType: 'Energy boost tips',
//       lastCreated: new Date(Date.now() - 60 * 60 * 1000).toISOString()
//     }
//   ];
// const demo_details: InboxMessagesDetails = {
//     patientId: "0195ca99-b985-e72f-1ae4-259934ad696b",
//     patientFullName: "Sara Ortega",
//     patientEmail: "saraortega@gmail.com",
//     listOfInboxMessageDetailContentViews: [
//         {
//             id: "1",
//             messageType: "Medication refill",
//             assistantMessage: "Sara is running low on Donepezil 10mg (once daily at bedtime) and Memantine 5mg (twice daily, morning and evening) for her Alzheimer’s management.",
//             assistantMessageCreated: new Date(Date.now() - 5 * 60 * 1000).toISOString(),
//             taskId: '1',
//             taskName: 'Next medication dose is scheduled for Saturday at 1:30 PM',
//             scheduledFor: new Date('2025-02-03T10:17:00-08:00').toISOString(),
//             taskStatus: TaskStatusEnum.IN_PROGRESS,
//             responderFullName: "Olivia Rhye",
//             response: "I’ve reviewed your medication needs, and I will send a refill request for your Donepezil 10mg (once daily at bedtime) and Memantine 5mg (twice daily, morning and evening) to your pharmacy.",
//             responseCreated: new Date().toISOString(),
//             responseStatus: TaskStatusEnum.COMPLETED
//         },
//         {
//           id: "2",
//           messageType: "Medication refill",
//           assistantMessage: "Sara is running low on Donepezil 10mg (once daily at bedtime) and Memantine 5mg (twice daily, morning and evening) for her Alzheimer’s management.",
//           assistantMessageCreated: new Date(Date.now() - 25 * 60 * 1000).toISOString(),
//           taskId: '1',
//           taskName: 'Next medication dose is scheduled for Saturday at 1:30 PM',
//           scheduledFor: new Date('2025-02-03T10:17:00-08:00').toISOString(),
//           taskStatus: TaskStatusEnum.IN_PROGRESS
//       },
//     ]
// }

type Props = ConnectedProps<typeof connectRedux>;
const Inbox: React.FC<Props> = ({
  authPersonSelector,
  inboxMessages,
  inboxMessagesDetails,
  fetchInboxMessages,
  fetchInboxMessagesDetails,
  clearInboxMessagesDetails,
  postResponse
}) => {
  const organizationId = authPersonSelector?.organizationId ?? '';
  const personId = authPersonSelector?.id ?? '';
  const [selectedInboxMessage, setSelectedInboxMessage] = useState<Nullable<string>>(null);
  const [selectedTaskId, setSelectedTaskId] = useState<string>('');

  const onSendResponse = async (reply: string, messageId: string) => {
    await postResponse({
      inboxMessageId: messageId,
      responderId: personId,
      response: reply
    });
  };

  const onSelectTaskSwitch = useCallback((id: string = '') => {
    setSelectedTaskId(id);
  }, []);

  useEffect(() => {
    const fetchInbox = async () => {
      ``;
      await fetchInboxMessages(organizationId);
    };

    fetchInbox();
  }, []);

  useEffect(() => {
    if (selectedInboxMessage) {
      if (inboxMessagesDetails) clearInboxMessagesDetails();

      console.log('fetiching message details');
      fetchInboxMessagesDetails(selectedInboxMessage);
    }
  }, [selectedInboxMessage]);

  return (
    <NavTopBarContentWrapper>
      <div className={style.inboxLayoutContainer}>
        <div className={style.conversationsAreaContainer}>
          <ConversationsArea
            inboxMessages={inboxMessages}
            selectedInboxMessage={selectedInboxMessage}
            onInboxMessageSelected={(inboxMessageId) => setSelectedInboxMessage(inboxMessageId)}
          ></ConversationsArea>
        </div>
        <div className={style.conversationFeedAreaContainer}>
          {!selectedInboxMessage && <NoRequestSelectedArea></NoRequestSelectedArea>}
          {!!selectedInboxMessage && !!inboxMessagesDetails && (
            <ConversationFeedArea inboxMessagesDetails={inboxMessagesDetails!} onSelectTaskSwitch={onSelectTaskSwitch} sendResponse={onSendResponse}></ConversationFeedArea>
          )}
        </div>
      </div>
      {selectedTaskId && <TaskInfoDrawer taskId={selectedTaskId} onClose={() => onSelectTaskSwitch()} />}
    </NavTopBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    authPersonSelector: getAuthPersonSelector(state),
    inboxMessages: getOrganizationInboxMessagesSelector(state),
    inboxMessagesDetails: getInboxMessagesDetailsSelector(state)
  }),
  {
    fetchInboxMessages: getInboxMessagesThunk,
    fetchInboxMessagesDetails: getInboxMessagesDetailsThunk,
    clearInboxMessagesDetails: clearSelectedInboxMessagesDetailsThunk,
    postResponse: postInboxMessagesResponseThunk
  }
);

export default compose(connectRedux)(Inbox);
