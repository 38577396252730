import { combineReducers } from 'redux';
import activityReducer from './activity/activityReducer';
import analyticsReducer from './analytics/analyticsReducer';
import carePlanReducer from './carePlan/carePlanReducer';
import chatReducer from './chat/chatReducer';
import clientDeviceReducer from './clientDevice/clientDeviceReducer';
import clinicalReducer from './clinical/clinicalReducer';
import contentReducer from './content/contentReducer';
import deviceReducer from './device/deviceReducer';
import inboxMessagesReducer from './inboxMessages/inboxMessagesReducer';
import oauthReducer from './oauth/oauthReducer';
import organizationReducer from './organization/organizationReducer';
import paymentReducer from './payment/paymentReducer';
import personReducer from './person/personReducer';
import sleepScheduleReducer from './sleepschedule/sleepScheduleReducer';
import tasksReducer from './tasks/tasksReducer';

export const rootReducer = combineReducers({
  activity: activityReducer,
  analytics: analyticsReducer,
  carePlan: carePlanReducer,
  clinical: clinicalReducer,
  chat: chatReducer,
  clientDevice: clientDeviceReducer,
  device: deviceReducer,
  oauth: oauthReducer,
  person: personReducer,
  content: contentReducer,
  payment: paymentReducer,
  sleepSchedule: sleepScheduleReducer,
  organization: organizationReducer,
  tasks: tasksReducer,
  inboxMessages: inboxMessagesReducer
});
