import { ErrorPayload } from '@redux/common/types';
import { ThunkParams } from '@redux/types';
import { clearSelectedInboxMessagesDetails, getInboxMessagesAction, getInboxMessagesDetailsAction, postInboxMessagesResponseAction } from './inboxMessagesActions';
import { InboxMessages, InboxMessagesDetails, InboxMessagesResponsePayload } from './inboxMessagesTypes';

function processGetInboxMessagesSuccess(payload: InboxMessages[]) {
  return function (dispatch: Function, _: Function, { apiClient }: ThunkParams) {
    dispatch(getInboxMessagesAction.success(payload));
  };
}

function processGetInboxMessagesFail(error: ErrorPayload) {
  return function (dispatch: Function, _: Function, { apiClient }: ThunkParams) {
    dispatch(getInboxMessagesAction.fail(error));
  };
}

export function getInboxMessagesThunk(organizationId: string) {
  return async function (dispatch: Function, _: Function, { apiClient }: ThunkParams) {
    dispatch(getInboxMessagesAction.start());
    return apiClient.get(`v1/engagementPlan/inboxMessages/${organizationId}`, {
      auth: true,
      successAction: processGetInboxMessagesSuccess,
      failAction: processGetInboxMessagesFail
    });
  };
}

function processGetInboxMessagesDetailsSuccess(payload: InboxMessagesDetails) {
  return function (dispatch: Function, _: Function, { apiClient }: ThunkParams) {
    dispatch(getInboxMessagesDetailsAction.success(payload));
  };
}

function processGetInboxMessagesDetailsFail(error: ErrorPayload) {
  return function (dispatch: Function, _: Function, { apiClient }: ThunkParams) {
    dispatch(getInboxMessagesDetailsAction.fail(error));
  };
}

export function getInboxMessagesDetailsThunk(personId: string) {
  return async function (dispatch: Function, _: Function, { apiClient }: ThunkParams) {
    dispatch(getInboxMessagesDetailsAction.start());
    return apiClient.get(`v1/engagementPlan/inboxMessage/${personId}`, {
      auth: true,
      successAction: processGetInboxMessagesDetailsSuccess,
      failAction: processGetInboxMessagesDetailsFail
    });
  };
}

export function clearSelectedInboxMessagesDetailsThunk() {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(clearSelectedInboxMessagesDetails());
  };
}

function postInboxMessagesResponseThunkSuccess(payload: InboxMessagesDetails) {
  return function (dispatch: Function, _: Function, { apiClient }: ThunkParams) {
    dispatch(postInboxMessagesResponseAction.success(payload));
  };
}

function postInboxMessagesResponseThunkFail(error: ErrorPayload) {
  return function (dispatch: Function, _: Function, { apiClient }: ThunkParams) {
    dispatch(postInboxMessagesResponseAction.fail(error));
  };
}

export function postInboxMessagesResponseThunk(response: InboxMessagesResponsePayload) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(postInboxMessagesResponseAction.start());

    return apiClient.patch(`v1/engagementPlan/inboxMessage/${response.inboxMessageId}/respond`, {
      auth: true,
      data: response,
      successAction: postInboxMessagesResponseThunkSuccess,
      failAction: postInboxMessagesResponseThunkFail
    });
  };
}
