import { Button, CardHeader, Dialog, DialogActions, DialogContent, IconButton, TextField } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { PersonWorkflowComment, WorkflowType } from '@redux/content/contentTypes';
import { getIconForWorkflow } from 'utils/breadcrumbs';
import { defaultPrimaryColor } from 'utils/styles';
import { timestampAndRandomCombinedUUIDString } from '../../../../shared/src/util/uuid';
import { CommentIcon } from '../CommentIcon/CommentIcon';

interface Props {
  workflowType?: Nullable<WorkflowType>;
  title?: Nullable<string>;
  personWorkflowId?: Nullable<string>;
  authenticatedPersonId?: Nullable<string>;
  flagComplete: boolean;
  style?: React.CSSProperties; //external component does not support nullable types, so we can't apply Nullable<> type here
  titleTypographyProps?: TypographyProps<any>; //external component does not support nullable types, so we can't apply Nullable<> type here
  saveFlagComment?: Nullable<(personWorkflowComment: PersonWorkflowComment) => void>;
  tailElement?: Nullable<JSX.Element>;
}

export const FlagBullet: React.FC<Props> = ({
  workflowType,
  title,
  personWorkflowId,
  authenticatedPersonId,
  flagComplete,
  style,
  titleTypographyProps,
  saveFlagComment,
  tailElement
}) => {
  const [displayComment, setDisplayComment] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');

  const handleClose = (): void => {
    setDisplayComment(() => false);
    setComment(() => '');
  };

  const saveComment = () => {
    const flagComment: PersonWorkflowComment = {
      id: timestampAndRandomCombinedUUIDString(),
      personWorkflowId: personWorkflowId || '',
      authorPersonId: authenticatedPersonId || '',
      comment: comment
    };
    if (saveFlagComment) {
      saveFlagComment(flagComment);
    }

    handleClose();
  };

  return (
    <React.Fragment>
      <CardHeader
        avatar={getIconForWorkflow(workflowType, flagComplete)}
        style={style}
        titleTypographyProps={titleTypographyProps}
        title={
          <React.Fragment>
            {title}
            {!!saveFlagComment && (
              <IconButton size="small" style={{ marginLeft: '16px' }} onClick={() => setDisplayComment((displayComment) => !displayComment)}>
                <CommentIcon style={{ color: defaultPrimaryColor }} />
              </IconButton>
            )}
          </React.Fragment>
        }
        action={tailElement}
      />
      <Dialog open={displayComment} onClose={handleClose} fullWidth>
        <DialogContent>
          <TextField autoFocus margin="dense" label="Comment" fullWidth value={comment} onChange={(e) => setComment(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={saveComment} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
