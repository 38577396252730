import { Box } from '@material-ui/core';
import { Moment } from 'moment';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { isiScoreTypesList } from '@redux/content/contentTypes';
import { IsiScoreType, PersonDetails, PersonIsiScore } from '@redux/person/personTypes';
import { currentDateTime } from 'utils/time';
import { timestampAndRandomCombinedUUIDString } from '../../../../../shared/src/util/uuid';
import { EditableTable } from '../../components/EditableTable/EditableTable';

export const IsiScoreTab: React.FC<{
  selectedPersonDetails?: Nullable<PersonDetails>;
  personIsiScoreById: Record<string, PersonIsiScore>;
  userTimeZone?: Nullable<string>;
  saveIsiScores: Function;
}> = ({ selectedPersonDetails, personIsiScoreById, userTimeZone, saveIsiScores }) => {
  const scoreState: [number | string, Dispatch<SetStateAction<number | string>>] = useState<number | string>(0);
  const timestampState: [Nullable<Moment>, Dispatch<SetStateAction<Nullable<Moment>>>] = useState<Nullable<Moment>>(undefined);
  const scoreTypeState: [IsiScoreType, Dispatch<SetStateAction<IsiScoreType>>] = useState<IsiScoreType>(IsiScoreType.START);

  const saveIsiScore = async (editRowIndex: number) => {
    const isiScore: PersonIsiScore = {
      id: editRowIndex >= 0 ? selectedPersonDetails?.personIsiScoreIds?.[editRowIndex] : timestampAndRandomCombinedUUIDString(),
      personId: selectedPersonDetails?.personId || '',
      isiScore: +scoreState[0],
      isiTs: timestampState[0]?.format() || '',
      isiScoreType: scoreTypeState[0]
    };
    await saveIsiScores(isiScore);
  };

  return (
    <Box display="flex" alignItems="flex-start" justifyContent="center" mt={3} minHeight="100vh">
      <EditableTable
        data={{
          headerConfig: [
            { value: 'SCORE', attribute: 'isiScore', type: 'number', state: scoreState },
            { value: 'TIMESTAMP', attribute: 'isiTs', type: 'timestamp', state: timestampState, defaultValue: currentDateTime(userTimeZone) },
            { value: 'TYPE', attribute: 'isiScoreType', type: 'select', selectOptions: isiScoreTypesList, state: scoreTypeState, defaultValue: IsiScoreType.START }
          ],
          rowData: selectedPersonDetails?.personIsiScoreIds?.map((personIsiScoreId) => (personIsiScoreId ? personIsiScoreById?.[personIsiScoreId] : undefined))
        }}
        editProps={{
          addRowEnabled: true,
          saveRowToDb: saveIsiScore
        }}
        timeZone={userTimeZone}
      />
    </Box>
  );
};
