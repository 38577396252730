import { ErrorPayload } from '../common/types';
import { ThunkParams } from '../types';
import {
  changeSleepCoachMessageReadActions,
  getChatGPTDeviceMessagesActions,
  getChatGPTMobileMessagesActions,
  getChatSleepCoachMessagesActions,
  getLatestUnreadSleepCoachMessagesActions,
  postChatSleepCoachMessageActions
} from './chatActions';
import { ChatChannelType, ChatMessage } from './chatTypes';

function processGetChatGPTMobileMessagesSuccess(personId: string, messages: ChatMessage[]) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getChatGPTMobileMessagesActions.success({ personId, messages }));
  };
}

function processGetChatGPTMobileMessagesFail(personId: string, error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getChatGPTMobileMessagesActions.fail({ personId, error }));
  };
}

export function getChatGPTMobileMessagesThunk(personId: string) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getChatGPTMobileMessagesActions.start(personId));
    return apiClient.get('v1/chat/messages', {
      auth: true,
      params: { 'person-id': personId, 'chat-channel-type': ChatChannelType.CHAT_BOT_MOBILE },
      successAction: (payload: ChatMessage[]) => processGetChatGPTMobileMessagesSuccess(personId, payload),
      failAction: (payload: ErrorPayload) => processGetChatGPTMobileMessagesFail(personId, payload)
    });
  };
}

function processGetChatGPTDeviceMessagesSuccess(personId: string, messages: ChatMessage[]) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getChatGPTDeviceMessagesActions.success({ personId, messages }));
  };
}

function processGetChatGPTDeviceMessagesFail(personId: string, error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getChatGPTDeviceMessagesActions.fail({ personId, error }));
  };
}

export function getChatGPTDeviceMessagesThunk(personId: string, startMs?: number, endMs?: number) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getChatGPTDeviceMessagesActions.start(personId));
    return apiClient.get('v1/chat/messages', {
      auth: true,
      params: { 'person-id': personId, 'chat-channel-type': ChatChannelType.CHAT_BOT_DEVICE, 'start-ts': startMs, 'end-ts': endMs },
      successAction: (payload: ChatMessage[]) => processGetChatGPTDeviceMessagesSuccess(personId, payload),
      failAction: (payload: ErrorPayload) => processGetChatGPTDeviceMessagesFail(personId, payload)
    });
  };
}

function processGetChatSleepCoachMessagesSuccess(personId: string, messages: ChatMessage[]) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getChatSleepCoachMessagesActions.success({ personId, messages }));
  };
}

function processGetChatSleepCoachMessagesFail(personId: string, error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getChatSleepCoachMessagesActions.fail({ personId, error }));
  };
}

export function getChatSleepCoachMessagesThunk(personId: string) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getChatSleepCoachMessagesActions.start(personId));
    return apiClient.get('v1/chat/messages', {
      auth: true,
      params: { 'person-id': personId, 'chat-channel-type': ChatChannelType.SLEEP_COACH },
      successAction: (payload: ChatMessage[]) => processGetChatSleepCoachMessagesSuccess(personId, payload),
      failAction: (payload: ErrorPayload) => processGetChatSleepCoachMessagesFail(personId, payload)
    });
  };
}

function processGetLatestUnreadSleepCoachMessagesSuccess(payload: ChatMessage[]) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getLatestUnreadSleepCoachMessagesActions.success(payload));
  };
}

function processGetLatestUnreadSleepCoachMessagesFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getLatestUnreadSleepCoachMessagesActions.fail(error));
  };
}

export function getLatestUnreadSleepCoachMessagesThunk() {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getLatestUnreadSleepCoachMessagesActions.start());
    return apiClient.get('v1/chat/messages/latestUnread', {
      auth: true,
      params: { 'chat-channel-type': ChatChannelType.SLEEP_COACH },
      successAction: processGetLatestUnreadSleepCoachMessagesSuccess,
      failAction: processGetLatestUnreadSleepCoachMessagesFail
    });
  };
}

function processPostChatSleepCoachMessageSuccess(personId: string, message: ChatMessage) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(postChatSleepCoachMessageActions.success({ personId, message }));
  };
}

function processPostChatSleepCoachMessageFail(personId: string, error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(postChatSleepCoachMessageActions.fail({ personId, error }));
  };
}

export function postChatSleepCoachMessageThunk(chatMessage: ChatMessage) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    const personId = chatMessage.personId;
    dispatch(postChatSleepCoachMessageActions.start(personId));

    return apiClient.post('v1/chat/message', {
      auth: true,
      data: chatMessage,
      successAction: (payload: ChatMessage[]) => processPostChatSleepCoachMessageSuccess(personId, chatMessage),
      failAction: (payload: ErrorPayload) => processPostChatSleepCoachMessageFail(personId, payload)
    });
  };
}

function processChangeSleepCoachMessageReadSuccess(payload: any, personId: string, messageId: string, readStatus: boolean) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(changeSleepCoachMessageReadActions.success({ personId, messageId, readStatus }));
  };
}

function processChangeSleepCoachMessageReadFail(error: ErrorPayload) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(changeSleepCoachMessageReadActions.fail(error));
  };
}

export function changeSleepCoachMessageReadStatusThunk(personId: string, messageId: string, readStatus: boolean) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(changeSleepCoachMessageReadActions.start());

    return apiClient.put(`v1/chat/${messageId}/readStatus`, {
      auth: true,
      data: readStatus,
      params: { 'person-id': personId, 'chat-channel-type': ChatChannelType.SLEEP_COACH },
      successAction: (payload: any) => processChangeSleepCoachMessageReadSuccess(payload, personId, messageId, readStatus),
      failAction: processChangeSleepCoachMessageReadFail
    });
  };
}
