import { createAction } from '@reduxjs/toolkit';
import { TaskDetailsType, TasksListItemType } from '@redux/tasks/tasksTypes';
import { createReduxActionCreator } from '@redux/types';

export const TASK_ACTION_PREFIX = 'tasks-redux/';

export const getTasksListAction = createReduxActionCreator<TasksListItemType[]>(`${TASK_ACTION_PREFIX}get-tasks-list`);
export const getTaskDetailsAction = createReduxActionCreator<TaskDetailsType>(`${TASK_ACTION_PREFIX}get-task-details`);
export const cancelTaskAction = createReduxActionCreator<TaskDetailsType>(`${TASK_ACTION_PREFIX}cancel-task`);
export const clearSelectedTaskDetails = createAction<undefined>(`${TASK_ACTION_PREFIX}clear-selected-task-details`);
export const postNewTaskAction = createReduxActionCreator<{}>(`${TASK_ACTION_PREFIX}post-new-task`);
