import { Box, Button, Checkbox, FormControlLabel, MenuItem, TextField, Typography } from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getLatestSleepScheduleIdByPersonIdSelector, getSleepSchedulesByIdSelector } from '@redux/sleepschedule/sleepScheduleSelector';
import {
  createNewSleepScheduleThunk,
  getLatestSleepScheduleThunk,
  postSleepScheduleSendThunk,
  updateSleepScheduleLunaSummaryThunk
} from '@redux/sleepschedule/sleepScheduleThunks';
import { SleepCalculationRequest, SleepSchedule } from '@redux/sleepschedule/sleepScheduleTypes';
import TimeInput from 'components/TimeInput/TimeInput';
import { Person } from 'redux/person/personTypes';
import { ReduxState } from 'redux/types';
import { getTimesInFiveMinuteInterval, getTimeValueFromSelectField, timeAsTimeStr } from 'utils/time';
import { SleepScheduleDetails } from '../SleepScheduleDetails';
import style from './SleepScheduleCreator.scss';

interface Props {
  person?: Nullable<Person>;
  latestSleepScheduleIdByPersonId: Record<string, string>;
  createNewSleepSchedule: (personId: string, sleepCalculationRequest: SleepCalculationRequest) => Promise<any>;
  postSleepScheduleSend: (sleepSchedule: SleepSchedule) => Promise<any>;
  updateSleepScheduleLunaSummary: (SleepSchedule: SleepSchedule, updateWeeklySummary: boolean) => Promise<any>;
  getLatestSleepScheduleForPersonId: (personId: string) => Promise<any>;
  draftScheduleById: Record<string, SleepSchedule>;
  sleepScheduleById: Record<string, SleepSchedule>;
  onHideCreator: () => void;
}

const SleepScheduleCreator: React.FC<Props> = ({
  person,
  createNewSleepSchedule,
  getLatestSleepScheduleForPersonId,
  updateSleepScheduleLunaSummary,
  postSleepScheduleSend,
  onHideCreator,
  draftScheduleById,
  sleepScheduleById,
  latestSleepScheduleIdByPersonId
}) => {
  useEffect(() => {
    setRefDate(() => moment());
  }, []);

  useEffect(() => {
    if (person?.id) {
      getLatestSleepScheduleForPersonId(person.id);
    }
  }, [person]);

  const scheduleReferenceItems = ['1 week of sleep logs', 'latest sleep schedule'];

  const [useLatestSchedule, setUseLatestSchedule] = useState<boolean>(false);
  const [refDate, setRefDate] = useState<Moment>(moment());
  const [preferredWakeTime, setPreferredWakeTime] = useState<Nullable<string>>(null);

  const timeIntervals = getTimesInFiveMinuteInterval();

  const newSleepSchedule = person?.id ? draftScheduleById[person.id] : null;
  const oldSleepScheduleId = person?.id ? latestSleepScheduleIdByPersonId[person.id] : null;

  const renderTextSelectField = (label: string, placeholder: string, value: Nullable<string> | Moment, onChange: (value: string) => void, showCalender: boolean) => {
    return (
      <Box className={style.inputContainer}>
        <Typography className={style.inputLabel}>{label}</Typography>
        {!showCalender && (
          <TextField className={style.input} value={value || ''} variant="outlined" placeholder={placeholder} size="small" select onChange={(e) => onChange(e.target.value)}>
            {timeIntervals.map((time) => {
              const textValue = time ? time : '';
              return (
                <MenuItem key={textValue} value={textValue}>
                  {textValue}
                </MenuItem>
              );
            })}
          </TextField>
        )}
        {showCalender && <TimeInput moment={value} onChange={onChange} disableTime={true} datePickerClass={style.dateBox} popperPlacement="bottom-start" />}
      </Box>
    );
  };

  const onCreateNewSchedule = () => {
    const sleepCalculationRequest: SleepCalculationRequest = {
      sleepScheduleRefDate: refDate.format('YYYY-MM-DD'),
      preferredWakeTime: preferredWakeTime ? preferredWakeTime : null,
      usingLatestSchedule: useLatestSchedule
    };
    createNewSleepSchedule(person?.id || '', sleepCalculationRequest);
  };

  return (
    <Box className={style.container}>
      <Box className={style.inputRow}>
        <Box className={style.inputContent}>
          {renderTextSelectField('Reference Week End Date', '', refDate, (value: string) => setRefDate(moment(value)), true)}
          {renderTextSelectField(
            'Preferred Wake Time',
            'Choose Time (optional)',
            preferredWakeTime ? timeAsTimeStr(preferredWakeTime) : null,
            (value: string) => setPreferredWakeTime(getTimeValueFromSelectField(value)),
            false
          )}
          <FormControlLabel
            control={<Checkbox color="primary" checked={useLatestSchedule} onChange={() => setUseLatestSchedule(!useLatestSchedule)} />}
            label="Use Latest Schedule"
          />
          <Button variant="contained" color="primary" onClick={onCreateNewSchedule} startIcon={<AutorenewIcon />}>
            GENERATE
          </Button>
        </Box>
      </Box>
      <Box className={style.schedule}>
        <SleepScheduleDetails
          person={person}
          sleepScheduleById={sleepScheduleById}
          oldSleepScheduleId={oldSleepScheduleId}
          creatorMode={true}
          draftScheduleById={draftScheduleById}
          updateSleepScheduleLunaSummary={updateSleepScheduleLunaSummary}
          postSleepScheduleSend={(newSleepSchedule: SleepSchedule) => {
            onHideCreator();
            return postSleepScheduleSend(newSleepSchedule as SleepSchedule);
          }}
        />
      </Box>
    </Box>
  );
};
const connectRedux = connect(
  (state: ReduxState) => {
    return {
      sleepScheduleById: getSleepSchedulesByIdSelector(state),
      latestSleepScheduleIdByPersonId: getLatestSleepScheduleIdByPersonIdSelector(state)
    };
  },
  (dispatch: Function) => ({
    createNewSleepSchedule: (personId: string, sleepCalculationRequest: SleepCalculationRequest): Promise<any> => {
      return dispatch(createNewSleepScheduleThunk(personId, sleepCalculationRequest));
    },
    getLatestSleepScheduleForPersonId: (personId: string): Promise<any> => {
      return dispatch(getLatestSleepScheduleThunk(personId));
    },
    updateSleepScheduleLunaSummary: (sleepSchedule: SleepSchedule, weeklySummary: boolean): Promise<any> => {
      return dispatch(updateSleepScheduleLunaSummaryThunk(sleepSchedule, weeklySummary));
    },
    postSleepScheduleSend: (sleepSchedule: SleepSchedule): Promise<any> => {
      return dispatch(postSleepScheduleSendThunk(sleepSchedule));
    }
  })
);

export default compose(connectRedux)(SleepScheduleCreator);
