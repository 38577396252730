import { Container, MuiThemeProvider, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import clsx from 'clsx';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { getOauthErrorStatusSelector, isEmailConfirmedSelector } from '@redux/oauth/oauthSelectors';
import { confirmEmailThunk, resendEmailConfirmThunk } from '@redux/oauth/oauthThunks';
import { ReduxState } from '@redux/types';
import { DEVICE_IMAGE_URL } from 'assets/images/images';
import fail from '../../assets/images/fail.png';
import success from '../../assets/images/success.png';
import { defaultTheme } from '../../utils/styles';
import style from './ConfirmationPage.scss';

interface Contents {
  status: string;
  title: string;
  body?: Nullable<(onClick: MouseEventHandler) => JSX.Element>;
}

const contentMap = new Map<string, Contents>([
  [
    'success',
    {
      status: success,
      title: 'Email confirmed!'
    }
  ],
  [
    'expired',
    {
      status: fail,
      title: 'Something went wrong.',
      body: (x) => (
        <Typography className={style.bodyFail}>
          <br />
          Your email confirmation link has expired
          <br />
          <br />
          Please click{' '}
          <Link component="button" className={clsx(style.linkButton, style.bodyFail)} onClick={x}>
            here
          </Link>{' '}
          to request a new confirmation link
        </Typography>
      )
    }
  ],
  [
    'error',
    {
      status: fail,
      title: 'Something went wrong.',
      body: () => (
        <Typography className={style.bodyFail}>
          <br />
          The request failed. Please try again or contact customer support if you continue to experience issues.
        </Typography>
      )
    }
  ],
  [
    'newRequest',
    {
      status: success,
      title: 'Thank you!',
      body: () => (
        <Typography className={style.bodyFail}>
          <br />A new confirmation email has been sent to your email address.
        </Typography>
      )
    }
  ]
]);

interface Props {
  resendEmail: Function;
  isEmailConfirmed: boolean;
  errorStatus?: Nullable<number>;
  confirmEmail: (token: string) => void;
}

const ConfirmationPage: React.FC<Props> = ({ resendEmail, isEmailConfirmed, errorStatus, confirmEmail }) => {
  const getPageType = (): 'success' | 'expired' | 'error' | 'newRequest' =>
    isEmailConfirmed ? 'success' : (errorStatus || errorStatus === 0) && errorStatus < 500 ? 'expired' : 'error';

  const [content, setContent] = useState<Nullable<Contents>>(contentMap?.get(getPageType()));
  const params = useParams();

  const { token } = params || {};

  useEffect(() => {
    if (token) {
      confirmEmail(token);
    }
  }, [token]);

  useEffect(() => {
    setContent(() => contentMap?.get(getPageType()));
  }, [isEmailConfirmed]);

  const sendNewRequest = (): void => {
    resendEmail(token);
    setContent(() => contentMap?.get('newRequest'));
  };

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={style.root}>
        <Container className={style.centeredContent} maxWidth="xl">
          {!!content && (
            <React.Fragment>
              <Box className={style.statusBox}>
                <img className={style.statusImage} src={content.status} alt="Status" />
              </Box>
              <Box className={style.imageBox}>
                <img className={style.kokoImage} src={DEVICE_IMAGE_URL} alt="Full Sleep" />
              </Box>
              <Box className={style.textBox}>
                <Typography className={content.status === 'fail' ? style.failureHeader : style.successHeader}>{content.title}</Typography>
                {!!content.body && content.body(() => sendNewRequest())}
              </Box>
            </React.Fragment>
          )}
        </Container>
      </div>
    </MuiThemeProvider>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      isEmailConfirmed: isEmailConfirmedSelector(state),
      errorStatus: getOauthErrorStatusSelector(state)
    };
  },
  (dispatch: Function) => ({
    resendEmail: (token?: Nullable<string>) => {
      dispatch(resendEmailConfirmThunk(token));
    },
    confirmEmail: (token: string) => {
      dispatch(confirmEmailThunk(token));
    }
  })
);

export default compose(connectRedux)(ConfirmationPage) as React.ComponentType;
