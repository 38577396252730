import { Box, Typography } from '@mui/material';
import React, { memo } from 'react';
import style from './PatientCarePlanInfo.scss';

interface Props {
  fullName?: Nullable<string>;
  carePlanName?: string;
}

const PatientCarePlanInfo: React.FC<Props> = memo(({ fullName, carePlanName }) => {
  return (
    <Box className={style.container}>
      <Typography variant="subtitle1" gutterBottom>
        {fullName?.toUpperCase()}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {carePlanName || 'Care Plan Is Not Assigned'}
      </Typography>
    </Box>
  );
});

export default PatientCarePlanInfo;
