import { Box, Breadcrumbs, Grid } from '@material-ui/core';
import React, { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BreadCrumbData, BreadCrumbType, SelectOption } from '@redux/common/types';
import { fromDefault, renderBreadCrumbs } from 'utils/breadcrumbs';
import { LogoutCrumb } from '../BreadCrumbs/LogoutCrumb';

interface Props {
  breadCrumbs: BreadCrumbData[];
  appendBreadCrumbs?: Nullable<(breadCrumb: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[], isOldApp?: boolean) => void>;
  defaultCurrentCrumb?: Nullable<BreadCrumbData>;
  defaultCrumbHistory?: Nullable<BreadCrumbData[]>;
  overrideTitle?: Nullable<string>;
  overrideUrl?: Nullable<string>;
  overrideType?: Nullable<BreadCrumbType>;
  overrideValueOptions?: Nullable<SelectOption[]>;
  overrideSelectedIndex?: Nullable<number>;
  refreshOnChangeRef?: Nullable<any>;
  logout?: Nullable<() => void>;
}

export const NavBar: React.FC<Props> = memo(
  ({
    breadCrumbs,
    appendBreadCrumbs,
    defaultCurrentCrumb,
    defaultCrumbHistory,
    overrideTitle,
    overrideUrl,
    overrideType,
    overrideValueOptions,
    overrideSelectedIndex,
    refreshOnChangeRef,
    logout
  }): JSX.Element => {
    const location = useLocation();

    useEffect(() => {
      if (breadCrumbs && appendBreadCrumbs && defaultCurrentCrumb && defaultCrumbHistory) {
        appendBreadCrumbs(fromDefault(defaultCurrentCrumb, overrideUrl, overrideTitle, overrideType, overrideSelectedIndex, overrideValueOptions), defaultCrumbHistory, true);
      }
    }, [location, overrideTitle, overrideUrl, overrideType, overrideSelectedIndex, overrideValueOptions, refreshOnChangeRef]);

    return (
      <Grid container>
        <Grid container justifyContent="flex-start" item xs={9}>
          <Breadcrumbs>
            {renderBreadCrumbs(breadCrumbs).map((breadCrumb, index) => (
              <Box key={index} component="span">
                {breadCrumb}
              </Box>
            ))}
          </Breadcrumbs>
        </Grid>
        {!!logout && (
          <Grid container justifyContent="flex-end" item xs={3}>
            <Breadcrumbs>
              <LogoutCrumb logout={logout} />
            </Breadcrumbs>
          </Grid>
        )}
      </Grid>
    );
  }
);
