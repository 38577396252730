import React from 'react';
import { SVGBasicType } from 'types/global';
import { Color } from 'utils/color';

export const DoubleCheckmarkIcon = ({ width = 23, height = 16, color = Color.GREEN }: SVGBasicType) => (
  <svg width={width} height={height} viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.354 4.85403L6.35403 12.854C6.30759 12.9005 6.25245 12.9374 6.19175 12.9626C6.13105 12.9877 6.06599 13.0007 6.00028 13.0007C5.93457 13.0007 5.86951 12.9877 5.80881 12.9626C5.74811 12.9374 5.69296 12.9005 5.64653 12.854L2.14653 9.35403C2.05271 9.26021 2 9.13296 2 9.00028C2 8.8676 2.05271 8.74035 2.14653 8.64653C2.24035 8.55271 2.3676 8.5 2.50028 8.5C2.63296 8.5 2.76021 8.55271 2.85403 8.64653L6.00028 11.7934L13.6465 4.14653C13.7403 4.05271 13.8676 4 14.0003 4C14.133 4 14.2602 4.05271 14.354 4.14653C14.4478 4.24035 14.5006 4.3676 14.5006 4.50028C14.5006 4.63296 14.4478 4.76021 14.354 4.85403Z"
      fill={color}
    />
    <path
      d="M21.354 4.85403L13.354 12.854C13.3076 12.9005 13.2524 12.9374 13.1917 12.9626C13.131 12.9877 13.066 13.0007 13.0003 13.0007C12.9346 13.0007 12.8695 12.9877 12.8088 12.9626C12.7481 12.9374 12.693 12.9005 12.6465 12.854L9.14653 9.35403C9.05271 9.26021 9 9.13296 9 9.00028C9 8.8676 9.05271 8.74035 9.14653 8.64653C9.24035 8.55271 9.3676 8.5 9.50028 8.5C9.63296 8.5 9.76021 8.55271 9.85403 8.64653L13.0003 11.7934L20.6465 4.14653C20.7403 4.05271 20.8676 4 21.0003 4C21.133 4 21.2602 4.05271 21.354 4.14653C21.4478 4.24035 21.5006 4.3676 21.5006 4.50028C21.5006 4.63296 21.4478 4.76021 21.354 4.85403Z"
      fill={color}
    />
  </svg>
);
