import { createReducer } from '@reduxjs/toolkit';
import { appEnteredAction, appLeftAction, createSessionAction, pageEnteredAction, pageLeftAction, updateBackgroundTimeAction, updateSessionEndAction } from './analyticsActions';
import { AnalyticsState, PageType } from './analyticsTypes';

export const initialAnalyticsState: AnalyticsState = {
  sessionId: '',
  backgroundMs: 0,
  sessionStartMs: 0,
  sessionEndMs: 0,
  appEnterDate: '0',
  pageEnterDate: '0',
  pageLeftDate: '0',
  pageType: PageType.LOGIN
};
export default createReducer(initialAnalyticsState, (builder) => {
  builder
    .addCase(appEnteredAction, (state, action) => {
      const nowDate = new Date();
      state.appEnterDate = action.payload || nowDate.toISOString();
    })
    .addCase(appLeftAction, (state, action) => {
      state.sessionEndMs = action.payload;
    })
    .addCase(pageEnteredAction, (state, action) => {
      state.pageType = action.payload.page;
      state.pageEnterDate = action.payload.date || new Date().toISOString();
      state.pageLeftDate = '0';
    })
    .addCase(pageLeftAction, (state, action) => {
      state.pageType = action.payload.page;
      state.pageLeftDate = action.payload.date || new Date().toISOString();
    })
    .addCase(createSessionAction, (state, action) => {
      state.sessionId = action.payload.sessionId;
      state.sessionStartMs = action.payload.sessionStartMs;
      state.sessionEndMs = action.payload.sessionStartMs;
      state.backgroundMs = 0;
    })
    .addCase(updateSessionEndAction, (state, action) => {
      state.sessionEndMs = action.payload;
    })
    .addCase(updateBackgroundTimeAction, (state, action) => {
      state.backgroundMs = action.payload;
    });
});
