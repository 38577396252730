import { Button, IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { memo, useEffect, useState } from 'react';

enum StatusType {
  UNKNOWN,
  SUCCESS,
  FAIL,
  WARN
}

interface Props {
  saveSuccessful?: Nullable<boolean>;
  saveUnsuccessful?: Nullable<boolean>;
  clearResponseStatus: () => void;
  undoAction?: Nullable<Function>;
  saveMessage?: Nullable<string>;
  errorMessage?: Nullable<string>;
  warningMessage?: Nullable<string>;
  duration?: Nullable<number>;
}

export const StatusMessage: React.FC<Props> = memo(({ saveSuccessful, saveUnsuccessful, clearResponseStatus, undoAction, saveMessage, errorMessage, warningMessage, duration }) => {
  const [initialized, setInitialized] = useState(false);
  const [status, setStatus] = useState<StatusType>(StatusType.UNKNOWN);

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      if (saveSuccessful && !saveUnsuccessful) {
        if (!warningMessage) {
          setStatus(() => StatusType.SUCCESS);
        } else {
          setStatus(() => StatusType.WARN);
        }
      } else if (!saveSuccessful && saveUnsuccessful) {
        setStatus(() => StatusType.FAIL);
      }
    }
  }, [saveSuccessful, saveUnsuccessful]);

  const determineColor = (): string => {
    if (status === StatusType.SUCCESS) {
      return '#4caf50';
    } else if (status === StatusType.FAIL) {
      return '#f44336';
    } else if (status === StatusType.WARN) {
      return '#ff9800';
    } else {
      return '#ffffff';
    }
  };

  const undo = async () => {
    if (undoAction) {
      await undoAction();
    }
    await closeMessage();
  };

  const closeMessage = async () => {
    await clearResponseStatus();
    setStatus(StatusType.UNKNOWN);
  };

  if (errorMessage) {
    console.log(errorMessage);
  }

  return (
    <Snackbar
      ContentProps={{ style: { backgroundColor: determineColor() } }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={status !== StatusType.UNKNOWN}
      autoHideDuration={duration || 2000}
      disableWindowBlurListener={true}
      ClickAwayListenerProps={{ mouseEvent: 'onMouseDown', touchEvent: 'onTouchStart', onClickAway: closeMessage }}
      onClose={closeMessage}
      message={
        status === StatusType.SUCCESS
          ? saveMessage || 'Save Complete'
          : status === StatusType.FAIL
            ? errorMessage || 'Error While Saving'
            : warningMessage || 'Completed With Warnings'
      }
      action={
        <React.Fragment>
          {!!undoAction && (
            <Button size="small" color="secondary" onClick={undo}>
              UNDO
            </Button>
          )}
          <IconButton size="small" color="inherit" onClick={closeMessage}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
});
