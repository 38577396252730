import { Error } from '@redux/common/types';

export enum TaskStatusEnum {
  ERROR = 'ERROR',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  SCHEDULED = 'SCHEDULED',
  DELIVERED = 'DELIVERED'
}

export interface TasksListItemType {
  taskId: string;
  patientFullName: string;
  taskMessage?: string;
  patientResponse?: string;
  scheduledForDateTime: string;
  status: TaskStatusEnum;
}

export interface TaskDetailsType {
  id: string;
  scheduledForDateTime: string;
  status: TaskStatusEnum;
  assignee: TaskAssigneeType;
  logs: TaskLogsType[];
}

export interface TaskAssigneeType {
  assigneeId: string;
  assigneeFullname: string;
  assigneeEmail: string;
}

export interface TaskLogsType {
  id: string;
  logTitle: string;
  logCreated: string;
  logMessage?: string;
}

export interface TaskCreationPayloadType {
  taskMessage: string;
  assigneeId: string;
  assigneeFullName: string;
  scheduledForDateTime: string;
}

export interface TasksState {
  readonly isLoading: Record<string, boolean>;
  readonly error: Record<string, Error>;
  readonly success: Record<string, boolean>;
  tasksList: TasksListItemType[];
  selectedTaskDetails: Nullable<TaskDetailsType>;
}
