import classNames from 'classnames';
import React, { forwardRef, useState } from 'react';
import alertCircleIcon from 'assets/images/alert-circle.png';
import { Button } from 'components/Button/Button';
import { PolymorphicElement } from 'types/PolymorphicElement';
import { Text } from '../Typography';
import style from './TextArea.scss';

export type TextVariant = 'xl' | 'lg' | 'md' | 'sm' | 'xs';
('xs');
export type TextWeight = 400 | 500 | 600 | 700;

export type TextAreaProps = PolymorphicElement<'textarea'> & {
  className?: string;
  label?: React.ReactNode;
  leading?: React.ReactNode;
  trailing?: React.ReactNode;
  error?: React.ReactNode;
  helperText?: React.ReactNode;
  isMandatory?: boolean;
  rows?: number;
  cols?: number;
  onClick?: React.MouseEvent<HTMLDivElement>;
  onSendButton?: () => void;
  sendButtonIcon?: React.ReactNode;
  maxLength?: number;
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const { className, label, leading, trailing, onSendButton, maxLength, sendButtonIcon, error, helperText, onClick, rows = 4, cols = 50, ...rest } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [charCount, setCharCount] = useState(0)

  const handleOnBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(false);
    props?.onBlur?.(event);
  };

  const handleOnFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(true);
    props?.onFocus?.(event);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCharCount(e.target.value.length)
    props?.onChange?.(e)
  }

  return (
    <div className={classNames(style.container, className)} onClick={onClick as any}>
      {label && (
        <Text variant="sm" className={style.label}>
          {label}
          {props?.isMandatory && <span> *</span>}
        </Text>
      )}
      <div className={classNames(style.inputContainer, props.disabled && style.disabled, error && style.error, isFocused && style.isFocused)}>
        <div className={style.inputSubContainer}>
          {leading && <span className={style.leading}>{leading}</span>}

          <textarea style={{ resize: 'none' }} ref={ref} maxLength={maxLength} {...rest} onChange={handleOnChange} rows={rows} cols={cols} onBlur={handleOnBlur} onFocus={handleOnFocus} className={style.input} />

          {trailing && !error && <span className={style.trailing}>{trailing}</span>}
        </div>
        {(onSendButton||maxLength) && (
          <div className={style.sendButtonContainer}>
            {maxLength && <Text className={style.maxLength} variant='sm'>{charCount}/{maxLength}</Text>}
            {onSendButton && <Button className={style.sendButton} variant="contained" onClick={onSendButton}>
              {sendButtonIcon}
            </Button> }
          </div>
        )}
        {/* {error && <AlertIcon></AlertIcon>} */}
      </div>

      {error && (
        <Text variant="sm" className={style.error}>
          {error}
        </Text>
      )}
      {!error && helperText && (
        <Text variant="sm" className={style.helperText}>
          {helperText}
        </Text>
      )}
    </div>
  );
});
