import { Autocomplete, autocompleteClasses, outlinedInputClasses, Paper, Popper, PopperProps, styled, TextField } from '@mui/material';
import React from 'react';
import { Color } from 'utils/color';

export const CarePlanAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`&.${autocompleteClasses.root}`]: {
    backgroundColor: Color.WHITE,
    padding: '4px' // space for shadow when "isFocused"
  },
  // INPUT ICONS
  [`& button.${autocompleteClasses.popupIndicator}`]: {
    color: Color.GRAY_950,
    '&:hover': {
      backgroundColor: Color.GRAY_50
    }
  },
  [`& button.${autocompleteClasses.clearIndicator}`]: {
    color: Color.GRAY_950,
    '&:hover': {
      backgroundColor: Color.GRAY_50
    }
  }
}));

// POP UP (positioning)
export const CarePlanAutocompletePopper = styled((props: PopperProps) => {
  return (
    <Popper
      {...props}
      placement="bottom-start"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 4] // X and Y offset in pixels
          }
        }
      ]}
    />
  );
})(({ theme }) => ({
  [`&.${autocompleteClasses.popper}`]: {}
}));

// POP UP CONTENT
export const CarePlanAutocompletePaper = styled(Paper)(({ theme }) => ({
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      fontFamily: 'Outfit',
      fontSize: '16px',
      lineHeight: '24px',
      color: Color.GRAY_950,
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: '12px 16px 12px 16px',
      [`&[aria-selected="true"], &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
        backgroundColor: Color.GRAY_50
      },
      [`&.${autocompleteClasses.focused}`]: {
        backgroundColor: Color.WHITE,
        color: Color.GRAY_700
      }
    }
  }
}));

// Input
export const CarePlanAutocompleteTextField = styled(TextField)({
  [`& .${outlinedInputClasses.root}`]: {
    backgroundColor: Color.WHITE,
    width: '100%',
    borderRadius: '8px',
    color: Color.GRAY_950,
    fontFamily: 'Outfit',
    fontSize: '16px',
    lineHeight: '24px',
    padding: '11px 14px', // adding 1 px to make total height 46px
    '& fieldset': {
      borderColor: Color.GRAY_200,
      borderWidth: '1px'
    },
    '&:hover fieldset': {
      borderColor: Color.GRAY_200
    },
    [`& .${outlinedInputClasses.input}`]: {
      padding: 0,
      height: '24px'
    },
    [`& .${outlinedInputClasses.input}::placeholder`]: {
      color: Color.GRAY_700,
      opacity: 1
    },
    [`&.${outlinedInputClasses.focused} fieldset`]: {
      borderColor: Color.GRAY_200,
      borderWidth: '1px',
      boxShadow: '0 0 0 4px rgba(16, 24, 40, 0.05)'
    }
  }
});
