import { createReducer, Draft } from '@reduxjs/toolkit';
import { getPersonDetailsActions } from '@redux/person/personActions';
import {
  assignCarePlanAction,
  createNewActivityAction,
  deleteCareFeedItemThunkAction,
  fetchCarePlanActivitiesAction,
  fetchCarePlanMeasurementsAction,
  fetchCarePlansAction,
  fetchPatientCareFeedItemsAction,
  fetchPatientsAndCarePlansAction,
  unassignCarePlanAction
} from './carePlanActions';
import { CareFeedItem, CareFeedItemPayload, CarePlansPayload, CarePlanState, MeasurementsPayload } from './carePlanTypes';

export const initialCarePlanState: CarePlanState = {
  isLoading: {},
  error: {},
  success: {},
  carePlanById: {},
  carePlanStepById: {},
  carePlanUnitById: {},
  selectedPersonCareFeedItemsByDate: {},
  isLoadingAssignCarePlan: false,
  isLoadingUnassignCarePlan: false,
  isLoadingCarePlans: false,
  isLoadingMeasurements: false,
  isLoadingPatientsWithCarePlans: false,
  isLoadingCarePlanActivities: false,
  isLoadingCareFeedItems: false,
  isDeletingCareFeedItem: false,
  personWithCarePlansById: {},
  triggerById: {},
  metricById: {},
  metricUnitById: {},
  carePlanActivityById: {}
};

export default createReducer(initialCarePlanState, (builder) => {
  builder
    .addCase(fetchCarePlansAction.start, (state, action) => {
      state.isLoadingCarePlans = true;
      delete state.errorLoadingCarePlans;
    })
    .addCase(fetchCarePlansAction.success, (state, action) => {
      state.isLoadingCarePlans = false;
      addCarePlansToState(state, action.payload);
    })
    .addCase(fetchCarePlansAction.fail, (state, action) => {
      state.isLoadingCarePlans = false;
      state.errorLoadingCarePlans = action.payload;
    })
    .addCase(fetchPatientsAndCarePlansAction.start, (state, action) => {
      state.isLoadingPatientsWithCarePlans = true;
      delete state.errorLoadingPatientsWithCarePlans;
    })
    .addCase(fetchPatientsAndCarePlansAction.success, (state, action) => {
      state.isLoadingPatientsWithCarePlans = false;
      action.payload?.forEach((personWithCarePlan) => {
        const { carePlans, ...rest } = personWithCarePlan;
        const carePlansIds = carePlans?.map((carePlan) => carePlan?.id);
        state.personWithCarePlansById[personWithCarePlan.id] = { ...rest, carePlans: carePlansIds };
      });
    })
    .addCase(fetchPatientsAndCarePlansAction.fail, (state, action) => {
      state.isLoadingPatientsWithCarePlans = false;
      state.errorLoadingPatientsWithCarePlans = action.payload;
    })
    .addCase(assignCarePlanAction.start, (state, action) => {
      state.isLoadingAssignCarePlan = true;
      delete state.errorAssignCarePlan;
    })
    .addCase(assignCarePlanAction.success, (state, action) => {
      state.isLoadingAssignCarePlan = false;
      const personCarePlans = state.personWithCarePlansById[action.payload?.personId]?.carePlans || [];
      state.personWithCarePlansById[action.payload?.personId] = {
        ...state.personWithCarePlansById[action.payload?.personId],
        carePlans: [...personCarePlans, action.payload?.carePlanId]
      };
    })
    .addCase(assignCarePlanAction.fail, (state, action) => {
      state.isLoadingAssignCarePlan = false;
      // note: workaround for patient without device setup!
      /* @ts-expect-error: temp workaround for 404 error for patient without device setup */
      const { error, carePlanId, personId } = action.payload;
      state.errorAssignCarePlan = error;
      const personCarePlans = state.personWithCarePlansById[personId]?.carePlans || [];
      if (error.status === 404) {
        state.personWithCarePlansById[personId] = {
          ...state.personWithCarePlansById[personId],
          carePlans: [...personCarePlans, carePlanId]
        };
      }
    })
    .addCase(unassignCarePlanAction.start, (state, action) => {
      state.isLoadingUnassignCarePlan = true;
      delete state.errorUnassignCarePlan;
    })
    .addCase(unassignCarePlanAction.success, (state, action) => {
      state.isLoadingUnassignCarePlan = false;
      const personCarePlans = state.personWithCarePlansById[action.payload?.personId]?.carePlans || [];
      const newPersonPlans = personCarePlans.filter((carePlanId: string) => carePlanId !== action.payload?.carePlanId);
      state.personWithCarePlansById[action.payload?.personId] = {
        ...state.personWithCarePlansById[action.payload?.personId],
        carePlans: newPersonPlans
      };
    })
    .addCase(unassignCarePlanAction.fail, (state, action) => {
      state.isLoadingUnassignCarePlan = false;
      // note: workaround for patient without device setup!
      /* @ts-expect-error: temp workaround for 404 error for patient without device setup */
      const { error, carePlanId, personId } = action.payload;
      state.errorUnassignCarePlan = error;
      const personCarePlans = state.personWithCarePlansById[personId]?.carePlans || [];
      const newPersonPlans = personCarePlans.filter((id: string) => id !== carePlanId);
      if (error.status === 404) {
        state.personWithCarePlansById[personId] = {
          ...state.personWithCarePlansById[personId],
          carePlans: newPersonPlans
        };
      }
    })
    .addCase(fetchCarePlanMeasurementsAction.start, (state, action) => {
      state.isLoadingMeasurements = true;
      delete state.errorLoadingMeasurements;
    })
    .addCase(fetchCarePlanMeasurementsAction.success, (state, action) => {
      state.isLoadingMeasurements = false;
      addMeasurementsToState(state, action.payload);
    })
    .addCase(fetchCarePlanMeasurementsAction.fail, (state, action) => {
      state.isLoadingMeasurements = false;
      state.errorLoadingMeasurements = action.payload;
    })
    .addCase(fetchCarePlanActivitiesAction.start, (state, action) => {
      state.isLoadingCarePlanActivities = true;
    })
    .addCase(fetchCarePlanActivitiesAction.success, (state, action) => {
      state.isLoadingCarePlanActivities = false;
      action.payload?.forEach((deviceContent) => {
        state.carePlanActivityById[deviceContent.id] = deviceContent;
      });
    })
    .addCase(fetchCarePlanActivitiesAction.fail, (state, action) => {
      state.isLoadingCarePlanActivities = false;
      // note: workaround for patient without device setup!
      /* @ts-expect-error: temp workaround for 404 error for patient without device setup */
      const { error } = action.payload;
      state.errorLoadingCarePlanActivities = error;
    })
    .addCase(createNewActivityAction.start, (state, action) => {
      const tag = action.type;
      state.isLoading[tag] = true;
      delete state.error[tag];
      delete state.success[tag];
    })
    .addCase(createNewActivityAction.success, (state, action) => {
      const tag = action.type;
      state.isLoading[tag] = false;
      state.success[tag] = true;
    })
    .addCase(createNewActivityAction.fail, (state, action) => {
      const tag = action.type;
      state.isLoading[tag] = false;
      state.error[tag] = action.payload;
    })
    .addCase(getPersonDetailsActions.start, (state, action) => {
      state.selectedPersonCareFeedItemsByDate = {};
    })
    .addCase(fetchPatientCareFeedItemsAction.start, (state, action) => {
      state.isLoadingCareFeedItems = true;
      delete state.errorFetchCareFeedItems;
    })
    .addCase(fetchPatientCareFeedItemsAction.success, (state, action) => {
      state.isLoadingCareFeedItems = false;
      const { startUtcMs, payload } = action.payload;
      const careFeedItems: CareFeedItem[] = payload.map((careFeedItemPayload: CareFeedItemPayload) => {
        const { deviceContent, carePlanStep, trigger, ...rest } = careFeedItemPayload;
        if (carePlanStep?.id) {
          state.carePlanStepById[carePlanStep.id] = carePlanStep;
        }
        if (trigger?.id) {
          state.triggerById[trigger.id] = trigger;
        }
        return { ...rest, deviceContentId: deviceContent?.id, carePlanStepId: carePlanStep?.id, triggerId: trigger?.id };
      });
      state.selectedPersonCareFeedItemsByDate[startUtcMs] = careFeedItems;
    })
    .addCase(fetchPatientCareFeedItemsAction.fail, (state, action) => {
      state.isLoadingCareFeedItems = false;
      state.errorFetchCareFeedItems = action.payload;
    })
    .addCase(deleteCareFeedItemThunkAction.start, (state, action) => {
      state.isDeletingCareFeedItem = true;
      delete state.errorDeleteCareFeedItem;
    })
    .addCase(deleteCareFeedItemThunkAction.success, (state, action) => {
      state.isDeletingCareFeedItem = false;
      const { careFeedItemId, startUtcMs } = action.payload;
      const updatedCareFeedItems = state.selectedPersonCareFeedItemsByDate[startUtcMs].filter((careFeedItem) => careFeedItem.id !== careFeedItemId);
      state.selectedPersonCareFeedItemsByDate[startUtcMs] = updatedCareFeedItems;
    })
    .addCase(deleteCareFeedItemThunkAction.fail, (state, action) => {
      state.isDeletingCareFeedItem = false;
      state.errorDeleteCareFeedItem = action.payload;
    });
});

const addCarePlansToState = (draftState: Draft<CarePlanState>, payload: CarePlansPayload) => {
  payload.forEach((carePlanPayload) => {
    const { carePlanSteps, ...rest } = carePlanPayload;
    const carePlanStepsIds = carePlanSteps?.map((carePlanStep) => carePlanStep.id);
    // carePlan
    draftState.carePlanById[carePlanPayload.id] = {
      ...rest,
      carePlanStepsIds
    };
    carePlanSteps?.forEach((carePlanStepPayload) => {
      const { carePlanUnit, trigger, ...rest } = carePlanStepPayload;
      // carePlanStep
      draftState.carePlanStepById[carePlanStepPayload.id] = { ...rest };
      // carePlanUnit
      if (carePlanUnit) {
        const { deviceContent, ...rest } = carePlanUnit;
        draftState.carePlanUnitById[carePlanUnit.id] = { ...rest };
      }
      // trigger
      if (trigger) {
        draftState.triggerById[trigger.id] = trigger;
      }
    });
  });
};

const addMeasurementsToState = (draftState: Draft<CarePlanState>, payload: MeasurementsPayload) => {
  const measurementsByPersonId = {};
  payload.forEach((measurementPayload) => {
    const { surveyResponse, personId, metricId, unitId, floatValue, created, ...rest } = measurementPayload;
    const { surveyQuestion } = surveyResponse || {};
    const { metric } = surveyQuestion || {};
    const { units, ...restMetric } = metric || {};
    // measurements
    if (!measurementsByPersonId[personId]) {
      measurementsByPersonId[personId] = [measurementPayload];
    } else {
      measurementsByPersonId[personId].push(measurementPayload);
    }
    // metric
    if (!draftState.metricById[metricId]) {
      draftState.metricById[metricId] = { ...restMetric, unit: units && units[0] };
    }
    if (units && units[0] && units[0]?.id && !draftState.metricUnitById[units[0]?.id]) {
      draftState.metricUnitById[units[0]?.id] = units[0];
    }
  });
  draftState.measurementsByPersonId = measurementsByPersonId;
};
