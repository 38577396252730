import { Box, Button } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { getCarePlanByIdSelector, getPersonWithCarePlansByIdSelector } from '@redux/carePlan/carePlanSelectors';
import { assignCarePlanThunk, fetchCarePlansThunk, fetchPatientsAndCarePlansThunk, unassignCarePlanThunk } from '@redux/carePlan/carePlanThunks';
import { getDeviceContentThunk } from '@redux/content/contentThunks';
import { DeviceContentType } from '@redux/content/contentTypes';
import { ReduxState } from '@redux/types';
import { PersonWithCarePlans } from 'redux/carePlan/carePlanTypes';
import {
  CarePlanAutocomplete,
  CarePlanAutocompletePaper,
  CarePlanAutocompletePopper,
  CarePlanAutocompleteTextField
} from '../../components/CarePlanAutocomplete/CarePlanAutocomplete';
import NavTopBarContentWrapper from '../../components/NavTopBarContentWrapper/NavTopBarContentWrapper';
import { Text } from '../../components/Typography/Text';
import style from './AssignCarePlan.scss';
import PatientCarePlanInfo from './components/PatientCarePlanInfo/PatientCarePlanInfo';
import SelectCarePlanDialog from './components/SelectCarePlanDialog/SelectCarePlanDialog';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;

type Props = PropsFromRedux & {};

const AssignCarePlan: React.FC<Props> = ({
  carePlanById,
  personWithCarePlansById,
  assignCarePlan,
  unassignCarePlan,
  fetchCarePlanUnitDeviceContent,
  fetchCarePlans,
  fetchPatientsAndCarePlans
}) => {
  const [selectedPatientId, setSelectedPatientId] = useState<string>();
  const patientCarePlanIds = selectedPatientId ? (personWithCarePlansById[selectedPatientId]?.carePlans ?? ['']) : ['']; // ensure a default selectedCarePlanIds if valueProp is undefined
  const [openCarePlanDialog, setOpenCarePlanDialog] = useState<boolean>(false);
  const [selectedCarePlanIds, setSelectedCarePlanIds] = useState<string[]>(patientCarePlanIds);

  const saveCarePlanButtonDisabled = patientCarePlanIds.length === selectedCarePlanIds.length && patientCarePlanIds.every((value) => selectedCarePlanIds.includes(value));

  const carePlanNames = patientCarePlanIds
    .map((carePlanId: string) => {
      const carePlanName = carePlanById[carePlanId] && carePlanById[carePlanId]?.name;
      return carePlanName;
    })
    .join(', ');

  const sortedPatientOptions = useMemo(() => {
    const persons: PersonWithCarePlans[] = Object.values(personWithCarePlansById);
    return persons.sort((a, b) => (a.fullName || '').localeCompare(b.fullName || ''));
  }, [personWithCarePlansById]);

  useEffect(() => {
    fetchPatientsAndCarePlans();
    fetchCarePlanUnitDeviceContent();
    fetchCarePlans();
  }, []);

  useEffect(() => {
    if (!openCarePlanDialog) {
      setSelectedCarePlanIds(patientCarePlanIds); // reset the selectedCarePlanIds if the dialog is closed
    } else {
      setSelectedCarePlanIds(patientCarePlanIds);
    }
  }, [openCarePlanDialog]);

  const handlePatientChange = (event, value) => {
    setSelectedPatientId(value?.id);
  };

  const onPressEditCareGroup = () => {
    setOpenCarePlanDialog(true);
  };

  const handleCarePlanChange = (id: string) => {
    const selectedCarePlanIdIndex = selectedCarePlanIds.indexOf(id);
    const newSelectedPlanIds = [...selectedCarePlanIds];
    if (selectedCarePlanIdIndex > -1) {
      newSelectedPlanIds.splice(selectedCarePlanIdIndex, 1);
    } else {
      newSelectedPlanIds.push(id);
    }
    setSelectedCarePlanIds(newSelectedPlanIds);
  };

  const onPressSaveCarePlanDialog = async () => {
    setOpenCarePlanDialog(false);
    const carePlanIdsToAssign = selectedCarePlanIds.filter((carePlanId) => !patientCarePlanIds.includes(carePlanId));
    const carePlanIdsToUnassign = patientCarePlanIds.filter((carePlanId) => !selectedCarePlanIds.includes(carePlanId));
    for (const carePlanIdToAssign of carePlanIdsToAssign) {
      if (selectedPatientId) {
        try {
          await assignCarePlan(carePlanIdToAssign, selectedPatientId);
        } catch (err) {
          console.log('err: assignCarePlan', err);
        }
      }
    }
    for (const carePlanIdToUnassign of carePlanIdsToUnassign) {
      if (selectedPatientId) {
        try {
          await unassignCarePlan(carePlanIdToUnassign, selectedPatientId);
        } catch (err) {
          console.log('err: unassignCarePlan', err);
        }
      }
    }
  };

  const onPressCloseCarePlanDialog = () => {
    setOpenCarePlanDialog(false);
  };

  return (
    <NavTopBarContentWrapper centerAlignContent headerText="Care plan">
      <Box className={style.container}>
        <div>
          <Text variant="sm" className={style.labelSelect}>
            Patient
          </Text>
          <CarePlanAutocomplete
            id="select-patient-autocomplete"
            options={sortedPatientOptions}
            onChange={handlePatientChange}
            getOptionLabel={(option) => (option as PersonWithCarePlans).fullName || ''} // display only the full name as label
            isOptionEqualToValue={(option, value) => (option as PersonWithCarePlans).id === (value as PersonWithCarePlans).id} // use id for comparison
            PaperComponent={CarePlanAutocompletePaper}
            PopperComponent={CarePlanAutocompletePopper}
            renderInput={(params) => <CarePlanAutocompleteTextField {...params} placeholder="Select" />}
            renderOption={(props, option, state) => (
              <li {...props} key={(option as PersonWithCarePlans).id}>
                <Box>{(option as PersonWithCarePlans).fullName?.toUpperCase()}</Box>
              </li>
            )}
          />
        </div>
        {!!selectedPatientId && !!personWithCarePlansById[selectedPatientId] && (
          <>
            <PatientCarePlanInfo fullName={personWithCarePlansById[selectedPatientId].fullName} carePlanName={carePlanNames} />
            <Button variant="contained" onClick={onPressEditCareGroup} disabled={!selectedPatientId} className={style.button}>
              Edit Care Plan
            </Button>
          </>
        )}
        <SelectCarePlanDialog
          id="select-care-plan-dialog"
          open={openCarePlanDialog}
          options={Object.values(carePlanById)}
          value={selectedCarePlanIds}
          saveButtonDisabled={saveCarePlanButtonDisabled}
          handleChange={handleCarePlanChange}
          handleSave={onPressSaveCarePlanDialog}
          onClose={onPressCloseCarePlanDialog}
        />
      </Box>
    </NavTopBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    carePlanById: getCarePlanByIdSelector(state),
    personWithCarePlansById: getPersonWithCarePlansByIdSelector(state)
  }),
  {
    assignCarePlan: assignCarePlanThunk,
    unassignCarePlan: unassignCarePlanThunk,
    fetchCarePlans: fetchCarePlansThunk,
    fetchPatientsAndCarePlans: fetchPatientsAndCarePlansThunk,
    fetchCarePlanUnitDeviceContent: () => getDeviceContentThunk([DeviceContentType.CARE_PLAN_UNIT])
  }
);

export default compose(connectRedux)(AssignCarePlan);
