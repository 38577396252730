import Box from '@material-ui/core/Box';
import React, { cloneElement } from 'react';
import { TabPanelProps } from './TabbedPage';

export const TabPanel: React.FC<{ props: TabPanelProps; index: number; selectedIndex: number }> = ({ props, index, selectedIndex }) => {
  return (
    <div role="tabpanel" hidden={selectedIndex !== index} id={`tabpanel-${index}`}>
      {(selectedIndex === index || props.preRender) && props.content && (
        <Box>
          {/* cloning content (children) element and adding the new props "tabSelected" to it */}
          {cloneElement(props.content, { tabSelected: selectedIndex === index })}
        </Box>
      )}
    </div>
  );
};
