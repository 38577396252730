import { Drawer } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { PersonIdNameTimezone } from '@redux/person/personTypes';
import { TaskCreationPayloadType } from '@redux/tasks/tasksTypes';
import { Button } from 'components/Button/Button';
import { CloseIconButton } from 'components/Button/CloseButton/CloseIconButton';
import DatePicker from 'components/DatePickerV2/DatePickerV2';
import { InformationIcon } from 'components/Icons';
import { TextArea } from 'components/TextArea/TextArea';
import { Text } from 'components/Typography';
import { Color } from 'utils/color';
import { applyTimezoneKeepTimeOfDay, formatDateWithLuxonNoTz, getTimeZoneString } from 'utils/time';
import { ActivePersonSelect } from '../ActivePersonSelect/ActivePersonSelect';
import style from './TaskCreationDrawer.scss';

type TaskCreationProps = {
  //   person: Person;
  //   task: TaskDataType;
  visible: boolean;
  onClose: () => void;
  activePersons: PersonIdNameTimezone[];
  completeSubmitNewTask: (TaskCreationPayloadType) => void;
};

export const TaskCreationDrawer: React.FC<TaskCreationProps> = ({ visible, onClose, activePersons, completeSubmitNewTask }) => {
  const getPatientTimezone = (patientId: String) => {
    return activePersons.find((value, index) => value.personId == patientId)?.timezone;
  };
  const createPlaceholderTime = (patientId: String): Date => {
    const dateTimeNow = DateTime.now();
    const placeHolderTime = dateTimeNow.setZone(getPatientTimezone(patientId)).setZone('local', { keepLocalTime: true }).toJSDate();
    const currentMinutes = placeHolderTime.getMinutes();
    const newMinutes = currentMinutes + (15 - (currentMinutes % 15));
    placeHolderTime.setMinutes(newMinutes % 60);
    placeHolderTime.setSeconds(0);
    placeHolderTime.setMilliseconds(0);
    if (newMinutes >= 60) {
      placeHolderTime.setHours(placeHolderTime.getHours() + 1);
    }

    return placeHolderTime;
  };

  const [message, setMessage] = useState<string>('');
  const [patient, setPatient] = useState<string>('');
  const [dateTime, setDateTime] = useState<Date>(createPlaceholderTime(''));

  const [errors, setErrors] = useState({
    message: '',
    patient: '',
    dateTime: ''
  });

  const MAX_MESSAGE_LENGTH = 1000;

  const submitNewTask = () => {
    if (validateForm()) {
      let assigneeName = activePersons.find((value, index) => value.personId == patient)?.fullName;
      let convertedTime = applyTimezoneKeepTimeOfDay(dateTime, getPatientTimezone(patient));
      completeSubmitNewTask({
        taskMessage: message,
        assigneeId: patient,
        assigneeFullName: assigneeName,
        scheduledForDateTime: convertedTime?.toISOString()
      });
    }
  };

  const validateForm = () => {
    const newErrors = {
      message: message && message.trim() ? '' : 'This field is required',
      patient: patient && patient.trim() ? '' : 'Please select a patient',
      dateTime: !isTimeInThePast(dateTime) ? (isTimeDuringSleep(dateTime) ? 'Task time must be between 7AM and 10PM' : '') : 'Task time must be in the future'
    };
    console.log(newErrors);
    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === '');
  };

  const patientSelectionChanged = (patientId: string) => {
    setPatient(patientId);
    setDateTime(createPlaceholderTime(patientId));
  };

  const isTimeInThePast = (date: Date) => {
    const timeNow = DateTime.fromJSDate(new Date(), { zone: getPatientTimezone(patient) }).toJSDate();
    const dateZoned = applyTimezoneKeepTimeOfDay(date, getPatientTimezone(patient));

    return dateZoned <= timeNow;
  };

  const isTimeDuringSleep = (date: Date) => {
    // Get the hours from the dateToCheck
    const hours = date.getHours();

    // Check if the time is between 10 PM and 7 AM
    return hours >= 22 || hours < 7;
  };

  const isValidTaskTime = (date: Date) => {
    if (isTimeInThePast(date)) return false;
    if (isTimeDuringSleep(date)) return false;

    return true;
  };

  const isValidTaskDate = (date: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date >= today;
  };

  return (
    <Drawer anchor="right" open={visible} onClose={onClose}>
      <div className={style.mainContainer}>
        <div className={style.drawerContainer}>
          <div className={style.drawerHeader}>
            <CloseIconButton onClick={onClose} />
          </div>
          <div className={style.title}>Create a task</div>
          <div className={style.informationBox}>
            <div className={style.informationIconContainer}>
              <InformationIcon color={Color.DARK_BLUE_600}></InformationIcon>
            </div>
            <div className={style.informationMessage}>
              This message will send the next time the patient is in the designated monitoring zones around their bed, including nearside bed, farside bed, and bedroom. The message
              expires at the end of the day.
            </div>
          </div>
          <div className={style.messageContainer}>
            <div className={style.messageTitle}>Add message</div>
            <div className={style.messageDescription}>Enter the message that will be delivered to the patient</div>
            <TextArea
              // multiline
              rows={4}
              defaultValue=""
              // variant='outlined'
              placeholder="Type message... (ie. Please make sure to do your exercises today.)"
              // fullWidth
              error={errors.message}
              onChange={(e) => setMessage(e.target.value)}
              maxLength={MAX_MESSAGE_LENGTH}
            />
          </div>
          <div className={style.messageContainer}>
            <div className={style.messageTitle}>Add conditions *</div>
            <div className={style.messageDescription}>Set condition to be met for the task to trigger</div>
            <Text variant="sm" className={style.error}>
              {[errors.patient, errors.dateTime].filter((value) => value).join(', ') || ' '}
            </Text>
            <div className={style.conditionsContainer}>
              <div className={style.conditionHolder}>
                <ActivePersonSelect error={Boolean(errors.patient)} activePersons={activePersons} patientSelectionChanged={patientSelectionChanged}></ActivePersonSelect>
              </div>
              <div className={style.conditionHolder}>
                <DatePicker
                  id="activity-datetime"
                  disablePast
                  disabled={!(patient && patient.trim())}
                  date={dateTime}
                  onChange={(date) => setDateTime(date as Date)}
                  showTimeInput
                  placement="auto"
                  variant="default"
                  errorText={errors.dateTime ? ' ' : ''}
                  filterTime={isValidTaskTime}
                  filterDate={isValidTaskDate}
                  timeCaption={`Patient's time: ${dateTime ? formatDateWithLuxonNoTz(dateTime) : ' - '} ${getTimeZoneString(getPatientTimezone(patient))}`}
                  inputStyles={{ fontSize: '14px' }}
                  // minDate={getNowRoundedTo30Minutes(timeZone)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={style.footerContainer}>
          <div className={style.drawerFooter}>
            <Button variant="contained" onClick={submitNewTask}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
