import { createReducer } from '@reduxjs/toolkit';
import { getPersonDetailsActions, getPersonOverviewActions } from '../person/personActions';
import { PersonDetailsResponse, PersonOverviewResponse } from '../person/personTypes';
import { getActionStringWithoutState } from '../reduxUtils';
import {
  DEVICE_ACTION_PREFIX,
  getClearDeviceResponseStatus,
  getDeviceConectivityActions,
  getDeviceFirmwareVersionOverviewActions,
  getDeviceFirmwareVersionsRecentActions,
  getDevicePersonOverviewActions,
  getDeviceProvisioningEnvUpdateActions,
  getDeviceProvisioningOverviewActions,
  getDeviceStateOverviewActions,
  getDeviceVersionHistoryActions,
  getHwRevisionsAndFirmwareMappingsActions,
  getSaveDeviceDataCollectionActions,
  getUpdateDeviceConfigActions,
  getUpdateRemoteCommandPortActions,
  postUnlinkDeviceActions,
  putAutoUpdateActions,
  putConnectivityNotificationActions,
  putProvisioningDeviceNumberActions,
  resetDeviceActions,
  setSelectedDevicePersonActions
} from './deviceActions';
import {
  Device,
  DeviceConfig,
  DeviceDataCollection,
  DeviceEnvironmentPayload,
  DeviceNumberPayload,
  DevicePerson,
  DeviceProvisioning,
  DeviceRemoteCommand,
  DeviceState,
  DeviceStateEntry,
  GetDevicePersonPayload,
  Location,
  SensorDevice
} from './deviceTypes';

export const initialDeviceState: DeviceState = {
  isLoading: {},
  error: {},
  success: {},
  devicePerson: {
    byDeviceId: {},
    byPersonId: {}
  },
  selectedDevicePerson: undefined,
  deviceById: {},
  sensorDeviceById: {},
  deviceVersionsByDeviceId: {},
  locationById: {},
  deviceRemoteCommandById: {},
  deviceConfigById: {},
  deviceProvisioningById: {},
  deviceStateById: {},
  firmwareVersionById: {},
  firmwareVersionRecentById: {},
  deviceConnectivityById: {},
  kokoMapDeviceConnectivityIds: [],
  hwRevisionFirmwareMappings: [],
  successMessage: undefined
};

export default createReducer(initialDeviceState, (builder) => {
  builder
    .addCase(getDeviceStateOverviewActions.success, (state, action) => {
      if (action?.payload) {
        for (const device of action.payload) {
          state.deviceStateById[device.id] = device;
        }
      }
    })
    .addCase(getDeviceProvisioningOverviewActions.success, (state, action) => {
      if (action?.payload) {
        for (const device of action.payload) {
          state.deviceProvisioningById[device.id] = device;
        }
      }
    })
    .addCase(putProvisioningDeviceNumberActions.success, (state, action) => {
      const payload: DeviceNumberPayload = action.payload;
      const deviceProvisioning: DeviceProvisioning = state.deviceProvisioningById?.[payload.deviceId];
      if (deviceProvisioning) {
        deviceProvisioning.deviceNumber = payload.deviceNumber;
      }
      state.success[action.type] = true;
    })
    .addCase(getDeviceProvisioningEnvUpdateActions.success, (state, action) => {
      const payload: DeviceEnvironmentPayload = action.payload;
      const deviceProvisioning: DeviceProvisioning = state.deviceProvisioningById?.[payload.deviceId];
      if (deviceProvisioning) {
        deviceProvisioning.environmentType = payload.environmentType;
      }
      state.success[action.type] = true;
    })
    .addCase(getDevicePersonOverviewActions.success, (state, action) => {
      const devicePersonResponse: GetDevicePersonPayload = action.payload;
      devicePersonResponse?.forEach((devicePersonLocationResponse) => {
        const devicePerson: DevicePerson = {
          deviceId: devicePersonLocationResponse.device.id,
          personId: devicePersonLocationResponse?.person?.id,
          locationId: devicePersonLocationResponse?.location?.id,
          sensorId: devicePersonLocationResponse?.sensorPerson?.sensorId
        };
        state.devicePerson.byDeviceId[devicePerson.deviceId] = devicePerson;
        if (devicePerson?.personId) {
          state.devicePerson.byPersonId[devicePerson.personId] = devicePerson;
        }
        state.deviceById[devicePerson.deviceId] = devicePersonLocationResponse.device;
        if (devicePerson.locationId && devicePersonLocationResponse.location) {
          state.locationById[devicePerson.locationId] = devicePersonLocationResponse.location;
        }
        if (devicePersonLocationResponse.deviceRemoteCommand) {
          state.deviceRemoteCommandById[devicePerson.deviceId] = devicePersonLocationResponse.deviceRemoteCommand;
        }
        if (devicePersonLocationResponse.deviceConfig) {
          state.deviceConfigById[devicePerson.deviceId] = devicePersonLocationResponse.deviceConfig;
        }
      });
    })
    .addCase(getUpdateRemoteCommandPortActions.success, (state, action) => {
      const deviceId: string = action.payload?.deviceId;
      if (deviceId) {
        const deviceRemoteCommand: DeviceRemoteCommand = state.deviceRemoteCommandById?.[deviceId];
        if (deviceRemoteCommand) {
          deviceRemoteCommand.port = action.payload?.port;
        }
        state.success[action.type] = true;
      }
    })
    .addCase(getSaveDeviceDataCollectionActions.success, (state, action) => {
      const payload: DeviceDataCollection = action.payload;
      if (payload.id) {
        const deviceState: DeviceStateEntry = state.deviceStateById[payload.id];
        deviceState.fitbitId = payload.fitbitId;
        deviceState.fitbitAuthToken = payload.fitbitAuthToken;
        deviceState.kobeId = payload.kobeId;
        deviceState.museId = payload.museId;
        deviceState.hexoId = payload.hexoId;
        deviceState.deviceSleeperType = payload.deviceSleeperType;
      }
      state.success[action.type] = true;
    })
    .addCase(getUpdateDeviceConfigActions.success, (state, action) => {
      const deviceConfig: DeviceConfig = action.payload;
      const deviceId: string = deviceConfig?.id;
      if (deviceId) {
        const deviceConfigById: Record<string, DeviceConfig> = state.deviceConfigById;
        deviceConfigById[deviceId] = deviceConfig;
      }
      state.success[action.type] = true;
    })
    .addCase(getDeviceFirmwareVersionOverviewActions.success, (state, action) => {
      for (const firmwareVersion of action.payload) {
        state.firmwareVersionById[firmwareVersion.id] = firmwareVersion;
      }
    })
    .addCase(getDeviceFirmwareVersionsRecentActions.success, (state, action) => {
      for (const firmwareVersion of action.payload) {
        state.firmwareVersionRecentById[firmwareVersion.id] = firmwareVersion;
      }
    })
    .addCase(setSelectedDevicePersonActions.success, (state, action) => {
      state.selectedDevicePerson = state.devicePerson.byDeviceId[action.payload];
    })
    .addCase(getPersonOverviewActions.success, (state, action) => {
      action.payload?.map((personOverviewResponse: PersonOverviewResponse) => setPersonOverview(state, personOverviewResponse));
    })
    .addCase(getPersonDetailsActions.success, (state, action) => {
      const personDetailsResponse: PersonDetailsResponse = action.payload;
      setPersonDetails(state, personDetailsResponse);
    })
    .addCase(getDeviceConectivityActions.start, (state, action) => {
      state.kokoMapDeviceConnectivityIds = [];
    })
    .addCase(getDeviceConectivityActions.success, (state, action) => {
      action.payload?.forEach((deviceConnectivity) => {
        state.kokoMapDeviceConnectivityIds.push(deviceConnectivity.id);
        state.deviceConnectivityById[deviceConnectivity.id] = deviceConnectivity;
      });
    })
    .addCase(putAutoUpdateActions.success, (state, action) => {
      const deviceId: string = action.payload?.deviceId;
      if (deviceId) {
        const deviceStateEntry: DeviceStateEntry = state.deviceStateById?.[deviceId];
        if (deviceStateEntry) {
          deviceStateEntry.autoUpdateDisabled = action.payload?.autoUpdateDisabled;
        }
      }
      state.success[action.type] = true;
    })
    .addCase(putConnectivityNotificationActions.success, (state, action) => {
      const deviceId: string = action.payload?.deviceId;
      if (deviceId) {
        const device: Device = state.deviceById?.[deviceId];
        if (device) {
          device.connectivityNotification = action.payload?.connectivityNotification;
        }
      }
      state.success[action.type] = true;
    })
    .addCase(postUnlinkDeviceActions.success, (state, action) => {
      const deviceId: string = action.payload?.deviceId;
      const personId = state.devicePerson.byDeviceId?.[deviceId]?.personId;
      if (deviceId && personId) {
        delete state.devicePerson.byPersonId[personId];
        state.devicePerson.byDeviceId[deviceId].personId = undefined;
      }
      state.success[action.type] = true;
    })
    .addCase(resetDeviceActions.success, (state, action) => {
      const deviceId: string = action.payload;
      if (deviceId) {
        const device: Device = state.deviceById?.[deviceId];
        if (device) {
          device.latestFactoryResetTs = new Date().toISOString();
        }
      }
      state.success[action.type] = true;
      state.successMessage =
        'Factory reset initiated on device. Please make sure to wait for the blue LED light to be turned on after the factory reset is complete before shipping it.';
    })

    .addCase(getDeviceVersionHistoryActions.success, (state, action) => {
      if (action.payload?.length > 0) {
        state.deviceVersionsByDeviceId[action.payload[0].deviceId] = action.payload;
      }
    })
    .addCase(getClearDeviceResponseStatus, (state, action) => {
      for (const key in state.isLoading) {
        delete state.isLoading[key];
      }
      for (const key in state.error) {
        delete state.error[key];
      }
      for (const key in state.success) {
        delete state.success[key];
      }
      state.successMessage = undefined;
    })
    .addCase(getHwRevisionsAndFirmwareMappingsActions.success, (state, action) => {
      state.hwRevisionFirmwareMappings = action.payload;
    })
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-start') && action.type.startsWith(DEVICE_ACTION_PREFIX),
      (state, action) => {
        const tag = getActionStringWithoutState(action.type);
        state.isLoading[tag] = true;
        delete state.error[tag];
        delete state.success[tag];
        state.successMessage = undefined;
      }
    )
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-success') && action.type.startsWith(DEVICE_ACTION_PREFIX),
      (state, action) => {
        const tag = getActionStringWithoutState(action.type);
        state.isLoading[tag] = false;
      }
    )
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-fail') && action.type.startsWith(DEVICE_ACTION_PREFIX),
      (state, action) => {
        const tag = getActionStringWithoutState(action.type);
        state.isLoading[tag] = true;
        delete state.error[tag];
        delete state.success[tag];
        state.successMessage = undefined;
      }
    );
});

// export default produce(deviceRecipe, initialDeviceState);

const setPersonDetails = (draftState: DeviceState, personDetailsResponse: PersonDetailsResponse): void => {
  setPersonOverview(draftState, personDetailsResponse);
  if (personDetailsResponse?.device?.id && personDetailsResponse.deviceOverviewResponseEntry) {
    draftState.deviceStateById[personDetailsResponse.device.id] = personDetailsResponse.deviceOverviewResponseEntry;
  }
  const sensorDevices: Nullable<SensorDevice[]> = personDetailsResponse.sensorDevices;
  sensorDevices?.forEach((sensorDevice) => {
    draftState.sensorDeviceById[sensorDevice.id] = sensorDevice;
  });
  const devices: Nullable<Device[]> = personDetailsResponse.devices;
  devices?.forEach((device) => {
    draftState.deviceById[device.id] = device;
  });
  const locations: Nullable<Location[]> = personDetailsResponse.locations;
  locations?.forEach((location) => {
    if (location?.id) {
      draftState.locationById[location.id] = location;
    }
  });
};

const setPersonOverview = (draftState: DeviceState, personOverviewResponse: PersonOverviewResponse): void => {
  const deviceId: Nullable<string> = personOverviewResponse?.device?.id;
  const sensorDeviceId: Nullable<string> = personOverviewResponse?.sensorDevice?.id;
  const locationId: Nullable<string> = personOverviewResponse?.location?.id;
  if (deviceId && personOverviewResponse.device) {
    draftState.deviceById[deviceId] = personOverviewResponse.device;
  }
  if (deviceId && personOverviewResponse.deviceRemoteCommand) {
    draftState.deviceRemoteCommandById[deviceId] = personOverviewResponse.deviceRemoteCommand;
  }
  if (sensorDeviceId && personOverviewResponse.sensorDevice) {
    draftState.sensorDeviceById[sensorDeviceId] = personOverviewResponse.sensorDevice;
  }
  if (locationId && personOverviewResponse.location) {
    draftState.locationById[locationId] = personOverviewResponse.location;
  }
};
