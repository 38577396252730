import { Chip, Container, MuiThemeProvider, Tooltip, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { cloneDeep } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { BreadCrumbData } from '@redux/common/types';
import { getClearResponseStatusAction } from '@redux/content/contentActions';
import { getDeviceContentByTypeSelector, getProgramContentByTypeSelector, getSaveSuccessfulSelector, getSelectedContentType } from '@redux/content/contentSelectors';
import { getProgramContentByTypeThunk, saveDeviceAndProgramContentsThunk, syncContentThunk } from '@redux/content/contentThunks';
import {
  ContentDropdownOption,
  DeviceAndProgramContentUpdateRequest,
  DeviceContent,
  DeviceContentType,
  findCategoryId,
  ProgramContent,
  ProgramSubType,
  ProgramType,
  subTypeList
} from '@redux/content/contentTypes';
import { appendBreadcrumbAction } from '@redux/oauth/oauthActions';
import { getAccessTokenSelector, getBreadcrumbsSelector } from '@redux/oauth/oauthSelectors';
import { getUserPermissionsSelector } from '@redux/person/personSelector';
import { getUserPermissionsThunk } from '@redux/person/personThunks';
import { PERMISSION_ROLE_ID_ADMIN, PermissionRole } from '@redux/person/personTypes';
import { ReduxState } from '@redux/types';
import { ContentDropdown } from '../../components/ContentDropdown/ContentDropdown';
import { ContentTile } from '../../components/ContentTile/ContentTile';
import { FeatureUnlockChips } from '../../components/FeatureUnlockChips/FeatureUnlockChips';
import { Header } from '../../components/Header/Header';
import { RankedContentActions } from '../../components/RankedContentActions/RankedContentActions';
import { StatusMessage } from '../../components/StatusMessage/StatusMessage';
import { defaultTrackCrumb, defaultTrackCrumbs } from '../../utils/breadcrumbs';
import { constructOrderedMapContent, constructRankedMap, mapProgramTypeToDeviceContentType, normalizeEnumName } from '../../utils/content';
import { EnvType, EnvUrl, getCurrentAppEnvironment } from '../../utils/environment';
import { defaultTheme } from '../../utils/styles';
import { getMaxKeyOfMaps, goTo, mapsAreEqual } from '../../utils/util';
import style from './Track.scss';

interface Props {
  programContentByType?: Nullable<Record<ProgramType, ProgramContent[]>>;
  deviceContentByType?: Nullable<Record<DeviceContentType, DeviceContent[]>>;
  saveSuccessful?: Nullable<boolean>;
  userPermissions?: Nullable<PermissionRole[]>;
  accessToken?: Nullable<string>;
  track?: Nullable<ProgramSubType>;
  openAsDialog?: Nullable<boolean>;
  newProgramContent?: Nullable<ProgramContent>;
  selectedDeviceContentId?: Nullable<string>;
  breadCrumbs?: Nullable<BreadCrumbData[]>;
  saveDeviceAndProgramContent: (deviceAndProgramContentUpdateRequest: DeviceAndProgramContentUpdateRequest) => void;
  syncContentToProd: (deviceAndProgramContentUpdateRequest: DeviceAndProgramContentUpdateRequest, authToken: string) => void;
  getUserPermissions: () => void;
  appendBreadCrumbs?: Nullable<(breadCrumb: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => void>;
  clearResponseStatus: () => void;
  programTypeFilter?: ProgramType;
  getProgramContentByTypeAndSubType: (programType: ProgramType, programSubType?: ProgramSubType) => void;
}

export const Track: React.FC<Props> = ({
  programContentByType,
  deviceContentByType,
  saveSuccessful,
  userPermissions,
  accessToken,
  track,
  openAsDialog,
  newProgramContent,
  selectedDeviceContentId,
  breadCrumbs,
  getProgramContentByTypeAndSubType,
  saveDeviceAndProgramContent,
  syncContentToProd,
  getUserPermissions,
  appendBreadCrumbs,
  clearResponseStatus,
  programTypeFilter
}) => {
  const params = useParams();
  const { subType } = params || {};
  const navigate = useNavigate();
  const location: any = useLocation();

  const [selectedContentType, setSelectedContentType] = useState<Nullable<ProgramType>>(programTypeFilter || (subType as ProgramType) || null); // TODO check why subType used as ProgramType
  const [filteredData, setFilteredData] = useState<ProgramContent[]>([]);

  const [rankMap, setRankMap]: [Map<number, string>, Dispatch<SetStateAction<Map<number, string>>>] = useState<Map<number, string>>(
    constructRankedMap('programStep', filteredData, 'deviceContent', newProgramContent?.id)
  );
  const [expiredSet, setExpiredSet]: [Set<string>, Dispatch<SetStateAction<Set<string>>>] = useState<Set<string>>(new Set<string>());

  const contentFilterTypes: ProgramType[] = [ProgramType.CBTI_NATIVE, ProgramType.CBTI_14_DAY_TRIAL, ProgramType.DEMO_CONTENT];

  useEffect(() => {
    if (programContentByType) {
      refreshFilteredDataAndRanks(programContentByType, newProgramContent);
    }
  }, [selectedContentType]);

  useEffect(() => {
    if (programContentByType) {
      refreshFilteredDataAndRanks(programContentByType, newProgramContent);
    }
  }, [programContentByType]);

  useEffect(() => {
    getUserPermissions();
    if (programTypeFilter && programContentByType) {
      setSelectedContentType(programTypeFilter);
      refreshFilteredDataAndRanks(programContentByType, newProgramContent);
    }
    if (subType && subType !== 'DEMO_CONTENT') {
      getProgramContentByTypeAndSubType(ProgramType.CBTI_NATIVE, subType as ProgramSubType);
      getProgramContentByTypeAndSubType(ProgramType.CBTI_14_DAY_TRIAL, subType as ProgramSubType);
      getProgramContentByTypeAndSubType(ProgramType.AD_HOC_NATIVE, subType as ProgramSubType);
    }
    getProgramContentByTypeAndSubType(ProgramType.DEMO_CONTENT);
  }, []);

  const refreshFilteredDataAndRanks = (programContentByType: Record<ProgramType, ProgramContent[]>, newProgramContent: Nullable<ProgramContent>): void => {
    const rankedData = getAllRankedData(programContentByType, newProgramContent);
    setFilteredData(rankedData.filteredData);
    setRankMap(rankedData.rankMap);
    setExpiredSet(new Set<string>());
  };

  const getAllRankedData = (programContentByType, newProgramContent) => {
    let filteredData: ProgramContent[];
    let rankMap: Map<number, string>;
    if (selectedContentType) {
      //select only program type
      filteredData = filteredData = programContentByType?.[selectedContentType];
      rankMap = constructRankedMap('programStep', filteredData, 'deviceContent', newProgramContent?.id);
    } else {
      //select all data and squash maps for sorting
      const rankedMaps: Map<number, string>[] = [];
      filteredData = contentFilterTypes.reduce((arr, programType) => {
        const content: ProgramContent[] = programContentByType?.[programType];
        if (content) {
          arr.push(...content);
          const rankMap: Map<number, string> = constructRankedMap('programStep', content, 'deviceContent', newProgramContent?.id);
          rankedMaps.push(rankMap);
        }
        return arr;
      }, [] as ProgramContent[]);
      rankMap = squashRankedMaps(rankedMaps);
    }
    return { filteredData: filteredData, rankMap: rankMap };
  };

  const squashRankedMaps = (rankedMaps: Map<number, string>[]) => {
    const maxKey = getMaxKeyOfMaps(...rankedMaps) || 1;
    return rankedMaps.reduce((acc, map) => {
      map.forEach((value, key) => {
        if (acc.has(key)) {
          acc.set(key + maxKey, value);
        } else {
          acc.set(key, value);
        }
      });
      return acc;
    }, new Map());
  };

  const isAdmin = (): boolean => !!userPermissions?.find((p) => p.permissionRoleTypeId === PERMISSION_ROLE_ID_ADMIN);
  const displaySync = (): boolean => isAdmin() && getCurrentAppEnvironment() !== EnvType.PROD;
  const displayToken = (): boolean => isAdmin() && getCurrentAppEnvironment() === EnvType.PROD;

  const matchDeviceContent = (deviceContentId: Nullable<string>, deviceContentType: Nullable<DeviceContentType>): Nullable<DeviceContent> => {
    if (deviceContentType && deviceContentId) {
      return deviceContentByType?.[deviceContentType]?.find((deviceContent) => deviceContent.id === deviceContentId);
    }
  };

  const assembleChangedDataRequest = (): DeviceAndProgramContentUpdateRequest => {
    const programContent: ProgramContent[] = constructOrderedMapContent(
      rankMap,
      expiredSet,
      'programStep',
      true,
      'endDateTs',
      new Date().toISOString(),
      filteredData,
      newProgramContent
    );
    const deviceContent: DeviceContent[] = findDeviceContentToMatchProgramChanges(programContent);
    return {
      programContent,
      deviceContent
    };
  };

  const assembleAllDataRequest = (): DeviceAndProgramContentUpdateRequest => {
    const programContent: ProgramContent[] = constructOrderedMapContent(
      rankMap,
      expiredSet,
      'programStep',
      true,
      'endDateTs',
      new Date().toISOString(),
      filteredData,
      newProgramContent
    );
    const deviceContent: DeviceContent[] = findDeviceContentToMatchPrograms(programContent);
    return createUpsertableRequest(programContent, deviceContent);
  };

  const saveProgramChanges = async () => {
    const deviceAndProgramContentUpdateRequest: DeviceAndProgramContentUpdateRequest = assembleChangedDataRequest();
    await saveDeviceAndProgramContent(deviceAndProgramContentUpdateRequest);
  };

  const syncProgramWithProd = async (authToken: string): Promise<any> => {
    const deviceAndProgramContentAllRequest: DeviceAndProgramContentUpdateRequest = assembleAllDataRequest();
    await syncContentToProd(deviceAndProgramContentAllRequest, authToken);
  };

  const createUpsertableRequest = (programContents?: Nullable<ProgramContent[]>, deviceContents?: Nullable<DeviceContent[]>): DeviceAndProgramContentUpdateRequest => {
    const upsertProgramContents: ProgramContent[] = programContents ? cloneDeep(programContents) : [];
    for (let i = 0; i < upsertProgramContents.length; i++) {
      upsertProgramContents[i].created = undefined;
      upsertProgramContents[i].modifiedDate = undefined;
    }
    const upsertDeviceContents: DeviceContent[] = deviceContents ? cloneDeep(deviceContents) : [];
    for (let i = 0; i < upsertDeviceContents.length; i++) {
      upsertDeviceContents[i].created = undefined;
      upsertDeviceContents[i].modifiedDate = undefined;
      const length = upsertDeviceContents[i]?.deviceContentPages?.length || 0;
      for (let j = 0; j < length; j++) {
        const deviceContentPage = upsertDeviceContents[i]?.deviceContentPages?.[j];
        if (deviceContentPage) {
          deviceContentPage.created = undefined;
          deviceContentPage.modifiedDate = undefined;
          if (deviceContentPage.surveyQuestion) {
            deviceContentPage.surveyQuestion.created = undefined;
            deviceContentPage.surveyQuestion.modifiedDate = undefined;
            if (deviceContentPage.surveyQuestion.surveyOptions) {
              for (let k = 0; k < deviceContentPage.surveyQuestion.surveyOptions?.length; k++) {
                deviceContentPage.surveyQuestion.surveyOptions[k].created = undefined;
                deviceContentPage.surveyQuestion.surveyOptions[k].modifiedDate = undefined;
              }
            }
          }
        }
      }
    }
    return {
      programContent: upsertProgramContents,
      deviceContent: upsertDeviceContents
    };
  };

  const findDeviceContentToMatchPrograms = (programContents: ProgramContent[]): DeviceContent[] => {
    const deviceContentValues: DeviceContent[] = [];
    programContents.forEach((programContent) => {
      const matchedDeviceContent: Nullable<DeviceContent> = matchDeviceContent(programContent.deviceContentId, mapProgramTypeToDeviceContentType(programContent.programType));
      if (matchedDeviceContent) {
        deviceContentValues.push(matchedDeviceContent);
      }
    });
    return deviceContentValues;
  };

  const addToDeviceContentMap = (programContent, resultMap) => {
    if (programContent.deviceContentId) {
      const deviceContentType: DeviceContentType = mapProgramTypeToDeviceContentType(programContent.programType);
      let deviceContentSet = resultMap.get(deviceContentType);
      if (!deviceContentSet) {
        deviceContentSet = new Set<string>();
        resultMap.set(deviceContentType, deviceContentSet);
      }
      deviceContentSet.add(programContent.deviceContentId);
    }
  };

  const getExpiredDeviceIds = (programContents: ProgramContent[]): Map<DeviceContentType, Set<string>> => {
    const resultMap = new Map<DeviceContentType, Set<string>>();
    expiredSet.forEach((expiredProgramContentId) => {
      programContents.filter((programContent) => programContent.id === expiredProgramContentId).forEach((programContent) => addToDeviceContentMap(programContent, resultMap));
    });
    return resultMap;
  };

  const hasSomeNotExpiredProgramContents = (programContents: ProgramContent[]): boolean =>
    programContents.some((programContent) => programContent.id && !expiredSet.has(programContent.id) && programContent.programType !== selectedContentType);
  const hasNoProgram = (programContents: Nullable<ProgramContent[]>): boolean => programContents === null || !programContents?.length;
  const isNewProgram = (deviceContentId: string): boolean => newProgramContent?.deviceContentId === deviceContentId;

  const findDeviceContentToMatchProgramChanges = (programContents: ProgramContent[]): DeviceContent[] => {
    const deviceContentIdsToCheck: Map<DeviceContentType, Set<string>> = getExpiredDeviceIds(programContents);
    if (newProgramContent) {
      addToDeviceContentMap(newProgramContent, deviceContentIdsToCheck);
    }

    const updateDeviceContentValues: DeviceContent[] = [];
    deviceContentIdsToCheck.forEach((deviceContentIds, deviceContentType) => {
      deviceContentIds.forEach((deviceContentId) => {
        const matchedDeviceContent: Nullable<DeviceContent> = matchDeviceContent(deviceContentId, deviceContentType);
        if (matchedDeviceContent) {
          const programContents: Nullable<ProgramContent[]> = matchedDeviceContent.programContents;
          if (isNewProgram(deviceContentId) || hasNoProgram(programContents) || (programContents && hasSomeNotExpiredProgramContents(programContents))) {
            queueDeviceContentUpdate(matchedDeviceContent, deviceContentType, updateDeviceContentValues);
          }
        }
      });
    });
    return updateDeviceContentValues;
  };

  const queueDeviceContentUpdate = (deviceContent: DeviceContent, newDeviceType: DeviceContentType, updateQueue: DeviceContent[]): void => {
    const updatedDeviceContent: DeviceContent = { ...deviceContent };
    updatedDeviceContent.categoryId = findCategoryId(newDeviceType);
    updatedDeviceContent.deviceContentType = newDeviceType;
    updateQueue.push(updatedDeviceContent);
  };

  const createActionIcons = (programContent: ProgramContent): JSX.Element => {
    const deviceContent: Nullable<DeviceContent> = matchDeviceContent(programContent.deviceContentId, mapProgramTypeToDeviceContentType(programContent.programType));
    if (openAsDialog && programContent.id !== newProgramContent?.id && programContent.deviceContentId !== selectedDeviceContentId) {
      return <FeatureUnlockChips programContents={deviceContent?.programContents} deviceContentPages={deviceContent?.deviceContentPages} />;
    }
    return (
      <Box component="div" className={style.tileIconBar} onClick={(e) => e.stopPropagation()}>
        <FeatureUnlockChips programContents={deviceContent?.programContents} deviceContentPages={deviceContent?.deviceContentPages} />
        <RankedContentActions
          rankMapDispatcher={[rankMap, setRankMap]}
          expiredSetDispatcher={[expiredSet, setExpiredSet]}
          content={programContent}
          newContent={newProgramContent}
          rankFieldName="programStep"
          fontSize="small"
          cssClass="tileIcon"
        />
      </Box>
    );
  };

  const createProgramContentTypeChip = (programContent: ProgramContent): JSX.Element => {
    const programType: ProgramType = programContent.programType;
    return (
      <Tooltip title="Select a content type filter to reorder items" arrow>
        <Chip key={programContent.id} className={style.subTypeChip} label={`${normalizeEnumName(programType)}`} color={'primary'} />
      </Tooltip>
    );
  };

  const hasChanged = () => !mapsAreEqual(constructRankedMap('programStep', filteredData, 'deviceContent', newProgramContent?.id), rankMap);

  const handleDropdownChange = (selectedOption: ContentDropdownOption): void => {
    if (selectedOption.value === 'SELECT_ALL') {
      setSelectedContentType(null);
    } else {
      setSelectedContentType(selectedOption.value as ProgramType);
    }
  };

  const selectedOption: DeviceContentType | ProgramType = (programTypeFilter as ProgramType) || subType;

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={style.root}>
        <Container maxWidth="xl">
          <Header
            breadCrumbs={!openAsDialog ? breadCrumbs : []}
            subtitle={normalizeEnumName((subType && ProgramSubType[subType]) || track)}
            appendBreadCrumbs={appendBreadCrumbs}
            defaultCurrentCrumb={defaultTrackCrumb}
            defaultCrumbHistory={defaultTrackCrumbs}
            overrideTitle={(subType && ProgramSubType[subType]) || track}
            overrideUrl={location?.pathname}
            overrideSelectedIndex={subTypeList
              .filter((subType) => subType !== ProgramSubType.UNKNOWN)
              .findIndex((subType) => subType === ((subType && ProgramSubType[subType]) || track))}
            saveFunction={saveProgramChanges}
            syncFunction={displaySync() ? syncProgramWithProd : undefined}
            disableSave={!newProgramContent && ((!rankMap.size && !expiredSet.size) || !hasChanged())}
            disableSync={!rankMap.size || hasChanged()}
            saveSuccessful={saveSuccessful}
          />
          <ContentDropdown
            contentTypeOptions={contentFilterTypes}
            selectAll={true}
            selectedOption={selectedOption}
            onClick={handleDropdownChange}
            disabled={!!selectedDeviceContentId}
          />
          <StatusMessage
            saveSuccessful={saveSuccessful !== undefined && saveSuccessful}
            saveUnsuccessful={saveSuccessful !== undefined && !saveSuccessful}
            clearResponseStatus={clearResponseStatus}
          />
          {constructOrderedMapContent(rankMap, expiredSet, 'programStep', false, 'endDateTs', new Date().toISOString(), filteredData, newProgramContent).map(
            (programContent, index) => (
              <ContentTile
                key={index}
                title={programContent?.deviceContent?.title}
                subTitles={[{ text: programContent?.deviceContent?.subTitle || '' }]}
                tileNumber={programContent.programStep}
                disableClick={openAsDialog}
                onClick={goTo(`/deviceContentDetails/${programContent.deviceContentId}`, navigate, {
                  track: (subType && ProgramSubType[subType]) || track
                })}
                tailElement={(selectedContentType === null && createProgramContentTypeChip(programContent)) || createActionIcons(programContent)}
              />
            )
          )}
          {displayToken() && (
            <React.Fragment>
              <Typography>Your prod access token:</Typography>
              <Typography className={style.authToken}>{accessToken}</Typography>
            </React.Fragment>
          )}
        </Container>
      </div>
    </MuiThemeProvider>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      programContentByType: getProgramContentByTypeSelector(state),
      deviceContentByType: getDeviceContentByTypeSelector(state),
      saveSuccessful: getSaveSuccessfulSelector(state),
      userPermissions: getUserPermissionsSelector(state),
      accessToken: getAccessTokenSelector(state),
      breadCrumbs: getBreadcrumbsSelector(state),
      selectedContentType: getSelectedContentType(state)
    };
  },
  (dispatch: Function) => ({
    saveDeviceAndProgramContent: (deviceAndProgramContentUpdateRequest: DeviceAndProgramContentUpdateRequest) => {
      dispatch(saveDeviceAndProgramContentsThunk(deviceAndProgramContentUpdateRequest));
    },
    getProgramContentByTypeAndSubType: (programType: ProgramType, programSubType?: ProgramSubType) => {
      dispatch(getProgramContentByTypeThunk(programType, programSubType));
    },
    syncContentToProd: (deviceAndProgramContentUpdateRequest: DeviceAndProgramContentUpdateRequest, authToken: string) => {
      dispatch(syncContentThunk(deviceAndProgramContentUpdateRequest, EnvUrl.PROD, authToken));
    },
    getUserPermissions: () => {
      dispatch(getUserPermissionsThunk());
    },
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => {
      dispatch(appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }));
    },
    clearResponseStatus: () => {
      dispatch(getClearResponseStatusAction());
    }
  })
);

export default compose(connectRedux)(Track) as React.ComponentType;
