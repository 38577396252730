import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { TaskAssigneeType } from '@redux/tasks/tasksTypes';
import { Button } from 'components/Button/Button';
import { Text } from 'components/Typography';
import { getInitials } from 'utils/util';
import style from './PersonInfoArea.scss';

type PersonInfoAreaProps = {
  assignee: TaskAssigneeType;
};

export const PersonInfoArea: React.FC<PersonInfoAreaProps> = ({ assignee: { assigneeId, assigneeEmail, assigneeFullname } }) => {
  const navigate = useNavigate();
  const onPressOpenDashboard = useCallback(() => {
    navigate(`/patients/${assigneeId}`);
  }, [assigneeId]);

  return (
    <>
      <div className={style.container}>
        <div className={style.avatar}>
          <Text variant="xl" weight={500} className={style.initials}>
            {getInitials(assigneeFullname!)}
          </Text>
        </div>
        <div className={style.personalInfo}>
          <Text variant="lg">{assigneeFullname}</Text>
          <Text variant="md" className={style.email}>
            {assigneeEmail}
          </Text>
        </div>
        <Button variant="outlined" onClick={onPressOpenDashboard}>
          Open dashboard
        </Button>
      </div>
    </>
  );
};
