import moment from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';
import { Chart } from 'react-google-charts';
import { PersonVitalInsights, PersonVitalTimeFrame, PersonVitalType } from '@redux/person/personTypes';
import { CircledCustomIcon, TableIcon } from 'components/Icons';
import { Text } from 'components/Typography';
import {
  createCustomTooltip,
  generateChartTicks,
  getMinTime,
  getYMaxValue,
  getYMinValue,
  updateControlLinesStyles,
  updateHeaderStyles,
  getXAxisGridlineCount,
  getDateFormat,
  getChartColor
} from 'utils/charts';
import { Color } from 'utils/color';
import { getDateFromStringIgnoreTimezone } from 'utils/time';
import { getFixedPointDisplay } from 'utils/util';
import style from './HeartBreathChart.scss';

export type HeartRateChartProps = {
  timeZoneId: string;
  personVitalInsights?: PersonVitalInsights | null;
  vitalType: PersonVitalType;
  timeFrame: PersonVitalTimeFrame;
  minRate?: number;
  maxRate?: number;
  minControlRate?: number;
  maxControlRate?: number;
};

type HeartRateChartDataType = Array<Array<Date | number | string>>;

export const HeartBreathChart: React.FC<HeartRateChartProps> = ({ timeZoneId, personVitalInsights, vitalType, timeFrame, minRate, maxRate, minControlRate, maxControlRate }) => {
  const yMinValue = getYMinValue(vitalType, minRate, minControlRate);
  const yMaxValue = getYMaxValue(vitalType, maxRate, maxControlRate);
  const patLocalTime = moment.tz(timeZoneId).format('YYYY-MM-DD HH:mm:ss z');
  const xMaxValue = getDateFromStringIgnoreTimezone(patLocalTime)!;
  const xMinValue = getMinTime(xMaxValue, timeFrame);
  const chartColor = getChartColor(vitalType);
  const xAxisGridlineCount = getXAxisGridlineCount(timeFrame);
  const title = vitalType === PersonVitalType.HEART_RATE ? 'Heart rate (bpm)' : 'Breathing rate (bpm)';

  const isDateValid = (dt: Date | null) => {
    return Boolean(dt && dt >= xMinValue && dt <= xMaxValue);
  };

  const isValueValid = (value: number | undefined) => {
    return typeof value === 'number' && !Number.isNaN(value);
  };

  const calculateChartData = (personVitalInsights?: PersonVitalInsights | null): HeartRateChartDataType => {
    if (!personVitalInsights || !personVitalInsights.listOfAggregatedProcessedVitalSigns) {
      return [];
    }
    const aggregatedProcessedVitalSigns = personVitalInsights.listOfAggregatedProcessedVitalSigns;
    return aggregatedProcessedVitalSigns
      .map((vitalSign) => {
        const min = vitalSign.minHeartRate;
        const max = vitalSign.maxHeartRate;
        const status = vitalSign.heartRateStatus;
        const bucket = getDateFromStringIgnoreTimezone(vitalSign.bucket);
        const start = getDateFromStringIgnoreTimezone(vitalSign.startTime);
        const end = getDateFromStringIgnoreTimezone(vitalSign.endTime);

        if (isValueValid(min) && isValueValid(max) && isDateValid(start) && isDateValid(end) && isDateValid(bucket)) {
          const minVal = getFixedPointDisplay(min);
          const maxVal = getFixedPointDisplay(max);

          return [bucket!, minVal, minVal, maxVal, maxVal, createCustomTooltip(start!, end!, minVal, maxVal, timeFrame, status)];
        }
        return [];
      })
      .filter((data) => !!data?.length);
  };

  const chartData = useMemo(() => {
    const data = calculateChartData(personVitalInsights);
    return [['Date', 'Low', 'Open', 'Close', 'High', { type: 'string', role: 'tooltip', p: { html: true } }], ...data];
  }, [personVitalInsights]);

  const options = {
    legend: 'none',
    title,
    titleTextStyle: {
      color: chartColor,
      fontSize: 12,
      lineHeight: 18,
      marginLeft: -32
    },
    tooltip: { isHtml: true },
    bar: {
      groupWidth: 14
    },
    fontName: 'Outfit',
    colors: [chartColor],
    chartArea: {
      left: 32,
      top: 18,
      bottom: 24,
      marginTop: -16,
      right: 20,
      width: '100%',
      height: '100%'
    },
    hAxis: {
      title: '',
      textStyle: {
        color: Color.GRAY,
        fontSize: 12,
        lineHeight: 18
      },
      viewWindowMode: 'explicit',
      minValue: xMinValue,
      maxValue: xMaxValue,
      viewWindow: {
        min: xMinValue,
        max: xMaxValue
      },
      format: getDateFormat(timeFrame),
      gridlines: {
        count: xAxisGridlineCount,
        color: 'transparent'
      },
      minorGridlines: {
        count: 0
      }
    },
    vAxis: {
      ticks: generateChartTicks(yMinValue, yMaxValue, minControlRate, maxControlRate) as (number | Date)[],
      title: '',
      textStyle: {
        color: chartColor,
        fontSize: 12,
        lineHeight: 18
      },
      minValue: yMinValue,
      maxValue: yMaxValue,
      viewWindow: {
        min: yMinValue,
        max: yMaxValue
      },
      gridlines: {
        color: Color.GRAY_100
      },
      minorGridlines: {
        count: 0
      },
      baselineColor: Color.GRAY_100
    }
  };

  const updateChartStyles = useCallback(() => {
    updateHeaderStyles();
    updateControlLinesStyles(minControlRate, maxControlRate);
    //updateCandleStickStyles();
  }, [minControlRate, maxControlRate]);

  return chartData?.length > 1 ? (
    <div className={style.chartContainer}>
      <Chart
        width="100%"
        height="100%"
        chartEvents={[{ eventName: 'ready', callback: updateChartStyles }]}
        chartType="CandlestickChart"
        loader={<div>Loading Chart</div>}
        data={chartData}
        options={options}
      />
    </div>
  ) : (
    <div className={style.noDataChartContainer}>
      <CircledCustomIcon icon={TableIcon} />
      <Text>No data to display</Text>
    </div>
  );
};
