import { createAction } from '@reduxjs/toolkit';
import { createReduxActionCreator } from '@redux/types';
import { InboxMessages, InboxMessagesDetails } from './inboxMessagesTypes';

export const INBOX_MESSAGES_ACTION_PREFIX = 'inbox-messages-redux/';

export const getInboxMessagesAction = createReduxActionCreator<InboxMessages[]>(`${INBOX_MESSAGES_ACTION_PREFIX}get-inbox-messages`);
export const getInboxMessagesDetailsAction = createReduxActionCreator<InboxMessagesDetails>(`${INBOX_MESSAGES_ACTION_PREFIX}get-inbox-messages-details`);
export const clearSelectedInboxMessagesDetails = createAction<undefined>(`${INBOX_MESSAGES_ACTION_PREFIX}clear-selected-inbox-messages-details`);
export const postInboxMessagesResponseAction = createReduxActionCreator<InboxMessagesDetails>(`${INBOX_MESSAGES_ACTION_PREFIX}post-inbox-messages-response`);
