import { ButtonProps, Button as MaterialUIButton } from '@mui/material';
import classNames from 'classnames';
import React, { memo } from 'react';
import style from './Button.scss';

export const Button: React.FC<ButtonProps> = memo((props) => {
  let customStyle = '';
  if (props.variant === 'contained') {
    customStyle = style.contained;
  } else if (props.variant === 'outlined') {
    customStyle = style.outlined;
  } else if (props.variant === 'text') {
    customStyle = style.text;
  }
  return <MaterialUIButton {...props} className={classNames(customStyle, props.className)} />;
});
