import { FormControl, InputAdornment, ListSubheader, MenuItem, Select, TextField, makeStyles } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import React, { useMemo, useState } from 'react';
import { PersonIdNameTimezone } from '@redux/person/personTypes';
import { UserCircleIcon } from 'components/Icons';
import { getInitials } from 'utils/util';
import style from './ActivePersonSelect.scss';

type ActivePersonSelectProps = {
  activePersons: PersonIdNameTimezone[];
  patientSelectionChanged: (patientId: string) => void;
  error: boolean;
};

const makeSelectStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 200
  },
  select: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #BCCAD4',
      boxShadow: '0 0 0 4px rgba(16, 24, 40, 0.05)'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      borderColor: '#D7E0E6'
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: '7px 8px'
    },
    '&': {
      margin: 'auto 0'
    }
  },
  selectError: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #FDAAA4',
      boxShadow: '0 0 0 4px rgba(240, 68, 56, 0.24)'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      borderColor: '#FDA29B'
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: '7px 8px'
    },
    '&': {
      margin: 'auto 0'
    }
  },
  search: {
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #BCCAD4',
      boxShadow: '0 0 0 4px rgba(16, 24, 40, 0.05)'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      borderColor: '#D7E0E6'
    }
  }
}));

export const ActivePersonSelect: React.FC<ActivePersonSelectProps> = ({ activePersons, patientSelectionChanged, error }) => {
  const [personSearchText, setPersonSearchText] = useState<string>('');
  const [person, setSelectedPerson] = useState<string>('');

  const containsText = (text, searchText) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  const activePersonsOptions = useMemo(() => activePersons.filter((person) => containsText(person.fullName, personSearchText)), [personSearchText]);

  const patientSelectStyles = makeSelectStyles();

  return (
    <FormControl fullWidth variant="outlined" size="small">
      {/* <InputLabel id="demo-simple-select-label">Patient</InputLabel> */}

      <Select
        MenuProps={{
          autoFocus: false,
          classes: { paper: patientSelectStyles.menuPaper },
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          getContentAnchorEl: null
        }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={person}
        onChange={(event) => {
          if (!event.target.value) return;
          setSelectedPerson(event.target.value as string);
          patientSelectionChanged(event.target.value as string);
        }}
        onClose={() => setPersonSearchText('')}
        inputProps={{ IconComponent: () => null }}
        displayEmpty
        className={error ? patientSelectStyles.selectError : patientSelectStyles.select}
      >
        <ListSubheader
          style={{ background: 'white' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onFocus={(e) => e.stopPropagation()}
        >
          <TextField
            size="small"
            // Autofocus on textfield
            margin="normal"
            autoFocus
            placeholder="Type to search..."
            fullWidth
            variant="outlined"
            className={patientSelectStyles.search}
            InputProps={{
              style: { fontSize: 14 },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            onChange={(e) => setPersonSearchText(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        <MenuItem disabled value="">
          <div style={{ height: 32, display: 'inline-flex' }}>
            <div style={{ margin: 'auto 8px' }}>
              <UserCircleIcon height={20} width={20} color="#0C2545"></UserCircleIcon>
            </div>
            <span className={style.patientSelectPlaceholderText}>Add patient</span>
          </div>
        </MenuItem>
        {activePersonsOptions.map((option, i) => (
          <MenuItem value={option.personId} key={option.personId} style={{ fontSize: 14 }}>
            <div className={style.avatar}>{getInitials(option.fullName)}</div>
            <span style={{ fontSize: 14, lineHeight: '32px' }}>{option.fullName}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
