import classNames from 'classnames';
import React from 'react';
import { ChatIcon } from 'components/Icons';
import { StatusLabel } from 'components/StatusLabel/StatusLabel';
import { Text } from 'components/Typography';
import { TaskMenu } from 'containers/Tasks/components/TaskMenu/TaskMenu';
import { TasksTableColName } from 'containers/Tasks/components/TasksTable/TasksTable.types';
import { TASK_LABEL_STATUSES } from 'containers/Tasks/Tasks.const';
import { TableCellValueType } from 'types/global';
import { getFormatedTimeIgnoreTimezone } from 'utils/time';
import { getDisplayValueOrDefault, getInitials } from 'utils/util';
import style from './TasksTableCell.scss';
import { TasksTableCellProp, ValueTextElementProps } from './TasksTableCell.types';

const ValueTextElement = ({ value = '', cellTextClassName, textVariant = 'sm' }: ValueTextElementProps) => {
  return (
    <Text variant={textVariant} className={classNames(style.cellText, cellTextClassName)}>
      {value}
    </Text>
  );
};

function getCell<T extends TableCellValueType>({ value, colName, avatarUrl, onSelectTask, onCancelTask, taskStatus }: TasksTableCellProp<T>) {
  const strValue = '' + value;

  switch (colName) {
    case TasksTableColName.PATIENT_NAME:
      return (
        <>
          <div className={style.avatar}>
            {avatarUrl ? (
              <img src={avatarUrl} alt="avatar" />
            ) : (
              <Text variant="xs" weight={500} className={style.initials}>
                {getInitials(strValue)}
              </Text>
            )}
          </div>
          <ValueTextElement value={strValue} />
        </>
      );
    case TasksTableColName.TASK_MESSAGE:
      return <ValueTextElement value={getDisplayValueOrDefault(value)} cellTextClassName={style.noWrapText} />;
    case TasksTableColName.PATIENT_RESPONSE:
      return (
        <div className={style.cellIconText}>
          <div className={style.iconBox}>
            <ChatIcon />
          </div>
          <ValueTextElement value={getDisplayValueOrDefault(value)} cellTextClassName={style.noWrapText} />
        </div>
      );
    case TasksTableColName.SCHEDULED_DATE: {
      const formatedTime = value ? getFormatedTimeIgnoreTimezone(value as string) : '';
      return <ValueTextElement value={getDisplayValueOrDefault(formatedTime)} />;
    }
    case TasksTableColName.STATUS: {
      const actualStatus = TASK_LABEL_STATUSES[value as string] || TASK_LABEL_STATUSES['Default'];
      return value && <StatusLabel label={actualStatus.label} status={actualStatus.type} />;
    }
    case TasksTableColName.DETAILS:
      return <TaskMenu onSelectTask={onSelectTask!} onCancelTask={onCancelTask!} status={taskStatus!} />;
    default:
      return <ValueTextElement value={getDisplayValueOrDefault(value)} />;
  }
}

export const TasksTableCell = <T extends TableCellValueType>(cellProps: TasksTableCellProp<T>) => {
  return (
    <td className={style.tableCell}>
      <div className={classNames(style.cellBox, cellProps.containerClassName)}>{getCell(cellProps)}</div>
    </td>
  );
};
