import { Container, MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { BreadCrumbData } from '@redux/common/types';
import { appendBreadcrumbAction } from '@redux/oauth/oauthActions';
import { getBreadcrumbsSelector } from '@redux/oauth/oauthSelectors';
import { ReduxState } from '@redux/types';
import { ContentTile } from '../../components/ContentTile/ContentTile';
import { Header } from '../../components/Header/Header';
import { defaultContentManagerCrumb, defaultContentManagerCrumbs } from '../../utils/breadcrumbs';
import { defaultTheme } from '../../utils/styles';
import { goTo } from '../../utils/util';
import style from './ContentManager.scss';

interface Props {
  breadCrumbs: BreadCrumbData[];
  appendBreadCrumbs: (breadCrumb: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => void;
}

export const ContentManager: React.FC<Props> = ({ breadCrumbs, appendBreadCrumbs }) => {
  const navigate = useNavigate();

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={style.root}>
        <Container maxWidth="xl">
          <Header
            breadCrumbs={breadCrumbs}
            title="Content"
            appendBreadCrumbs={appendBreadCrumbs}
            defaultCurrentCrumb={defaultContentManagerCrumb}
            defaultCrumbHistory={defaultContentManagerCrumbs}
          ></Header>
          <ContentTile title="By Track" subTitles={[{ text: 'Organize content placed in tracks' }]} onClick={goTo('/allTracks', navigate)} />
          <ContentTile title={'All Content'} subTitles={[{ text: 'Every piece of individual content' }]} onClick={goTo('/allContent', navigate)} />
        </Container>
      </div>
    </MuiThemeProvider>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      breadCrumbs: getBreadcrumbsSelector(state)
    };
  },
  (dispatch: Function) => ({
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => {
      dispatch(appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }));
    }
  })
);

export default compose(connectRedux)(ContentManager) as React.ComponentType;
