import React, { memo } from 'react';
import { CircledCustomIcon, MessagesIcon } from 'components/Icons';
import { Text } from 'components/Typography';
import style from './NoRequestSelectedArea.scss';

export const NoRequestSelectedArea: React.FC = memo(() => {
  return (
    <div className={style.emptyArea}>
      <div className={style.centeredContent}>
        <CircledCustomIcon containerStyle={style.centeredIconCircle} icon={MessagesIcon} />
        <Text variant="2xl">No request selected</Text>
      </div>
    </div>
  );
});
