import styled from '@emotion/styled';
import { Button } from '@mui/material';
import React from 'react';
import { SignOutIcon } from 'components/Icons';
import { getInitials } from 'utils/util';
import style from './SignOut.scss';

const StyledButton = styled(Button)({
  padding: '6px',
  minWidth: '32px',
  background: 'transparent',
  '&:hover': {
    background: 'transparent',
    opacity: 0.5
  }
});

interface Props {
  avatarUrl: string;
  personFullName: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const SignOut: React.FC<Props> = ({ avatarUrl, personFullName, onClick }) => (
  <div className={style.container}>
    <div className={style.subContainer}>
      <div className={style.avatar}>{avatarUrl ? <img src={avatarUrl} /> : getInitials(personFullName)}</div>
      {personFullName}
    </div>
    <StyledButton variant="text" onClick={onClick}>
      <SignOutIcon width={20} height={20} />
    </StyledButton>
  </div>
);

export default SignOut;
