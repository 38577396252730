import React from 'react';
import { VitalSignsStatus } from '@redux/person/personTypes';
import darkGreenDot from 'assets/images/darkGreenDot.png';
import grayDot from 'assets/images/grayDot.png';
import { DeviceIcon } from 'components/Icons';
import { TextVariant } from 'components/TextField/TextField';
import { Text } from 'components/Typography';
import { VitalSign } from 'components/VitalSign/VitalSign';
import { TableCellValueType } from 'types/global';
import { Color } from 'utils/color';
import { getPatientPresence, PatientPresenceType } from 'utils/patientPresence';
import { getElapsedTimeDisplay } from 'utils/time';
import { getDisplayValueOrDefault, getInitials, properCase } from 'utils/util';
import { PatientTableColName } from './PatientTableTypes';
import style from './TableCell.scss';

export type PatientTableCellProp<T extends TableCellValueType> = {
  value?: T;
  colName: PatientTableColName;
  avatarUrl?: string;
  modifiedDate?: string;
  status?: VitalSignsStatus;
};

type ValueTextElementProps = {
  value: string;
  cellTextClassName?: string;
  textVariant?: TextVariant;
};

const ValueTextElement = ({ value = '', cellTextClassName = style.cellText, textVariant = 'sm' }: ValueTextElementProps) => {
  return (
    <Text variant={textVariant} className={cellTextClassName}>
      {value}
    </Text>
  );
};

const getDeviceStatusStyleClassName = (value: string) => {
  const capitalized = value.toUpperCase();
  if (['ONLINE', 'HEALTHY'].includes(capitalized)) return { divClassName: style.deviceStatusActive, textClassName: style.cellTextDeviceStatusActive };
  if (capitalized === 'OFFLINE') return { divClassName: style.deviceStatusOffline, textClassName: style.cellTextDeviceStatusOffline };
  if (capitalized === 'ERROR') return { divClassName: style.deviceStatusError, textClassName: style.cellTextDeviceStatusError };
  return { divClassName: style.deviceStatusOffline, textClassName: style.cellTextDeviceStatusOffline };
};

function getCell<T extends TableCellValueType>({ value, colName, avatarUrl, modifiedDate, status }: PatientTableCellProp<T>) {
  const strValue = '' + value;
  const elapsedTime = modifiedDate ? getElapsedTimeDisplay(modifiedDate) : '';

  switch (colName) {
    case PatientTableColName.NAME:
      return (
        <>
          <div className={style.avatar}>
            {avatarUrl ? (
              <img src={avatarUrl} alt="avatar" />
            ) : (
              <Text variant="xs" weight={500} className={style.initials}>
                {getInitials(strValue)}
              </Text>
            )}
          </div>
          <ValueTextElement value={strValue} />
        </>
      );
    case PatientTableColName.HEART_RATE:
    case PatientTableColName.BREATHING_RATE:
    case PatientTableColName.SLEEP_DURATION:
      return <VitalSign status={status} textValue={getDisplayValueOrDefault(value)} line2TextValue={elapsedTime} />;
    case PatientTableColName.DEVICE_STATUS: {
      const { divClassName, textClassName } = getDeviceStatusStyleClassName(strValue);
      const deviceStatus: string = properCase((value ?? '') as string);
      return (
        <div className={divClassName}>
          <ValueTextElement value={getDisplayValueOrDefault(deviceStatus)} cellTextClassName={textClassName} />
        </div>
      );
    }
    case PatientTableColName.DEVICE_NUMBER: {
      return (
        <div className={style.deviceIdStatus}>
          {value && <DeviceIcon width={16} height={16} color={Color.DARK_BLUE_NEW} />}
          <ValueTextElement value={getDisplayValueOrDefault(value)} />
        </div>
      );
    }
    case PatientTableColName.PERSON_PRESENCE: {
      const patientPresence = value as PatientPresenceType;
      return (
        <div>
          <div className={style.patientPresenceStatus}>
            <img className={style.dotImg} src={patientPresence === PatientPresenceType.NEAR_THE_DEVICE ? darkGreenDot : grayDot} />
            <ValueTextElement value={patientPresence} />
          </div>
          {patientPresence !== PatientPresenceType.NEAR_THE_DEVICE && (
            <div className={style.patientPresenceTime}>
              <ValueTextElement value={elapsedTime} cellTextClassName={style.timeText} />
            </div>
          )}
        </div>
      );
    }
    case PatientTableColName.SLEEP_EFFICIENCY:
      return <ValueTextElement value={getDisplayValueOrDefault(value, '-', '%')} />;
    default:
      return <ValueTextElement value={getDisplayValueOrDefault(value)} />;
  }
}

const TableCell = <T extends TableCellValueType>(cellProps: PatientTableCellProp<T>) => {
  return (
    <td className={style.tableCell}>
      <div className={style.cellBox}>{getCell(cellProps)}</div>
    </td>
  );
};

export default TableCell;
