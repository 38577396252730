import React from 'react';
import { SVGBasicType } from 'types/global';
import { Color } from 'utils/color';

export const BedIcon = ({ width = 24, height = 24, color = Color.GRAY }: SVGBasicType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 6.75H2.25V4.5C2.25 4.30109 2.17098 4.11032 2.03033 3.96967C1.88968 3.82902 1.69891 3.75 1.5 3.75C1.30109 3.75 1.11032 3.82902 0.96967 3.96967C0.829018 4.11032 0.75 4.30109 0.75 4.5V19.5C0.75 19.6989 0.829018 19.8897 0.96967 20.0303C1.11032 20.171 1.30109 20.25 1.5 20.25C1.69891 20.25 1.88968 20.171 2.03033 20.0303C2.17098 19.8897 2.25 19.6989 2.25 19.5V16.5H21.75V19.5C21.75 19.6989 21.829 19.8897 21.9697 20.0303C22.1103 20.171 22.3011 20.25 22.5 20.25C22.6989 20.25 22.8897 20.171 23.0303 20.0303C23.171 19.8897 23.25 19.6989 23.25 19.5V10.5C23.25 9.50544 22.8549 8.55161 22.1516 7.84835C21.4484 7.14509 20.4946 6.75 19.5 6.75ZM2.25 8.25H9V15H2.25V8.25ZM10.5 15V8.25H19.5C20.0967 8.25 20.669 8.48705 21.091 8.90901C21.5129 9.33097 21.75 9.90326 21.75 10.5V15H10.5Z"
        fill={color}
      />
    </svg>
  );
};
