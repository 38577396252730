import { ReduxAction, ReduxActionState } from '@redux/types';

export const getActionStringWithoutState = (action: string): string => {
  if (action.includes('-')) {
    return action.substring(0, action.lastIndexOf('-'));
  }

  return action;
};

export const defaultActionStart = <T extends ReduxActionState>(state: T, action) => {
  const actionTag = getActionStringWithoutState(action.type);
  state.isLoading[actionTag] = true;
  delete state.error[actionTag];
};

export const defaultActionFail = <T extends ReduxActionState>(state: T, action) => {
  const actionTag = getActionStringWithoutState(action.type);
  state.isLoading[actionTag] = false;
  state.error[actionTag] = {
    message: action.payload?.message,
    status: action.payload.status
  };
};

export const setDefaultStart = <T extends ReduxActionState>(state: T, tag: string): void => {
  state.isLoading[getActionStringWithoutState(tag)] = true;
  delete state.error[tag];
  delete state.success[tag];
};

export const setDefaultFail = <T extends ReduxActionState>(state: T, action: ReduxAction): void => {
  delete state.isLoading[getActionStringWithoutState(action.type)];
  state.error[action.type] = {
    message: action.payload?.message,
    status: action.payload.status
  };
  state.success[action.type] = false;
};
