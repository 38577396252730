import Bugsnag from '@bugsnag/js';
import axios from 'axios';
import { stringify } from 'qs';
import createFrontEndError from 'utils/createFrontEndError';
import { isClient } from 'utils/util';
import Config from './config/config';
import { getLoginActions, getLogoutActions } from './redux/oauth/oauthActions';
import { getAccessTokenSelector, getRegisteredUserEmailSelector } from './redux/oauth/oauthSelectors';

export const HEADER_CSRF = 'x-csrf-token';

export const DEFAULT_API_TIMEOUT = 30000; //30 sec
export const API_CLIENT_BASE_URLS_MISSING = "Couldn't retrieve base urls to configure ApiClient";
const RETRY_WAIT_SECONDS = 10;
const MAX_NETWORK_WAIT_TIME_SECONDS = 30;

export class ApiError extends Error {
  data: any;
  constructor(message, data) {
    super(message);
    this.data = data;
  }
}

type ApiParams = {
  baseUrl?: Nullable<string>;
  headers?: Nullable<object>;
  auth?: Nullable<string | boolean>;
  params?: Nullable<any>;
  retries?: Nullable<number>;
  successAction?: Nullable<Function>;
  failAction?: Nullable<Function>;
  data?: Nullable<any>;
  suppressInternetErrorMessage?: Nullable<boolean>;
  timeout?: number; //external component does not support nullable types, so we can't apply Nullable<> type here
};

const standardHeaders: any = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

export const errorResponse = (status: number, err: any | string, dataJson?: Nullable<Object>): Object | unknown => {
  const message = err?.message || err;
  return {
    meta: {
      status: status
    },
    error: {
      message: message,
      data: dataJson
    }
  };
};

export default class ApiClient {
  private apiBaseUrl: string;
  private authBaseUrl: string;
  private store: any;
  private refreshAccessTokenPromise?: Nullable<Promise<any>>;
  private networkOnlinePromise?: Nullable<Promise<any>>;
  private csrfToken?: Nullable<string>;
  private static apiClient: ApiClient;
  private constructor() {
    this.apiBaseUrl = Config.getApiBaseUrl();
    this.authBaseUrl = Config.getAuthBaseUrl();
  }

  public static getInstance(): ApiClient {
    if (!ApiClient.apiClient) {
      ApiClient.apiClient = new ApiClient();
    }
    return ApiClient.apiClient;
  }

  setStore(store: any): void {
    this.store = store;
  }

  setCsrfToken(csrfToken: Nullable<string>): void {
    this.csrfToken = csrfToken;
  }
  getCsrfToken(): string {
    return this.csrfToken || '';
  }

  async get(path: string, apiParams: ApiParams): Promise<any> {
    return await this.fetchApi('get', path, apiParams);
  }

  public async post(path: string, apiParams: ApiParams): Promise<any> {
    return await this.fetchApi('post', path, apiParams);
  }

  async put(path: string, apiParams: ApiParams): Promise<any> {
    return await this.fetchApi('put', path, apiParams);
  }

  async patch(path: string, apiParams: ApiParams): Promise<any> {
    return await this.fetchApi('patch', path, apiParams);
  }

  async delete(path: string, apiParams: ApiParams): Promise<any> {
    return await this.fetchApi('delete', path, apiParams);
  }

  hasNetworkConnection(): boolean {
    if (!isClient()) {
      return true;
    }
    return window?.navigator?.onLine !== false;
  }

  async fetchApi(method: string, path: string, apiParams: ApiParams): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const { baseUrl, headers, auth, params, retries, data, successAction, failAction, suppressInternetErrorMessage, timeout = DEFAULT_API_TIMEOUT } = apiParams;
    const requestBaseUrl = baseUrl || this.apiBaseUrl;

    const url = path.startsWith('/') ? path : '/' + path;

    let retryCount = retries || 0;
    let networkWaitTime = 0;

    const getRefreshAccessTokenPromise = () => {
      if (!this.refreshAccessTokenPromise) {
        this.refreshAccessTokenPromise = this.refreshAccessToken(failAction);
        return this.refreshAccessTokenPromise;
      } else {
        return this.refreshAccessTokenPromise;
      }
    };
    const handleTokenRefresh = async (retryFetch): Promise<any> => {
      console.log('refreshing access token');
      if (!isClient()) {
        throw new Error('ApiClient: Refresh access token only supported on client');
      }
      const tokenRefreshPromise = getRefreshAccessTokenPromise();
      if (tokenRefreshPromise) {
        console.log('refreshing access token: promis exists');
        try {
          const response = await tokenRefreshPromise;
          console.log('refreshing access token: promise resolved, retrying fetch');
          return retryFetch();
        } catch (error: any) {
          console.log(error);
          throw new Error(error);
        }
      } else {
        throw new Error('ApiClient: Refresh access token only supported on client');
      }
    };

    function sleep(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    const waitForNetwork = async (): Promise<any> => {
      while (!this.hasNetworkConnection() && networkWaitTime < MAX_NETWORK_WAIT_TIME_SECONDS) {
        await sleep(1000);
        networkWaitTime += 1;
      }
      if (networkWaitTime >= MAX_NETWORK_WAIT_TIME_SECONDS) {
        throw new Error();
      }
      this.networkOnlinePromise = undefined;
    };

    const getNetworkOnlinePromise = (): Promise<any> => {
      if (!this.networkOnlinePromise) {
        this.networkOnlinePromise = waitForNetwork();
      }
      return this.networkOnlinePromise;
    };

    const handleWaitForNetworkOnline = async (retryFetch: Function): Promise<any> => {
      const waitNetworkPromise = getNetworkOnlinePromise();
      if (waitNetworkPromise) {
        try {
          await waitNetworkPromise;
          return retryFetch();
        } catch (error) {
          throw new Error('No Network connection');
        }
      } else {
        throw new Error('ApiClient: Refresh access token only supported on client');
      }
    };

    const networkRetryOrError = async (networkStatus: number, retryFetch: Function): Promise<any> => {
      // retry anytime the network status is equal or larger than 500
      // this also includes cases where the server is not reachable, since we treat this as 503
      if (networkStatus > 500 && retryCount) {
        console.log('retries left: ' + retryCount);
        retryCount--;
        if (!this.hasNetworkConnection()) {
          return handleWaitForNetworkOnline(retryFetch);
        } else {
          await sleep(RETRY_WAIT_SECONDS * 1000);
          await retryFetch();
          return;
        }
      } else {
        // error cannot be retried, giving up
        throw new Error();
      }
    };

    const showNetworkOfflineAlert = () => {
      alert('No Internet Connection! Check your network settings and try again.');
      // ToDo: add modal error handling
    };

    const doFetch = async (): Promise<any> => {
      if (!this.hasNetworkConnection()) {
        try {
          await handleWaitForNetworkOnline(doFetch);
          return;
        } catch (error) {
          const connected = this.hasNetworkConnection();
          const frontEndError = createFrontEndError(error, connected);
          if (!suppressInternetErrorMessage && !connected) {
            showNetworkOfflineAlert();
          }
          if (failAction) {
            self.store.dispatch(failAction(frontEndError));
          }
          throw new ApiError(error, frontEndError);
        }
      }
      if (auth) {
        if (auth === true) {
          let accessToken = getAccessTokenSelector(this.store.getState());
          if (!accessToken) {
            console.log('params:', params);
            try {
              return await handleTokenRefresh(doFetch);
            } catch (error: any) {
              console.log('this is the error');
              console.log(error);
              throw new Error(error);
            }
          }
          standardHeaders.Authorization = accessToken;
        } else {
          standardHeaders.Authorization = auth;
        }
      }
      const header = Object.assign({}, standardHeaders, headers);

      // only attach the csrf token to requests sent to our webserver
      if (requestBaseUrl === Config.getAuthBaseUrl() && self.csrfToken) {
        header[HEADER_CSRF] = self.csrfToken;
      }

      const request: any = {
        method: method,
        headers: header,
        credentials: 'same-origin',
        url: url,
        data: data,
        params: params,
        paramsSerializer: { serialize: (params) => stringify(params, { arrayFormat: 'repeat' }) }
      };

      const axiosInstance = axios.create({
        baseURL: requestBaseUrl,
        timeout: timeout,
        headers: header
      });
      try {
        const response = await axiosInstance.request(request);
        const csrfToken = response.headers[HEADER_CSRF];
        if (csrfToken) {
          self.csrfToken = csrfToken;
        }

        if (successAction) {
          if (response?.data?.data) {
            self.store.dispatch(successAction(response.data.data));
          } else {
            self.store.dispatch(successAction(null));
            const responseJS = {
              response: { ...response },
              data: { ...response?.data },
              dataData: { ...response?.data?.data },
              dataJS: JSON.stringify(response?.data)
            };
            Bugsnag.leaveBreadcrumb('fetch: no data in response', responseJS);
          }
        }
        return response.data;
      } catch (error: any) {
        console.log(error);
        const email = getRegisteredUserEmailSelector(this.store.getState());
        console.log(email);
        // check for retries or network outage
        try {
          const status = error?.response?.status || 503;
          await networkRetryOrError(status, doFetch);
        } catch (errorRetry) {
          const connected = this.hasNetworkConnection();
          const frontEndError = createFrontEndError(error, connected);
          if (!suppressInternetErrorMessage && !connected) {
            showNetworkOfflineAlert();
          }
          if (failAction) {
            self.store.dispatch(failAction(frontEndError));
          }

          throw new ApiError(error, frontEndError);
        }
      }
    };
    return doFetch();
  }

  async refreshAccessToken(failAction?: Nullable<Function>): Promise<string> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const request: any = {
      method: 'post',
      headers: standardHeaders,
      credentials: 'same-origin',
      url: '/api/oauth/refresh'
    };
    const axiosInstance = axios.create({
      baseURL: this.authBaseUrl,
      timeout: DEFAULT_API_TIMEOUT,
      headers: standardHeaders
    });
    try {
      console.log('refreshing access token function');
      const response = await axiosInstance.request(request);
      console.log('refreshing access token complete: response received', response);
      console.log('refreshing access token complete: response received', response?.data, response?.data?.data);
      if (response?.data?.data) {
        self.store.dispatch(getLoginActions.success(response.data.data));
      } else {
        const responseJS = {
          response: { ...response },
          data: { ...response?.data },
          dataData: { ...response?.data?.data },
          dataJS: JSON.stringify(response?.data)
        };
        Bugsnag.leaveBreadcrumb('refreshAccessToken: no data in response', responseJS);
      }
      this.refreshAccessTokenPromise = undefined;
      return response.data.data.access_token;
    } catch (error: any) {
      console.log('caught in refreshAccessToken');
      console.log('error', error);
      console.log('error.response', error?.response);
      console.log('error.response.data', error?.response?.data);
      console.log('error.data', error?.data);
      self.store.dispatch(getLogoutActions.success());
      if (error?.response?.data?.data) {
        if (failAction) {
          self.store.dispatch(failAction(error.response.data));
          throw new Error(error.response.data);
        }
      } else {
        if (failAction) {
          self.store.dispatch(failAction(null));
        }
        const responseJS = {
          response: { ...error?.response },
          data: { ...error?.response?.data },
          dataData: { ...error?.response?.data?.data },
          dataJS: JSON.stringify(error?.response?.data)
        };
        Bugsnag.leaveBreadcrumb('refresh accessToken catch: no data in response', responseJS);
      }

      throw new Error(error);
    }
  }
}
