import React, { memo } from 'react';
import { TableHeader } from 'components/TableHeader/TableHeader';
import { TasksTableCell } from '../TasksTableCell/TasksTableCell';
import style from './TasksTable.scss';
import { TasksTableColName, TasksTableProps } from './TasksTable.types';

export const TasksTable: React.FC<TasksTableProps> = memo(({ data, onSelectTaskSwitch, onCancelTask }) => {
  return (
    <table className={style.patientsTable}>
      <thead>
        <tr className={style.tableRow}>
          <TableHeader containerClassName={style.nameColumn}>Patient</TableHeader>
          <TableHeader containerClassName={style.taskMessageColumn}>Task message</TableHeader>
          <TableHeader containerClassName={style.patientMessageColumn}>Patient response</TableHeader>
          <TableHeader containerClassName={style.dateColumn}>Scheduled for</TableHeader>
          <TableHeader containerClassName={style.statusColumn}>Status</TableHeader>
          <TableHeader containerClassName={style.detailsColumn}>{''}</TableHeader>
        </tr>
      </thead>
      <tbody>
        {data.map(({ taskId, avatar, taskMessage, patientResponse, scheduledForDateTime, patientFullName, status }) => {
          const onSelect = () => {
            onSelectTaskSwitch(taskId);
          };

          const onCancel = () => {
            onCancelTask(taskId);
          };

          return (
            <tr key={taskId} onClick={onSelect} className={style.tableRow}>
              <TasksTableCell containerClassName={style.nameColumn} colName={TasksTableColName.PATIENT_NAME} value={patientFullName} avatarUrl={avatar} />
              <TasksTableCell containerClassName={style.taskMessageColumn} colName={TasksTableColName.TASK_MESSAGE} value={taskMessage} />
              <TasksTableCell containerClassName={style.patientMessageColumn} colName={TasksTableColName.PATIENT_RESPONSE} value={patientResponse} />
              <TasksTableCell containerClassName={style.dateColumn} colName={TasksTableColName.SCHEDULED_DATE} value={scheduledForDateTime} />
              <TasksTableCell containerClassName={style.statusColumn} colName={TasksTableColName.STATUS} value={status} />
              <TasksTableCell
                containerClassName={style.detailsColumn}
                colName={TasksTableColName.DETAILS}
                value={taskId}
                onSelectTask={onSelect}
                onCancelTask={onCancel}
                taskStatus={status}
              />
            </tr>
          );
        })}
      </tbody>
    </table>
  );
});
