import { Grid } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { VitalSignsStatus } from '@redux/person/personTypes';
import { Text, TextVariant } from 'components/Typography';
import { VitalSign } from 'components/VitalSign/VitalSign';
import style from './GridRow.scss';

export type GridRowProps = {
  label: string;
  metricValue: number | string;
  metricStatus?: VitalSignsStatus;
  normalControlValue: number | string;
  abnormalControlValue?: number | string;
  suboptimalControlValue?: number | string;
  textVariant?: TextVariant;
  rowHeaderClassName?: string;
  rowValueTextClassName?: string;
  rowValueContainerClassName?: string;
  rowClassName?: string;
  isHeaderRow?: boolean;
  hasRowLine?: boolean;
  alignCenter?: boolean;
};

export const GridRow: React.FC<GridRowProps> = ({
  label,
  metricValue,
  metricStatus,
  normalControlValue,
  abnormalControlValue,
  suboptimalControlValue,
  textVariant = 'sm',
  rowHeaderClassName,
  rowValueTextClassName,
  rowValueContainerClassName,
  rowClassName,
  isHeaderRow = false,
  hasRowLine = true,
  alignCenter = false
}) => {
  return (
    <Grid container className={classNames(style.rowContainer, !isHeaderRow && hasRowLine && style.rowLine, rowClassName)}>
      <Grid item xs={4} className={alignCenter ? style.alignCenterText : ''}>
        <Text variant={textVariant} className={classNames(style.defaultGrayText, rowHeaderClassName)}>
          {label}
        </Text>
      </Grid>
      {[metricValue, normalControlValue].map((controlValue, index) => (
        <Grid item xs={4} key={index} className={classNames(style.valueContainer, rowValueContainerClassName)}>
          <VitalSign
            status={index === 0 ? metricStatus : undefined}
            textValue={controlValue}
            textVariant={textVariant}
            textClassName={classNames(isHeaderRow && style.defaultGrayText, rowValueTextClassName)}
          />
        </Grid>
      ))}
    </Grid>
  );
};
