import React from 'react';
import { SVGBasicType } from 'types/global';
import { Color } from 'utils/color';

export const SingleCheckmarkIcon = ({ width = 16, height = 16, color = Color.DARK_BLUE }: SVGBasicType) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3535 4.85403L6.35354 12.854C6.3071 12.9005 6.25196 12.9374 6.19126 12.9626C6.13056 12.9877 6.0655 13.0007 5.99979 13.0007C5.93408 13.0007 5.86902 12.9877 5.80832 12.9626C5.74762 12.9374 5.69248 12.9005 5.64604 12.854L2.14604 9.35403C2.05222 9.26021 1.99951 9.13296 1.99951 9.00028C1.99951 8.8676 2.05222 8.74035 2.14604 8.64653C2.23986 8.55271 2.36711 8.5 2.49979 8.5C2.63247 8.5 2.75972 8.55271 2.85354 8.64653L5.99979 11.7934L13.646 4.14653C13.7399 4.05271 13.8671 4 13.9998 4C14.1325 4 14.2597 4.05271 14.3535 4.14653C14.4474 4.24035 14.5001 4.3676 14.5001 4.50028C14.5001 4.63296 14.4474 4.76021 14.3535 4.85403Z"
      fill={color}
    />
  </svg>
);
