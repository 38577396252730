import { EnvType } from 'utils/environment';
import { DEFAULT_TIMEZONE_ID } from 'utils/time';
import { ErrorPayload } from '../common/types';
import { getAccessTokenSelector } from '../oauth/oauthSelectors';
import { GetDeviceTimezoneThunkParams, ThunkParams } from '../types';
import {
  getDeviceCancelUpdateActions,
  getDeviceCheckStatusActions,
  getDeviceConectivityActions,
  getDeviceFirmwareVersionOverviewActions,
  getDeviceFirmwareVersionsRecentActions,
  getDevicePersonOverviewActions,
  getDeviceProvisioningActions,
  getDeviceProvisioningEnvMoveOutActions,
  getDeviceProvisioningEnvUpdateActions,
  getDeviceProvisioningOverviewActions,
  getDeviceRebootActions,
  getDeviceRestartAppActions,
  getDeviceRetireActions,
  getDeviceStateOverviewActions,
  getDeviceUpdateActions,
  getDeviceUpdateFirmwareVersionActions,
  getDeviceVersionHistoryActions,
  getHwRevisionsAndFirmwareMappingsActions,
  getSaveDeviceDataCollectionActions,
  getSensorDeviceActions,
  getUpdateDeviceConfigActions,
  getUpdateRemoteCommandPortActions,
  postUnlinkDeviceActions,
  putAutoUpdateActions,
  putConnectivityNotificationActions,
  putProvisioningDeviceNumberActions,
  resetDeviceActions
} from './deviceActions';
import { getDeviceTimeZoneIdSelector } from './deviceSelectors';
import {
  DeviceConfig,
  DeviceConnectivity,
  DeviceDataCollection,
  DeviceEnvironmentPayload,
  DeviceNumberPayload,
  DeviceVersion,
  FirmwareVersion,
  GetDeviceFirmwareVersionPayload,
  GetDeviceFirmwareVersionsRecentPayload,
  GetDevicePersonPayload,
  GetDeviceProvisioningPayload,
  GetDeviceStatePayload,
  HwRevisionFirmware,
  SensorDevice,
  UpdateRemoteCommandPortSuccessPayload,
  DeviceRequest
} from './deviceTypes';

function processGetDeviceStateOverviewSuccess(payload: GetDeviceStatePayload): Function {
  return function (dispatch: Function): void {
    dispatch(getDeviceStateOverviewActions.success(payload));
  };
}
function processGetDeviceStateOverviewFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDeviceStateOverviewActions.fail(error));
  };
}

export function getDeviceStateOverviewThunk() {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getDeviceStateOverviewActions.start());
    return apiClient.get('/v1/device/state/all', {
      auth: true,
      successAction: processGetDeviceStateOverviewSuccess,
      failAction: processGetDeviceStateOverviewFail
    });
  };
}

function processGetDeviceProvisioningOverviewSuccess(payload: GetDeviceProvisioningPayload): Function {
  return function (dispatch: Function): void {
    dispatch(getDeviceProvisioningOverviewActions.success(payload));
  };
}
function processGetDeviceProvisioningOverviewFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDeviceProvisioningOverviewActions.fail(error));
  };
}
export function getDeviceProvisioningOverviewThunk(): Function {
  return function (dispatch: Function, getProvisioning: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getDeviceProvisioningOverviewActions.start());
    return apiClient.get('/v1/device/provision/all', {
      auth: true,
      successAction: processGetDeviceProvisioningOverviewSuccess,
      failAction: processGetDeviceProvisioningOverviewFail
    });
  };
}

function processPutProvisioningDeviceNumberSuccess(payload: DeviceNumberPayload): Function {
  return function (dispatch: Function): void {
    dispatch(putProvisioningDeviceNumberActions.success(payload));
  };
}
function processPutProvisioningDeviceNumberFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(putProvisioningDeviceNumberActions.fail(error));
  };
}
export function putProvisioningDeviceNumberThunk(deviceId: string, deviceNumber?: Nullable<number>): Function {
  return function (dispatch: Function, getProvisioning: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(putProvisioningDeviceNumberActions.start());
    return apiClient.put('/v1/device/provision/deviceNumber', {
      auth: true,
      params: {
        d: deviceId,
        'device-number': deviceNumber
      },
      successAction: () => processPutProvisioningDeviceNumberSuccess({ deviceId, deviceNumber }),
      failAction: processPutProvisioningDeviceNumberFail
    });
  };
}

function processGetDevicePersonOverviewSuccess(payload: GetDevicePersonPayload): Function {
  return function (dispatch: Function): void {
    dispatch(getDevicePersonOverviewActions.success(payload));
  };
}
function processGetDevicePersonOverviewFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDevicePersonOverviewActions.fail(error));
  };
}
export function getDevicePersonOverviewThunk() {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getDevicePersonOverviewActions.start());
    return apiClient.get('/v1/device/devicePerson/all', {
      auth: true,
      successAction: processGetDevicePersonOverviewSuccess,
      failAction: processGetDevicePersonOverviewFail
    });
  };
}

export function getDeviceUserTimezoneThunk({ userId, setTimezone }: GetDeviceTimezoneThunkParams) {
  return async function (dispatch: Function, getState: Function) {
    await dispatch(getDevicePersonOverviewThunk());
    const timezone = userId ? getDeviceTimeZoneIdSelector(userId, getState()) : null;
    setTimezone?.(timezone || DEFAULT_TIMEZONE_ID);
  };
}

function processGetDeviceFirmwareVersionOverviewSuccess(payload: GetDeviceFirmwareVersionPayload): Function {
  return function (dispatch: Function): void {
    dispatch(getDeviceFirmwareVersionOverviewActions.success(payload));
  };
}
function processGetDeviceFirmwareVersionOverviewFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDeviceFirmwareVersionOverviewActions.fail(error));
  };
}
export function getDeviceFirmwareVersionOverviewThunk(): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getDeviceFirmwareVersionOverviewActions.start());
    return apiClient.get('/v1/device/firmwareVersion/all', {
      auth: true,
      successAction: processGetDeviceFirmwareVersionOverviewSuccess,
      failAction: processGetDeviceFirmwareVersionOverviewFail
    });
  };
}

function processGetDeviceFirmwareVersionsRecentSuccess(payload: GetDeviceFirmwareVersionsRecentPayload): Function {
  return function (dispatch: Function): void {
    dispatch(getDeviceFirmwareVersionsRecentActions.success(payload));
  };
}
function processGetDeviceFirmwareVersionsRecentFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDeviceFirmwareVersionsRecentActions.fail(error));
  };
}
export function getDeviceFirmwareVersionsRecentThunk(): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getDeviceFirmwareVersionsRecentActions.start());
    return apiClient.get('/v1/device/firmwareVersion/recent', {
      auth: true,
      params: {
        'released-limit': 10,
        'beta-released-limit': 10
      },
      successAction: processGetDeviceFirmwareVersionsRecentSuccess,
      failAction: processGetDeviceFirmwareVersionsRecentFail
    });
  };
}

function processCheckStatusSuccess(): Function {
  return function (dispatch: Function): void {
    dispatch(getDeviceCheckStatusActions.success());
  };
}
function processCheckStatusFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDeviceCheckStatusActions.fail(error));
  };
}
export function checkDeviceStatusThunk(deviceId: string): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getDeviceCheckStatusActions.start());
    return apiClient.post(`/v1/device/${deviceId}/requestStatus`, {
      auth: true,
      successAction: processCheckStatusSuccess,
      failAction: processCheckStatusFail
    });
  };
}

function processRebootDeviceSuccess(): Function {
  return function (dispatch: Function): void {
    dispatch(getDeviceRebootActions.success());
  };
}
function processRebootDeviceFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDeviceRebootActions.fail(error));
  };
}
export function rebootDeviceThunk(deviceId: string): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getDeviceRebootActions.start());
    return apiClient.post(`/v1/device/${deviceId}/reboot`, {
      auth: true,
      successAction: processRebootDeviceSuccess,
      failAction: processRebootDeviceFail
    });
  };
}

function processRestartAppSuccess(): Function {
  return function (dispatch: Function): void {
    dispatch(getDeviceRestartAppActions.success());
  };
}
function processRestartAppFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDeviceRestartAppActions.fail(error));
  };
}
export function restartAppThunk(deviceId: string): Function {
  // restart the app on the person's koko device
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getDeviceRestartAppActions.start());
    return apiClient.post(`/v1/device/${deviceId}/restartApp`, {
      auth: true,
      successAction: processRestartAppSuccess,
      failAction: processRestartAppFail
    });
  };
}

function processUpdateDeviceSuccess(): Function {
  return function (dispatch: Function): void {
    dispatch(getDeviceUpdateActions.success());
  };
}
function processUpdateDeviceFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDeviceUpdateActions.fail(error));
  };
}
export function updateDeviceThunk(deviceId: string, osTreeRefSpec: string, isAutoUpdateDisabled: boolean): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getDeviceUpdateActions.start());
    return apiClient.post(`/v1/device/${deviceId}/update`, {
      auth: true,
      params: {
        refspec: osTreeRefSpec,
        'autoupdate-disabled': isAutoUpdateDisabled
      },
      successAction: processUpdateDeviceSuccess,
      failAction: processUpdateDeviceFail
    });
  };
}

function processCancelUpdateDeviceSuccess(): Function {
  return function (dispatch: Function): void {
    dispatch(getDeviceCancelUpdateActions.success());
  };
}
function processCancelUpdateDeviceFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDeviceCancelUpdateActions.fail(error));
  };
}
export function cancelUpdateDeviceThunk(deviceId: string, osTreeRefSpec: string): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getDeviceCancelUpdateActions.start());
    return apiClient.delete(`/v1/device/${deviceId}/update`, {
      auth: true,
      params: { refspec: osTreeRefSpec },
      successAction: processCancelUpdateDeviceSuccess,
      failAction: processCancelUpdateDeviceFail
    });
  };
}

function processUpdateFirmwareVersionSuccess(): Function {
  return function (dispatch: Function): void {
    dispatch(getDeviceUpdateFirmwareVersionActions.success());
  };
}
function processUpdateFirmwareVersionFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDeviceUpdateFirmwareVersionActions.fail(error));
  };
}
export function updateFirmwareVersionThunk(firmwareVersion: FirmwareVersion): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getDeviceUpdateFirmwareVersionActions.start());
    return apiClient.post(`/v1/device/firmwareVersion`, {
      auth: true,
      data: firmwareVersion,
      successAction: processUpdateFirmwareVersionSuccess,
      failAction: processUpdateFirmwareVersionFail
    });
  };
}

function processProvisioningDeviceSuccess(): Function {
  return function (dispatch: Function): void {
    dispatch(getDeviceProvisioningActions.start());
  };
}
function processProvisioningDeviceFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDeviceProvisioningActions.fail(error));
  };
}
export function provisionDeviceThunk(device: DeviceRequest, baseUrl: string, serialNumber: string, authToken?: Nullable<string>): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    const accessToken = authToken || getAccessTokenSelector(getState());
    dispatch(getDeviceProvisioningActions.start());
    return apiClient.post(`/v1/device/provision`, {
      auth: accessToken,
      baseUrl,
      data: device,
      params: { 'serial-number': serialNumber },
      successAction: processProvisioningDeviceSuccess,
      failAction: processProvisioningDeviceFail
    });
  };
}

function processProvisioningEnvMoveOutSuccess(): Function {
  return function (dispatch: Function): void {
    dispatch(getDeviceProvisioningEnvMoveOutActions.success());
  };
}
function processProvisioningEnvMoveOutFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDeviceProvisioningEnvMoveOutActions.fail(error));
  };
}
export function provisioningEnvMoveOutThunk(deviceId: string, baseUrl: string, authToken?: Nullable<string>): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getDeviceProvisioningEnvMoveOutActions.start());
    const accessToken = authToken || getAccessTokenSelector(getState());
    return apiClient.post(`/v1/device/${deviceId}/moveOut`, {
      auth: accessToken,
      baseUrl,
      successAction: processProvisioningEnvMoveOutSuccess,
      failAction: processProvisioningEnvMoveOutFail
    });
  };
}

function processProvisioningEnvUpdateSuccess(payload: DeviceEnvironmentPayload): Function {
  return function (dispatch: Function): void {
    dispatch(getDeviceProvisioningEnvUpdateActions.success(payload));
  };
}
function processProvisioningEnvUpdateFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDeviceProvisioningEnvUpdateActions.fail(error));
  };
}
export function provisioningEnvUpdateThunk(deviceId: string, baseUrl: string, environmentType: EnvType): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getDeviceProvisioningEnvUpdateActions.start());
    return apiClient.post(`/v1/device/${deviceId}/deploymentEnvironment`, {
      auth: true,
      baseUrl,
      params: { 'deployment-environment': environmentType },
      successAction: () => processProvisioningEnvUpdateSuccess({ deviceId, environmentType }),
      failAction: processProvisioningEnvUpdateFail
    });
  };
}

function processRetireSuccess(): Function {
  return function (dispatch: Function): void {
    dispatch(getDeviceRetireActions.success());
  };
}
function processRetireFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDeviceRetireActions.fail(error));
  };
}
export function retireDeviceThunk(deviceId: string): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getDeviceRetireActions.start());
    return apiClient.post(`/v1/device/${deviceId}/retire`, {
      auth: true,
      successAction: processRetireSuccess,
      failAction: processRetireFail
    });
  };
}

function processUpdateRemoteCommandPortSuccess(deviceId: string, port: number): Function {
  return function (dispatch: Function): void {
    const payload: UpdateRemoteCommandPortSuccessPayload = {
      deviceId,
      port
    };
    dispatch(getUpdateRemoteCommandPortActions.success(payload));
  };
}
function processUpdateRemoteCommandPortFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getUpdateRemoteCommandPortActions.fail(error));
  };
}
export function updateRemoteCommandPortThunk(deviceId: string, port: number): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getUpdateRemoteCommandPortActions.start());
    return apiClient.put(`/v1/device/${deviceId}/remoteCommand/port`, {
      auth: true,
      params: { port },
      successAction: () => processUpdateRemoteCommandPortSuccess(deviceId, port),
      failAction: processUpdateRemoteCommandPortFail
    });
  };
}

function processSaveDeviceDataCollectionSuccess(deviceDataCollection: DeviceDataCollection): Function {
  return function (dispatch: Function): void {
    dispatch(getSaveDeviceDataCollectionActions.success(deviceDataCollection));
  };
}
function processSaveDeviceDataCollectionFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getSaveDeviceDataCollectionActions.fail(error));
  };
}
export function saveDeviceDataCollectionThunk(deviceDataCollection: DeviceDataCollection): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getSaveDeviceDataCollectionActions.start());
    return apiClient.post(`/v1/device/deviceDataCollection`, {
      auth: true,
      data: deviceDataCollection,
      successAction: () => processSaveDeviceDataCollectionSuccess(deviceDataCollection),
      failAction: processSaveDeviceDataCollectionFail
    });
  };
}

function processUpdateDeviceConfigSuccess(deviceConfig: DeviceConfig): Function {
  return function (dispatch: Function): void {
    dispatch(getUpdateDeviceConfigActions.success(deviceConfig));
  };
}
function processUpdateDeviceConfigFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getUpdateDeviceConfigActions.fail(error));
  };
}
export function updateDeviceConfigThunk(deviceConfig: DeviceConfig): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getUpdateDeviceConfigActions.start());
    return apiClient.post(`/v1/device/${deviceConfig.id}/deviceConfig`, {
      auth: true,
      data: deviceConfig,
      successAction: () => processUpdateDeviceConfigSuccess(deviceConfig),
      failAction: processUpdateDeviceConfigFail
    });
  };
}

function processResetDeviceSuccess(deviceId: string): Function {
  return function (dispatch: Function): void {
    dispatch(resetDeviceActions.success(deviceId));
  };
}
function processResetDeviceFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(resetDeviceActions.fail(error));
  };
}
export function resetDevice(deviceId: string): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(resetDeviceActions.start());
    return apiClient.post(`/v1/device/${deviceId}/factoryReset`, {
      auth: true,
      successAction: () => processResetDeviceSuccess(deviceId),
      failAction: processResetDeviceFail
    });
  };
}

function processGetConnectivityValuesByRangeSuccess(payload: DeviceConnectivity[]): Function {
  return function (dispatch: Function): void {
    dispatch(getDeviceConectivityActions.success(payload));
  };
}
function processGetConnectivityValuesByRangeFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDeviceConectivityActions.fail(error));
  };
}
export function getConnectivityValuesByRangeThunk(deviceId: string, startTs: number, endTs: number): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getDeviceConectivityActions.start());
    return apiClient.get(`/v1/device/${deviceId}/connectivity/byRange`, {
      params: {
        'start-ts': startTs,
        'end-ts': endTs
      },
      auth: true,
      successAction: processGetConnectivityValuesByRangeSuccess,
      failAction: processGetConnectivityValuesByRangeFail
    });
  };
}

function processPutAutoUpdateSuccess(deviceId: string, autoUpdateDisabled: boolean): Function {
  return function (dispatch: Function): void {
    dispatch(putAutoUpdateActions.success({ deviceId, autoUpdateDisabled }));
  };
}
function processPutAutoUpdateFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(putAutoUpdateActions.fail(error));
  };
}
export function putAutoUpdateThunk(deviceId: string, autoUpdateDisabled?: Nullable<boolean>): Function {
  return function (dispatch: Function, getProvisioning: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(putAutoUpdateActions.start());
    return apiClient.put(`/v1/device/${deviceId}/autoUpdate`, {
      auth: true,
      params: {
        'autoupdate-disabled': autoUpdateDisabled
      },
      successAction: () => processPutAutoUpdateSuccess(deviceId, autoUpdateDisabled || false),
      failAction: processPutAutoUpdateFail
    });
  };
}

function processPutConnectivityNotificationSuccess(deviceId: string, connectivityNotification: boolean): Function {
  return function (dispatch: Function): void {
    dispatch(putConnectivityNotificationActions.success({ deviceId, connectivityNotification }));
  };
}
function processPutConnectivityNotificationFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(putConnectivityNotificationActions.fail(error));
  };
}
export function putConnectivityNotificationThunk(deviceId: string, connectivityNotification?: Nullable<boolean>): Function {
  return function (dispatch: Function, getProvisioning: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(putConnectivityNotificationActions.start());
    return apiClient.put(`/v1/device/${deviceId}/connectivityNotification`, {
      auth: true,
      params: {
        'connectivity-notification': connectivityNotification
      },
      successAction: () => processPutConnectivityNotificationSuccess(deviceId, connectivityNotification || false),
      failAction: processPutConnectivityNotificationFail
    });
  };
}

function processPostUnlinkDeviceSuccess(deviceId: string, personId: string): Function {
  return function (dispatch: Function): void {
    dispatch(postUnlinkDeviceActions.success({ deviceId, personId }));
  };
}
function processPostUnlinkDeviceFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(postUnlinkDeviceActions.fail(error));
  };
}
export function postUnlinkDeviceThunk(deviceId: string, personId: string, sensorId: string): Function {
  return function (dispatch: Function, getProvisioning: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(postUnlinkDeviceActions.start());
    return apiClient.post(`/v1/device/${deviceId}/unlink`, {
      auth: true,
      params: {
        'sensor-id': sensorId
      },
      successAction: () => processPostUnlinkDeviceSuccess(deviceId, personId),
      failAction: processPostUnlinkDeviceFail
    });
  };
}

function processGetDeviceVersionHistorySuccess(deviceVersions: DeviceVersion[]): Function {
  return function (dispatch: Function): void {
    dispatch(getDeviceVersionHistoryActions.success(deviceVersions));
  };
}
function processGetDeviceVersionHistoryFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDeviceVersionHistoryActions.fail(error));
  };
}
export function getDeviceVersionHistoryThunk(deviceId: string, startTs: number, endTs?: Nullable<number>): Function {
  return function (dispatch: Function, getProvisioning: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getDeviceVersionHistoryActions.start());
    return apiClient.get(`/v1/device/${deviceId}/versionHistory`, {
      auth: true,
      params: {
        'start-ts': startTs,
        'end-ts': endTs
      },
      successAction: processGetDeviceVersionHistorySuccess,
      failAction: processGetDeviceVersionHistoryFail
    });
  };
}

function processGetSensorDeviceSuccess(sensorDevice: SensorDevice): Function {
  return function (dispatch: Function): void {
    dispatch(getSensorDeviceActions.success(sensorDevice));
  };
}
function processGetSensorDeviceFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getSensorDeviceActions.fail(error));
  };
}
export function getSensorDeviceThunk(deviceId: string, baseUrl: string, authToken?: Nullable<string>): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    const accessToken = authToken || getAccessTokenSelector(getState());
    dispatch(getSensorDeviceActions.start());
    return apiClient.get(`/v1/device/${deviceId}/sensorDevice`, {
      auth: accessToken,
      baseUrl,
      successAction: processGetSensorDeviceSuccess,
      failAction: processGetSensorDeviceFail
    });
  };
}

function processGetHwRevisionsAndFirmwareMappingsSuccess(hwRevisionsAndFirmwareMappings: HwRevisionFirmware[]): Function {
  return function (dispatch: Function): void {
    dispatch(getHwRevisionsAndFirmwareMappingsActions.success(hwRevisionsAndFirmwareMappings));
  };
}

function processGetHwRevisionsAndFirmwareMappingsFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getHwRevisionsAndFirmwareMappingsActions.fail(error));
  };
}

export function getHwRevisionsAndFirmwareMappingsThunk(): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getHwRevisionsAndFirmwareMappingsActions.start());
    return apiClient.get('/v1/device/hwRevisionsAndFirmwareMappings', {
      auth: true,
      successAction: processGetHwRevisionsAndFirmwareMappingsSuccess,
      failAction: processGetHwRevisionsAndFirmwareMappingsFail
    });
  };
}
