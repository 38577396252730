import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, Box, IconButton, Snackbar, styled } from '@mui/material';
import React, { useState } from 'react';
import GreenBgCheckmark from 'assets/images/greenBgCheckmark.png';
import { Text } from 'components/Typography';
import style from './AlertInfo.scss';

interface Props {
  open: boolean;
  title: string;
  message: string;
  vertical?: 'top' | 'bottom';
  horizontal?: 'left' | 'center' | 'right';
  onClose?: () => void;
  duration?: number;
  iconSrc?: string;
}

const WhiteBgAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: '#fff'
}));

const AlertInfo: React.FC<Props> = ({ open, title, message, vertical = 'top', horizontal = 'right', onClose, duration = null, iconSrc = GreenBgCheckmark }): JSX.Element => {
  const icon = <img src={iconSrc} className={style.icon} />;

  const [visible, setVisible] = useState(open);

  const handleClose = () => {
    setVisible(false);
    onClose?.();
  };

  return (
    <Box>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={visible} onClose={onClose} key={vertical + horizontal}>
        <WhiteBgAlert
          onClose={onClose}
          className={style.alert}
          icon={icon}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle className={style.alertTitle}>{title}</AlertTitle>
          <Text variant="sm" className={style.message}>
            {message}
          </Text>
        </WhiteBgAlert>
      </Snackbar>
    </Box>
  );
};

export default AlertInfo;
