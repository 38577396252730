import { SvgIcon } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';

interface Props {
  asButton?: Nullable<boolean>;
  buttonSize?: Nullable<'small' | 'medium'>;
  style?: React.CSSProperties; //external component does not support nullable types, so we can't apply Nullable<> type here
}

export const StateFilledIcon: React.FC<Props> = ({ asButton, buttonSize, style }) => {
  const svgIcon = (): JSX.Element => (
    <SvgIcon width="16" height="19" viewBox="0 0 16 19" fill="none" style={style}>
      <path
        d="M15.0605 1.76252C14.6476 1.59141 14.1729 1.68635 13.8571 2.00207C12.4617 3.39636 10.1907 3.39747 8.79417 2.00207C6.53649 -0.253296 2.86569 -0.253296 0.609117 2.00207C0.401565 2.20851 0.285645 2.49002 0.285645 2.78257V17.1339C0.285645 17.7433 0.779133 18.2379 1.38964 18.2379C2.00016 18.2379 2.49364 17.7433 2.49364 17.1339V12.1076C3.89683 11.007 5.93923 11.1019 7.23312 12.3947C9.4908 14.65 13.1627 14.65 15.4182 12.3947C15.6257 12.1871 15.7416 11.9067 15.7416 11.6142V2.78257C15.7416 2.33547 15.4723 1.93363 15.0605 1.76252Z"
        fill="#D0490F"
      />
    </SvgIcon>
  );

  if (asButton) {
    return <IconButton size={buttonSize ? buttonSize : 'medium'}>{svgIcon()}</IconButton>;
  }
  return svgIcon();
};
