import { Box, Button, CircularProgress, IconButton, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import { BreadCrumbData, BreadCrumbType, SelectOption } from '@redux/common/types';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { NavBar } from '../NavBar/NavBar';
import style from './Header.scss';

interface Props {
  breadCrumbs?: Nullable<BreadCrumbData[]>;
  title?: Nullable<string | JSX.Element>;
  subtitle?: Nullable<string>;
  disableTitleGutters?: Nullable<boolean>;
  saveFunction?: Nullable<() => any>;
  newFunction?: Nullable<MouseEventHandler>;
  syncFunction?: Nullable<(authToken: string) => any>;
  sortFunction?: Nullable<() => void>;
  sortLabel?: Nullable<string>;
  deleteFunction?: Nullable<() => void>;
  logoutFunction?: Nullable<() => void>;
  disableSave?: Nullable<boolean>;
  disableSync?: Nullable<boolean>;
  disableDelete?: Nullable<boolean>;
  saveSuccessful?: Nullable<boolean>;
  appendBreadCrumbs?: Nullable<(breadCrumb: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => void>;
  defaultCurrentCrumb?: Nullable<BreadCrumbData>;
  defaultCrumbHistory?: Nullable<BreadCrumbData[]>;
  overrideTitle?: Nullable<string>;
  overrideUrl?: Nullable<string>;
  overrideType?: Nullable<BreadCrumbType>;
  overrideValueOptions?: Nullable<SelectOption[]>;
  overrideSelectedIndex?: Nullable<number>;
  refreshOnChangeRef?: Nullable<any>;
}

export const Header: React.FC<Props> = ({
  breadCrumbs,
  title,
  subtitle,
  disableTitleGutters,
  saveFunction,
  newFunction,
  syncFunction,
  sortFunction,
  sortLabel,
  deleteFunction,
  disableSave,
  disableSync,
  disableDelete,
  saveSuccessful,
  appendBreadCrumbs,
  defaultCurrentCrumb,
  defaultCrumbHistory,
  overrideTitle,
  overrideUrl,
  overrideType,
  overrideValueOptions,
  overrideSelectedIndex,
  refreshOnChangeRef,
  logoutFunction
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [syncDialog, setSyncDialog] = useState<boolean>(false);
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [authToken, setAuthToken] = useState<string>('');
  const [syncing, setSyncing] = useState<boolean>(false);

  useEffect((): void => {
    if (saveSuccessful !== undefined) {
      setLoading(() => false);
      if (syncing) {
        setSyncing(() => false);
      }
    }
  }, [saveSuccessful]);

  const save = () => {
    if (!loading) {
      setLoading(true);
    }
    if (saveFunction) {
      saveFunction();
    }
  };

  const del = () => {
    if (!loading) {
      setLoading(true);
    }
    if (deleteFunction) {
      deleteFunction();
    }
  };

  const sync = async () => {
    if (!syncing) {
      setSyncing(() => true);
    }
    if (syncFunction) {
      syncFunction(authToken);
    }
    closeDialog();
  };

  const closeDialog = () => {
    setSyncDialog(() => false);
    setAuthToken(() => '');
  };

  return (
    <React.Fragment>
      <Box className={style.navBar} component="div">
        {breadCrumbs && (
          <NavBar
            breadCrumbs={breadCrumbs}
            appendBreadCrumbs={appendBreadCrumbs}
            defaultCurrentCrumb={defaultCurrentCrumb}
            defaultCrumbHistory={defaultCrumbHistory}
            overrideTitle={overrideTitle}
            overrideUrl={overrideUrl}
            overrideType={overrideType}
            overrideSelectedIndex={overrideSelectedIndex}
            overrideValueOptions={overrideValueOptions}
            refreshOnChangeRef={refreshOnChangeRef}
            logout={logoutFunction}
          />
        )}
      </Box>
      <Box className={style.header} component="div">
        <Box component="span" className={style.headerTitleContainer}>
          <Typography variant="h3" gutterBottom={!disableTitleGutters} component="div">
            {title}
          </Typography>
          <Typography variant="h6" gutterBottom={!disableTitleGutters} component="div">
            {subtitle}
          </Typography>
        </Box>
        <Box component="span" className={style.headerIconBar}>
          {deleteFunction && (
            <Button
              className={style.saveButton}
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              disabled={disableDelete || loading}
              onClick={() => setDeleteDialog(true)}
            >
              {!loading ? 'Delete' : <CircularProgress size={24} />}
            </Button>
          )}
          {saveFunction && (
            <Button className={style.saveButton} variant="contained" color="primary" disabled={disableSave || loading} onClick={save}>
              {!loading ? 'Save' : <CircularProgress size={24} />}
            </Button>
          )}
          {!!syncFunction && (
            <Button
              className={style.saveButton}
              variant="contained"
              color="primary"
              startIcon={<SyncAltIcon />}
              disabled={disableSync || syncing}
              onClick={() => setSyncDialog(true)}
            >
              {!syncing ? 'Prod Sync' : <CircularProgress size={24} />}
            </Button>
          )}
          {!!sortFunction && (
            <Button className={style.saveButton} variant="contained" color="primary" startIcon={<ArrowDownwardIcon />} onClick={() => sortFunction()}>
              {sortLabel}
            </Button>
          )}
          {newFunction && (
            <IconButton className={style.newIcon} color="primary" onClick={newFunction}>
              <AddCircleIcon fontSize="inherit" />
            </IconButton>
          )}
        </Box>
      </Box>
      <ConfirmationDialog
        open={syncDialog}
        dialogText={'Enter auth token from production application'}
        inputSetter={setAuthToken}
        proceedEvent={sync}
        proceedEventText={'Sync'}
        closeEvent={closeDialog}
      />
      <ConfirmationDialog
        open={deleteDialog}
        titleText={'Are you sure you want to delete this flag?'}
        dialogText={'Deleting this flag will remove it from all users that currently have it active with open task(s).'}
        proceedEvent={del}
        proceedEventText={'Yes, delete this flag'}
        proceedEventTextColor={'secondary'}
        closeEvent={() => {
          setDeleteDialog(() => false);
          setLoading(() => false);
        }}
      />
    </React.Fragment>
  );
};
