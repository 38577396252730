export enum PatientPresenceType {
  NEAR_THE_DEVICE = 'Near the device',
  OUT_OF_RANGE = 'Out of range',
  UNAVAILABLE = 'Unavailable'
}

export const getPatientPresence = (patientPresence: string | undefined, deviceStatus: string | undefined): PatientPresenceType => {
  if (!deviceStatus || deviceStatus?.toUpperCase() === 'OFFLINE' || patientPresence === 'UNKNOWN') {
    return PatientPresenceType.UNAVAILABLE;
  }
  const nearDevicePresences = ['BED', 'BEDSIDE_NEAR', 'BEDSIDE_FAR', 'BEDROOM'];
  return nearDevicePresences.includes(patientPresence ?? '') ? PatientPresenceType.NEAR_THE_DEVICE : PatientPresenceType.OUT_OF_RANGE;
};
