import { CardHeader, ClickAwayListener, IconButton, MenuItem, Paper } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import { TypographyProps } from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { cloneDeep } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { PersonWorkflowTask, PersonWorkflowTaskStatus, WorkflowTaskStatusType } from '@redux/content/contentTypes';
import { timestampAndRandomCombinedUUIDString } from '../../../../shared/src/util/uuid';
import { CompleteIcon } from '../CompleteIcon/CompleteIcon';
import { DismissedIcon } from '../DismissedIcon/DismissedIcon';
import { IncompleteIcon } from '../IncompleteIcon/IncompleteIcon';
import style from './FlagTaskBullet.scss';

interface Props {
  personWorkflowTask: PersonWorkflowTask;
  authenticatedPersonId?: Nullable<string>;
  latestWorkflowOfType: boolean;
  saveFlagTaskStatus: (personWorkflowTaskStatus: PersonWorkflowTaskStatus) => void;
}

const dismissedTypographyProps: TypographyProps = {
  style: {
    textDecoration: 'line-through'
  }
};

const options = ['Dismiss task', 'Complete task', 'Un-dismiss task'];
const sortedTaskStatuses = (personWorkflowTask: PersonWorkflowTask): PersonWorkflowTaskStatus[] =>
  cloneDeep(personWorkflowTask?.personWorkflowTaskStatuses)?.sort((s1, s2) => s2?.submittedTs?.localeCompare(s1?.submittedTs));

export const FlagTaskBullet: React.FC<Props> = ({ personWorkflowTask, authenticatedPersonId, latestWorkflowOfType, saveFlagTaskStatus }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [workflowTaskStatuses, setWorkflowTaskStatuses] = useState<PersonWorkflowTaskStatus[]>([]);
  const anchorRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setWorkflowTaskStatuses(() => sortedTaskStatuses(personWorkflowTask));
  }, [personWorkflowTask]);

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>): void => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(() => false);
  };

  const handleMenuItemClick = (index: number): void => {
    setOpen(() => false);
    const personWorkflowTaskStatus: PersonWorkflowTaskStatus = {
      id: timestampAndRandomCombinedUUIDString(),
      personWorkflowTaskId: personWorkflowTask.id,
      personWorkflowId: personWorkflowTask.personWorkflowId,
      workflowId: personWorkflowTask?.workflowTask?.workflowId,
      workflowTaskStatusType: index === 1 ? WorkflowTaskStatusType.COMPLETED : index === 0 ? WorkflowTaskStatusType.DISMISSED : WorkflowTaskStatusType.REOPENED,
      submitterPersonId: authenticatedPersonId,
      submittedTs: new Date().toISOString()
    };
    saveFlagTaskStatus(personWorkflowTaskStatus);
  };

  const getTaskStatusAvatar = (): JSX.Element => {
    const workflowStatus: WorkflowTaskStatusType = workflowTaskStatuses?.[0]?.workflowTaskStatusType;
    if (workflowStatus === WorkflowTaskStatusType.COMPLETED) {
      return <CompleteIcon onClick={latestWorkflowOfType ? () => handleMenuItemClick(2) : undefined} />;
    } else if (workflowStatus === WorkflowTaskStatusType.DISMISSED) {
      return <DismissedIcon onClick={latestWorkflowOfType ? () => handleMenuItemClick(2) : undefined} />;
    } else {
      return <IncompleteIcon style={{ color: 'white' }} onClick={latestWorkflowOfType ? () => handleMenuItemClick(1) : undefined} />;
    }
  };

  return (
    <React.Fragment>
      <CardHeader
        avatar={getTaskStatusAvatar()}
        className={style.cardHeader}
        titleTypographyProps={workflowTaskStatuses?.[0]?.workflowTaskStatusType === WorkflowTaskStatusType.DISMISSED ? dismissedTypographyProps : undefined}
        title={
          <React.Fragment>
            {personWorkflowTask?.workflowTask?.title}
            {latestWorkflowOfType && (
              <IconButton size="small" ref={anchorRef} style={{ backgroundColor: 'lightblue', marginLeft: '16px' }} onClick={() => setOpen((open) => !open)}>
                <MoreHorizIcon fontSize="inherit" />
              </IconButton>
            )}
          </React.Fragment>
        }
      />
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 2147483647 }}>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={
                        index ===
                        (workflowTaskStatuses?.[0]?.workflowTaskStatusType === WorkflowTaskStatusType.COMPLETED
                          ? 1
                          : workflowTaskStatuses?.[0]?.workflowTaskStatusType === WorkflowTaskStatusType.DISMISSED
                            ? 0
                            : 2)
                      }
                      onClick={() => handleMenuItemClick(index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
