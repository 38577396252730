import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { deletePersonThunk } from '@redux/person/personThunks';
import { ReduxState } from '@redux/types';
import { ConfirmationDialogV2 } from 'components/ConfirmationDialog/ConfirmationDialogV2';
import { TrashIcon } from 'components/Icons';
import { Text } from 'components/Typography';
import style from './DeletePatient.scss';

export type DeletePatientProps = {
  patientName: string;
  patientId: string;
  showDialog: boolean;
  onDialogClose: () => void;
};

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & DeletePatientProps;

const DeletePatient: React.FC<Props> = ({ patientName, patientId, deletePatient, showDialog, onDialogClose }) => {
  const [showDeletePatientConfirmation, setShowDeletePatientConfirmation] = useState(showDialog);
  const [successMsg, setSuccessMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const onDeletePatientConfirmation = async () => {
    closeDialog();
    try {
      setErrMsg('');
      setSuccessMsg('');
      await deletePatient(patientId);
      setSuccessMsg('Patient deleted successfully.');
    } catch (err) {
      setErrMsg('Failed to delete patient.');
    }
  };

  const closeDialog = () => {
    setShowDeletePatientConfirmation(false);
    onDialogClose?.();
  };

  useEffect(() => {
    setShowDeletePatientConfirmation(showDialog);
  }, [showDialog]);

  return (
    <div>
      {showDeletePatientConfirmation && (
        <ConfirmationDialogV2
          open={showDeletePatientConfirmation}
          icon={TrashIcon}
          title={`Delete patient ${patientName}?`}
          description="Are you sure you want to delete this patient? This action cannot be undone."
          proceedEvent={onDeletePatientConfirmation}
          proceedEventText="Delete"
          closeEvent={closeDialog}
        />
      )}
      {!!errMsg && (
        <Text variant="sm" className={style.errorMessage}>
          {errMsg}
        </Text>
      )}
      {!!successMsg && (
        <Text variant="h5" className={style.successMessage}>
          {successMsg}
        </Text>
      )}
    </div>
  );
};

const connectRedux = connect((_: ReduxState) => ({}), {
  deletePatient: deletePersonThunk
});

export default compose(connectRedux)(DeletePatient);
