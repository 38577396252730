import Config from '../../config/config';
import { getClientDeviceIdSelector } from '../clientDevice/clientDeviceSelectors';
import { registerClientDeviceThunk, removeClientDevice } from '../clientDevice/clientDeviceThunks';
import { ErrorPayload } from '../common/types';
import { ThunkParams } from '../types';
import {
  createDemoAccessCodeAction,
  deleteAccountNotifySupportEmailActions,
  getAcceptInviteActions,
  getConfirmEmailActions,
  getDemoAccessCodesAction,
  getLoginActions,
  getLogoutActions,
  getRequestRegisterActions,
  getResetPasswordActions,
  updateDemoAccessCodesAction
} from './oauthActions';
import { getAuthPersonIdSelector, isOauthLoadingSelector } from './oauthSelectors';
import {
  AcceptAccountInviteRequest,
  DemoAccessCode,
  DemoAccessCodesResponsePayload,
  LoginPayload,
  LoginResponsePayload,
  LogoutPayload,
  RegisterPayload,
  ResetPasswordEmailPayload,
  ResetPasswordPayload
} from './oauthTypes';

function processAuthSuccess(payload: LoginResponsePayload): Function {
  return function (dispatch: Function, getState: Function): void {
    dispatch(getLoginActions.success(payload));
    dispatch(registerClientDeviceThunk(getState().clientDevice));
  };
}

function processAuthFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    if (error.status === 401) {
      error.message = "Your credentials didn't match an existing user.";
    }
    dispatch(getLoginActions.fail(error));
  };
}

export function loginThunk(loginPayload: LoginPayload): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    if (isOauthLoadingSelector(getState())) {
      return;
    }
    dispatch(getLoginActions.start());
    apiClient.post('/api/oauth/login', {
      baseUrl: Config.getAuthBaseUrl(),
      data: loginPayload,
      params: {
        'include-permissions': true
      },
      successAction: processAuthSuccess,
      failAction: processAuthFail
    });
  };
}

function processLogoutSuccess(): Function {
  return function (dispatch: Function): void {
    dispatch(getLogoutActions.success());
    removeClientDevice();
  };
}

function processLogoutFail(error: ErrorPayload) {
  return function (dispatch: Function) {
    dispatch(getLogoutActions.fail(error));
  };
}

export function logoutThunk() {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getLogoutActions.start());

    const logoutPayload: LogoutPayload = {
      clientDeviceId: getClientDeviceIdSelector(getState().clientDevice) || ''
    };
    apiClient.post('/api/oauth/logout', {
      auth: true,
      baseUrl: Config.getAuthBaseUrl(),
      data: logoutPayload,
      successAction: processLogoutSuccess,
      failAction: processLogoutFail
    });
  };
}

export function sendDeleteAccountConfirmationEmailThunk(email: string): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    return apiClient.post('/api/oauth/deleteAccountConfirmationEmail', {
      baseUrl: Config.getAuthBaseUrl(),
      data: { email }
    });
  };
}

function processDeleteAccountNotifySupportEmailSuccess(): Function {
  return function (dispatch: Function): void {
    dispatch(deleteAccountNotifySupportEmailActions.success());
  };
}

function processDeleteAccountNotifySupportEmailFail(error: ErrorPayload) {
  return function (dispatch: Function) {
    dispatch(deleteAccountNotifySupportEmailActions.fail(error));
  };
}

export function deleteAccountNotifySupportEmailThunk(token: any) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(deleteAccountNotifySupportEmailActions.start());
    return await apiClient.post('/api/oauth/deleteAccountNotifySupportEmail', {
      baseUrl: Config.getAuthBaseUrl(),
      headers: { Authorization: token },
      successAction: processDeleteAccountNotifySupportEmailSuccess,
      failAction: processDeleteAccountNotifySupportEmailFail
    });
  };
}

export function resetPasswordEmailThunk(resetPasswordEmailPayload: ResetPasswordEmailPayload): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    return apiClient.post('/api/oauth/forgotPassword', {
      baseUrl: Config.getAuthBaseUrl(),
      data: resetPasswordEmailPayload
    });
  };
}

function processResetPasswordSuccess(): Function {
  return function (dispatch: Function): void {
    dispatch(getResetPasswordActions.success());
  };
}

function processResetPasswordFail(error: ErrorPayload) {
  return function (dispatch: Function) {
    dispatch(getResetPasswordActions.fail(error));
  };
}

export function resetPasswordThunk(token: any, resetPasswordPayload: ResetPasswordPayload): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getResetPasswordActions.start());
    return apiClient.post('/api/oauth/resetPassword', {
      baseUrl: Config.getAuthBaseUrl(),
      data: resetPasswordPayload,
      headers: { Authorization: token },
      successAction: processResetPasswordSuccess,
      failAction: processResetPasswordFail
    });
  };
}

// accept invite thunks
function processAcceptInviteSuccess(): Function {
  return function (dispatch: Function): void {
    dispatch(getAcceptInviteActions.success());
  };
}

function processAcceptInviteFail(error: ErrorPayload) {
  return function (dispatch: Function) {
    dispatch(getAcceptInviteActions.fail(error));
  };
}

export function acceptInviteThunk(token: any, acceptAccountInviteRequest: AcceptAccountInviteRequest): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getAcceptInviteActions.start());
    return apiClient.post('/api/oauth/acceptInvite', {
      baseUrl: Config.getAuthBaseUrl(),
      headers: { Authorization: token },
      data: acceptAccountInviteRequest,
      successAction: processAcceptInviteSuccess,
      failAction: processAcceptInviteFail
    });
  };
}

function processRegisterSuccess(payload: LoginResponsePayload, doNotAuthorizeSession?: Nullable<boolean>): Function {
  return function (dispatch: Function, getState: Function): void {
    dispatch(getRequestRegisterActions.success(payload));
    if (!doNotAuthorizeSession) {
      dispatch(registerClientDeviceThunk(getState().clientDevice));
    }
    dispatch(getConfirmEmailActions.start());
  };
}

function processRegisterFail(error: ErrorPayload): Function {
  return function (dispatch: Function): void {
    dispatch(getRequestRegisterActions.fail(error));
  };
}

export function registerThunk(registerPayload: RegisterPayload) {
  return async function (dispatch: Function, getState: Function, { apiClient }: ThunkParams) {
    dispatch(getRequestRegisterActions.start());

    return apiClient.post('/api/oauth/createAccount', {
      baseUrl: Config.getAuthBaseUrl(),
      data: registerPayload,
      successAction: (payload) => processRegisterSuccess(payload, registerPayload.doNotAuthorizeSession),
      failAction: processRegisterFail
    });
  };
}

function updateEmailVerifiedStatus(payload: any): Function {
  return function (dispatch: Function): void {
    const { emailVerified } = payload;
    emailVerified ? dispatch(getConfirmEmailActions.success()) : dispatch(getConfirmEmailActions.success());
  };
}

export function checkEmailVerifiedStatusThunk(): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    const personId = getAuthPersonIdSelector(getState());

    return apiClient.get(`v1/person/${personId}`, {
      auth: true,
      successAction: updateEmailVerifiedStatus
    });
  };
}

export function resendEmailConfirmThunk(token?: Nullable<string>): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getConfirmEmailActions.start());
    return apiClient.get('v1/oauth/resendConfirmationEmail', {
      auth: token
    });
  };
}

function processConfirmEmailSuccessThunk(success: boolean): Function {
  return function (dispatch: Function): void {
    if (success) {
      dispatch(getConfirmEmailActions.success());
    } else {
      dispatch(getConfirmEmailActions.fail(null));
    }
  };
}

export function confirmEmailThunk(token: string): Function {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getConfirmEmailActions.start());
    return apiClient.get(`v1/oauth/confirmEmail`, {
      params: {
        token: token
      },
      successAction: processConfirmEmailSuccessThunk
    });
  };
}

function getAllDemoAccessCodesSuccess(payload: DemoAccessCodesResponsePayload): Function {
  return function (dispatch: Function): void {
    dispatch(getDemoAccessCodesAction.success(payload));
  };
}
function processGetPersonByIdFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(getDemoAccessCodesAction.fail(error));
  };
}
export function getAllDemoAccessCodesThunk() {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(getDemoAccessCodesAction.start());
    return apiClient.get(`/v1/oauth/demoAccessCodes`, {
      auth: true,
      successAction: getAllDemoAccessCodesSuccess,
      failAction: processGetPersonByIdFail
    });
  };
}

function processCreateDemoAccessCodesSuccess(payload: DemoAccessCode[]): Function {
  return function (dispatch: Function): void {
    dispatch(createDemoAccessCodeAction.success(payload));
  };
}
function processCreateDemoAccessCodesFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(createDemoAccessCodeAction.fail(error));
  };
}
export function createDemoAccessCodesThunk(count: number) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(createDemoAccessCodeAction.start());
    return apiClient.post(`/v1/oauth/demoAccessCodes?count=${count}`, {
      auth: true,
      successAction: processCreateDemoAccessCodesSuccess,
      failAction: processCreateDemoAccessCodesFail
    });
  };
}

function processUpdateDemoAccessCodeSuccess(payload: DemoAccessCode): Function {
  return function (dispatch: Function): void {
    dispatch(updateDemoAccessCodesAction.success(payload));
  };
}
function processUpdateDemoAccessCodeFail(error: ErrorPayload): Function {
  return function (dispatch: Function) {
    dispatch(updateDemoAccessCodesAction.fail(error));
  };
}
export function updateDemoAccessCodeThunk(demoAccessCode: DemoAccessCode) {
  return function (dispatch: Function, getState: Function, { apiClient }: ThunkParams): Promise<any> {
    dispatch(updateDemoAccessCodesAction.start());
    return apiClient.put(`/v1/oauth/demoAccessCode`, {
      auth: true,
      data: demoAccessCode,
      successAction: processUpdateDemoAccessCodeSuccess,
      failAction: processUpdateDemoAccessCodeFail
    });
  };
}
