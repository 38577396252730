import { Box, Button, Container, MuiThemeProvider } from '@material-ui/core';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { BreadCrumbData } from '@redux/common/types';
import { appendBreadcrumbAction } from '@redux/oauth/oauthActions';
import { getAllDemoAccessCodes, getBreadcrumbsSelector } from '@redux/oauth/oauthSelectors';
import { createDemoAccessCodesThunk, getAllDemoAccessCodesThunk, updateDemoAccessCodeThunk } from '@redux/oauth/oauthThunks';
import { DemoAccessCode } from '@redux/oauth/oauthTypes';
import { getPersonByIdSelector } from '@redux/person/personSelector';
import { Person } from '@redux/person/personTypes';
import { ReduxState } from '@redux/types';
import { Header } from '../../components/Header/Header';
import { SelectButton } from '../../components/SelectButton/SelectButton';
import { defaultAccessBreadCrumbs, defaultAccessCodesBreadCrumb } from '../../utils/breadcrumbs';
import { defaultTheme } from '../../utils/styles';
import style from './DemoAccessCodes.scss';

interface Props {
  breadCrumbs: BreadCrumbData[];
  appendBreadCrumbs: (breadCrumb: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => void;
  demoAccessCodes: DemoAccessCode[];
  createDemoAccessCodes: (count: number) => void;
  getAllDemoAccessCodes: () => void;
  updateDemoAccessCode: (demoAccessCode: DemoAccessCode) => void;
  personById: Record<string, Person>;
}

const DemoAccessCodes: React.FC<Props> = ({ breadCrumbs, appendBreadCrumbs, demoAccessCodes, createDemoAccessCodes, getAllDemoAccessCodes, updateDemoAccessCode, personById }) => {
  const [count, setCount] = useState<number>(1);
  const countValues = [1, 5, 10, 25, 50, 100];

  useEffect(() => {
    getAllDemoAccessCodes();
  }, []);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 350, hide: true, align: 'center', headerAlign: 'center' },
    { field: 'AccessCode', headerName: 'Access Code', width: 190, cellClassName: style.accessCodeText, align: 'center', headerAlign: 'center' },
    { field: 'PersonAssigned', headerName: 'Used by', width: 200, align: 'center', headerAlign: 'center' },
    { field: 'Assigned', headerName: 'Used Date', width: 200, align: 'center', headerAlign: 'center' },
    { field: 'CreatedPerson', headerName: 'Created By', width: 200, align: 'center', headerAlign: 'center' },
    { field: 'Created', headerName: 'Created', minWidth: 200, align: 'center', headerAlign: 'center' },
    { field: 'Note', headerName: 'Note', width: 300, flex: 1, editable: true, align: 'center', headerAlign: 'center' }
  ];

  const data = demoAccessCodes?.map((demoAccessCode) => {
    const createdPerson = personById[demoAccessCode.creatorPersonId];
    const assignedPerson = demoAccessCode.personId ? personById[demoAccessCode.personId] : null;
    return {
      id: demoAccessCode.id,
      AccessCode: demoAccessCode.accessCode.toUpperCase(),
      Note: demoAccessCode.description,
      PersonAssigned: assignedPerson ? assignedPerson.givenNames + ' ' + assignedPerson.familyName : '',
      Assigned: demoAccessCode.startTs ? DateTime.fromISO(demoAccessCode.startTs).toLocaleString(DateTime.DATETIME_MED) : undefined,
      CreatedPerson: createdPerson.givenNames + ' ' + createdPerson.familyName,
      Created: demoAccessCode.created ? DateTime.fromISO(demoAccessCode.created).toLocaleString(DateTime.DATETIME_MED) : undefined
    };
  });

  const handleCellEditCommit = useCallback(
    ({ id, field, value }) => {
      if (field === 'Note') {
        const demoAccessCode = demoAccessCodes?.find((demoAccessCode) => demoAccessCode.id === id);
        if (demoAccessCode) {
          updateDemoAccessCode({
            ...demoAccessCode,
            description: value
          });
        }
      }
    },
    [data]
  );

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className={style.root}>
        <Container maxWidth="xl">
          <Header
            breadCrumbs={breadCrumbs}
            title="Demo Access Codes"
            appendBreadCrumbs={appendBreadCrumbs}
            defaultCurrentCrumb={defaultAccessCodesBreadCrumb}
            defaultCrumbHistory={defaultAccessBreadCrumbs}
          />
          <Box
            component="fieldset"
            p={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="75px"
            alignSelf="center"
            maxWidth="75%"
            marginBottom="20px"
            paddingLeft="10px"
            margin="20 auto 20 auto"
          >
            <legend>Create New Demo Access Codes</legend>
            <SelectButton
              options={countValues?.map(String)}
              activeIndex={countValues.indexOf(count)}
              onClick={(selectedIndex) => setCount(countValues[selectedIndex])}
              label={'# Access Codes'}
              customPadding={'10px'}
              disableConfirmation={true}
              customWidth={'150px'}
            />
            <Button variant="contained" color="primary" className={style.button} onClick={() => createDemoAccessCodes(count)}>
              Create
            </Button>
          </Box>
          {!!data?.length && <DataGrid rows={data} columns={columns} onCellEditCommit={handleCellEditCommit} />}
        </Container>
      </div>
    </MuiThemeProvider>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      breadCrumbs: getBreadcrumbsSelector(state),
      demoAccessCodes: getAllDemoAccessCodes(state),
      personById: getPersonByIdSelector(state)
    };
  },
  (dispatch: Function) => ({
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => {
      dispatch(appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }));
    },
    createDemoAccessCodes: (count: number) => {
      dispatch(createDemoAccessCodesThunk(count));
    },
    getAllDemoAccessCodes: () => {
      dispatch(getAllDemoAccessCodesThunk());
    },
    updateDemoAccessCode: (demoAccessCode: DemoAccessCode) => {
      dispatch(updateDemoAccessCodeThunk(demoAccessCode));
    }
  })
);

export default compose(connectRedux)(DemoAccessCodes) as React.ComponentType;
