import { createReducer } from '@reduxjs/toolkit';
import { defaultActionFail, defaultActionStart, getActionStringWithoutState, setDefaultFail, setDefaultStart } from '@redux/reduxUtils';
import {
  INBOX_MESSAGES_ACTION_PREFIX,
  clearSelectedInboxMessagesDetails,
  getInboxMessagesAction,
  getInboxMessagesDetailsAction,
  postInboxMessagesResponseAction
} from './inboxMessagesActions';
import { InboxMessagesState } from './inboxMessagesTypes';

export const initialInboxMessagesState: InboxMessagesState = {
  isLoading: {},
  error: {},
  success: {},
  inboxMessagesList: [],
  inboxMessagesDetails: null
};

export default createReducer(initialInboxMessagesState, (builder) => {
  builder
    .addCase(getInboxMessagesAction.start, defaultActionStart)
    .addCase(getInboxMessagesAction.fail, defaultActionFail)
    .addCase(getInboxMessagesAction.success, (state, action) => {
      const inboxMessagesTag = getActionStringWithoutState(action.type);
      state.isLoading[inboxMessagesTag] = false;
      state.inboxMessagesList = action.payload ?? [];
    })

    .addCase(getInboxMessagesDetailsAction.start, defaultActionStart)
    .addCase(getInboxMessagesDetailsAction.fail, defaultActionFail)
    .addCase(getInboxMessagesDetailsAction.success, (state, action) => {
      const inboxMessagesTag = getActionStringWithoutState(action.type);
      state.isLoading[inboxMessagesTag] = false;
      state.inboxMessagesDetails = action.payload;
    })

    .addCase(postInboxMessagesResponseAction.start, defaultActionStart)
    .addCase(postInboxMessagesResponseAction.fail, defaultActionFail)
    .addCase(postInboxMessagesResponseAction.success, (state, action) => {
      const inboxMessagesTag = getActionStringWithoutState(action.type);
      state.isLoading[inboxMessagesTag] = false;
      state.inboxMessagesDetails = action.payload;
    })

    .addCase(clearSelectedInboxMessagesDetails, (state) => {
      state.inboxMessagesDetails = null;
    });

  builder
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-start') && action.type.startsWith(INBOX_MESSAGES_ACTION_PREFIX),
      (state, action) => {
        setDefaultStart(state, action.type);
      }
    )
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-success') && action.type.startsWith(INBOX_MESSAGES_ACTION_PREFIX),
      (state, action) => {
        const tag = getActionStringWithoutState(action.type);
        state.isLoading[tag] = false;
      }
    )
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-fail') && action.type.startsWith(INBOX_MESSAGES_ACTION_PREFIX),
      (state, action) => {
        setDefaultFail(state, action);
      }
    );
});
