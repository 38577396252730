import AddIcon from '@mui/icons-material/Add';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { getAuthPersonSelector } from '@redux/oauth/oauthSelectors';
import { getActivePersonIdNamePairByPersonIdSelector } from '@redux/person/personSelector';
import { getAllActivePersonThunk, getPersonByIdThunk, setSelectedPatientIdThunk } from '@redux/person/personThunks';
import { PersonIdNameTimezone } from '@redux/person/personTypes';
import { getOrganizationTasksListSelector } from '@redux/tasks/tasksSelector';
import { getTasksListThunk, postNewTaskThunk } from '@redux/tasks/tasksThunks';
import { TaskCreationPayloadType } from '@redux/tasks/tasksTypes';
import { ReduxState } from '@redux/types';
import RedBgExclamation from 'assets/images/redBgExclamation.png';
import AlertInfo from 'components/AlertInfo/AlertInfo';
import { Button } from 'components/Button/Button';
import NavTopBarContentWrapper from 'components/NavTopBarContentWrapper/NavTopBarContentWrapper';
import CancelTaskModal from 'containers/Tasks/components/CancelTaskModal/CancelTaskModal';
import { EmptyTasksListArea } from 'containers/Tasks/components/EmptyTasksListArea/EmptyTasksListArea';
import TaskInfoDrawer from 'containers/Tasks/components/TaskInfoDrawer/TaskInfoDrawer';
import { TasksTable } from 'containers/Tasks/components/TasksTable/TasksTable';
import { isProductionEnvironment } from 'utils/environment';
import { TaskCreationDrawer } from './components/TaskCreationDrawer/TaskCreationDrawer';

const FETCH_TASKS_STATE_INTERVAL = 60000;
type Props = ConnectedProps<typeof connectRedux>;

const Tasks: React.FC<Props> = ({ authPersonSelector, activePersonByIdSelector, getAllActivePerson, postNewTask, fetchTasksList, tasksList = [] }) => {
  const organizationId = authPersonSelector?.organizationId ?? '';
  const [initDone, setInitDone] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState<string>('');
  const [cancelTaskId, setCancelTaskId] = useState<string>('');
  const [addingTask, setAddingTask] = useState<boolean>(false);
  const [activePersonList, setActivePersonList] = useState<PersonIdNameTimezone[]>([]);
  const [showTaskCreationSuccess, setShowTaskCreationSuccess] = useState<boolean>(false);
  const [showTaskCreationError, setShowTaskCreationError] = useState<boolean>(false);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (isProductionEnvironment()) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    const fetchTasks = async () => {
      await fetchTasksList(organizationId);
      setInitDone(true);
    };

    fetchTasks();

    const intervalId = setInterval(() => {
      fetchTasks();
    }, FETCH_TASKS_STATE_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    getAllActivePerson(organizationId);
  }, []);

  useEffect(() => {
    const getActivePersons = (): PersonIdNameTimezone[] => {
      const activePersons: PersonIdNameTimezone[] = [];
      if (!activePersonByIdSelector) {
        return activePersons;
      }
      for (const personId of Object.keys(activePersonByIdSelector)) {
        const personIdNamePair = activePersonByIdSelector[personId];
        if (!personIdNamePair) {
          continue;
        }

        const activePerson: PersonIdNameTimezone = {
          ...personIdNamePair,
          fullName: personIdNamePair.fullName ?? ''
        };
        activePersons.push(activePerson);
      }

      return activePersons;
    };

    setActivePersonList(getActivePersons());
  }, [activePersonByIdSelector]);

  const addNewTask = () => {
    setAddingTask(true);
  };

  const handleSubmitAddNewTask = async (task: TaskCreationPayloadType) => {
    try {
      setShowTaskCreationError(false);
      setShowTaskCreationSuccess(false);
      await postNewTask(task);
      await fetchTasksList(organizationId);
      setAddingTask(false);
      setShowTaskCreationSuccess(true);
    } catch (err) {
      setShowTaskCreationError(true);
    }
  };

  const onSelectTaskSwitch = useCallback((id: string = '') => {
    setSelectedTaskId(id);
  }, []);

  const showConfirmationModal = useCallback((taskId: string = '') => {
    setCancelTaskId(taskId);
  }, []);

  const hideConfirmationModal = useCallback(() => {
    setCancelTaskId('');
  }, []);

  return (
    <NavTopBarContentWrapper
      headerText="Tasks"
      headerContent={
        initDone && tasksList.length ? (
          <Button variant="contained" onClick={addNewTask} startIcon={<AddIcon />}>
            Add Task
          </Button>
        ) : null
      }
    >
      {showTaskCreationSuccess && <AlertInfo open={showTaskCreationSuccess} title="Success!" message="Task created successfully!" />}
      {showTaskCreationError && (
        <AlertInfo open={showTaskCreationError} title="Oops!" message="Something went wrong creating your task. Please try again." iconSrc={RedBgExclamation} />
      )}
      {initDone && !!tasksList.length && <TasksTable data={tasksList} onSelectTaskSwitch={onSelectTaskSwitch} onCancelTask={showConfirmationModal} />}
      {initDone && !tasksList.length && <EmptyTasksListArea onAddPress={addNewTask} />}
      {selectedTaskId && <TaskInfoDrawer taskId={selectedTaskId} onClose={() => onSelectTaskSwitch()} onCancelTask={showConfirmationModal} />}
      {addingTask && (
        <TaskCreationDrawer
          activePersons={activePersonList}
          visible={true}
          onClose={() => {
            setAddingTask(false);
          }}
          completeSubmitNewTask={handleSubmitAddNewTask}
        ></TaskCreationDrawer>
      )}
      <CancelTaskModal taskId={cancelTaskId} visible={!!cancelTaskId} onConfirmPress={onSelectTaskSwitch} onClosePress={hideConfirmationModal} />
    </NavTopBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    authPersonSelector: getAuthPersonSelector(state),
    tasksList: getOrganizationTasksListSelector(state),
    activePersonByIdSelector: getActivePersonIdNamePairByPersonIdSelector(state)
  }),
  {
    fetchTasksList: getTasksListThunk,
    setSelectedPatientIdThunkCallback: setSelectedPatientIdThunk,
    fetchPersonById: getPersonByIdThunk,
    getAllActivePerson: getAllActivePersonThunk,
    postNewTask: postNewTaskThunk
  }
);

export default compose(connectRedux)(Tasks);
