import { CardHeader } from '@material-ui/core';
import moment from 'moment-timezone';
import React from 'react';
import { PersonWorkflowStatus } from '@redux/content/contentTypes';
import style from './FlagStatusBullet.scss';

interface Props {
  personWorkflowStatus: PersonWorkflowStatus;
  submitterFirstName?: Nullable<string>;
}

export const FlagStatusBullet: React.FC<Props> = ({ personWorkflowStatus, submitterFirstName }) => (
  <CardHeader
    avatar={<React.Fragment />}
    className={style.cardHeader}
    title={`Flag ${personWorkflowStatus?.workflowStatusType?.toLowerCase()} ${moment().diff(moment(personWorkflowStatus?.submittedTs), 'd')} days ago by ${submitterFirstName || '?'}`}
  />
);
