import { Box } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { connect } from 'react-redux';
import { compose } from 'redux';
import '../../assets/styles/Table.scss';
import { BreadCrumbData } from '@redux/common/types';
import { getHwRevisionFirmwareMappingsSelector, selectFirmwareVersionByIdSelector } from '@redux/device/deviceSelectors';
import { getDeviceFirmwareVersionOverviewThunk, getHwRevisionsAndFirmwareMappingsThunk, updateFirmwareVersionThunk } from '@redux/device/deviceThunks';
import { FirmwareType, FirmwareVersion, HwRevision, HwRevisionFirmware } from '@redux/device/deviceTypes';
import { appendBreadcrumbAction } from '@redux/oauth/oauthActions';
import { getBreadcrumbsSelector } from '@redux/oauth/oauthSelectors';
import { ReduxState } from '@redux/types';
import { NavBar } from 'components/NavBar/NavBar';
import { defaultFirmwareVersionsCrumb, defaultFirmwareVersionsCrumbs } from 'utils/breadcrumbs';
import { formatTimestamp, reverseFormatTimestamp } from 'utils/time';
import { timestampAndRandomCombinedUUIDString } from '../../../shared/src/util/uuid';
import style from './FirmwareVersionOverview.scss';

interface FirmwareVersionRow {
  versionName: string;
  groupName: string;
  stopGap: boolean;
  firmwareType: FirmwareType;
  created: string;
  id: string;
  unsaved: boolean;
}

interface Props {
  firmwareVersionById: Record<string, FirmwareVersion>;
  breadCrumbs: BreadCrumbData[];
  getFirmwareVersions: () => void;
  getHwRevisionFirmwareMappings: () => void;
  hwRevisionFirmwareMappings: HwRevisionFirmware[];
  update: (firmwareVersion: FirmwareVersion) => void;
  appendBreadCrumbs: (breadCrumb: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => void;
}

const FirmwareVersionOverview: React.FC<Props> = ({
  firmwareVersionById,
  breadCrumbs,
  getFirmwareVersions,
  getHwRevisionFirmwareMappings,
  hwRevisionFirmwareMappings,
  update,
  appendBreadCrumbs
}) => {
  useEffect(() => {
    getHwRevisionFirmwareMappings();
    getFirmwareVersions();
  }, []);

  useEffect(() => {
    setRows(getRows());
  }, [firmwareVersionById]);

  const firmwareTypeList: FirmwareType[] = Object.values(FirmwareType);
  const hwRevisionList: HwRevision[] = Object.values(HwRevision);

  const compareTimes = (a: string, b: string) => {
    const aDate = new Date(a);
    const bDate = new Date(b);
    return aDate < bDate ? -1 : 1;
  };

  const firmwareVersionToRow = (v: FirmwareVersion, unsaved: boolean): FirmwareVersionRow => {
    return {
      versionName: v.versionName,
      groupName: v.groupName,
      stopGap: v.stopGap,
      firmwareType: v.firmwareType,
      created: formatTimestamp(v.created),
      id: v.id, // not rendered
      unsaved
    };
  };

  const getRows = (): FirmwareVersionRow[] => {
    return Object.values(firmwareVersionById)
      .map((v) => firmwareVersionToRow(v, false))
      .sort((a: FirmwareVersionRow, b: FirmwareVersionRow) => {
        if (!a.groupName) return 1;
        if (!b.groupName) return -1;
        return a.groupName.localeCompare(b.groupName) || compareTimes(a.created, b.created);
      });
  };

  const [rows, setRows] = useState<FirmwareVersionRow[]>(getRows());

  const rowById = {};
  for (const row of rows) {
    rowById[row.id] = row;
  }

  const updateRow = (id: string, col: string, value: any) => {
    if (!rowById[id] || !Object.prototype.hasOwnProperty.call(rowById[id], col) /*|| rowById[id][col] == undefined*/) {
      return;
    } // use == undefined to allow ''
    rowById[id][col] = value;
    setRows(() => rows.map((x) => x));
  };

  const addNewVersion = () => {
    const now = new Date().toISOString();
    const v: FirmwareVersion = {
      versionName: '',
      groupName: '',
      stopGap: false,
      firmwareType: FirmwareType.UNKNOWN,
      created: now,
      modifiedDate: now,
      id: timestampAndRandomCombinedUUIDString()
    };
    const row: FirmwareVersionRow = firmwareVersionToRow(v, true);

    rowById[row.id] = row;
    rows.push(row);
    setRows(rows.map((x) => x));
  };

  const saveUpdates = () => {
    for (const row of rows) {
      let shouldUpdate = false;
      if (row.firmwareType === FirmwareType.UNKNOWN) {
        alert('Please select a firmware type for all firmware versions before saving.');
        continue;
      }
      const firmwareVersion: FirmwareVersion = { ...firmwareVersionById[row.id] };
      const changes: any = [];

      for (const col in row) {
        if (col == 'unsaved') continue;

        const original = firmwareVersion[col];
        let updated = row[col];
        if (col == 'created') {
          // date was formated for display, special case
          if (updated == formatTimestamp(original)) {
            updated = original;
          } else {
            updated = reverseFormatTimestamp(updated);
          }
        }

        if (original != updated) {
          firmwareVersion[col] = updated;
          shouldUpdate = true;
          changes.push([col, original, updated]);
        }
      }

      if (shouldUpdate) {
        const message = `Update firmware version ${firmwareVersion.versionName}\n${firmwareVersion.id}\n` + changes.map((arr) => `${arr[0]}: ${arr[1]} -> ${arr[2]}`).join('\n');
        if (confirm(message)) {
          update(firmwareVersion);
        }
      }
    }
  };

  const reload = () => {
    getFirmwareVersions();
    getHwRevisionFirmwareMappings();
    setTimeout(() => {
      setRows(getRows());
    }, 200);
  };

  return (
    <div className={style.outerContainer}>
      <NavBar
        breadCrumbs={breadCrumbs}
        appendBreadCrumbs={appendBreadCrumbs}
        defaultCurrentCrumb={defaultFirmwareVersionsCrumb}
        defaultCrumbHistory={defaultFirmwareVersionsCrumbs}
      />
      <Box
        component="fieldset"
        p={4}
        width="300px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="75px"
        alignSelf="center"
        maxWidth="75%"
        marginBottom="20px"
        paddingLeft="10px"
        margin="20 auto 20 auto"
      >
        <legend>HW Revision to Firmware Type Mapping</legend>
        <table>
          <thead>
            <tr>
              <th>HW Revision</th>
              <th>Firmware Type</th>
            </tr>
          </thead>
          <tbody>
            {hwRevisionFirmwareMappings.map((hwRevisionFirmware, index) => {
              return (
                <tr key={index}>
                  <td>{hwRevisionFirmware.hwRevision}</td>
                  <td>{hwRevisionFirmware.firmwareType}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
      <div className={style.container}>
        <div className={style.buttonsBar}>
          <button className={style.button} onClick={reload}>
            ↻
          </button>
          <button className={style.button} onClick={saveUpdates}>
            Save
          </button>
          <button className={style.button} onClick={addNewVersion}>
            Add New
          </button>
        </div>
        <table>
          <thead>
            <tr>
              <th>Version Name</th>
              <th>Group Name</th>
              <th>Stop Gap</th>
              <th>Firmware Type</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => {
              return (
                <tr key={row.id}>
                  <td>
                    <ContentEditable html={row.versionName || ''} onChange={(e) => updateRow(row.id, 'versionName', e.currentTarget.textContent)} />
                  </td>
                  <td>
                    <Autocomplete
                      freeSolo
                      filterOptions={(options) => {
                        // Defer to row value here since the state values for input and autofill are sometimes briefly out of sync with one another
                        const inputVal = row.groupName?.toLowerCase() || '';
                        return options.filter((option) => option.startsWith(inputVal) && option !== inputVal);
                      }}
                      options={['release', 'testing']}
                      inputValue={row.groupName}
                      onInputChange={(event, value, reason) => {
                        // avoid synchronization change events from autocomplete wrapper
                        if ((reason !== 'reset' || !!value) && row.groupName !== value) {
                          updateRow(row.id, 'groupName', value);
                        }
                      }}
                      renderInput={(params) => (
                        <div className={style.autoCompleteDiv} ref={params.InputProps.ref}>
                          <input type="text" {...params.inputProps} />
                        </div>
                      )}
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <input type="checkbox" defaultChecked={row.stopGap} onChange={() => updateRow(row.id, 'stopGap', !row.stopGap)}></input>
                  </td>
                  <td>
                    {!firmwareVersionById[row.id] ? (
                      <select
                        className={style.fwTypeSelect}
                        style={{ maxWidth: '150px' }}
                        defaultValue={row.firmwareType || FirmwareType.UNKNOWN}
                        onChange={(event) => {
                          updateRow(row.id, 'firmwareType', firmwareTypeList[event.target.selectedIndex]);
                        }}
                      >
                        {firmwareTypeList.map((fwType, index) => (
                          <option value={fwType} key={index}>
                            {fwType}
                          </option>
                        ))}
                      </select>
                    ) : (
                      row.firmwareType
                    )}
                  </td>
                  <td></td>
                  <td>{row.unsaved ? <ContentEditable html={row.created || ''} onChange={(e) => updateRow(row.id, 'created', e.currentTarget.textContent)} /> : row.created}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    firmwareVersionById: selectFirmwareVersionByIdSelector(state),
    breadCrumbs: getBreadcrumbsSelector(state),
    hwRevisionFirmwareMappings: getHwRevisionFirmwareMappingsSelector(state)
  }),
  (dispatch: Function) => ({
    getFirmwareVersions: () => {
      dispatch(getDeviceFirmwareVersionOverviewThunk());
    },
    getHwRevisionFirmwareMappings: () => {
      dispatch(getHwRevisionsAndFirmwareMappingsThunk());
    },
    update: (firmwareVersion: FirmwareVersion) => {
      dispatch(updateFirmwareVersionThunk(firmwareVersion));
    },
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[]) => {
      dispatch(appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory }));
    }
  })
);

export default compose(connectRedux)(FirmwareVersionOverview) as React.ComponentType;
