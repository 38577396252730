import { ReduxState } from '@redux/types';
import { InboxMessages, InboxMessagesDetails, InboxMessagesState } from './inboxMessagesTypes';

const getInboxMessagesState = (state: ReduxState): InboxMessagesState => {
  return state.inboxMessages;
};

export const getOrganizationInboxMessagesSelector = (state: ReduxState): InboxMessages[] => {
  return getInboxMessagesState(state).inboxMessagesList;
};

export const getInboxMessagesDetailsSelector = (state: ReduxState): Nullable<InboxMessagesDetails> => {
  return getInboxMessagesState(state).inboxMessagesDetails;
};
