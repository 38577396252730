import { Box, Checkbox, MenuItem, TextField as MuiTextField, styled, Tab } from '@mui/material';
import React, { useState } from 'react';
import { ActivityListItem } from '@redux/carePlan/carePlanTypes';
import { DeviceContent, SurveyOptionType } from '@redux/content/contentTypes';
import { Color } from 'utils/color';
import { Button } from '../../components/Button/Button';
import QuestionIcon from '../../components/QuestionIcon/QuestionIcon';
import { TextField } from '../../components/TextField';
import { Text } from '../../components/Typography';
import { getQuestionIconType } from '../../utils/carePlanUtils';
import { normalizeEnumName } from '../../utils/content';
import { ActivityDrawerTabs } from './ActivityDrawerTabs';
import style from './QuestionLibraryDrawer.scss';

interface Props {
  questions: DeviceContent[];
  onCancel: Function;
  onSubmit: (deviceContentIds: string[]) => void;
  activityListItems: ActivityListItem[];
}

export const StyledTab = styled(Tab)({
  '&.MuiTab-root': {
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'Outfit',
    color: Color.GRAY
  },
  '&.Mui-selected': {
    color: Color.GRAY_BLUE
  }
});

export const TabIndicatorProps = { sx: { backgroundColor: Color.GRAY_BLUE } };

export const QuestionLibraryDrawer: React.FC<Props> = ({ questions, onCancel, onSubmit, activityListItems }) => {
  const checked = {};
  activityListItems.map((item) => {
    if (typeof item === 'string') {
      const deviceContent = questions.find((question) => question.id === item);
      if (deviceContent) {
        checked[item] = true;
      }
    }
  });
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>(checked);
  const [searchText, setSearchText] = useState<string>('');
  const [categoryFilter, setCategoryFilter] = useState<string>('');
  const [tabValue, setTabValue] = useState<number>(0);

  const categories = new Set<string>();

  const allQuestions: DeviceContent[] = [];
  const fixedValueQuestions: DeviceContent[] = [];
  const numberQuestions: DeviceContent[] = [];
  const freeFormQuestions: DeviceContent[] = [];
  const booleanQuestions: DeviceContent[] = [];

  questions.map((deviceContent) => {
    categories.add(normalizeEnumName(deviceContent.deviceContentSubType));
    if (deviceContent.deviceContentPages?.length) {
      const surveyQuestion = deviceContent?.deviceContentPages[0].surveyQuestion;
      if (surveyQuestion?.surveyOptions?.length) {
        let surveyOption = surveyQuestion.surveyOptions[0];
        if (surveyOption) {
          if (surveyOption.optionType === SurveyOptionType.SKIP && surveyQuestion.surveyOptions.length > 1) {
            surveyOption = surveyQuestion.surveyOptions[1];
          }
          if (searchText && !surveyQuestion.questionText.toLowerCase().includes(searchText.toLowerCase())) {
            return;
          }
          if (categoryFilter && normalizeEnumName(deviceContent.deviceContentSubType) !== categoryFilter) {
            return;
          }
        }
        allQuestions.push(deviceContent);
        if (surveyOption.optionType === SurveyOptionType.FIXED_VALUE) {
          fixedValueQuestions.push(deviceContent);
        } else if (surveyOption.optionType === SurveyOptionType.INTEGER || surveyOption.optionType === SurveyOptionType.FLOAT) {
          numberQuestions.push(deviceContent);
        } else if (surveyOption.optionType === SurveyOptionType.STRING) {
          freeFormQuestions.push(deviceContent);
        } else if (surveyOption.optionType === SurveyOptionType.BOOLEAN) {
          booleanQuestions.push(deviceContent);
        }
      }
    }
  });

  let questionsFiltered = allQuestions;
  switch (tabValue) {
    case 0:
      questionsFiltered = allQuestions;
      break;
    case 1:
      questionsFiltered = fixedValueQuestions;
      break;
    case 2:
      questionsFiltered = numberQuestions;
      break;
    case 3:
      questionsFiltered = freeFormQuestions;
      break;
    case 4:
      questionsFiltered = booleanQuestions;
      break;
  }

  const handleCheckboxChange = (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = checkedItems;
    checked[id] = event.target.checked;
    setCheckedItems({
      ...checked
    });
  };

  const onSubmitQuestions = () => {
    const deviceContentIds: Set<string> = new Set<string>([]);
    Object.keys(checkedItems).forEach((key) => {
      if (checkedItems[key]) {
        deviceContentIds.add(key);
      }
    });
    onSubmit(Array.from(deviceContentIds));
  };

  const onSelectAll = (event) => {
    const isChecked = event.target.checked;
    const checked = checkedItems;
    questionsFiltered.map((deviceContent) => {
      checked[deviceContent.id] = isChecked;
    });
    setCheckedItems({
      ...checked
    });
  };

  const allSelected = questionsFiltered.length > 0 && questionsFiltered.every((deviceContent) => checkedItems[deviceContent.id]);

  return (
    <div className={style.container}>
      <Text as="div" variant="h5" className={style.title}>
        Question Library
      </Text>
      <div className={style.filterRow}>
        <TextField placeholder="Search" className={style.searchField} value={searchText} onChange={(e) => setSearchText(e.target.value)} />
        <MuiTextField
          value={categoryFilter}
          onChange={(event) => setCategoryFilter(event.target.value as string)}
          select
          label="Filter by category"
          className={style.categoryFilter}
          InputProps={{ sx: { borderRadius: '7px', height: '44px' } }}
          InputLabelProps={{ style: { top: '-5px', fontSize: '16px' } }}
          inputProps={{ style: { fontSize: '16px' } }}
        >
          <MenuItem value={''}>No Filter</MenuItem>
          {Array.from(categories).map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </MuiTextField>
      </div>
      <ActivityDrawerTabs
        setTabValue={setTabValue}
        tabValue={tabValue}
        allQuestionsLength={allQuestions.length}
        fixedValueQuestionsLength={fixedValueQuestions.length}
        numberQuestionsLength={numberQuestions.length}
        freeFormQuestionsLength={freeFormQuestions.length}
        booleanQuestionsLength={booleanQuestions.length}
      />
      <div className={style.itemList}>
        <div className={style.rowItem}>
          <div className={style.rowItem}>
            <Checkbox checked={allSelected} onChange={(event) => onSelectAll(event)} className={style.checkbox} />
            <Box className={style.boxLabelBox}>
              <Text variant="md" className={style.boxLabel}>
                Select all
              </Text>
            </Box>
          </div>
        </div>
        {Object.values(questionsFiltered).map((deviceContent) => {
          const id = deviceContent.id;
          if (deviceContent.deviceContentPages?.length) {
            const surveyQuestion = deviceContent.deviceContentPages[0].surveyQuestion;
            const firstDeviceContentPage = deviceContent?.deviceContentPages?.length ? deviceContent.deviceContentPages[0] : null;
            if (surveyQuestion) {
              return (
                <div key={id} className={style.rowItem}>
                  <div className={style.rowItem}>
                    <Checkbox checked={checkedItems[id] || false} onChange={handleCheckboxChange(id)} className={style.checkbox} />
                    <Box className={style.boxLabelBox}>
                      <Text variant="md" className={style.boxLabel}>
                        {surveyQuestion.questionText}
                      </Text>
                      <Text as="div" variant="sm" className={style.boxLabelDescription}>
                        {normalizeEnumName(deviceContent.deviceContentSubType)}
                      </Text>
                    </Box>
                  </div>
                  <QuestionIcon type={getQuestionIconType(firstDeviceContentPage, deviceContent?.deviceContentType)} className={style.contentIcon} />
                </div>
              );
            }
          }
        })}
      </div>
      <div className={style.divider} />
      <div className={style.drawerButtonsContainer}>
        <Button variant="outlined" onClick={() => onCancel()} className={style.headerButton}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSubmitQuestions} className={style.headerButton}>
          Submit
        </Button>
      </div>
    </div>
  );
};
