import { SleepStage } from '@redux/activity/activityTypes';
import { PoiType } from '@redux/device/deviceTypes';

export enum Color {
  DARK_BLUE = '#284660',
  LIGHT_BLUE = '#E0EEF5',
  GREEN = '#55dd55',
  YELLOW = '#ffdd44',
  YELLOW_400 = '#FAC315',
  YELLOW_600 = '#CA8204',
  ORANGE = '#ff8844',
  RED = '#ff4444',
  RED_2 = '#FF0000',
  RED_500 = '#F04438',
  RED_600 = '#DE3024',
  RED_700 = '#BB241A',
  PINK_600 = '#dd259d',
  WHITE = '#ffffff',
  BLACK = '#000000',
  // new colors
  NEUTRAL_GRAY_300 = '#F1F1F1',
  NEUTRAL_GRAY_700 = '#C9C9CC',
  GRAY = '#6C809A',
  GRAY_50 = '#F5F7F9',
  GRAY_100 = '#E9EDF0',
  GRAY_200 = '#D7E0E6',
  GRAY_300 = '#BCCAD4',
  GRAY_400 = '#9CAFBE',
  GRAY_700 = '#66768F',
  GRAY_900 = '#485160',
  GRAY_950 = '#2F343C',
  GRAY_BLUE = '#194373',
  DARK_BLUE_NEW = '#0C2545',
  DARK_BLUE_500 = '#2d7cca',
  DARK_BLUE_600 = '#1E61AB',
  DARK_BLUE_700 = '#1A4D8A',
  DARK_GREEN = '#008482',
  ORANGE_600 = '#E04F16',
  LIGHT_GREEN_700 = '#00835E'
}

export const KokoMapColor = {
  WD_CANCELED: '#74f174',
  WD_COMPLETED: '#3f90c5',
  SS_COMPLETED: '#3aa9ef',
  SL_IN_BED: '#69a966',
  SL_TTS: '#7affff',
  SL_ASLEEP: '#a965a9a8',
  BR_C: '#29d2e9',
  BR_F: '#1d911d',
  SS_NONE: '#cfcfcfcf',
  SS_UNKNOWN: '#726d6d',
  SS_AWAKE: '#b9ffffcc',
  SS_ASLEEP: '#cfabcf',
  POI_NONE: '#d10f51',
  POI_UNKNOWN: '#726d6d',
  POI_BEDROOOM: '#feffb7',
  POI_BED: '#aecfacc4',
  DEVICE_DETECTED: 'lightblue',
  DEVICE_OFFLINE: '#f57d04',
  DEVICE_ONLINE: '#83d285',
  SL_CREATED: '#dcc95f',
  SL_WAKE_ALARM_TIME: '#f09eba',
  DV_UPDATE: '#fde60d'
};

export const getSleepStageColor = (sleepStage: SleepStage) => {
  let color = sleepStage === SleepStage.ASLEEP ? KokoMapColor.SS_ASLEEP : KokoMapColor.SS_AWAKE;
  if (sleepStage === SleepStage.NONE) {
    color = KokoMapColor.SS_NONE;
  }
  if (sleepStage === SleepStage.UNKNOWN) {
    color = KokoMapColor.SS_UNKNOWN;
  }
  return color;
};

export const getPoiEventColor = (poiType: PoiType) => {
  let color = poiType === PoiType.BED ? KokoMapColor.POI_BED : KokoMapColor.POI_BEDROOOM;
  if (poiType === PoiType.NONE) {
    color = KokoMapColor.POI_NONE;
  }
  if (poiType === PoiType.UNKNOWN) {
    color = KokoMapColor.POI_UNKNOWN;
  }
  return color;
};

// hex to rgba converter
export const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
