import { Button } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { getAuthPersonIdSelector } from '@redux/oauth/oauthSelectors';
import { logoutThunk } from '@redux/oauth/oauthThunks';
import { getOrganizationByIdSelector } from '@redux/organization/organizationSelectors';
import {
  getPersonByIdSelector,
  getSelectedPatientIdSelector,
  hasAuthUserAdminPermissionSelector,
  hasManageCareTeamPermissionSelector,
  hasPatientAssignPermissionSelector,
  hasPatientClinicalDataInCareTeamPhiReadPermissionSelector,
  hasTeamMemberAccountsCreatePermissionSelector
} from '@redux/person/personSelector';
import { getPersonOverviewThunk } from '@redux/person/personThunks';
import { ReduxState } from '@redux/types';
import { CAIRNS_LOGO_IMAGE_URL } from 'assets/images/images';
import { DropDownMenu } from 'components/DropDownMenu/DropDownMenu';
import { BellIcon, GearIcon, SignOutIcon } from 'components/Icons';
import { Text } from 'components/Typography';
import { ComingSoonLabel } from 'containers/ComingSoonLabel/ComingSoonLabel';
import { isProductionEnvironment } from 'utils/environment';
import { RouteNames } from 'utils/navigationUtils';
import { getInitials } from 'utils/util';
import { useAppDispatch } from '../../hooks';
import style from './NavTopBar.scss';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;

const NavTopBar: React.FC<PropsFromRedux> = ({
  authPersonId,
  personById,
  selectedPatientId,
  hasTeamMemberAccountsCreatePermission,
  hasManageCareTeamPermission,
  hasPatientClinicalDataInCareTeamPhiReadPermission,
  hasAuthUserAdminPermission,
  organizationById,
  getPersonOverview
}) => {
  const authPerson = personById[authPersonId || ''];
  const patientDashboardPath = generatePath(RouteNames.PATIENT_DASHBOARD, { userId: selectedPatientId ?? authPersonId ?? '' });
  const organization = authPerson?.organizationId ? organizationById[authPerson?.organizationId] : undefined;
  const logoImgUrl = organization?.logoImageUrl ?? CAIRNS_LOGO_IMAGE_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const personInitials = getInitials(authPerson?.fullName ?? '');

  const isActive = (name: string) => {
    return location.pathname.match(`^/.*${name}/?$`);
  };

  useEffect(() => {
    getPersonOverview({});
  }, []);

  const onCareTeamClick = () => {
    navigate(RouteNames.CARE_TEAM);
  };

  const onNewTeamMemberClick = () => {
    navigate(RouteNames.NEW_TEAM_MEMBER);
  };

  const onSettingsClick = () => {
    navigate(RouteNames.ADMIN);
  };

  const onLogOutClick = () => {
    dispatch(logoutThunk());
  };

  return (
    <div className={style.topHeaderContainer}>
      <div className={style.headerContainer}>
        <div className={style.logoContainer}>
          <a href="/">
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = CAIRNS_LOGO_IMAGE_URL;
              }}
              src={logoImgUrl}
              alt="Logo"
              className={style.logoImage}
            />
          </a>
        </div>

        {/* Dashboard, Patients, and Manage */}
        <div className={style.groupContainer}>
          {hasPatientClinicalDataInCareTeamPhiReadPermission && (
            <div className={style.dashboardTab}>
              <Button disabled className={style.tab} onClick={() => navigate(patientDashboardPath)}>
                Dashboard
              </Button>
              <ComingSoonLabel containerClassName={style.label} />
            </div>
          )}
          {hasPatientClinicalDataInCareTeamPhiReadPermission && (
            <Button className={classNames(style.tab, isActive('patients') ? style.active : {})} onClick={() => navigate(RouteNames.ALL_PATIENTS)}>
              Patients
            </Button>
          )}
          {!isProductionEnvironment() && hasPatientClinicalDataInCareTeamPhiReadPermission && (
            <Button className={classNames(style.tab, isActive('tasks') ? style.active : {})} onClick={() => navigate(RouteNames.TASKS)}>
              Tasks
            </Button>
          )}
          {!isProductionEnvironment() && hasPatientClinicalDataInCareTeamPhiReadPermission && (
            <Button className={classNames(style.tab, isActive('inbox') ? style.active : {})} onClick={() => navigate(RouteNames.INBOX)}>
              Inbox
            </Button>
          )}
          <DropDownMenu
            menuText="Manage"
            customButtonClass={style.tab}
            menuItems={[
              hasManageCareTeamPermission && {
                onClick: onCareTeamClick,
                text: 'Care team'
              },
              hasTeamMemberAccountsCreatePermission && {
                onClick: onNewTeamMemberClick,
                text: 'New team member'
              }
            ]}
          />
        </div>
        <div className={style.rightMenuContainer}>
          <div className={style.bellContainer}>
            <BellIcon />
          </div>
          <div>
            <DropDownMenu
              customMenuElement={(onMenuClick) => (
                <button className={style.avatar} onClick={onMenuClick}>
                  <Text variant="md" className={style.personInitials}>
                    {personInitials}
                  </Text>
                </button>
              )}
              menuItems={[
                {
                  customMenuItem: () => (
                    <>
                      <div className={style.smallAvatar}>
                        <Text variant="sm" className={style.personInitials}>
                          {personInitials}
                        </Text>
                      </div>
                      <Text variant="md" className={style.menuItemText}>
                        {authPerson?.fullName}
                      </Text>
                    </>
                  )
                },
                hasAuthUserAdminPermission && {
                  text: 'Settings',
                  onClick: onSettingsClick,
                  icon: GearIcon
                },
                {
                  text: 'Log out',
                  onClick: onLogOutClick,
                  icon: SignOutIcon
                }
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      authPersonId: getAuthPersonIdSelector(state),
      personById: getPersonByIdSelector(state),
      selectedPatientId: getSelectedPatientIdSelector(state),
      hasTeamMemberAccountsCreatePermission: hasTeamMemberAccountsCreatePermissionSelector(state),
      hasManageCareTeamPermission: hasManageCareTeamPermissionSelector(state),
      hasPatientAssignPermission: hasPatientAssignPermissionSelector(state),
      hasPatientClinicalDataInCareTeamPhiReadPermission: hasPatientClinicalDataInCareTeamPhiReadPermissionSelector(state),
      hasAuthUserAdminPermission: hasAuthUserAdminPermissionSelector(state),
      organizationById: getOrganizationByIdSelector(state)
    };
  },
  (dispatch: Function) => ({
    getPersonOverview: getPersonOverviewThunk
  })
);

export default compose(connectRedux)(NavTopBar) as React.ComponentType;
