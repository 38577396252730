import React from 'react';
import { EmptyListArea } from 'components/EmptyListArea/EmptyListArea';
import { UsersThreeIcon } from 'components/Icons';

type Props = { onAddPress: () => void; isButtonVisible: boolean };

const NoPatients: React.FC<Props> = ({ onAddPress, isButtonVisible }) => (
  <EmptyListArea
    title="No patients to display yet"
    description="Once you add a patient, their details will appear here, giving you a clear overview of their health journey."
    icon={UsersThreeIcon}
    buttonText="Add patient"
    onAddPress={onAddPress}
    isButtonVisible={isButtonVisible}
  />
);

export default NoPatients;
