import classNames from 'classnames';
import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/Button/Button';
import { CircledCustomIcon, MessagesIcon } from 'components/Icons';
import { Text } from 'components/Typography';
import { getInitials } from 'utils/util';
import { ConversationFeedItem } from '../ConversationFeedItem/ConversationFeedItem';
import style from './ConversationFeedArea.scss';
import { ConversationFeedAreaProps } from './ConversationFeedArea.types';

export const ConversationFeedArea: React.FC<ConversationFeedAreaProps> = memo(({ inboxMessagesDetails, onSelectTaskSwitch, sendResponse }) => {
  const navigate = useNavigate();
  const onPressOpenDashboard = useCallback(() => {
    navigate(`/patients/${inboxMessagesDetails.patientId}`);
  }, [inboxMessagesDetails]);

  return (
    <div className={style.conversationArea}>
      <div className={style.chatFeedHeader}>
        <div className={style.avatar}>
          <Text variant="lg" weight={500} className={style.initials}>
            {getInitials(inboxMessagesDetails.patientFullName)}
          </Text>
        </div>
        <div className={style.conversationLabel}>
          <Text className={classNames(style.conversationLabelText, style.personName)} variant="nlg">
            {inboxMessagesDetails.patientFullName}
          </Text>
          <Text className={style.conversationLabelText} variant="md">
            {inboxMessagesDetails.patientEmail}
          </Text>
        </div>
        <Button onClick={onPressOpenDashboard} variant="outlined">
          Open Dashboard
        </Button>
      </div>
      <div className={style.chatFeedContainer}>
        {inboxMessagesDetails.listOfInboxMessageDetailContentViews.map((item, index) => (
          <ConversationFeedItem key={index} message={item} sendResponse={sendResponse} onSelectTaskSwitch={onSelectTaskSwitch}></ConversationFeedItem>
        ))}
      </div>
    </div>
  );
});
