import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { getPersonByIdSelector } from '@redux/person/personSelector';
import { getPersonByIdThunk } from '@redux/person/personThunks';
import { PatientInfoDrawer } from 'components/PatientInfoDrawer/PatientInfoDrawer';
import { ReduxState } from 'redux/types';

export type ViewPersonProps = {
  personId: string;
  showDialog: boolean;
  onDialogClose: () => void;
};

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & ViewPersonProps;

const PatientDashboardInfoDrawerModule: React.FC<Props> = ({ personId, showDialog, onDialogClose, personByIdSelector, fetchPersonById }) => {
  useEffect(() => {
    if (showDialog) {
      fetchPersonById(personId);
    }
  }, [personId, showDialog]);

  return <PatientInfoDrawer person={personByIdSelector[personId]} visible={showDialog} onClose={onDialogClose} />;
};

const connectRedux = connect(
  (state: ReduxState) => ({
    personByIdSelector: getPersonByIdSelector(state)
  }),
  {
    fetchPersonById: getPersonByIdThunk
  }
);

export const PatientDashboardInfoDrawer = compose(connectRedux)(PatientDashboardInfoDrawerModule);
