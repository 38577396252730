declare global {
  interface Window {
    __data__: any;
    __store__: any;
  }
}
// prepare store

declare global {
  interface Window {
    env: any;
    __root__: any;
  }
}
const serverData = window?.__data__ || {};

import { Buffer } from 'buffer';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import ApiClient from './ApiClient';
import App from './App';
import Config from './config/config';
import { hydrateStartThunk } from './redux/hydration/hydrationThunks';
import { createStore } from './redux/store';

window.Buffer = Buffer;

Bugsnag.start({
  apiKey: Config.getBugSnagKey(),
  plugins: [new BugsnagPluginReact()],
  appVersion: Config.getAppVersion(),
  releaseStage: Config.getDeploymentEnv(),
  enabledReleaseStages: ['production', 'staging']
});

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

const dest: any = document.getElementById('content');

const apiClient = ApiClient.getInstance();

const store = createStore(apiClient);
if (!window?.__store__) {
  apiClient.setStore(store);
  window.__store__ = store;
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch | any;

apiClient.setCsrfToken(serverData.CSRF_TOKEN);
window.__data__.CSRF_TOKEN = undefined;
window.__data__.store = undefined;

store.dispatch(hydrateStartThunk());

let root = window.__root__;
if (!root) {
  root = hydrateRoot(
    dest,
    <Provider store={store}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Provider>
  );
  window.__root__ = root;
} else {
  root.render(
    <Provider store={store}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Provider>
  );
}

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', () => {
    const NewApp = require('./App').default;
    root.render(
      <Provider store={store}>
        <ErrorBoundary>
          <NewApp />
        </ErrorBoundary>
      </Provider>
    );
  });
}
