import React from 'react';
import { SVGBasicType } from 'types/global';
import { Color } from 'utils/color';

export const SendIcon = ({ width = 24, height = 24, color = Color.GRAY_950 }: SVGBasicType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4898 8.90708L4.36484 1.41567C4.1436 1.29157 3.88983 1.23771 3.63726 1.26126C3.38468 1.2848 3.14525 1.38464 2.95076 1.5475C2.75628 1.71037 2.61594 1.92855 2.54841 2.17306C2.48087 2.41758 2.48933 2.67686 2.57265 2.91645L4.99452 9.98442C4.99421 9.98702 4.99421 9.98964 4.99452 9.99223C4.99409 9.99482 4.99409 9.99746 4.99452 10L2.57265 17.0836C2.50592 17.2721 2.48538 17.4738 2.51274 17.6719C2.5401 17.87 2.61458 18.0586 2.72991 18.2219C2.84525 18.3852 2.99808 18.5185 3.17557 18.6105C3.35307 18.7026 3.55005 18.7507 3.74999 18.7508C3.96692 18.7503 4.18004 18.6938 4.36874 18.5868L17.4867 11.0829C17.6802 10.9745 17.8414 10.8165 17.9537 10.6253C18.066 10.434 18.1254 10.2163 18.1258 9.99455C18.1262 9.77276 18.0676 9.55485 17.956 9.3632C17.8443 9.17154 17.6837 9.01304 17.4906 8.90395L17.4898 8.90708ZM3.74999 17.5008V17.4938L6.10468 10.6258H10.625C10.7908 10.6258 10.9497 10.56 11.0669 10.4428C11.1841 10.3256 11.25 10.1666 11.25 10.0008C11.25 9.83507 11.1841 9.6761 11.0669 9.55889C10.9497 9.44168 10.7908 9.37583 10.625 9.37583H6.11093L3.75468 2.5102L3.74999 2.50083L16.875 9.98755L3.74999 17.5008Z"
        fill={color}
      />
    </svg>
  );
};
