import classNames from 'classnames';
import React, { useState } from 'react';
import style from './HorizontalTabs.scss';

interface TabType {
  label: string;
  content: React.ReactNode;
}

interface HorizontalTabsProps {
  tabs: TabType[];
  tabsClassName?: string;
  tabsContentClassname?: string;
  buttonClassName?: string;
}

const HorizontalTabs: React.FC<HorizontalTabsProps> = ({ tabs, tabsClassName, tabsContentClassname, buttonClassName }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className={style.container}>
      <div className={classNames(style.tabs, tabsClassName)}>
        {tabs.map((tab, index) => (
          <button key={index} className={classNames(style.tab, buttonClassName, activeTab === index ? style.active : {})} onClick={() => setActiveTab(index)}>
            {tab.label}
          </button>
        ))}
      </div>
      <div className={classNames(style.tabPanelContainer, tabsContentClassname)}>{tabs[activeTab].content}</div>
    </div>
  );
};

export default HorizontalTabs;
