import { FunnelUX } from '@redux/analytics/analyticsTypes';
import { getStripeCheckoutUrl } from './environment';
import { getQueryParamObject } from './util';

export enum RouteNames {
  LOGIN = '/login',
  HOME = '/',
  HOME_OLD = '/home',
  CREATE_ACCOUNT = '/create-account',
  CREATE_ACCOUNT_REGISTER = '/create-account/register',
  CREATE_ACCOUNT_PRE_INTERSTITIAL = '/create-account/pre-interstitial',
  CREATE_ACCOUNT_POST_INTERSTITIAL = '/create-account/post-interstitial',
  LINK_TO_MOBILE = '/link-to-mobile',
  CHECKOUT_PRE_INTERSTITIAL = '/payment/pre-interstitial',
  PAYMENT_CONFIRMATION = '/payment/confirmation',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  INVITE = '/invite',
  DASHBOARD = '/dashboard',
  DELETE_ACCOUNT = '/delete-account',
  DELETE_ACCOUNT_CONFIRM = '/delete-account-confirm',
  DEMO_ACCESS_CODES = '/accessCodes',
  USERS = '/users',
  ALL_USERS = '/allUsers',
  ALL_FLAGS = '/allFlags',
  NEW_SLEEP_COACH_CHATS = '/newSleepCoachChats',
  SLEEP_SCHEDULE_REVIEW = '/sleepScheduleReview',
  SLEEP_SCHEDULE_REVIEW_DETAILS = '/sleepScheduleReview/:sleepScheduleId',
  FLAG_DETAILS = '/flagDetails/:flagId',
  FLAG_MANAGER = '/flagManager',
  FLAGGED_USERS = '/flaggedUsers',
  USER_DETAILS = '/user/:userId',
  KOKO_MAP = '/user/:userId/kokoMap',
  USER_DETAILS_SLEEP_COACH_CHAT = '/user/:userId/sleepCoachChat',
  DEVICE_OVERVIEW = '/devices/:deviceId?',
  // PERSON_PERMISSIONS = '/permissions',
  PERSON_PROGRAM = '/program/:email?',
  FIRMWARE_VERSIONS = '/firmwareVersions',
  DEVICE_PROVISIONING = '/deviceProvisioning',
  CONTENT_MANAGER = '/contentManager',
  ALL_CONTENT = '/allContent',
  ALL_TRACKS = '/allTracks',
  TRACK = '/track/:subType',
  DEVICE_CONTENT_DETAILS = '/deviceContentDetails/:deviceContentId?',
  PAGE_DETAILS_UPDATE = '/updateDeviceContentPage/:deviceContentPageId',
  PAGE_DETAILS_ADD = '/addDeviceContentPage/:deviceContentId/:pageType',
  ALL_PATIENTS = '/patients',
  TASKS = '/tasks',
  INBOX = '/inbox',
  PATIENT_DASHBOARD = '/patients/:userId',
  EDIT_PATIENT = '/patients/:userId/editPatient',
  NEW_ACTIVITY = '/patients/:userId/newActivity',
  NEW_PATIENT = '/patients/newPatient',
  NEW_TEAM_MEMBER = '/newTeamMember',
  ASSIGN_CARE_PLAN = '/assignCarePlan',
  CARE_TEAM = '/careTeam',
  CARE_GROUP = '/careGroup',
  MANAGE_CARE_PLANS = '/manageCarePlans',
  ADMIN = '/admin',
  ADMIN_ORGANIZATIONS = '/admin/organizations',
  ADMIN_USERS = '/admin/users',
  ADMIN_PERMISSION_ROLES = '/admin/permissionRoles'
}

export const FULLSLEEP_COM_SITE_URL: string = 'https://www.fullsleep.com';
export const FREE_TRIAL_URL: string = 'https://www.fullsleep.com/free-trial';

export const getStripeCheckoutParameterizedUrl = (email: string) => `${getStripeCheckoutUrl()}?prefilled_email=${encodeURIComponent(decodeParam(email))}`;

const getFunnelUxExperience = (email: string, funnelDelegator?: Nullable<(email: string) => FunnelUX>): FunnelUX => {
  if (!funnelDelegator) {
    return FunnelUX.INTERSTITIAL_AFTER_CREATE_ACCOUNT;
  }
  return funnelDelegator(email);
};

export const getFirstFunnelPageUrl = (email: string, priceValue: string, funnelDelegator?: Nullable<(email: string) => FunnelUX>): string => {
  const funnel: FunnelUX = getFunnelUxExperience(email, funnelDelegator);
  let returnUrl = '';
  switch (funnel) {
    case FunnelUX.INTERSTITIAL_AFTER_CREATE_ACCOUNT:
      returnUrl = parameterizeUrl(RouteNames.CREATE_ACCOUNT_REGISTER, email, priceValue);
      break;
    case FunnelUX.INTERSTITIAL_BEFORE_CREATE_ACCOUNT:
    case FunnelUX.INTERSTITIAL_BEFORE_AFTER_CREATE_ACCOUNT:
      returnUrl = parameterizeUrl(RouteNames.CREATE_ACCOUNT_PRE_INTERSTITIAL, email, priceValue);
      break;
    case FunnelUX.INTERSTITIAL_BEFORE_CHECKOUT:
      returnUrl = parameterizeUrl(RouteNames.CHECKOUT_PRE_INTERSTITIAL, email, priceValue);
      break;
  }

  return addAllQueryParams(returnUrl);
};

export const getAccountCreatedUrl = (email: string, priceValue: string, funnelDelegator?: Nullable<(email: string) => FunnelUX>) => {
  const funnel: FunnelUX = getFunnelUxExperience(email, funnelDelegator);
  let returnUrl = '';
  switch (funnel) {
    case FunnelUX.INTERSTITIAL_AFTER_CREATE_ACCOUNT:
    case FunnelUX.INTERSTITIAL_BEFORE_AFTER_CREATE_ACCOUNT:
      returnUrl = parameterizeUrl(RouteNames.CREATE_ACCOUNT_POST_INTERSTITIAL, email, priceValue);
      break;
    case FunnelUX.INTERSTITIAL_BEFORE_CREATE_ACCOUNT:
      returnUrl = getStripeCheckoutParameterizedUrl(email);
      break;
    case FunnelUX.INTERSTITIAL_BEFORE_CHECKOUT:
      returnUrl = 'TBD...';
      break;
  }
  return addAllQueryParams(returnUrl);
};

export const addAllQueryParams = (url: string): string => {
  const searchParams = new URLSearchParams(document.location?.search);
  let returnUrl = url;
  searchParams?.forEach((value, key) => {
    if (key !== 'email' && key !== 'priceValue') {
      returnUrl += url.includes('?') ? '&' : '?';
      returnUrl += `${key}=${value}`;
    }
  });
  return returnUrl;
};
export const getEmailParam = (location: any): string => decodeParam(getQueryParamObject(location?.search)?.email || '');
export const getPriceParam = (location: any): string => decodeParam(getQueryParamObject(location?.search)?.priceValue || '');
export const getPriceParamOrDefault = (location: any): string => getPriceParam(location) || '129';

export const cameFromStripe = (location: any): boolean => !!getQueryParamObject(location?.search)?.csId;

export const parameterizeUrl = (url: string, email: string, priceValue: string) =>
  `${url}${email ? '?email=' + encodeURIComponent(decodeParam(email)) : ''}${priceValue ? `${email ? '&' : '?'}priceValue=` + encodeURIComponent(decodeParam(priceValue)) : ''}`;

export const isExternalUrl = (url: string) => url.startsWith(getStripeCheckoutUrl()) || url.startsWith(FULLSLEEP_COM_SITE_URL);

const decodeParam = (param: string): string => decodeURIComponent(param)?.replace(' ', '+');

export const isUserDetailSleepCoachChatRoute = (pathname: string): boolean => pathname.endsWith('/sleepCoachChat');
