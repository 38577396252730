import { Drawer } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { getTaskDetailsSelector } from '@redux/tasks/tasksSelector';
import { clearSelectedTaskDetailsThunk, getTaskDetailsThunk } from '@redux/tasks/tasksThunks';
import { TaskStatusEnum } from '@redux/tasks/tasksTypes';
import { ReduxState } from '@redux/types';
import { CloseIconButton } from 'components/Button/CloseButton/CloseIconButton';
import { DropDownMenu } from 'components/DropDownMenu/DropDownMenu';
import { XSquareIcon } from 'components/Icons';
import { StatusLabel } from 'components/StatusLabel/StatusLabel';
import { Text } from 'components/Typography';
import { PersonInfoArea } from 'containers/Tasks/components/PersonInfoArea/PersonInfoArea';
import { TaskLogs } from 'containers/Tasks/components/TaskLogs/TaskLogs';
import { TASK_LABEL_STATUSES } from 'containers/Tasks/Tasks.const';
import { Color } from 'utils/color';
import { getFormatedTimeIgnoreTimezone } from 'utils/time';
import style from './TaskInfoDrawer.scss';

type TaskPersonProps = {
  taskId: Nullable<string>;
  onClose: () => void;
  onCancelTask?: (taskId: string) => void;
};

type Props = ConnectedProps<typeof connectRedux> & TaskPersonProps;

const TaskInfoDrawer: React.FC<Props> = ({ onClose, taskId, onCancelTask, taskDetails, fetchTaskDetails, clearTaskDetails }) => {
  useEffect(() => {
    if (taskId) {
      if (taskDetails) clearTaskDetails();

      fetchTaskDetails(taskId);
    }
  }, [taskId]);

  useEffect(() => {
    return () => {
      clearTaskDetails();
    };
  }, []);

  if (!taskId) {
    return null;
  }

  const actualStatus = taskDetails?.status ? TASK_LABEL_STATUSES[taskDetails.status] : null;

  return (
    taskId && (
      <Drawer anchor="right" open={true} onClose={onClose}>
        <div className={style.drawerContainer}>
          <div className={style.drawerButtonHeader}>
            {taskDetails && (
              <DropDownMenu
                menuText="Actions"
                menuItems={[
                  {
                    text: 'Cancel',
                    textClassName: style.redText,
                    icon: () => <XSquareIcon width={16} height={16} color={Color.RED_600} />,
                    onClick: onCancelTask && (() => onCancelTask(taskId)),
                    disabled: !onCancelTask || taskDetails?.status !== TaskStatusEnum.SCHEDULED
                  }
                ]}
              />
            )}
            <CloseIconButton onClick={onClose} />
          </div>
          <div className={style.content}>
            {taskDetails && (
              <>
                <div className={style.titleContainer}>
                  {actualStatus && <StatusLabel label={actualStatus.label} containerClassName={style.topMargin} status={actualStatus.type} />}
                  <Text variant="h5" weight={500} className={style.topMargin}>
                    Task
                  </Text>
                  {taskDetails?.scheduledForDateTime && (
                    <Text variant="md" className={style.date}>
                      Scheduled for{' '}
                      <Text variant="md" className={style.dateGray}>
                        {getFormatedTimeIgnoreTimezone(taskDetails.scheduledForDateTime)}
                      </Text>
                    </Text>
                  )}
                </div>
                <PersonInfoArea assignee={taskDetails.assignee} />
                {taskDetails?.logs?.length && <TaskLogs logsList={taskDetails.logs} />}
              </>
            )}
          </div>
        </div>
      </Drawer>
    )
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    taskDetails: getTaskDetailsSelector(state)
  }),
  {
    fetchTaskDetails: getTaskDetailsThunk,
    clearTaskDetails: clearSelectedTaskDetailsThunk
  }
);

export default compose(connectRedux)(TaskInfoDrawer);
