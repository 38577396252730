import React from 'react';
import { Text } from 'components/Typography';
import { ComingSoonLabel } from 'containers/ComingSoonLabel/ComingSoonLabel';
import { SVGBasicType } from 'types/global';
import { Color } from 'utils/color';
import style from './BasicCard.scss';

type Props = {
  title?: string;
  imgSrc?: string;
  icon: React.FC<SVGBasicType>;
  size?: 'sm' | 'lg';
  hasComingSoonLabel?: boolean;
  value?: string | number;
};

export const BasicCard: React.FC<Props> = ({ title, icon: Icon, size = 'lg', hasComingSoonLabel = false, value = '-' }) => {
  const isLarge = size === 'lg';
  return (
    <div className={isLarge ? style.card : style.smallCard}>
      <div className={style.content}>
        <Icon width={32} height={32} color={Color.GRAY} />
        <div className={style.textWrapper}>
          <Text variant={isLarge ? 'xl' : 'nlg'} className={style.title}>
            {title}
          </Text>
          {hasComingSoonLabel ? (
            <ComingSoonLabel />
          ) : (
            <Text variant="h5" weight={500}>
              {value || '-'}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};
