import { GridRowData, GridRowsProp } from '@mui/x-data-grid';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { QuickSearchField } from './QuickSearchField';

export interface ClientQuickSearchProps {
  rows: GridRowsProp;
  setRows: Dispatch<SetStateAction<GridRowsProp>>;
  excludedColumns?: Nullable<string[]>;
}

const escapeRegExp = (value: string): string => value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

export const GridClientQuickSearch: React.FC<ClientQuickSearchProps> = ({ rows, setRows, excludedColumns }) => {
  const [searchText, setSearchText] = useState<string>('');

  const clientSideFilter = (searchValue: string): GridRowsProp => {
    const searchRegex: RegExp = new RegExp(escapeRegExp(searchValue), 'i');
    return rows.filter((row: GridRowData) =>
      Object.keys(row)
        .filter((column) => !excludedColumns?.includes(column))
        .some((field: any) => searchRegex.test(row?.[field]?.toString()))
    );
  };

  const requestSearch = (searchValue: string): void => {
    setSearchText(() => searchValue);
    setRows(() => clientSideFilter(searchValue));
  };

  return <QuickSearchField searchText={searchText} requestSearch={requestSearch} />;
};
