import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { memo, useEffect, useState } from 'react';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import style from './SelectButton.scss';

interface Props {
  options: string[];
  activeIndex: number;
  onClick: (selectedIndex: number) => void;
  label?: Nullable<string>;
  customWidth?: Nullable<string>;
  customPadding?: Nullable<string | number>;
  disableConfirmation?: Nullable<boolean>;
  disableSelection?: Nullable<boolean>;
  placeholderOption?: Nullable<string>;
  revertToInitialState?: Nullable<boolean>;
}

export const SelectButton: React.FC<Props> = memo(
  ({ options, activeIndex, onClick, label, customWidth, customPadding, disableConfirmation, disableSelection, placeholderOption, revertToInitialState }) => {
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number>(activeIndex);
    const [fallbackIndex, setFallbackIndex] = useState<number>(activeIndex);

    useEffect(() => {
      setFallbackIndex(() => selectedIndex);
      setSelectedIndex(() => activeIndex);
      setOpenDialog(() => false);
    }, [activeIndex]);

    useEffect(() => {
      if (revertToInitialState) {
        setOpenDialog(() => false);
        setSelectedIndex(() => activeIndex);
        setFallbackIndex(() => activeIndex);
      }
    }, [revertToInitialState]);

    const onMenuItemSelect = (index: number) => {
      if (index !== selectedIndex) {
        if (!disableConfirmation) {
          setFallbackIndex(() => selectedIndex);
          setSelectedIndex(() => index);
          setOpenDialog(() => true);
        } else {
          setSelectedIndex(() => index);
          onClick(index);
        }
      }
    };

    const onSubmitDialog = () => {
      onClick(selectedIndex);
      setOpenDialog(false);
    };

    const onCloseDialog = () => {
      setSelectedIndex(fallbackIndex);
      setOpenDialog(false);
    };

    return (
      <React.Fragment>
        <FormControl variant="outlined">
          <InputLabel>{label}</InputLabel>
          <Select
            value={selectedIndex}
            disabled={!!disableSelection}
            label={label}
            SelectDisplayProps={{ style: { padding: customPadding ?? 0, backgroundColor: 'white' } }}
            displayEmpty={!!placeholderOption}
            MenuProps={{ classes: { paper: style.menuItem } }}
            style={{ width: customWidth || '160px' }}
          >
            {!!placeholderOption && (
              <MenuItem value={-1} disabled>
                {placeholderOption}
              </MenuItem>
            )}
            {options.map((option, index) => (
              <MenuItem key={option} value={index} selected={index === selectedIndex} onClick={() => onMenuItemSelect(index)}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ConfirmationDialog
          open={!disableConfirmation && openDialog}
          dialogText={`ARE YOU SURE YOU WANT TO CHANGE ${label?.toUpperCase()} TO: ${options[selectedIndex]?.trim()}?`}
          proceedEvent={onSubmitDialog}
          closeEvent={onCloseDialog}
        />
      </React.Fragment>
    );
  }
);
