import { TaskStatusEnum } from '@redux/tasks/tasksTypes';
import { AVAILABLE_STATUS_LABELS } from 'components/StatusLabel/StatusLabel';

type TaskLabelType = {
  type: AVAILABLE_STATUS_LABELS;
  label: string;
};

export const TASK_LABEL_STATUSES: { [key in TaskStatusEnum | 'Default']: TaskLabelType } = {
  [TaskStatusEnum.SCHEDULED]: {
    type: AVAILABLE_STATUS_LABELS.Waiting,
    label: 'Scheduled'
  },
  [TaskStatusEnum.IN_PROGRESS]: {
    type: AVAILABLE_STATUS_LABELS.InProgress,
    label: 'In progress'
  },
  [TaskStatusEnum.COMPLETED]: {
    type: AVAILABLE_STATUS_LABELS.Success,
    label: 'Completed'
  },
  [TaskStatusEnum.CANCELED]: {
    type: AVAILABLE_STATUS_LABELS.Canceled,
    label: 'Canceled'
  },
  [TaskStatusEnum.ERROR]: {
    type: AVAILABLE_STATUS_LABELS.Error,
    label: 'Error'
  },
  [TaskStatusEnum.EXPIRED]: {
    type: AVAILABLE_STATUS_LABELS.Expired,
    label: 'Expired'
  },
  [TaskStatusEnum.DELIVERED]: {
    type: AVAILABLE_STATUS_LABELS.Delivered,
    label: 'Delivered'
  },
  Default: {
    type: AVAILABLE_STATUS_LABELS.Canceled,
    label: '   -   '
  }
};
