import { Avatar, Box, CardHeader } from '@material-ui/core';
import moment from 'moment-timezone';
import React from 'react';
import { PersonWorkflowComment } from '@redux/content/contentTypes';
import style from './FlagCommentBullet.scss';

interface Props {
  personWorkflowComment: PersonWorkflowComment;
  authorInitials: string;
}

export const FlagCommentBullet: React.FC<Props> = ({ personWorkflowComment, authorInitials }) => (
  <CardHeader
    avatar={<Avatar className={style.avatar}>{authorInitials}</Avatar>}
    className={style.cardHeader}
    title={
      <React.Fragment>
        {personWorkflowComment.comment}{' '}
        <Box component="span" className={style.createdStamp}>
          ({moment().diff(moment(personWorkflowComment?.created), 'd')} days)
        </Box>
      </React.Fragment>
    }
  />
);
