import { ThemeProvider } from '@mui/material/styles';
import classNames from 'classnames';
import React from 'react';
import NavTopBar from 'navigation/NavTopBar/NavTopBar';
import { mvpTheme } from 'utils/styles';
import { Text } from '../Typography';
import style from './NavTopBarContentWrapper.scss';

interface Props {
  headerText?: string;
  headerClassName?: string;
  contentClassName?: string;
  centerAlignContent?: boolean;
  children: React.ReactNode;
  headerContent?: React.ReactNode;
}

const NavTopBarContentWrapper: React.FC<Props> = ({ children, headerText, headerClassName, contentClassName, centerAlignContent = false, headerContent }) => {
  return (
    <ThemeProvider theme={mvpTheme}>
      <div className={style.topContainer}>
        <NavTopBar />
        <div className={style.container}>
          {headerText || headerContent ? (
            <div className={classNames(style.header, headerClassName)}>
              {headerText && <Text variant="h5">{headerText}</Text>}
              {headerContent}
            </div>
          ) : null}
          <div className={classNames(style.content, centerAlignContent && style.centerAligned, contentClassName)}>{children}</div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default NavTopBarContentWrapper;
