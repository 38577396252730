import React from 'react';
import { PersonVitalInsights, PersonVitalTimeFrame, PersonVitalType } from '@redux/person/personTypes';
import { HeartBreathChart } from './HeartBreathChart/HeartBreathChart';
import { SleepChart } from './SleepChart/SleepChart';

export type ChartProps = {
  timeZoneId: string;
  personVitalInsights?: PersonVitalInsights | null;
  vitalType: PersonVitalType;
  timeFrame: PersonVitalTimeFrame;
  minRate?: number;
  maxRate?: number;
  minControlRate?: number;
  maxControlRate?: number;
};

export const Charts: React.FC<ChartProps> = ({ timeZoneId, personVitalInsights, vitalType, timeFrame, minRate, maxRate, minControlRate, maxControlRate }) => {
  const generalProps = {
    timeZoneId,
    personVitalInsights,
    timeFrame,
    minRate,
    maxRate,
    minControlRate,
    maxControlRate
  };

  return vitalType === PersonVitalType.SLEEP ? <SleepChart {...generalProps} /> : <HeartBreathChart {...generalProps} vitalType={vitalType} />;
};
