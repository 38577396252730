import React, { KeyboardEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { getChatGPTDeviceMessagesSelector, getChatGPTMobileMessagesSelector, getChatSleepCoachMessagesSelector, getLatestPersonMessageSelector } from '@redux/chat/chatSelectors';
import {
  changeSleepCoachMessageReadStatusThunk,
  getChatGPTDeviceMessagesThunk,
  getChatGPTMobileMessagesThunk,
  getChatSleepCoachMessagesThunk,
  postChatSleepCoachMessageThunk
} from '@redux/chat/chatThunks';
import { ChatChannelType, ChatMessage } from '@redux/chat/chatTypes';
import { getAuthPersonIdSelector } from '@redux/oauth/oauthSelectors';
import { getPersonByIdSelector, getPersonFromPersonByIdSelector, getUserPermissionsSelector, isLoadingUserPermissionsSelector } from '@redux/person/personSelector';
import { getUserPermissionsThunk } from '@redux/person/personThunks';
import { PERMISSION_ROLE_ID_ADMIN, PERMISSION_ROLE_ID_AGENT } from '@redux/person/personTypes';
import { ReduxState } from 'redux/types';
import { timestampAndRandomCombinedUUIDString } from '../../../../../shared/src/util/uuid';
import ChatComponent from '../../components/Chat/Chat';

const SLEEP_COACH_CHAT_UPDATE_INTERVAL_MS = 20000;

interface ChatProps {
  chatChannelType: ChatChannelType;
  tabSelected?: Nullable<boolean>;
}

type PropsFromRedux = ConnectedProps<typeof connectRedux>;

const Chat: React.FC<ChatProps & PropsFromRedux> = ({
  chatChannelType,
  tabSelected,
  agentId,
  chatGPTDeviceMessages,
  chatGPTMobileMessages,
  chatSleepCoachMessages,
  isLoadingUserPermissions,
  getLatestPersonMessage,
  personById,
  getPersonFromUserId,
  userPermissions,
  fetchUserPermissions,
  fetchChatGPTDeviceMessages,
  fetchChatGPTMobileMessages,
  fetchChatSleepCoachMessages,
  postChatSleepCoachMessage,
  saveChangeSleepCoachMessageReadStatus
}) => {
  const params = useParams();
  const { userId } = params || {};

  const latestPersonMessage = userId ? getLatestPersonMessage(userId) : undefined;
  const person = userId && getPersonFromUserId(userId);

  const isAdminOrAgent = !!userPermissions?.find((p) => p.permissionRoleTypeId === PERMISSION_ROLE_ID_ADMIN || p.permissionRoleTypeId === PERMISSION_ROLE_ID_AGENT);

  useEffect(() => {
    fetchUserPermissions();
  }, []);

  useEffect(() => {
    const fetchMessages = async () => {
      if (userId) {
        if (chatChannelType === ChatChannelType.CHAT_BOT_DEVICE) {
          fetchChatGPTDeviceMessages(userId);
        } else if (chatChannelType === ChatChannelType.CHAT_BOT_MOBILE) {
          fetchChatGPTMobileMessages(userId);
        } else if (chatChannelType === ChatChannelType.SLEEP_COACH) {
          fetchChatSleepCoachMessages(userId);
        }
      }
    };
    const setChatUpdateIntervals = () => {
      return setInterval(() => {
        fetchMessages();
      }, SLEEP_COACH_CHAT_UPDATE_INTERVAL_MS);
    };

    let updateChatMessagesInterval;
    if (userId && isAdminOrAgent) {
      fetchMessages(); // initial fetch
      updateChatMessagesInterval = setChatUpdateIntervals();
    }

    return () => {
      clearInterval(updateChatMessagesInterval);
    };
  }, [isAdminOrAgent, userId]);

  let messages: ChatMessage[] = [];
  if (chatChannelType === ChatChannelType.CHAT_BOT_DEVICE) {
    messages = userId ? chatGPTDeviceMessages(userId) : [];
  } else if (chatChannelType === ChatChannelType.CHAT_BOT_MOBILE) {
    messages = userId ? chatGPTMobileMessages(userId) : [];
  } else if (chatChannelType === ChatChannelType.SLEEP_COACH) {
    messages = userId ? chatSleepCoachMessages(userId) : [];
  }

  const [sleepCoachMessageFormValue, setSleepCoachMessageFormValue] = useState<string>('');
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);

  const scrollRef = useRef<Nullable<any>>(null);
  const emojiPickerRef = useRef<Nullable<any>>(null);

  useEffect(() => {
    const handleOutsideEmojiPickerClick = (event) => {
      if (emojiPickerRef.current && emojiPickerRef.current && !emojiPickerRef.current?.contains(event.target)) {
        // Clicked outside of the EmojiPicker, close it
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideEmojiPickerClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideEmojiPickerClick);
    };
  }, []);

  useEffect(() => {
    if (scrollRef.current && messages.length > 0 && tabSelected) {
      scrollRef.current.scrollIntoView({ behavior: 'instant' });
    }
  }, [messages.length, tabSelected, scrollRef.current]);

  const onSubmitFormSleepCoachMessageCallback = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        await sendSleepCoachChatMessage(sleepCoachMessageFormValue);
        setSleepCoachMessageFormValue('');
        if (latestPersonMessage && !latestPersonMessage.messageReadTs && userId) {
          await changePersonMessageReadStatusCallback(userId, latestPersonMessage.id || '', true);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [sleepCoachMessageFormValue, latestPersonMessage]
  );

  const onChangeFormSleepCoachMessagesCallback = useCallback((event) => {
    setSleepCoachMessageFormValue(event.target.value);
  }, []);

  async function sendSleepCoachChatMessage(message: string) {
    const chatMessage: ChatMessage = {
      id: timestampAndRandomCombinedUUIDString(),
      personId: userId || '',
      organizationId: person ? person.organizationId : '',
      senderId: agentId,
      recipientId: userId || '',
      message,
      prevMessageId: messages.length > 0 ? messages[messages.length - 1].id : null,
      chatChannelType: ChatChannelType.SLEEP_COACH
    };
    try {
      await postChatSleepCoachMessage(chatMessage);
    } catch (err) {
      console.error(err);
      alert(`Fail to send message: ${message}`);
    }
  }

  const changePersonMessageReadStatusCallback = useCallback(async (personId: string, messageId: string, messageReadStatus: boolean) => {
    try {
      await saveChangeSleepCoachMessageReadStatus(personId, messageId, messageReadStatus);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handleKeyPressCallback = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>): KeyboardEventHandler<HTMLElement> | undefined => {
      if (event.key === 'Enter' && sleepCoachMessageFormValue && !event.shiftKey && !event.ctrlKey) {
        onSubmitFormSleepCoachMessageCallback(event);
      } else if (event.key === 'Enter' && (event.shiftKey || event.ctrlKey)) {
        setSleepCoachMessageFormValue(sleepCoachMessageFormValue + '\n');
        event.preventDefault();
      }
      return;
    },
    [onSubmitFormSleepCoachMessageCallback]
  );

  const handleEmojiSelectCallback = useCallback(
    (emojiObject) => {
      setSleepCoachMessageFormValue(sleepCoachMessageFormValue + emojiObject.emoji);
    },
    [sleepCoachMessageFormValue]
  );

  return (
    <ChatComponent
      messages={messages}
      showEmojiPicker={showEmojiPicker}
      handleShowEmojiPicker={() => setShowEmojiPicker(!showEmojiPicker)}
      handleEmojiSelect={handleEmojiSelectCallback}
      latestPersonMessage={latestPersonMessage}
      showAccessNotAllowed={isLoadingUserPermissions ? false : !isAdminOrAgent}
      userId={userId || ''}
      personById={personById}
      showSendMessageFooter={chatChannelType === ChatChannelType.SLEEP_COACH}
      showAgentName={chatChannelType === ChatChannelType.SLEEP_COACH}
      blueBackground={chatChannelType !== ChatChannelType.SLEEP_COACH}
      sleepCoachMessageFormValue={sleepCoachMessageFormValue}
      scrollRef={scrollRef}
      emojiPickerRef={emojiPickerRef}
      onChangeFormSleepCoachMessages={onChangeFormSleepCoachMessagesCallback}
      onSubmitFormSleepCoachMessage={onSubmitFormSleepCoachMessageCallback}
      changePersonMessageReadStatus={changePersonMessageReadStatusCallback}
      handleKeyPress={handleKeyPressCallback}
    />
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      agentId: getAuthPersonIdSelector(state),
      chatGPTDeviceMessages: (userId: string) => getChatGPTDeviceMessagesSelector(userId, state),
      chatGPTMobileMessages: (userId: string) => getChatGPTMobileMessagesSelector(userId, state),
      chatSleepCoachMessages: (userId: string) => getChatSleepCoachMessagesSelector(userId, state),
      isLoadingUserPermissions: isLoadingUserPermissionsSelector(state),
      getLatestPersonMessage: (userId: string) => getLatestPersonMessageSelector(userId, state),
      personById: getPersonByIdSelector(state),
      getPersonFromUserId: (userId: string) => getPersonFromPersonByIdSelector(state, userId),
      userPermissions: getUserPermissionsSelector(state)
    };
  },
  {
    fetchUserPermissions: getUserPermissionsThunk,
    fetchChatGPTDeviceMessages: getChatGPTDeviceMessagesThunk,
    fetchChatGPTMobileMessages: getChatGPTMobileMessagesThunk,
    fetchChatSleepCoachMessages: getChatSleepCoachMessagesThunk,
    postChatSleepCoachMessage: postChatSleepCoachMessageThunk,
    saveChangeSleepCoachMessageReadStatus: changeSleepCoachMessageReadStatusThunk
  }
);

export default compose(connectRedux)(Chat) as React.ComponentType<ChatProps>;
