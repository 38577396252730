import classNames from 'classnames';
import React from 'react';
import { Text } from 'components/Typography';
import style from './StatusLabel.scss';

type Props = {
  status: AVAILABLE_STATUS_LABELS;
  label?: string;
  containerClassName?: 'string';
};

export enum AVAILABLE_STATUS_LABELS {
  Error = 'error',
  Canceled = 'canceled',
  InProgress = 'inProgress',
  Waiting = 'waiting',
  Success = 'success',
  Delivered = 'delivered',
  Expired = 'expired'
}

const labelStyles = {
  [AVAILABLE_STATUS_LABELS.Waiting]: style.waiting,
  [AVAILABLE_STATUS_LABELS.InProgress]: style.inProgress,
  [AVAILABLE_STATUS_LABELS.Success]: style.success,
  [AVAILABLE_STATUS_LABELS.Canceled]: style.canceled,
  [AVAILABLE_STATUS_LABELS.Error]: style.error,
  [AVAILABLE_STATUS_LABELS.Delivered]: style.delivered,
  [AVAILABLE_STATUS_LABELS.Expired]: style.expired
};

export const StatusLabel: React.FC<Props> = ({ status, containerClassName, label = '' }) => {
  return (
    <div className={classNames(style.container, labelStyles[status], containerClassName)}>
      <Text variant="xs">{label}</Text>
    </div>
  );
};
