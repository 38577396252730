import React from 'react';
import { EmptyListArea } from 'components/EmptyListArea/EmptyListArea';
import { SparkleIcon } from 'components/Icons';

type Props = {
  onAddPress: () => void;
};

export const EmptyTasksListArea: React.FC<Props> = ({ onAddPress }) => (
  <EmptyListArea
    title="No tasks available yet"
    description="Once you create tasks, you’ll be able to track their progress, update details, and manage them all in one place."
    icon={SparkleIcon}
    buttonText="Add task"
    onAddPress={onAddPress}
  />
);
