import { List, ListItem, Paper, Typography } from '@mui/material';
import React from 'react';

const DeleteAccountDataList: React.FC = () => (
  <Paper elevation={1} sx={{ p: 2, my: 2, backgroundColor: '#f7f7f7' }}>
    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
      Upon confirmation, we will delete:
    </Typography>
    <List sx={{ padding: '5px 0 10px 0', '& .MuiListItem-root': { paddingTop: '2px', paddingBottom: '2px' } }}>
      <ListItem>Personal info (name, email, user ID)</ListItem>
      <ListItem>Health and fitness data</ListItem>
      <ListItem>Messages</ListItem>
      <ListItem>App activity and user-generated content</ListItem>
      <ListItem>Crash logs and diagnostics</ListItem>
      <ListItem>Device IDs</ListItem>
    </List>
    <Typography variant="body1">Time processing data deletion request: 30 days</Typography>
  </Paper>
);

export default DeleteAccountDataList;
