import classNames from 'classnames';
import React from 'react';
import { PolymorphicElement } from 'types/PolymorphicElement';
import style from './Text.scss';

export type TextElement = 'div' | 'span';
export type TextVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | '2xl' | 'xl' | 'lg' | 'nlg' | 'md' | 'sm' | 'xs';
export type TextWeight = 400 | 500 | 600 | 700;

export type TextProps<T extends TextElement> = PolymorphicElement<T> & {
  className?: string;
  variant?: TextVariant;
  weight?: TextWeight;
};

export const Text = <T extends TextElement = 'span'>(props: TextProps<T>) => {
  const { as, className, children, ...rest } = props;
  const variant = props.variant ?? '1';
  const weight = props.weight ?? 400;

  const Component = as ?? 'span';

  return (
    <Component
      className={classNames(
        style.text,
        variant === 'h1' && style.h1,
        variant === 'h2' && style.h2,
        variant === 'h3' && style.h3,
        variant === 'h4' && style.h4,
        variant === 'h5' && style.h5,
        variant === '2xl' && style.text2Xl,
        variant === 'xl' && style.textXl,
        variant === 'lg' && style.textLg,
        variant === 'nlg' && style.textNarrowLg,
        variant === 'md' && style.textMd,
        variant === 'sm' && style.textSm,
        variant === 'xs' && style.textXs,
        weight === 400 && style.weight400,
        weight === 500 && style.weight500,
        weight === 600 && style.weight600,
        weight === 700 && style.weight700,
        className
      )}
      {...rest}
    >
      {children}
    </Component>
  );
};
