import { Box, Button, MenuItem, TextField, Typography } from '@material-ui/core';
import { DateTime, Duration } from 'luxon';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Person } from '@redux/person/personTypes';
import { SleepSchedule } from '@redux/sleepschedule/sleepScheduleTypes';
import alertCircleIcon from '../../assets/images/alert-circle-outline.png';
import attentionOrangeIcon from '../../assets/images/attention-orange.png';
import infoIcon from '../../assets/images/information-circle-sharp.png';
import linkIcon from '../../assets/images/link-icon.png';
import lunaButtonDisabled from '../../assets/images/luna-button-disabled.png';
import lunaButtonEnabled from '../../assets/images/luna-button-enabled.png';
import pageDownDisabledIcon from '../../assets/images/page-down-disabled.png';
import pageDownIcon from '../../assets/images/page-down.png';
import pageUpDisabledIcon from '../../assets/images/page-up-disabled.png';
import pageUpIcon from '../../assets/images/page-up.png';
import sendOutlineIcon from '../../assets/images/send-outline.png';
import timeIconGreen from '../../assets/images/time-outline-green.png';
import { getTimesInFiveMinuteInterval, getTimeValueFromSelectField, timeAsTimeStr, tsAsDetailedDateTimeStr, tsAsTimeSinceUnit } from '../../utils/time';
import { goTo } from '../../utils/util';
import style from './SleepScheduleDetails.scss';

const LIGHT_GREY = '#F3F7FA';
const WHITE = 'white';

interface Props {
  sleepScheduleById: Record<string, SleepSchedule>;
  draftScheduleById?: Nullable<Record<string, SleepSchedule>>;
  newSleepScheduleId?: Nullable<string>;
  oldSleepScheduleId?: Nullable<string>;
  person?: Nullable<Person>;
  sleepLogs?: Nullable<JSX.Element>;
  surveys?: Nullable<JSX.Element>;
  putSleepScheduleEditable?: (sleepScheduleId: string) => Promise<any>;
  postSleepScheduleSend?: (sleepSchedule: SleepSchedule) => Promise<any>;
  updateSleepScheduleLunaSummary?: (SleepSchedule: SleepSchedule, updateWeeklySummary: boolean) => Promise<any>;
  currentIndex?: number;
  isLastItem?: boolean;
  viewMode?: Nullable<boolean>;
  creatorMode?: Nullable<boolean>;
  updateIndex?: (index: number) => void;
}

export const SleepScheduleDetails: React.FC<Props> = ({
  newSleepScheduleId,
  oldSleepScheduleId,
  sleepScheduleById,
  draftScheduleById,
  person,
  sleepLogs,
  surveys,
  putSleepScheduleEditable,
  postSleepScheduleSend,
  updateSleepScheduleLunaSummary,
  updateIndex,
  currentIndex,
  isLastItem,
  viewMode,
  creatorMode
}) => {
  const navigate = useNavigate();

  let newSleepSchedule =
    newSleepScheduleId && !creatorMode
      ? {
          ...sleepScheduleById[newSleepScheduleId]
        }
      : null;
  if (!newSleepSchedule) {
    newSleepSchedule = draftScheduleById && person ? { ...draftScheduleById[person.id] } : null;
  }
  const oldSleepSchedule = oldSleepScheduleId ? sleepScheduleById[oldSleepScheduleId] : null;

  const isInEditMode = !viewMode && !newSleepSchedule?.enabledTs;
  const timeIntervals = getTimesInFiveMinuteInterval(true);

  const [coverLunaSummaryNeedsChange, setCoverLunaSummaryNeedsChange] = useState<boolean>(false);
  const [weeklyLunaSummaryNeedsChanged, setWeeklyLunaSummaryNeedsChanged] = useState<boolean>(false);

  const [sleepTime, setSleepTime] = useState<string>(newSleepSchedule?.sleepTime || '');
  const [wakeTime, setWakeTime] = useState<string>(newSleepSchedule?.wakeTime || '');

  const [lunaCoverSummary, setLunaCoverSummary] = useState<string>(newSleepSchedule?.lunaSummary || '');
  const [weeklyLunaSummary, setWeeklyLunaSummary] = useState<string>(newSleepSchedule?.sleepScheduleCalculation?.lunaSummary || '');

  useEffect(() => {
    setCoverLunaSummaryNeedsChange(false);
    setWeeklyLunaSummaryNeedsChanged(false);
  }, [newSleepScheduleId, draftScheduleById]);

  useEffect(() => {
    let newSleepSchedule = newSleepScheduleId ? sleepScheduleById[newSleepScheduleId] : null;
    if (!newSleepSchedule && draftScheduleById && person && draftScheduleById[person.id]) {
      newSleepSchedule = draftScheduleById[person.id];
    }
    setSleepTime(newSleepSchedule?.sleepTime || '');
    setWakeTime(newSleepSchedule?.wakeTime || '');
    setLunaCoverSummary(newSleepSchedule?.lunaSummary || '');
    setWeeklyLunaSummary(newSleepSchedule?.sleepScheduleCalculation?.lunaSummary || '');
  }, [newSleepScheduleId, draftScheduleById]);

  const renderblockWithHeaderAndFooter = (
    header: Nullable<string>,
    header2: Nullable<string>,
    content: string,
    footer: Nullable<string>,
    bgColor: string,
    showSleepNeedsInfo: boolean = false
  ): JSX.Element => (
    <Box className={style.blockWithHeader}>
      {header && <Typography className={style.blockHeader}>{header}</Typography>}
      {showSleepNeedsInfo && (
        <Box className={style.sleepNeedsInfoBox}>
          <img src={infoIcon} className={style.infoIcon} />
          <Box className={style.hoverBox}>
            <Typography className={style.sleepNeedsInfoText}>{'≤9: no change or reduce TIB if SE <80%'}</Typography>
            <Typography className={style.sleepNeedsInfoText}>{'10-12: increase TIB by 15 min'}</Typography>
            <Typography className={style.sleepNeedsInfoText}>{'13+: increase TIB by 30 min'}</Typography>
            <Typography className={style.sleepNeedsInfoText}>{'YES: sleep needs submitted'}</Typography>
            <Typography className={style.sleepNeedsInfoText}>{'N/A: sleep needs not submitted'}</Typography>
          </Box>
        </Box>
      )}
      <Box className={style.blockContent} bgcolor={bgColor}>
        {header2 && <Typography className={style.blockHeaderInside}>{header2}</Typography>}
        <Typography>{content}</Typography>
      </Box>
      {footer && <Typography className={style.blockFooter}>{footer}</Typography>}
    </Box>
  );

  const renderSelectTimeBlock = (header: string, footer: string, value: string, onChange: (value: string) => void): JSX.Element => (
    <Box className={style.selectBlockWithHeader}>
      <Typography className={style.blockHeader}>{header}</Typography>
      <TextField
        className={viewMode ? style.blockTextInputWhite : style.blockTextInput}
        value={value}
        placeholder="-"
        variant="outlined"
        InputProps={{
          readOnly: !isInEditMode
        }}
        SelectProps={{
          readOnly: !isInEditMode
        }}
        select={isInEditMode}
        onChange={(e) => onChange(e.target.value)}
      >
        {timeIntervals.map((time) => {
          const textValue = time ? time : '-';
          return (
            <MenuItem key={textValue} value={textValue}>
              {textValue}
            </MenuItem>
          );
        })}
      </TextField>
      <Typography className={style.blockFooter}>{footer}</Typography>
    </Box>
  );

  let sendButtonNote;
  let sendButtonNoteIcon;
  let sendButtonNoteStyle = style.sendButtonNote;
  if (newSleepSchedule?.enabledTs) {
    sendButtonNote = `AUTO SENDS IN ${tsAsTimeSinceUnit(newSleepSchedule?.enabledTs)}`;
    sendButtonNoteIcon = timeIconGreen;
    sendButtonNoteStyle = style.sendButtonNote;
  } else if (newSleepSchedule?.created) {
    const created = DateTime.fromISO(newSleepSchedule?.created);
    const now = DateTime.local();
    const diff = now.diff(created, 'days').days;
    if (diff >= 1) {
      sendButtonNote = `IDLE FOR ${Math.floor(diff)} DAYS`;
      sendButtonNoteIcon = alertCircleIcon;
      sendButtonNoteStyle = style.sendButtonNoteOrange;
    }
  }

  const onFieldChange = (value: string, fieldName: string, isTimeChange: boolean) => {
    const newValue = isTimeChange ? getTimeValueFromSelectField(value) : value;
    if (newSleepSchedule && newValue !== newSleepSchedule[fieldName]) {
      newSleepSchedule[fieldName] = newValue;
      if (fieldName === 'sleepTime') {
        setSleepTime(newValue);
      } else if (fieldName === 'wakeTime') {
        setWakeTime(newValue);
      }
      newSleepSchedule.hasChanged = true;
      newSleepSchedule = { ...newSleepSchedule };
      setCoverLunaSummaryNeedsChange(true);
      setWeeklyLunaSummaryNeedsChanged(true);
    }
  };

  const onLunaButtonClick = (updateWeeklySummary: boolean) => {
    if (isInEditMode && updateSleepScheduleLunaSummary && newSleepSchedule) {
      if (updateWeeklySummary) {
        setWeeklyLunaSummaryNeedsChanged(false);
      } else {
        setCoverLunaSummaryNeedsChange(false);
      }
      updateSleepScheduleLunaSummary(newSleepSchedule, updateWeeklySummary);
    }
  };

  const sendSleepSchedule = () => {
    if (postSleepScheduleSend && newSleepSchedule && confirm("Are you sure you want to send this sleep schedule? It can't be changed afterwards.") === true) {
      newSleepSchedule.hasChanged = false;

      newSleepSchedule.sleepTime = sleepTime;
      newSleepSchedule.wakeTime = wakeTime;
      newSleepSchedule.lunaSummary = lunaCoverSummary;
      if (newSleepSchedule.sleepScheduleCalculation) {
        newSleepSchedule.sleepScheduleCalculation = {
          ...newSleepSchedule.sleepScheduleCalculation,
          lunaSummary: weeklyLunaSummary
        };
      }

      postSleepScheduleSend(newSleepSchedule);
    }
  };

  const onPageButtonClick = (increment: boolean) => {
    if (!updateIndex) {
      return;
    }
    let changeIndex = false;
    if (increment && !isLastItem && (currentIndex || currentIndex === 0)) {
      updateIndex(currentIndex + 1);
      changeIndex = true;
    } else if (!increment && currentIndex && currentIndex > 0) {
      updateIndex(currentIndex - 1);
      changeIndex = true;
    }
    if (changeIndex) {
      setWeeklyLunaSummaryNeedsChanged(false);
      setCoverLunaSummaryNeedsChange(false);
    }
  };

  let containerClass = style.container;
  if (viewMode) {
    containerClass = style.containerWithBackground;
  } else if (creatorMode) {
    containerClass = style.containerCreator;
  }

  const inputBackground = viewMode ? WHITE : LIGHT_GREY;
  const rowTextInputClass = viewMode ? style.rowTextInputWhite : style.rowTextInput;

  return (
    <Box className={containerClass}>
      {!viewMode && !creatorMode && (
        <Box className={style.navigationRow}>
          <img src={currentIndex && currentIndex > 0 ? pageUpIcon : pageUpDisabledIcon} className={style.pageUpIcon} onClick={onPageButtonClick.bind(this, false)} />
          <img src={isLastItem ? pageDownDisabledIcon : pageDownIcon} className={style.pageDownIcon} onClick={onPageButtonClick.bind(this, true)} />
        </Box>
      )}
      <Box className={!creatorMode ? style.headerRow : style.headerRowRight}>
        <Fragment>
          {!viewMode && !creatorMode && (
            <Box className={style.headerNameBox} onClick={goTo(`/user/${person?.id}`, navigate)}>
              <Typography className={style.header}>{person?.givenNames + ' ' + person?.familyName}</Typography>
              <img src={linkIcon} className={style.linkIcon} />
            </Box>
          )}

          {!viewMode && (
            <Box className={style.buttonRow}>
              {newSleepSchedule?.enabledTs && (
                <Button
                  variant="outlined"
                  className={style.editButton}
                  onClick={() => (putSleepScheduleEditable && newSleepSchedule ? putSleepScheduleEditable(newSleepSchedule.id) : null)}
                >
                  EDIT
                </Button>
              )}
              {newSleepSchedule?.id && (
                <Box className={style.sendButtonBox}>
                  <Button
                    variant="contained"
                    className={coverLunaSummaryNeedsChange || weeklyLunaSummaryNeedsChanged ? style.sendButtonOrange : style.sendButton}
                    onClick={() => sendSleepSchedule()}
                  >
                    SEND NOW
                  </Button>
                  <Box className={style.sendButtonNoteBox}>
                    {sendButtonNoteIcon && <img src={sendButtonNoteIcon} className={style.sendButtonNoteIcon} />}
                    {sendButtonNote && <Typography className={sendButtonNoteStyle}>{sendButtonNote}</Typography>}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Fragment>

        {viewMode && newSleepSchedule && (
          <Box className={style.assignedInfoBox}>
            <img src={sendOutlineIcon} className={style.assignedIcon} />
            <Typography className={style.assignedInfoText}>{`Sleep Schedule Assigned on ${tsAsDetailedDateTimeStr(newSleepSchedule?.startDate)}`}</Typography>
          </Box>
        )}
      </Box>

      <Box className={style.row}>
        <Box className={style.rowContent}>
          {renderSelectTimeBlock('New Bedtime', `${timeAsTimeStr(newSleepSchedule?.sleepTime)} recommended`, timeAsTimeStr(sleepTime), (value: string) =>
            onFieldChange(value, 'sleepTime', true)
          )}
          {renderSelectTimeBlock('New Waketime', `${timeAsTimeStr(newSleepSchedule?.wakeTime)} recommended`, timeAsTimeStr(wakeTime), (value: string) =>
            onFieldChange(value, 'wakeTime', true)
          )}
        </Box>
      </Box>
      {!viewMode && (
        <Box className={style.row}>
          <Typography className={style.rowHeader}>Sleep Schedule Metrics</Typography>
          <Box className={style.rowContent}>
            {renderblockWithHeaderAndFooter(null, 'CURRENT BEDTIME', timeAsTimeStr(oldSleepSchedule?.sleepTime) || 'N/A', null, inputBackground)}
            {renderblockWithHeaderAndFooter(null, 'CURRENT WAKETIME', timeAsTimeStr(oldSleepSchedule?.wakeTime) || 'N/A', null, inputBackground)}
            {renderblockWithHeaderAndFooter(
              null,
              'SE',
              `${
                oldSleepSchedule?.sleepScheduleCalculation?.avgSleepEfficiency || oldSleepSchedule?.sleepScheduleCalculation?.avgSleepEfficiency === 0
                  ? oldSleepSchedule?.sleepScheduleCalculation?.avgSleepEfficiency + '%'
                  : 'N/A'
              }`,
              null,
              inputBackground
            )}
            {renderblockWithHeaderAndFooter(
              null,
              'SLEEP NEEDS',
              `${
                oldSleepSchedule?.sleepScheduleCalculation?.surveyScore || oldSleepSchedule?.sleepScheduleCalculation?.surveyScore === 0
                  ? oldSleepSchedule?.sleepScheduleCalculation?.surveyScore
                  : 'N/A'
              }`,
              null,
              inputBackground,
              true
            )}
          </Box>
        </Box>
      )}
      <Box className={style.row}>
        <Box className={style.iconRowWrapper}>
          <Typography className={coverLunaSummaryNeedsChange ? style.rowHeaderOrange : style.rowHeader}>Sleep Schedule Cover</Typography>
          {coverLunaSummaryNeedsChange && (
            <Box className={style.alertIconBox}>
              <img src={attentionOrangeIcon} className={style.attentionIcon} />
              <Typography className={style.hoverBox}>Check that message makes sense with updated Sleep Schedule time(s).</Typography>
            </Box>
          )}
        </Box>

        <Box className={style.rowContent}>
          <TextField
            className={!coverLunaSummaryNeedsChange ? rowTextInputClass : style.rowTextInputChanged}
            variant="outlined"
            value={lunaCoverSummary}
            fullWidth={true}
            InputProps={{
              readOnly: !isInEditMode
            }}
            onChange={(e) => {
              setCoverLunaSummaryNeedsChange(false);
              setLunaCoverSummary(e.target.value);
            }}
          />
          <img src={isInEditMode ? lunaButtonEnabled : lunaButtonDisabled} className={style.lunaImage} onClick={() => onLunaButtonClick(false)} />
        </Box>
      </Box>
      <Box className={style.row}>
        <Typography className={style.rowHeader}>Sleep Schedule Adjustment Tip</Typography>
        <Box className={style.rowContent}>
          <TextField
            className={viewMode ? style.rowTextInputMarginWhite : style.rowTextInputMargin}
            value={newSleepSchedule?.description}
            variant="outlined"
            fullWidth={true}
            InputProps={{
              readOnly: !isInEditMode
            }}
          />
        </Box>
      </Box>
      {newSleepSchedule?.sleepScheduleCalculation && (
        <Box className={style.row}>
          <Box className={style.iconRowWrapper}>
            <Typography className={weeklyLunaSummaryNeedsChanged ? style.rowHeaderOrange : style.rowHeader}>Weekly Summary Tip</Typography>
            {weeklyLunaSummaryNeedsChanged && (
              <Box className={style.alertIconBox}>
                <img src={attentionOrangeIcon} className={style.attentionIcon} />
                <Typography className={style.hoverBox}>Check that message makes sense with updated Sleep Schedule time(s).</Typography>
              </Box>
            )}
          </Box>
          <Box className={style.rowContent}>
            <TextField
              className={!weeklyLunaSummaryNeedsChanged ? rowTextInputClass : style.rowTextInputChanged}
              variant="outlined"
              fullWidth={true}
              value={weeklyLunaSummary}
              InputProps={{
                readOnly: !isInEditMode
              }}
              onChange={(e) => {
                setWeeklyLunaSummaryNeedsChanged(false);
                setWeeklyLunaSummary(e.target.value);
              }}
            />
            <img src={isInEditMode ? lunaButtonEnabled : lunaButtonDisabled} className={style.lunaImage} onClick={() => onLunaButtonClick(true)} />
          </Box>
        </Box>
      )}
      <Box className={style.row}>
        <Typography className={style.rowHeader}>Additional Sleep Metrics</Typography>
        <Box className={style.rowContent}>
          {renderblockWithHeaderAndFooter(
            null,
            '*TST',
            newSleepSchedule?.sleepScheduleCalculation?.avgTotalSleepMs
              ? Duration.fromMillis(newSleepSchedule?.sleepScheduleCalculation?.avgTotalSleepMs).toFormat("h 'hrs' mm 'min'")
              : 'N/A',
            null,
            inputBackground
          )}
          {renderblockWithHeaderAndFooter(
            null,
            '*TIB',
            newSleepSchedule?.sleepScheduleCalculation?.avgInBedMs
              ? Duration.fromMillis(newSleepSchedule?.sleepScheduleCalculation?.avgInBedMs).toFormat("h 'hrs' mm 'min'")
              : 'N/A',
            null,
            inputBackground
          )}
          {renderblockWithHeaderAndFooter(
            null,
            '*TIME TO FALL ASLEEP',
            newSleepSchedule?.sleepScheduleCalculation?.avgFallAsleepMs
              ? Duration.fromMillis(newSleepSchedule?.sleepScheduleCalculation?.avgFallAsleepMs).toFormat("mm 'min'")
              : 'N/A',
            null,
            inputBackground
          )}
          {renderblockWithHeaderAndFooter(
            null,
            '*NWAK',
            newSleepSchedule?.sleepScheduleCalculation?.avgWakeUpCount || newSleepSchedule?.sleepScheduleCalculation?.avgWakeUpCount === 0
              ? `${newSleepSchedule?.sleepScheduleCalculation?.avgWakeUpCount}`
              : 'N/A',
            null,
            inputBackground
          )}
          {renderblockWithHeaderAndFooter(
            null,
            '*WASO',
            newSleepSchedule?.sleepScheduleCalculation?.avgWakeUpDurationMs || newSleepSchedule?.sleepScheduleCalculation?.avgWakeUpDurationMs === 0
              ? Duration.fromMillis(newSleepSchedule?.sleepScheduleCalculation?.avgWakeUpDurationMs).toFormat("mm 'min'")
              : 'N/A',
            null,
            inputBackground
          )}
        </Box>
      </Box>
      <Box className={style.row}>
        <Box className={style.rowContent}>
          {renderblockWithHeaderAndFooter(
            null,
            '*IN BED',
            newSleepSchedule?.sleepScheduleCalculation?.avgBedTime ? `${timeAsTimeStr(newSleepSchedule?.sleepScheduleCalculation?.avgBedTime)}` : 'N/A',
            null,
            inputBackground
          )}
          {renderblockWithHeaderAndFooter(
            null,
            '*TRIED TO SLEEP',
            newSleepSchedule?.sleepScheduleCalculation?.avgTryToSleepTime ? `${timeAsTimeStr(newSleepSchedule?.sleepScheduleCalculation?.avgTryToSleepTime)}` : 'N/A',
            null,
            inputBackground
          )}
          {renderblockWithHeaderAndFooter(
            null,
            '*WAKE UP',
            newSleepSchedule?.sleepScheduleCalculation?.avgWakeTime ? `${timeAsTimeStr(newSleepSchedule?.sleepScheduleCalculation?.avgWakeTime)}` : 'N/A',
            null,
            inputBackground
          )}
          {renderblockWithHeaderAndFooter(
            null,
            '*OUT OF BED',
            newSleepSchedule?.sleepScheduleCalculation?.avgOutOfBedTime ? `${timeAsTimeStr(newSleepSchedule?.sleepScheduleCalculation?.avgOutOfBedTime)}` : 'N/A',
            null,
            inputBackground
          )}
          {renderblockWithHeaderAndFooter(
            null,
            'SLEEP NEEDS',
            `${
              newSleepSchedule?.sleepScheduleCalculation?.surveyScore || newSleepSchedule?.sleepScheduleCalculation?.surveyScore === 0
                ? newSleepSchedule?.sleepScheduleCalculation?.surveyScore
                : 'N/A'
            }`,
            null,
            inputBackground,
            true
          )}
          <Typography className={style.blockFooterAbsolute}>* Average based on the last 7 days</Typography>
        </Box>
      </Box>
      {sleepLogs && (
        <Box className={style.row}>
          <Typography className={style.rowHeaderSleepLogs}>Sleep Logs:</Typography>
          <Box className={style.rowContent}>{sleepLogs}</Box>
        </Box>
      )}
      {surveys && (
        <Box className={style.row}>
          <Typography className={style.rowHeaderSleepLogs}>Sleep Need Surveys:</Typography>
          <Box className={style.rowContent}>{surveys}</Box>
        </Box>
      )}
    </Box>
  );
};
