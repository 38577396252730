import { createReducer } from '@reduxjs/toolkit';
import { getActionStringWithoutState } from '@redux/reduxUtils';
import { getLoginActions } from '../oauth/oauthActions';
import { addNewOrganizationAction, getAllOrganizationsAction, getOrganizationByIdAction, updateOrganizationAction } from './organizationActions';
import { OrganizationState } from './organizationTypes';

export const initialOrganizationState: OrganizationState = {
  organizationById: {},
  isLoading: {},
  error: {},
  success: {}
};

export default createReducer(initialOrganizationState, (builder) => {
  builder.addCase(addNewOrganizationAction.start, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = true;
    delete state.error[tag];
  });
  builder.addCase(addNewOrganizationAction.success, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = false;
    delete state.error[tag];
    state.success[tag] = true;
    if (action.payload?.id) {
      state.organizationById[action.payload.id] = action.payload;
    }
  });
  builder.addCase(addNewOrganizationAction.fail, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = false;
    state.error[tag] = {
      message: action.payload?.message,
      status: action.payload?.status
    };
  });
  builder.addCase(updateOrganizationAction.start, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = true;
    delete state.error[tag];
  });
  builder.addCase(updateOrganizationAction.success, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = false;
    delete state.error[tag];
    state.success[tag] = true;
    if (action.payload?.id) {
      state.organizationById[action.payload.id] = action.payload;
    }
  });
  builder.addCase(updateOrganizationAction.fail, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = false;
    state.error[tag] = {
      message: action.payload?.message,
      status: action.payload?.status
    };
  });
  builder.addCase(getOrganizationByIdAction.start, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = true;
    delete state.error[tag];
  });
  builder.addCase(getOrganizationByIdAction.success, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = false;
    delete state.error[tag];
    state.success[tag] = true;
    if (action.payload?.id) {
      state.organizationById[action.payload.id] = action.payload;
    }
  });
  builder.addCase(getOrganizationByIdAction.fail, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = false;
    state.error[tag] = {
      message: action.payload?.message,
      status: action.payload?.status
    };
  });
  builder.addCase(getAllOrganizationsAction.start, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = true;
    delete state.error[tag];
  });
  builder.addCase(getAllOrganizationsAction.success, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = false;
    delete state.error[tag];
    state.success[tag] = true;
    action.payload?.forEach((organization) => {
      state.organizationById[organization.id] = organization;
    });
  });
  builder.addCase(getAllOrganizationsAction.fail, (state, action) => {
    const tag = getActionStringWithoutState(action.type);
    state.isLoading[tag] = false;
    state.error[tag] = {
      message: action.payload?.message,
      status: action.payload?.status
    };
  });
  builder.addCase(getLoginActions.success, (state, action) => {
    if (action.payload.organization) {
      state.organizationById[action.payload.organization.id] = action.payload.organization;
    }
  });
});
