import { PersonVitals } from 'redux/person/personTypes';

export enum PatientTableColName {
  NAME = 'name',
  HEART_RATE = 'heart rate',
  BREATHING_RATE = 'breathing rate',
  SLEEP_DURATION = 'sleep duration',
  SLEEP_EFFICIENCY = 'sleep efficiency',
  PERSON_PRESENCE = 'patient presence',
  DEVICE_NUMBER = 'device number',
  DEVICE_STATUS = 'device status'
}

export type PatientTableColDataRangeType = {
  min: number;
  max: number;
};

export type PatientTableData = PersonVitals & {
  name: string;
  avatar?: string;
  deviceNumber?: number;
};

export interface PatientTableProps extends React.ComponentPropsWithoutRef<'table'> {
  patientTableData: PatientTableData[];
  onPressTableRow: (personId: string) => void;
}
