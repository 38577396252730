import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getClearLoginResponseAction } from '@redux/oauth/oauthActions';
import { getOauthErrorStatusSelector, isOauthLoadingSelector, userHasAccessTokenSelector } from '@redux/oauth/oauthSelectors';
import { ReduxState } from '@redux/types';
import envelopeSimple from 'assets/images/envelope-simple.png';
import { CAIRNS_LOGO_WHITE_IMAGE_URL } from 'assets/images/images';
import { StatusMessage } from 'components/StatusMessage/StatusMessage';
import { Text } from 'components/Typography';
import Login from './Login';
import style from './Login.scss';

export enum Tabs {
  LOGIN = 'login',
  SIGNUP = 'signup'
}

interface Props {
  isLoading: boolean;
  errorStatus?: Nullable<number>;
  isLoggedIn: boolean;
  login: Function;
  errorMessage: any;
  clearLoginResponse: () => void;
}

const LoginWrapper: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <div className={style.top_container}>
        <div className={style.left_container}>
          <div className={style.login_container}>
            <Login {...props}></Login>
          </div>
          <div className={style.bottom_container}>
            <Text variant="sm" className={style.footer_label}>
              © Cairns Health 2025
            </Text>
            <div className={style.envelope_container}>
              <img src={envelopeSimple} className={style.envelope_img} alt="Email" />
              <a href="mailto:support@cairns.ai" className={style.mail_to}>
                support@cairns.ai
              </a>
            </div>
          </div>
        </div>
        <div className={style.right_container}>
          <div className={style.logo_container}>
            <img className={style.titleBox} src={CAIRNS_LOGO_WHITE_IMAGE_URL} alt="Cairns Health" />
          </div>
          <div className={style.health_container}>
            <Text variant="h3" className={style.white_text}>
              Your Voice.
            </Text>
            <Text variant="h3" className={style.white_text}>
              Your Health.
            </Text>
            <Text variant="h3" className={style.white_text}>
              We Care.
            </Text>
          </div>
        </div>
      </div>
      <StatusMessage
        saveUnsuccessful={!props.isLoading && !!props.errorStatus}
        clearResponseStatus={props.clearLoginResponse}
        errorMessage={props.errorStatus === 401 ? 'Username or password not found' : 'Something went wrong'}
      />
    </React.Fragment>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      isLoading: isOauthLoadingSelector(state),
      isLoggedIn: userHasAccessTokenSelector(state),
      errorStatus: getOauthErrorStatusSelector(state)
    };
  },
  (dispatch: Function) => ({
    clearLoginResponse: () => {
      dispatch(getClearLoginResponseAction());
    }
  })
);

export default compose(connectRedux)(LoginWrapper);
