export interface Error {
  readonly message: string;
  readonly status: number;
}

export interface ErrorPayload {
  name: string;
  message: string;
  status: number;
}

export interface SelectOption {
  value: any;
  label: string;
}

export interface BreadCrumbData {
  title: string;
  route: string;
  url: string;
  type: BreadCrumbType;
  valueOptions?: Nullable<SelectOption[]>;
  selectedIndex?: Nullable<number>;
}

export enum Flag {
  ENABLED = '\u2705 Enabled',
  DISABLED = '\u274C Disabled'
}

export enum BreadCrumbType {
  HOME,
  SIMPLE,
  SELECT,
  LINK,
  TEXT,
  USER_PROFILE
}

export const flagOptions: Flag[] = Object.values(Flag);
