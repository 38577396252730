import { Error } from '../common/types';
import { PoiType } from '../device/deviceTypes';

export interface ActivityState {
  isLoading: Record<string, boolean>;
  error: Record<string, Error>;
  readonly success: Record<string, boolean>;
  tracks: Array<any>;
  supertrack: Array<any>;
  heatMap: Record<string, any>;
  falls: Record<string, any>;
  pois: Array<any>;
  voiceMessageStatus: string;
  sleepLogs?: Nullable<DailySleepLog[]>;
  sleepLogsByPersonId: Record<string, DailySleepLog[]>;
  kokoMapSleepStageEvents?: Nullable<SleepStageEvent[]>;
  kokoMapPoiChangeEvents?: Nullable<PoiChangeEvent[]>;
  kokoMapActivityEvents?: Nullable<ActivityEvent[]>;
}

export enum ActivityLoadingTags {
  GET_ACTIVITY_EVENTS_RANGE = 'GET_ACTIVITY_EVENTS_RANGE',
  GET_POI_CHANGE_EVENTS = 'GET_POI_CHANGE_EVENTS',
  GET_SLEEP_STAGES = 'GET_SLEEP_STAGES'
}

export interface ActivityEvent {
  id: string;
  organizationId: string;
  groupId?: Nullable<string>;
  activityType: ActivityType;
  sensorId?: Nullable<string>;
  personId?: Nullable<string>;
  startDate?: Nullable<string>;
  endDate: string;
  timeZoneId: string;
  playlistId?: Nullable<string>;
  deviceContentId?: Nullable<string>;
  boolValue?: Nullable<boolean>;
  intValue?: Nullable<number>;
  longValue?: Nullable<number>;
  uuidValue?: Nullable<string>;
  textValue?: Nullable<string>;
  jsonValue?: Nullable<string>;
  status?: Nullable<ActivityEventStatus>;
  created: string;
  modifiedDate: string;
}

export interface DailySleepLog {
  timeZone: string;
  bedTime: string;
  tryToSleepTime?: Nullable<string>;
  sleepTime?: Nullable<string>;
  wakeTime?: Nullable<string>;
  outOfBedTime?: Nullable<string>;
  nightWakeupCount?: Nullable<number>;
  nightAwakeDuration?: Nullable<number>;
  awake20Min?: Nullable<number>;
  bedResetFollowed?: Nullable<number>;
  bedResetHeard?: Nullable<number>;
  sleepQuality?: Nullable<SleepQualityType>;
  tags?: Nullable<string[]>;
  comments?: Nullable<string>;
  latencyToSleepMs?: Nullable<number>;
  inBedMs?: Nullable<number>;
  totalSleepMs?: Nullable<number>;
  sleepEfficiency?: Nullable<number>;
}

export enum SleepQualityType {
  UNKNOWN = 'UNKNOWN',
  VERY_POOR = 'VERY_POOR',
  POOR = 'POOR',
  FAIR = 'FAIR',
  GOOD = 'GOOD',
  VERY_GOOD = 'VERY_GOOD'
}

export enum ActivityEventStatus {
  UNKNOWN = 'UNKNOWN'
}

export enum ActivityType {
  UNKNOWN = 'UNKNOWN',
  WIND_DOWN_COMPLETED = 'WIND_DOWN_COMPLETED',
  WIND_DOWN_CANCELED = 'WIND_DOWN_CANCELED',
  SLEEP_SOUND_ENDED = 'SLEEP_SOUND_ENDED',
  BED_DEPARTURE_REQUEST_COMPLETED = 'BED_DEPARTURE_REQUEST_COMPLETED',
  BED_DEPARTURE_REQUEST_IGNORED = 'BED_DEPARTURE_REQUEST_IGNORED',
  WAKE_ALARM_TOGGLED_NEXT_TIME = 'WAKE_ALARM_TOGGLED_NEXT_TIME',
  SLEEP_LOG_BED_TIME = 'SLEEP_LOG_BED_TIME',
  SLEEP_LOG_TRY_TO_SLEEP_TIME = 'SLEEP_LOG_TRY_TO_SLEEP_TIME',
  SLEEP_LOG_SLEEP_TIME = 'SLEEP_LOG_SLEEP_TIME',
  SLEEP_LOG_WAKE_TIME = 'SLEEP_LOG_WAKE_TIME',
  SLEEP_LOG_AWAKE_20_MIN = 'SLEEP_LOG_AWAKE_20_MIN',
  SLEEP_LOG_BED_RESET_HEARD = 'SLEEP_LOG_BED_RESET_HEARD',
  SLEEP_LOG_BED_RESET_FOLLOWED = 'SLEEP_LOG_BED_RESET_FOLLOWED',
  SLEEP_LOG_OUT_OF_BED_TIME = 'SLEEP_LOG_OUT_OF_BED_TIME',
  SLEEP_LOG_NIGHT_WAKE_UP_COUNT = 'SLEEP_LOG_NIGHT_WAKE_UP_COUNT',
  SLEEP_LOG_NIGHT_AWAKE_DURATION = 'SLEEP_LOG_NIGHT_AWAKE_DURATION',
  SLEEP_LOG_SLEEP_QUALITY = 'SLEEP_LOG_SLEEP_QUALITY',
  SLEEP_LOG_TAGS = 'SLEEP_LOG_TAGS',
  SLEEP_LOG_COMMENTS = 'SLEEP_LOG_COMMENTS'
}

export enum SleepStage {
  UNKNOWN = 'UNKNOWN',
  NONE = 'NONE',
  AWAKE = 'AWAKE',
  ASLEEP = 'ASLEEP'
}

export interface PoiChangeEvent {
  organizationId: string;
  newPoiType?: Nullable<PoiType>;
  newPoiTs: string;
  oldPoiType?: Nullable<PoiType>;
  oldPoiTs?: Nullable<string>;
  confidence?: Nullable<number>;
  created?: Nullable<string>;
}

export interface SleepStageEvent {
  organizationId: string;
  newSleepStage?: Nullable<SleepStage>;
  newSleepStageTs: string;
  oldSleepStage?: Nullable<SleepStage>;
  oldSleepStageTs?: Nullable<string>;
  confidence?: Nullable<number>;
  created?: Nullable<string>;
}
